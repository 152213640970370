import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Style from "./AccountSetup2.module.css";
import WorkingHours from "./WorkingHours";
import TagsInput from "src/components/apps/tags/TagsInput";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import BranchDetailForm from "./BranchDetailForm";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";
import ErrorMsg from "src/components/custom/ErrorMsg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AgencyWebsite from "./AgencyWebsiteForm";
import TextInput from "src/components/forms/TextInput";

import useProperty from "src/components/apps/property/hooks/useAddproperty";


const BranchDetailCard = ({
  index,
  totalCount,
  handleNext,
  isDisabled,
  handleBranchSubmit,
  currentBranch,
  handleAgencyData,
  agency,
  branch,
  handleOpenBranch,
  singleBranch,
  handleBranchList,
  getBranch,
  isEdit,
  // handleWebsite
  settings,
  
}) => {

  const {setPlacesData,placesData,fetchPlacesList}= useProperty();

  const navigate = useNavigate();
  
  const isBranchInAgency = index < (agency?.branches?.length || 0);

  const [isLoading, setIsLoading] = useState(false);
  // const [collapsed, setCollapsed] = useState(isBranchInAgency ? true : false);
  const [collapsed, setCollapsed] = useState([]);

  const [website, setWebsite] = useState("");
  const [isError, setIsError] = useState(false);

  const checkOverlappingDays = (workingHours) => {
    const daysMap = {
      "everyday": ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
      "mon-fri": ["monday", "tuesday", "wednesday", "thursday", "friday"],
      "sat-sun": ["saturday", "sunday"]
    };

    const selectedDays = new Set();

    for (const hours of workingHours) {
      const { day } = hours;
      if (day in daysMap) {
        for (const mappedDay of daysMap[day]) {
          if (selectedDays.has(mappedDay)) {
            return false; // Overlapping day found
          }
          selectedDays.add(mappedDay);
        }
      } else {
        if (selectedDays.has(day)) {
          return false; // Overlapping day found
        }
        selectedDays.add(day);
      }
    }

    return true;
  };

  const initialValues = {
    companyWebsite: agency?.companyUrl,
    branches: agency?.branches?.length > 0 ? agency?.branches?.map(branch => ({
      _id: branch?._id,
      branchName: branch?.branchName || "",
      branchWebsite: branch?.branchWebsite || "",
      dealsWith: branch?.dealsWith ? (branch?.dealsWith.length > 1 ? "both" : branch?.dealsWith[0]) : "residential",
      typeOfAgency: branch?.typeOfAgency ? (branch?.typeOfAgency.length > 1 ? "both" : branch?.typeOfAgency[0]) : "both",
      displayLoc: branch?.servingLocation?.displayLoc || [],
      location:branch?.location,
      workingHours: branch?.workingHours || [{ day: "", opening: "", closing: "" }]
    })) : branch?.map(() => ({
      branchName: "",
      branchWebsite: "",
      dealsWith: "residential",
      typeOfAgency: "both",
      displayLoc: [],
      location:{displayAddress:""},
      workingHours: [{ day: "", opening: "", closing: "" }]
    }))
  };


  const validationSchema = Yup.object().shape({
    companyWebsite: Yup.string()
      .test("is-valid-url", "Invalid URL format.", (value) => {
        if (!value) return false; // If value is empty, fail validation

        // Check if URL has www or not
        if (!/^(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[a-z]{2,})(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?$/i.test(value)) {
          return false;
        }
        return true; // Passes all validations
      })
      .required("Agency website is required."),
    branches: Yup.array().of(
      Yup.object().shape({
        branchName: Yup.string()
          .required("Branch name is required.")
          .test("not-start-with-space", "Branch name cannot start with a space.", (value) => !value.startsWith(" ")),
        branchWebsite: Yup.string().test("is-valid-url", "Invalid URL format.", (value) => {
          if (!value) return true;
          return /^(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[a-z]{2,})(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?$/i.test(value);
        }),
        dealsWith: Yup.string().required("Deals with is required."),
        typeOfAgency: Yup.string().required("Type of agency is required."),
        location: Yup.object().shape({
          displayAddress: Yup.string().required("Branch address is required.")
        }),
        workingHours: Yup.array().of(
          Yup.object().shape({
            day: Yup.string().required("Day is required."),
            opening: Yup.string()
              .nullable()
              .test("is-valid-opening", "Opening time is required if closing time is provided.", function (value) {
                const { closing } = this.parent;
                if (closing && !value) return false;
                return true;
              }),
            closing: Yup.string()
              .nullable()
              .test("is-after-opening", "Closing time must be after opening time.", function (value) {
                const { opening } = this.parent;
                if (!opening || !value) return true;
                return moment(value, "HH:mm").isAfter(moment(opening, "HH:mm"));
              })
              .test("is-valid-closing", "Closing time is required if opening time is provided.", function (value) {
                const { opening } = this.parent;
                if (opening && !value) return false;
                return true;
              }),
          })
        ).min(1, "At least one working hours entry is required.")
          .test("no-overlapping-days", "There are overlapping days in the working hours.", (workingHours) => checkOverlappingDays(workingHours)),
        displayLoc: Yup.array().min(1, "At least one postal code is required."),
      })
    ).required("At least one branch is required.")
  });

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {

      if (isEdit) {
        navigate("/users?branch=created")
      }

      let response = await Axios.post(`/AccountSetup/${agency?.branches?.length > 0 ? "update-branch-new" : "create-branch-new"}`, { ...values,...(settings && {settings}) });


      if(settings){
        Toast(`Branch ${agency?.branches?.length > 0?'updated successfully' : "created successfully"} `, "success");
      }
      handleAgencyData(response?.data?.data);
      setIsLoading(false);
      
      if(settings !==true){
        handleNext();
      }
      
    
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };


  const handleTagsChange = async (tags, setFieldValue, values, branchIndex, type) => {
    try {
      const newEntries = tags.map(tag => ({
        locValue: tag, locType: type,
        displayPostCode: type === 'zip' ? [tag] : [],
      }));

      let responseData = await Axios.post('/postCode/validate', {
        postCode: newEntries[newEntries.length - 1]?.locValue,
        isPostCode: newEntries[newEntries.length - 1]?.locType === "zip"
      });

      newEntries[newEntries.length - 1].displayPostCode = responseData?.data?.postCodes;

      if (responseData?.data?.postCodes?.length > 0) {
        const updatedDisplayLoc = [
          ...values.branches[branchIndex].displayLoc.filter(loc => loc.locType !== type),
          ...newEntries
        ];

        setFieldValue(`branches[${branchIndex}].displayLoc`, updatedDisplayLoc);
      } else {
        Toast(`This ${type === 'zip' ? "post code" : "location name"} is not valid, please enter again.`, "error");
      }
    } catch (error) {
      Toast();
    }
  };

  const handleDelete = (item, branchIndex, type, setFieldValue, values) => {
    const updatedDisplayLoc = values.branches[branchIndex].displayLoc.filter(
      loc => !(loc.locValue === item && loc.locType === type)
    );

    setFieldValue(`branches[${branchIndex}].displayLoc`, updatedDisplayLoc);
  };
  // Helper function to check if any working hours entry has empty fields
  const hasIncompleteWorkingHours = (workingHours) => {
    return workingHours?.every(({ day, opening, closing }) => day && opening && closing);
  };

  const handleCurrentBranch = () => {
    handleOpenBranch(index);
  }

  const toggleSection = (section, index) => {
    setCollapsed(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
 

  return (

    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => (
        <Form>
          <div
            className="d-flex align-items-center justify-content-center "
            style={{ marginTop: "3rem", maxWidth: "60%", margin: settings? "": "auto" }}
          >
            <div>
              <div className="mb-5 " >
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className={settings ? Style.question_heading_settings: Style.question_heading + " mb-0"}>What is your agency’s website?</h3>
                  <ButtonLoader
                    type="button"
                    onClickhandle={handleSubmit}
                    buttonName="Next"
                    isLoading={isLoading}
                    loaderColor={"white"}
                    style={{
                      marginLeft: "12px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                    className={Style.save_btn + ` ${settings && ' d-none'}`}
                    icon={<KeyboardArrowRightIcon />}
                  />
                </div>
                <hr></hr>

                <div className={Style.branch_rd + ` gap-2 shadow-sm  ${settings ?"" : "p-5" }` } style={{width:"100%"}}>
                <label className={Style.input_label + " mb-3"}>
                  Agency Website<span className="red_required_span"> * </span>
                </label>
                <TextInput
                  customClass={Style.website_input_custom + " w-100"}
                  type="text"
                  name="companyWebsite"
                  value={values.companyWebsite}
                  onChange={handleChange}
                  // variant="standard"
                  placeholder="Agency Website"
                  size={"small"}
                />
                <ErrorMsg hasError={touched.companyWebsite && errors.companyWebsite}>
                  {errors.companyWebsite}
                </ErrorMsg>
              </div>

              </div>
              <div>
                <h3 className={settings ? Style.question_heading_settings: Style.question_heading  + " mb-2"}>Add Branch Details</h3>
                <p className={Style.subHeading}>
                  Set up your branches to manage locations and operations effectively.
                </p>

                <FieldArray name="branches">
                  {({ remove, push }) => (
                    values?.branches?.map((branch, index) => (
                      <div key={index} className={Style.branch_card} style={{ ...(index < currentBranch ? { border: "1px solid #00A7BB" } : {}), ...(singleBranch ? { boxShadow: "none" } : {}) }}>
                        {index < (agency?.branches?.length || 0) ?
                          <div className="d-flex align-items-center" onClick={() => toggleSection('detail', index)}  >
                            <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#00A7BB" }} />
                            <div className={Style.branch_index} style={{ textTransform: "capitalize" }}>{branch?.branchName}</div>
                          </div>
                          :
                          <div className="d-flex align-items-center" onClick={() => toggleSection('detail', index)} >
                            <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#E3E3E5" }} />
                            <div className={Style.branch_index}> Branch {singleBranch ? "" : index + 1 + " of " + totalCount}{" "} </div>
                          </div>
                        }
                        {!collapsed[index] && <div className={Style.branch_details}>
                          <div className={Style.first_section}>
                            <div className="d-flex align-items-start">
                              
                              <div className="mt-1 w-100">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <h6 className={Style.detail_heading}>Branch Details</h6>
                                    <p>Add your basic branch details { }</p>
                                  </div>
                                 
                                </div>
                                  <BranchDetailForm
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}

                                    index={index}
                                    branch={branch}
                                    setFieldValue={setFieldValue}
                                    handleTagsChange={handleTagsChange}
                                    handleDelete={handleDelete}
                                    setPlacesData={setPlacesData}
                                    placesData={placesData}
                                    fetchPlacesList={fetchPlacesList}
                                  />
                                  {/* <BranchAddress/> */}
                              
                              </div>
                            </div>
                          </div>
                          <div className={Style.second_section}>
                            <div className="d-flex align-items-start">
                            
                              <div>
                                <div className="d-flex align-items-center justify-content-between mt-1 w-100">
                                  <div className="w-75">
                                    <h6 className={Style.detail_heading}> Branch Serving Location </h6>
                                    <p>
                                      Tell us where your branch serves to help us understand
                                      your service area by entering the postcodes (zip
                                      codes) or names of the areas your branch covers
                                    </p>
                                  </div>
                                  
                                </div>
                                
                                  <div className="">
                                    <ErrorMsg hasError={touched?.branches?.[index]?.displayLoc && errors?.branches?.[index]?.displayLoc}>{errors?.branches?.[index]?.displayLoc} </ErrorMsg>
                                    <div className="">
                                      <label>
                                        Enter a Postcode
                                      </label>
                                      <TagsInput
                                        tags={values.branches[index]?.displayLoc?.filter(loc => loc.locType === 'zip').map(loc => loc.locValue)}
                                        setTags={(tags) => handleTagsChange(tags, setFieldValue, values, index, "zip")}
                                        fullWidth
                                        variant="outlined"
                                        id="tags"
                                        name="tags"
                                        placeholder="e.g. UB10 , UB*"
                                        multiline={true}
                                        rows={3}
                                        customClass={Style.tag_box}
                                        handleDelete={(item) => handleDelete(item, index, "zip", setFieldValue, values)}
                                      />
                                    </div>
                                    <div className={Style.or_label}>or</div>
                                    <div className="">
                                      <label>Enter a Location</label>
                                      <TagsInput
                                        tags={values.branches[index]?.displayLoc?.filter(loc => loc.locType === 'loc').map(loc => loc.locValue)}
                                        setTags={(tags) => handleTagsChange(tags, setFieldValue, values, index, "loc")}
                                        fullWidth
                                        variant="outlined"
                                        id="tags"
                                        name="tags"
                                        placeholder="e.g. Wembley"
                                        multiline={true}
                                        rows={3}
                                        customClass={Style.tag_box}
                                        handleDelete={(item) => handleDelete(item, index, "loc", setFieldValue, values)}
                                      />
                                    </div>
                                  </div>
                                
                              </div>
                            </div>
                          </div>
                          <div className={Style.third_section}>
                            <div className="d-flex align-items-start">
                             
                              <div className="w-100">
                                <div className="d-flex align-items-center justify-content-between mt-1 w-100">
                                  <div className="w-75">
                                    <h6 className={Style.detail_heading}>Working hours</h6>
                                    <p> Communications will only be sent to this branch during these hours. </p>
                                  </div>
                                </div>
                                
                                  <div>
                                    <FieldArray name={`branches[${index}].workingHours`} >
                                      {({ push: pushWorkingHours, remove: removeWorkingHours }) => (
                                        <WorkingHours
                                          index={index}
                                          pushWorkingHours={pushWorkingHours}
                                          removeWorkingHours={removeWorkingHours}
                                          workingHours={values.branches[index]?.workingHours}
                                          touched={touched}
                                          errors={errors}
                                          handleChange={handleChange}
                                        />
                                      )}
                                    </FieldArray>

                                  </div>
                              
                              </div>
                            </div>
                          </div>
                        </div>}
                      </div>
                    ))
                  )}
                </FieldArray>
                <div className="text-end mb-3">
                  <ButtonLoader
                    type="button"
                    onClickhandle={() =>  {
                      console.log("val", errors);
                      
                      handleSubmit()}}
                    buttonName={ settings  ? "Save" : "Next"}
                    isLoading={isLoading}
                    loaderColor={"white"}
                    style={{
                      marginLeft: "12px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                    className={Style.save_btn}
                    
                    icon={ settings ? " " : <KeyboardArrowRightIcon />}
                  />
                </div>

              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>


  );
};

export default BranchDetailCard;