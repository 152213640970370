import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Style from "./AddProperty.module.css";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import sell from "src/assets/images/property/sell.svg";
import letimg from "src/assets/images/property/let.svg";
import questionImg from "src/assets/images/property/questionImg.svg";
import home from "src/assets/images/property/home.svg";
import corporate_fare from "src/assets/images/property/corporate_fare.svg";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import * as Yup from "yup";
import ErrorMsg from "src/components/custom/ErrorMsg";
import SelectWithSearch from "src/components/forms/SelectWithSearch";
import Drawer from "src/components/custom/Drawer";
import AddContact from 'src/views/apps/contacts/AddContact';
import { useSelector } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ListingDetails = ({ handleNext, listingType, setlistingType, PropertyFormDetails, fetchContactList,
    contactData, propertyCategory, setPropertyCategory, setContactName, singleData }) => {
    const userObj = useSelector((state) => state.auth.user);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const handleDrawer = (val, contacttag) => {
        setIsDrawerOpen(val);
    };

    useEffect(() => {
        if (singleData) {
            formik.setFieldValue('listingType', singleData?.listingType[0]);
            formik.setFieldValue('propertyCategory', singleData?.propertyCategory);
            formik.setFieldValue('sellerId', singleData?.sellerId?._id);
        }


    }, [singleData])
    const validationSchema = Yup.object({
        propertyCategory: Yup.string().required("Add property is required."),
        sellerId: Yup.string().required("Add seller is required."),
    });

    const formik = useFormik({
        initialValues: {
            listingType: listingType || '',
            sellerId: '',
            propertyCategory: propertyCategory || '',
            stepCompleted: 1,
            Sellername: '', // Added to manage seller name
        },
        validateOnChange: true,
        validationSchema,
        onSubmit: async (values) => {
            handleNext();
            const formData = new FormData();
            formData.append("listingType", values.listingType);
            formData.append("propertyCategory", values.propertyCategory);
            formData.append("sellerId", values.sellerId);
            formData.append("stepCompleted", values.stepCompleted);
            PropertyFormDetails(formData);
        },
    });





    const handleChange = (event) => {
        const value = event.target.value;
        setlistingType(value);
        formik.setFieldValue('listingType', value); // Correctly set 'listingType'
    };


    const handleChangePropertyCategory = (event) => {
        const value = event.target.value;
        setPropertyCategory(value);

        formik.setFieldValue('propertyCategory', value); // Update Formik's propertyCategory value
    };
    useEffect(
        () => {
            fetchContactList(userObj?.userCategory);
        }, // eslint-disable-next-line
        []
    );
    const convertArrayToLabelValue = (array) => {
        return (array || []).map((item) => ({
            label: item?.name,
            value: item?._id,
        }));
    };


    function TooltipSpilitName(name, maxLength) {
        if (name.length > maxLength) {
            return name.substring(0, maxLength - 3) + "...";
        } else {
            return name;
        }
    }

    return (
        <div style={{ padding: '0px 40px' }}>
            <Box className={Style.lisitngDetailHeader}>
                <Box>
                    <Typography variant="h6" className={Style.listDetailsFont}>1. Select your listing type</Typography>
                </Box>
                <Box>
                    <ButtonLoader
                        type={"button"}
                        buttonName="Next"
                        loaderColor={"white"}
                        className={Style.Add_btn}
                        onClickhandle={formik.handleSubmit}
                        icon={<KeyboardArrowRightIcon />}

                    />
                </Box>
            </Box>


            <Box className={Style.BoxListing} >
                <Box className={Style.BoxCheckList}>
                    <Box className={Style.BoxCheck}>
                        <Box>
                            <img src={sell} alt="Sell" />
                            <span className={Style.listData}>To Sell</span>
                        </Box>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="sell-radio-group"
                                name="listingType"
                                value={formik.values.listingType}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <FormControlLabel value="sell" control={<Radio />} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
                <Box className={Style.BoxCheckList}>
                    <Box className={Style.BoxCheck}>
                        <Box>
                            <img src={letimg} alt="Let" />
                            <span className={Style.listData}>To Let</span>
                        </Box>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="let-radio-group"
                                name="listingType"
                                value={formik.values.listingType}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <FormControlLabel value="let" control={<Radio />} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
            </Box>


            <Box>

                <Typography variant="h6" className={Style.listDetailsFont + " mt-3"}>{listingType === "sell" ? "2. Add seller details." : "2. Add landlord details."}
                    <Tooltip title={TooltipSpilitName("Search by name or email. From the results returned, select the seller for whom you would like to add the new property.", 150)} placement="top">
                        <img src={questionImg} alt="Info" />
                    </Tooltip>
                </Typography>
                {listingType === "sell" ? <>
                    <label className={Style.labelform}>
                        Find an existing seller
                    </label>
                </> : <>
                    <label className={Style.labelform}>
                        Find an existing landlord
                    </label>
                </>}

                <SelectWithSearch
                    name="sellerId"
                    options={convertArrayToLabelValue(contactData)}
                    value={formik.values.sellerId}
                    onChange={(e, obj) => {
                        const selectedContact = contactData.find(contact => contact._id === obj);
                        if (selectedContact) {
                            formik.setFieldValue("sellerId", selectedContact._id); // Set seller ID
                            setContactName(selectedContact.name); // Set contact name
                        }
                    }}
                    size={"small"}
                    noOptionsText={"No contact found."}
                    style={{ width: '99.5%' }}
                    placeholder={`Add ${listingType === "sell" ? "Seller" : "Landlord"} Details`}
                    className={Style.select_input}

                />
            </Box>
            <ErrorMsg hasError={formik.touched.sellerId && formik.errors.sellerId} > {formik.errors.sellerId} </ErrorMsg>

            <Box>
                {listingType === "sell" ? <>
                    <Typography variant="h6" className={Style.newsellercss}> Add a new seller.
                        <Tooltip title={TooltipSpilitName("Click here to add the seller. Once you have created the seller, you will then be able to create a new property for that seller", 150)} placement="top">
                            <img src={questionImg} alt="Info" />
                        </Tooltip>

                        <span className={Style.line}></span>
                    </Typography>
                </> : <>
                    <Typography variant="h6" className={Style.newsellercss}> Add a new landlord.
                        <Tooltip title={TooltipSpilitName("Click here to add the vendor. Once you have created the vendor, you will then be able to create a new property for that vendor", 150)} placement="top">
                            <img src={questionImg} alt="Info" />
                        </Tooltip>

                        <span className={Style.line}></span>
                    </Typography>
                </>}

                {listingType === "sell" ?
                    <Button variant="contained"
                        size="medium" style={{ textTransform: 'capitalize', marginTop: '15px' }}
                        onClick={() => handleDrawer(true)}

                    >
                        <AddIcon /> Add Seller
                    </Button> :
                    <Button variant="contained"
                        size="medium" style={{ textTransform: 'capitalize', marginTop: '15px' }}
                        onClick={() => handleDrawer(true)}

                    >
                        <AddIcon /> Add Landlord
                    </Button>}

                <hr className={Style.custom_hr} />
            </Box>
            <ErrorMsg hasError={formik.touched.Sellername && formik.errors.Sellername}>
                {formik.errors.Sellername}
            </ErrorMsg>
            <Box>
                <Typography variant="h6" className={Style.listDetailsFont}>3. Property</Typography>
            </Box>
            <Box className={Style.BoxListing}>
                <Box className={Style.BoxCheckList}>
                    <Box className={Style.BoxCheck}>
                        <Box>
                            <img src={home} alt="Residential" />
                            <span className={Style.listData}>Residential</span>
                        </Box>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="property-type-radio-group"
                                name="propertyCategory"
                                value={formik.values.propertyCategory}
                                onChange={handleChangePropertyCategory}
                                onBlur={formik.handleBlur}
                            >
                                <FormControlLabel value="residential" control={<Radio />} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
                <Box className={Style.BoxCheckList}>
                    <Box className={Style.BoxCheck}>
                        <Box>
                            <img src={corporate_fare} alt="Commercial" />
                            <span className={Style.listData}>Commercial</span>
                        </Box>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="property-type-radio-group"
                                name="propertyCategory"
                                value={formik.values.propertyCategory}
                                onChange={handleChangePropertyCategory}
                            >
                                <FormControlLabel value="commercial" control={<Radio />} />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                </Box>

            </Box>
            <ErrorMsg hasError={formik.touched.propertyCategory && formik.errors.propertyCategory}>
                {formik.errors.propertyCategory}
            </ErrorMsg>

            <Box style={{ float: 'right' }} className={Style.button_nav}>
                <ButtonLoader
                    type={"button"}
                    buttonName="Next"
                    loaderColor={"white"}
                    className={Style.Add_btn}
                    onClickhandle={formik.handleSubmit}
                    icon={<KeyboardArrowRightIcon />}

                />
            </Box>
            {/* Uncomment if you want to use Drawer */}
            <Drawer
                isDrawerOpen={isDrawerOpen}
                anchor={"right"}
                handleDrawer={() => handleDrawer(false)}
                drawerTitle={"Add Contact"}
            >
                <AddContact
                    toggleDrawer={() => handleDrawer(false)}
                    fetchContactList={() => fetchContactList(userObj?.userCategory)}
                />
            </Drawer>
        </div>
    );
};

export default ListingDetails;
