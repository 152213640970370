import React, { useEffect, useState } from "react";
import { Row, Col, TabContent, TabPane } from "reactstrap";
import TextInput from "../../components/forms/TextInput";
import ErrorMsg from "../../components/custom/ErrorMsg";
import * as Yup from "yup";
import { useFormik } from "formik";
import Axios from "../../utils/axios";
import Toast from "../../components/custom/Toast";
import { ButtonLoader } from "../../components/forms/ButtonLoader";
import { useRef } from "react";
import Loader from "src/layouts/loader/Loader";
import user1 from "src/assets/images/profileDrawer/user.svg";
import { MenuItem, TextField } from "@mui/material";
import { genderOptions } from "src/constants/formPicker";
import styles from "./chatbot.module.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useLocation } from "react-router-dom"
import QRCodeComponent from "./generateQr";
import BackNext from "../auth/account/BackNext/index"
import CommonCheckbox from "src/components/forms/CommonCheckbox";

const ChatbotProfile = ({ chatbotPageType, chatbotId, versionId, chatBotData, setchatBotData, isLoading, getUserListData, isAccountSetup, isSetup, handleNext, btnLoading, handleBack, settings }) => {
  const location = useLocation();
  let userId = new URLSearchParams(location.search)?.get("user");

  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [imageError, setImageError] = useState("");

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required.").test("not-start-with-space", "Full name cannot start with a space.", (value) => !value.startsWith(" ")),
    chatBotOfflineMessage: Yup.string().required("Offline hours message is required."),
    waitingTime: Yup.number().required("Trigger time is required.").min(0, "Trigger time can not be negative.")
  });

  const url = 'https://crm.propertyjinni.co.uk/auth/login';
  const logoUrl = 'https://jinni-bot-storage.s3.amazonaws.com/leads/black-logo2_1723205484990.jpg';
  const qrCodeSize = 300;

  useEffect(() => {
    formik.setFieldValue("name", chatBotData?.draftMetaData?.chatbotName || "");
    formik.setFieldValue("avatar", chatBotData?.draftMetaData?.chatbotImage || null);
    formik.setFieldValue("chatBotGender", chatBotData?.draftMetaData?.chatBotGender || "");
    formik.setFieldValue("waitingTime", chatBotData?.draftMetaData?.waitingTime || 0);
    formik.setFieldValue("chatBotOfflineMessage", chatBotData?.draftMetaData?.chatBotOfflineMessage || "");
    formik.setFieldValue("userId", userId);
  }, [chatBotData, userId]);

  const formik = useFormik({
    initialValues: {
      userId: null,
      name: "",
      avatar: null,
      chatBotGender: "male",
      chatBotOfflineMessage: "",
      waitingTime: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (imageError) { return 0; }

        setIsLoadingUpdate(true);
        const formdata = new FormData();

        if (chatbotId) {
          formdata.append("chatbotId", chatbotId)
        }

        formdata.append("name", values?.name);
        formdata.append("avatar", values?.avatar);
        formdata.append("chatBotGender", values?.chatBotGender);
        formdata.append("chatBotOfflineMessage", values?.chatBotOfflineMessage);
        formdata.append("waitingTime", values?.waitingTime);
        formdata.append("userId", values?.userId)

        const updateData = await Axios.post(`/chatBot/updateChatbotDetails`, formdata);

        if (updateData.data.success) {
          Toast(updateData.data.message, "success");
          setSelectedImage("");
          setIsLoadingUpdate(false);
          setchatBotData(updateData.data.data);

          if (isAccountSetup) {
            handleNext();
          }

        }
      } catch (error) {
        Toast(error, "error");
        setIsLoadingUpdate(false);
      }
    },
  });

  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const myStyle = {
    backgroundColor: 'whitesmoke',
    // height: '100%',
  };


  return (
    <div className={isSetup ? styles.custom_space : " p-5"} style={myStyle}>
      {isLoading ? (<Loader />) : (
        <>
          <Row className={styles.profile_card + `${isSetup ? "" : " mt-1"}`}>
            <Col xs="12" md="12" lg="12" >
              <TabContent activeTab={"3"} >
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <div className="p-4">
                        <div>
                          <div className=" ">
                            <div className=" border-bottom row align-items-center">
                              <div className="col-6">
                                <h5 className={styles.profile_title}>Chatbot Setting</h5>
                                <p className={styles.profile_subtitle}>
                                  Customize the details of your chatbot.
                                </p>
                              </div>

                            </div>
                            <div className="d-flex align-items-start  pt-2">
                              <div className={styles.wrap1}>
                                <div className={styles.chat_bot_user_image + " mt-3"}>
                                  {selectedImage ? (
                                    <img
                                      className="profile_img border"
                                      src={URL.createObjectURL(selectedImage)}
                                      alt="wrong-url"
                                      width="200"
                                      height="200"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  ) : (
                                    <img
                                      src={
                                        chatBotData?.draftMetaData?.chatbotImage &&
                                          chatBotData?.draftMetaData?.chatbotImage !==
                                          "undefined"
                                          ? chatBotData?.draftMetaData?.chatbotImage
                                          : user1
                                      }
                                      alt="wrong-url"
                                      width="120"
                                      height="120"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  )}

                                  <span onClick={handleClick}>
                                    <CameraAltIcon />
                                  </span>
                                </div>

                                <div className="w-100 mb-3">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    disabled={!isAccountSetup && chatbotPageType !== "chatbot"}
                                    name='chatbotimage'
                                    ref={fileInputRef}
                                    multiple
                                    onChange={(e) => {

                                      setImageError("")
                                      if (e.currentTarget.files[0].type.includes('image')) {
                                        setSelectedImage(e.currentTarget.files[0]);
                                        formik.setFieldValue(
                                          "avatar",
                                          e.currentTarget.files[0]
                                        );
                                      }
                                      else if (e.currentTarget.files?.length > 0) {
                                        setImageError("Invalid file type, only images are allowed.")
                                      }
                                      else {
                                        setImageError("")
                                      }

                                    }}
                                    hidden
                                  />
                                </div>
                              </div>


                              <div className={styles.wrap2}>
                                <label className={styles.custom_label + " mb-0"}>
                                  Chatbot Name
                                </label>
                                <TextInput
                                  customClass={styles.custom_field + " w-100  "}
                                  type="text"
                                  disabled={!isAccountSetup && chatbotPageType !== "chatbot"}
                                  name="name"
                                  value={formik.values.name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  variant="outlined"
                                  placeholder="Bot name"
                                  size={"small"}
                                />
                                <ErrorMsg
                                  hasError={
                                    formik.touched.name && formik.errors.name
                                  }
                                >
                                  {formik.errors.name}
                                </ErrorMsg>
                              </div>

                              <div className={styles.wrap3}>
                                <label className={styles.custom_label + " mb-0"}>
                                  Gender
                                </label>
                                <TextField
                                  select
                                  disabled={!isAccountSetup && chatbotPageType !== "chatbot"}
                                  className={styles.custom_field}
                                  label=""
                                  variant="outlined"
                                  placeholder="Select gender"
                                  name="chatBotGender"
                                  autoComplete="off"
                                  value={formik.values.chatBotGender}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.chatBotGender &&
                                    Boolean(formik.errors.chatBotGender)
                                  }
                                  helperText={
                                    formik.touched.chatBotGender &&
                                    formik.errors.chatBotGender
                                  }
                                  defaultValue="Male"
                                  // className={styles.custom_field}
                                  fullWidth
                                  SelectProps={{
                                    MenuProps: { sx: { height: "300px" } },
                                  }}
                                  size={"small"}
                                >
                                  {genderOptions.map((row, index) => {
                                    return (
                                      <MenuItem
                                        key={`ndtk${index}`}
                                        value={row.value}
                                      >
                                        {row.label}
                                      </MenuItem>
                                    );
                                  })}
                                </TextField>
                              </div>

                            </div>
                            <ErrorMsg hasError={imageError} >
                              {imageError}
                            </ErrorMsg>
                            <div className="d-flex align-items-start border-bottom pt-0">
                              <div className={styles.wrap1}>
                                <div hidden className={styles.chat_bot_user_image}>


                                  <span onClick={handleClick}>
                                    <CameraAltIcon />
                                  </span>
                                </div>
                                <div className="w-100 mb-3">

                                </div>
                              </div>
                              <div className={styles.wrap2 + " w-100 pt-0 pb-3 px-4"}>
                                <div>
                                  <label className={styles.custom_label + " mb-1"}>
                                    Offline Message
                                  </label>
                                  <TextInput
                                    customClass={styles.custom_textarea + " w-100  mb-2"}
                                    type="text"
                                    name="chatBotOfflineMessage"
                                    value={formik.values.chatBotOfflineMessage}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    multiline={true}
                                    rows={3}
                                    variant="outlined"
                                    placeholder="Offline hours message"
                                    size={"small"}
                                    disabled={!isAccountSetup && chatbotPageType !== "chatbot"}
                                  />
                                  <ErrorMsg
                                    hasError={
                                      formik.touched.chatBotOfflineMessage && formik.errors.chatBotOfflineMessage
                                    }
                                  >
                                    {formik.errors.chatBotOfflineMessage}
                                  </ErrorMsg>
                                </div>

                                <div>
                                  <label className={styles.custom_label + " mb-2 w-100 mt-2 "}>
                                    When do you want the chatbot to trigger ( in seconds)
                                  </label>
                                  <div style={{ width: "90px" }}>
                                    <TextInput
                                      customClass={styles.custom_field + "w-100"}
                                      type="number"
                                      name="waitingTime"
                                      value={formik.values.waitingTime}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      variant="outlined"
                                      placeholder="Seconds"
                                      size={"small"}
                                      min={0}
                                      disabled={!isAccountSetup && chatbotPageType !== "chatbot"}
                                    />
                                  </div>
                                  <ErrorMsg
                                    hasError={
                                      formik.touched.waitingTime && formik.errors.waitingTime
                                    }
                                  >
                                    {formik.errors.waitingTime}
                                  </ErrorMsg>
                                </div>
                            
                              </div>

                            </div>

                            <div class="d-flex pt-1">
                              <div className={styles.wrap1}></div>
                              <div class="col">
                                <div className={styles.wrap2 + ' w-100'}>
                                </div>
                              </div>
                            </div>
                            {!isAccountSetup && chatbotPageType === "chatbot" && <div className="row">
                              <div className="col pt-2">
                                <ButtonLoader
                                  onClickhandle={formik.handleSubmit}
                                  buttonName=" Update"
                                  isLoading={isLoadingUpdate}
                                  loaderColor={"white"}
                                  style={{
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                  }}
                                  className={styles.profile_btn}
                                ></ButtonLoader>
                              </div>
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>

          {isAccountSetup && <BackNext isLoading={btnLoading} handleNext={formik.handleSubmit} handleBack={handleBack} />}

          {/* {!isAccountSetup && !settings && chatbotPageType === "chatbot" && <Row className={styles.profile_card + " mt-5"}>
            <Col xs="12" md="12" lg="12" >
              <TabContent activeTab={"3"} >
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <div className="p-4">
                        <div>
                          <QRCodeComponent url={url} logoUrl={logoUrl} size={qrCodeSize} getUserListData={getUserListData} userdata={chatBotData?.draftMetaData} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>} */}
        </>
      )}
    </div>
  );
};

export default ChatbotProfile;