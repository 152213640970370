import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useState, useEffect } from 'react'
import Style from "./AddProperty.module.css";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import TextInput from "src/components/forms/TextInput";
import AstericSymbol from "src/components/custom/astericSymbol";
import FormGroup from '@mui/material/FormGroup';
import CommonCheckbox from "src/components/forms/CommonCheckbox";
import { useFormik } from 'formik';
import * as Yup from "yup";
import ErrorMsg from "src/components/custom/ErrorMsg";
import { debounce } from "../../../../../src/helper/commonHelp";
import Axios from 'src/utils/axios';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { removeNumericBeforeComma } from 'src/utils/Validator';

const PropertyAddress = ({ handleNext, handleBack, propertyInfo, PropertyAddressService, setPlacesData, placesData, fetchPlacesList, propertyCategory, fetchSingleListId, singleData
}) => {
    const [showList, setShowList] = useState(false);
    const [error, setError] = useState("");


    const handleBackButton = () => {
        fetchSingleListId(propertyInfo);
        handleBack()
    }

    useEffect(() => {
        if (singleData?.propertyAddress) {
            formik.setFieldValue('propertyAddress', singleData?.propertyAddress?.addressLine1);
            formik.setFieldValue('county', singleData?.propertyAddress?.county);
            formik.setFieldValue('country', singleData?.propertyAddress?.country);
            formik.setFieldValue('city', singleData?.propertyAddress?.city);
            formik.setFieldValue('addressLine', singleData?.propertyAddress?.addressLine1);
            formik.setFieldValue('postalCode', singleData?.propertyAddress?.postalCode);
            formik.setFieldValue('lat', singleData?.propertyAddress?.geoCoordinates?.coordinates[0]);
            formik.setFieldValue('lng', singleData?.propertyAddress?.geoCoordinates?.coordinates[1]);
            formik.setFieldValue('searchableArea', singleData?.searchableArea?.name);

        }


    }, [singleData])


    const fetchPlaceDetail = (place_id) => {
        Axios.get(`/places/place-detail-by-address-id?place_id=${place_id}`)
            .then((res) => {

                formik.setFieldValue("lat", res?.data?.data?.raw?.geometry?.location?.lat);
                formik.setFieldValue("lng", res?.data?.data?.raw?.geometry?.location?.lng);
                formik.setFieldValue("city", res?.data?.data?.address?.City);
                formik.setFieldValue("country", res?.data?.data?.address?.Country);
                formik.setFieldValue("postalCode", res?.data?.data?.address?.zip);

            })
            .catch((err) => {
                setError(err);
            });
    };

    const userTxtChangeDebounce = useCallback(
        debounce((txtval) => fetchPlacesList(txtval), 800),
        []
    );

    const onChangeAddress = (e) => {
        formik.setFieldValue("propertyAddress", e.target.value);

        if (e.target.value.length > 0) {
            userTxtChangeDebounce(e.target.value);
            setShowList(true);
        }
    };

    const handleLocationClick = (location) => {
        if (location) {
            fetchPlaceDetail(location.place_id);
            formik.setFieldValue("propertyAddress", location.description);
            formik.setFieldValue("addressLine", removeNumericBeforeComma(location.description));
        }
        setPlacesData([]);
        setShowList(false);
    };
    const validationSchema = Yup.object({
        propertyAddress: Yup.string().required("Property address is required.")
            .test(
                "not-start-with-space",
                "Property address cannot start with a space.",
                (value) => {
                    return !value || !value.startsWith(" ");
                }
            ).min(2, "Property address must be at least 2 characters long."),

        postalCode: Yup.string().required("postal code is required."),
        city: Yup.string().required("City is required."),
        country: Yup.string().required("Country is required."),

    });
    const formik = useFormik({
        initialValues: {
            propertyAddress: "",
            postalCode: "",
            city: "",
            country: "",
            county: "",
            searchableArea: "",
            addressLine: "",
            lat: "",
            lng: "",
            displayAddress: ""
        },
        validationSchema,
        validateOnChange: true,
        onSubmit: async (values) => {
            const propertyAddress = {
                addressLine1: values?.propertyAddress,
                postalCode: values?.postalCode,
                city: values?.city,
                country: values?.country,
                county: values?.county,
                addressLine: "",
                geoCoordinates: {
                    type: "Point",
                    coordinates: [values?.lat, values?.lng]
                },
                displayAddress: values?.addressLine,
            }
            handleNext()
            const formData = new FormData();
            formData.append("propertyAddress", JSON.stringify(propertyAddress));
            formData.append("searchableArea", values.searchableArea);
            formData.append("propertyId", propertyInfo);

            PropertyAddressService(formData, 3);
        },
    });
    return (
        <div>
            <div style={{ padding: '10px 40px' }}>

                <Box>
                    <Typography variant="h6" className={Style.listDetailsFont}>Property Address</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} className='pt-0'>
                            <FormGroup className={Style.form_top}>
                                <CommonCheckbox
                                    label={
                                        <span className={Style.label_value + " checkboxlabelwithline"}>
                                            Is the Seller address same
                                        </span>
                                    }
                                />
                            </FormGroup>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className='pt-0'>
                            <label className={Style.labelform}>
                                Address <AstericSymbol />
                            </label>
                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="propertyAddress"
                                value={formik.values.propertyAddress}
                                onChange={onChangeAddress}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                placeholder="Address"
                                size={"small"}
                            />
                            {showList &&
                                (placesData?.length > 0 ? (
                                    <div className={Style.place_card} onMouseLeave={()=>setShowList(false)}>
                                        {placesData?.map((place, index) => (
                                            <div
                                                className={Style.placeList}
                                                key={index}
                                            >
                                                <p
                                                    className="mb-0"
                                                    key={index}
                                                    onClick={() =>
                                                        handleLocationClick(place)
                                                    }
                                                >
                                                    {place.description}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    error && (
                                        <div className={Style.place_card}>
                                            {" "}
                                            <div className={Style.placeList}>
                                                {error}
                                            </div>
                                        </div>
                                    )
                                ))}
                            <ErrorMsg hasError={formik.touched.propertyAddress && formik.errors.propertyAddress} > {formik.errors.propertyAddress} </ErrorMsg>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className='pt-0'>
                            <label className={Style.labelform}>
                                Display Address <AstericSymbol />
                            </label>
                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="addressLine"
                                value={formik.values.addressLine}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                                placeholder="Display address"

                            />

                            <ErrorMsg hasError={formik.touched.propertyAddress && formik.errors.propertyAddress} > {formik.errors.propertyAddress} </ErrorMsg>

                        </Grid>

                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                Post Code <AstericSymbol />
                            </label>


                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="postalCode"
                                value={formik.values.postalCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                                placeholder=" Post Code"

                            />
                            <ErrorMsg hasError={formik.touched.postalCode && formik.errors.postalCode} > {formik.errors.postalCode} </ErrorMsg>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                Town/City <AstericSymbol />
                            </label>

                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                                placeholder="Town/City"

                            />
                            <ErrorMsg hasError={formik.touched.city && formik.errors.city} > {formik.errors.city} </ErrorMsg>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                Country <AstericSymbol />
                            </label>

                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="country"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                                placeholder="Country"
                            />
                            <ErrorMsg hasError={formik.touched.country && formik.errors.country} > {formik.errors.country} </ErrorMsg>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                County
                            </label>


                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="county"
                                value={formik.values.county}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                                placeholder="County"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                Searchable Area
                            </label>


                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="searchableArea"
                                value={formik.values.searchableArea}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                                placeholder="Searchable area"
                            />
                            <ErrorMsg hasError={formik.touched.searchableArea && formik.errors.searchableArea} > {formik.errors.searchableArea} </ErrorMsg>

                        </Grid>
                    </Grid>
                    <hr />
                    <div className={Style.propertycss1 + " " + Style.button_nav}>
                        <div style={{ marginRight: '12px' }}>
                            <Button color="secondary"
                                className={Style.delete_btn + " " + Style.edit_btn} onClick={handleBackButton}>
                                <KeyboardArrowLeftIcon /> Back
                            </Button>
                        </div>
                        <div>
                            <ButtonLoader
                                type={"button"}
                                buttonName="Next"
                                loaderColor={"white"}
                                className={Style.Add_btn}
                                onClickhandle={formik.handleSubmit}
                                icon={<KeyboardArrowRightIcon />}
                            />

                        </div>
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default PropertyAddress