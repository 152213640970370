import React from "react";
import { Select, MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const SelectInput = ({
  customClass,
  options,
  selectedValue,
  placeholder,
  onChange,
  label,
  onBlur,
  disabled,
  sx,
  size,
  onFocus,
  handleKeyDown
}) => {
 
  return (
    <FormControl fullWidth size={size ? size : ""}>
      <InputLabel id="demo-simple-select-label">
        {label ? label : ""}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        label={label ? label : ""}
        onChange={onChange}
        className={customClass}
        disabled={disabled}
        sx={sx}
        displayEmpty={placeholder ? true : false}
        onFocus={onFocus}
        MenuProps={{
          style: {
            maxHeight: 350,
          },
          PaperProps: {
            onKeyDown: handleKeyDown // Attach keydown to PaperProps
          }
        }}
      >
        {placeholder && (
          <MenuItem hidden value={""} sx={{fontSize:"14px", color:"grey"}}>
            {placeholder}
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem
            key={option.value}
            className="menu-item"
            value={option.value}
            sx={{fontSize:"14px"}}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
