import { Link, NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { DropdownMenu, DropdownToggle, Nav, NavItem, UncontrolledDropdown } from "reactstrap";
import * as Icon from "react-feather";

import NavItemContainer from "../sidebars/vertical/NavItemContainer";
import NavSubMenu from "../sidebars/vertical/NavSubMenu";
import Style from "../policy/Header.module.css";
import SidebarData from "../sidebars/sidebardata/SidebarData";

const HeaderNavigation = ({ topbarColor }) => {
  const SidebarArr = SidebarData();
  const location = useLocation();
  const currentURL = location.pathname.split("/").slice(-1).join("/");

  const [collapsed, setCollapsed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [focusedItem, setFocusedItem] = useState(null);

  // Calculate maxItemsToShow dynamically based on available width
  const maxItemsToShow = Math.floor((windowWidth - 557 - 60) / 125) - 1;
  const visibleItems = SidebarArr?.sort((a, b) => a.id - b.id)?.slice(0, maxItemsToShow);
  const hiddenItems = SidebarArr?.sort((a, b) => a.id - b.id)?.slice(maxItemsToShow);

  const toggle = () => { setCollapsed(!collapsed) };
  const handleItemClick = () => { setCollapsed(false) };
  const handleResize = () => { setWindowWidth(window.innerWidth) };

  const handleKeyDown = (event, href) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      window.location.href = href;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Nav className={Style.header_ul + " me-auto d-none d-lg-flex"} navbar>
      {visibleItems &&
        visibleItems.map((navi) => {
          if (navi.children) {
            return (
              <NavSubMenu
                key={navi.id}
                icon={navi.icon}
                title={navi.title}
                items={navi.children}
                suffix={navi.suffix}
                suffixColor={navi.suffixColor}
                isUrl={currentURL === navi.href}
                tabIndex="0"
                onKeyDown={(event) => handleKeyDown(event, navi.href)}
                onFocus={() => setFocusedItem(navi.id)}
                onBlur={() => setFocusedItem(null)}
                className={focusedItem === navi.id ? 'focusedItem' : ''}
              />
            );
          }
          return (
            <NavItemContainer
              key={navi.id}
              className={`${location.pathname === navi.href ? "activeLink nav_item nav_item_width" : "nav_item nav_item_width"}  ${focusedItem === navi.id ? 'focusedItem' : ''}`}
              to={navi.href}
              title={navi.title}
              suffix={navi.suffix}
              suffixColor={navi.suffixColor}
              icon={navi.icon}
              tabIndex="0"
              onKeyDown={(event) => handleKeyDown(event, navi.href)}
              onFocus={() => setFocusedItem(navi.id)}
              onBlur={() => setFocusedItem(null)}
            />
          );
        })}
      {hiddenItems?.length > 0 && (
        <UncontrolledDropdown className="parent_nav_item" isOpen={collapsed} toggle={toggle}>
          <DropdownToggle color={topbarColor} className={Style.dropdown_border + " p-0 gap-2"} tabIndex="0" onKeyDown={toggle}>
            <NavItem className={hiddenItems.some((item) => location.pathname === item.href) ? "activeParent activeLink nav_item nav_item_width" : "nav_item nav_item_width"} onClick={() => {setFocusedItem(null)}}>
              <div className="gap-1 nav_link d-flex">
                <span className="sidebarIcon"></span>
                <span className="hide-mini w-100">
                  <div className="d-flex align-items-center">
                    <span>See More</span>
                    <Icon.ChevronDown
                      size={19}
                      style={{ marginLeft: "3px" }}
                    />
                  </div>
                </span>
              </div>
            </NavItem>
          </DropdownToggle>
          <DropdownMenu className="" style={{ width: "250px" ,maxHeight:'38rem',overflowY:'auto'}}>
            {hiddenItems.map((item, index) => (
              <NavItem key={item.title} 
              className={`hide-mini sub_item  ${location.pathname === item.href ? "activeSubLink nav_item" : "nav_item"}  ${focusedItem === item?.id ? Style.focusedItem : ''}`} tabIndex="0"
                onKeyDown={(event) => handleKeyDown(event, item.href)}
                onFocus={() => setFocusedItem(item?.id)}
                onBlur={() => setFocusedItem(null)}
              >
                <NavLink tag={Link} to={item.href} onClick={handleItemClick} className="gap-3 nav_link sub_link">
                  <span className="sidebarIcon">
                    <img src={item.icon} alt=""></img>
                  </span>
                  <span className="hide-mini px-1">
                    <span>{item.title}</span>
                  </span>
                </NavLink>
              </NavItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </Nav>
  );
};

export default HeaderNavigation;
