import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import Stepper from "../../../Stepper";
import ListingDetails from './ListingDetails';
import Style from "./AddProperty.module.css";
import PropertyInformation from './PropertyInformation';
import PropertyAddress from './PropertyAddress';
import PropertyDescription from './PropertyDescription';
import PropertyPicture from './PropertyPicture';
import LettingFees from './LettingFees';
import { ArrowBackIos } from '@mui/icons-material';
import useProperty from "../hooks/useAddproperty";
import { useNavigate } from 'react-router-dom';

const AddProperty = () => {

    const navigate = useNavigate();
    const { PropertyFormDetails, PropertyInfromationService, propertyData, propertyInfo, PropertyAddressService, setPlacesData, placesData, fetchPlacesList, propertyAddress,
        PropertyDescriptionService, handleImageDelete, fetchContactList, contactData, PropertyPictureService, PropertyDescriptionData, PropertyLettingFeeservice,
        MasterListData, fetchMasterList, fetchStatusList, StatusData, fetchFeaturesList, FeatureList,
        singleData, fetchSingleListId, ReferenceId, setSingleData
    } = useProperty();

    const [step, setStep] = useState(0);
    const [listingType, setlistingType] = useState('sell')
    const [propertyCategory, setPropertyCategory] = useState(singleData?.propertyCategory)
    const [contactName, setContactName] = useState('');

    return (
        <div>
            <Box>
                <Grid container spacing={0} style={{ maxWidth: "100vw" }}>
                    <Grid item xs={12} sm={12} md={3} className={Style.propertyHeader}>
                        <Typography variant="h6" style={{ padding: "0rem 3.5rem" }} className={Style.sidebar_header}>
                            <span onClick={() => navigate(-1)}>
                                <ArrowBackIos style={{ fontSize: "22px", cursor: "pointer" }} />
                            </span>
                            Add Property</Typography>
                        <span className={Style.Line}></span>
                        <div style={{ padding: "1.8rem 3.5rem" }}>
                            <Stepper activeStep={step} listingType={listingType} />
                        </div>


                    </Grid>
                    <Grid item xs={12} sm={12} md={9} style={{ padding: "2rem 0rem", background: 'white' }}>

                        {step === 0 &&
                            <ListingDetails
                                handleNext={() => { setStep(1) }}
                                setlistingType={setlistingType}
                                listingType={listingType}
                                step={step}
                                PropertyFormDetails={PropertyFormDetails}
                                fetchContactList={fetchContactList}
                                contactData={contactData}
                                propertyCategory={propertyCategory}
                                setPropertyCategory={setPropertyCategory}
                                singleData={singleData}
                                fetchSingleListId={fetchSingleListId}
                                propertyData={propertyData}
                                setContactName={setContactName}
                            />}

                        {step === 1 &&
                            <PropertyInformation
                                handleNext={() => { setStep(2) }}
                                handleBack={() => { setStep(0) }}
                                PropertyInfromationService={PropertyInfromationService}
                                propertyData={propertyData}
                                propertyCategory={propertyCategory}
                                listingType={listingType}
                                fetchMasterList={fetchMasterList}
                                MasterListData={MasterListData}
                                StatusData={StatusData}
                                fetchStatusList={fetchStatusList}
                                fetchFeaturesList={fetchFeaturesList}
                                FeatureList={FeatureList}
                                singleData={singleData}
                                fetchSingleListId={fetchSingleListId}
                                propertyInfo={propertyInfo}
                                contactName={contactName}
                                ReferenceId={ReferenceId}

                            />}


                        {step === 2 &&
                            <PropertyAddress
                                handleNext={() => { setStep(3) }}
                                handleBack={() => { setStep(1) }}
                                propertyInfo={propertyInfo}
                                PropertyAddressService={PropertyAddressService}
                                setPlacesData={setPlacesData}
                                placesData={placesData}
                                fetchPlacesList={fetchPlacesList}
                                propertyCategory={propertyCategory}
                                fetchSingleListId={fetchSingleListId}
                                singleData={singleData}

                            />}
                        {step === 3 &&
                            <PropertyDescription
                                handleNext={() => {
                                    if (listingType === 'let') {
                                        setStep(4); // Skip step 4 for 'let'
                                    } else {
                                        setStep(4); // Move to step 4 for 'sell'
                                    }
                                }}
                                handleBack={() => {
                                    setStep(2);
                                    setSingleData(propertyAddress)
                                }}
                                propertyAddress={propertyAddress}
                                PropertyDescriptionService={PropertyDescriptionService}
                                propertyCategory={propertyCategory}
                                listingType={listingType}
                                fetchSingleListId={fetchSingleListId}
                                singleData={singleData}

                            />
                        }
                        {listingType === 'let' && step === 4 &&
                            <LettingFees
                                handleNext={() => { setStep(5) }}
                                handleBack={() => { setStep(3) }}
                                PropertyLettingFeeservice={PropertyLettingFeeservice}
                                PropertyDescriptionData={PropertyDescriptionData}
                                fetchSingleListId={fetchSingleListId}
                                singleData={singleData}


                            />
                        }

                        {((listingType === 'sell' && step === 4)) &&
                            <PropertyPicture
                                handleBack={() => { setStep(listingType === 'let' ? 4 : 3) }}
                                handleImageDelete={handleImageDelete}
                                PropertyPictureService={PropertyPictureService}
                                PropertyDescriptionData={PropertyDescriptionData}
                                fetchSingleListId={fetchSingleListId}
                                listingType={listingType}
                            />
                        }


                        {((listingType === 'let' && step === 5)) &&
                            <PropertyPicture
                                handleBack={() => { setStep(listingType === 'let' ? 4 : 3) }}
                                handleImageDelete={handleImageDelete}
                                PropertyPictureService={PropertyPictureService}
                                PropertyDescriptionData={PropertyDescriptionData}
                                fetchSingleListId={fetchSingleListId}
                                listingType={listingType}
                            />
                        }
                    </Grid>
                </Grid>
            </Box>

        </div>
    )
}

export default AddProperty