import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import { Col, Form, Row } from "reactstrap";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Styles from "./contact.module.css";
import { countryCodes } from "src/constants/formPicker";
import TextInput from "src/components/forms/TextInput";
import ErrorMsg from "src/components/custom/ErrorMsg";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import useHandleData from "./hooks/useHandleData";
import SelectWithSearch from "src/components/forms/SelectWithSearch";
import DeleteModal from "src/components/custom/DeleteModal";
import Style from "./contact.module.css";
import ImageDisplay from "src/components/custom/ImageDisplay";
import { getCategoryObject } from "src/helper/commonHelp";
// import MultiSelect from "src/layouts/mui/multiSelect";
import MultiSelect2 from "src/layouts/mui/multiSelect2";
import AstericSymbol from "src/components/custom/astericSymbol";
import PhoneNumberWithCountryCode from "src/components/forms/PhoneNumberWithCountryCode";
import CountryCode from "src/components/forms/CountriesCode";
import TagsInput from "src/components/apps/tags/TagsInput";
import Typography from '@mui/material/Typography'
const AddContact = ({ toggleDrawer, fetchContactList, edit, chips }) => {
  const branchList = useSelector((state) => state.auth.branchList);
  const userObj = useSelector((state) => state.auth.user);
  const [deletedImageUrl, setDeletedImageUrl] = useState("");
  const [showdelete, setShowdelete] = useState(false);
  const [tags, setTags] = useState([]);
  const fileInputRef = useRef(null);
  const { handleSuggestionList, suggestionList } = useHandleData()
  const handleClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleTagsChange = (updatedTags) => {
    const validUpdatedTags = Array.isArray(updatedTags) ? updatedTags : [];
    // Update formik field
    formik.setFieldValue('tags', validUpdatedTags);
  };


  useEffect(() => {
    formik.setFieldValue('tags', tags);

  }, [tags])



  const handleDeleteTags = (tagToDelete) => {
    // Filter out the tag to delete
    const updatedTags = formik.values.tags.filter(tag => tag !== tagToDelete);

    // Update formik field
    formik.setFieldValue('tags', updatedTags);
  };


  const { fetchAssignUsers, addContact, updateContact, isAddLoading, handleImageDelete, } = useHandleData();

  const handleCategoryChange = (selectedOption) => {
    if (typeof selectedOption === "string") {
      return [selectedOption];
    } else {
      return selectedOption;
    }
  };


  const emailRegex =
    /^[^\W_](?:[a-zA-Z0-9.!#$%&'*+/=?^`{|}~-]*[^\W_])?@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z ]+$/, "Name can only contain alphabetical characters.")
      .required("Name is required.")
      .test(
        "not-start-with-space",
        "Name cannot start with a space.",
        (value) => {
          return !value || !value.startsWith(" ");
        }
      ).min(2, "Name must be at least 2 characters long.")
      .max(30, "Name cannot be more than 30 characters long."),
    email: Yup.string()
      .email("Invalid email.")
      .required("Email is required.")
      .test("custom", "Please enter a valid email address.", (value) => {
        const trimmedValue = value.trim(); // Remove leading and trailing spaces
        return emailRegex.test(trimmedValue);
      }).min(2, "Name must be at least 2 characters long."),

    phone: Yup.string()
      .matches(/^\+?[0-9]\d{1,12}$/, "Invalid mobile number.")
      .min(10, "Phone number must be at least 10 characters.")
      .max(11, "Phone number cannot exceed 11 characters.")
      .required("Phone Number is required.")
      .test(
        "not-start-with-space",
        "Phone cannot start with a space.",
        (value) => {
          return !value || !value.startsWith(" ");
        }
      ),
    countryCode: Yup.string().required("Country Code is required."),
    companyName: Yup.string()
      // .required("Company Name is required")
      .test(
        "not-start-with-space",
        " Company Name start with a space.",
        (value) => !value?.startsWith(" ")
      ),
    category: edit
      ? Yup.array().required("Category is required.")
      : Yup.string().required("Category is required."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      category: null,
      companyName: "",
      phone: "",
      countryCode: "+44",
      documents: [],
      branch: userObj?.role !== 'companyadmin' ? userObj?.branch : "",
      tags: []
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      console.log(values,"values")
      if (edit) {
        let updateRest = { ...values, blockedChannels: JSON.stringify(values?.blockedChannels), platform: JSON.stringify(values?.platform) }
        updateContact(updateRest, edit?._id, toggleDrawer, fetchContactList);
      } else {
        addContact(
          { ...values, category: handleCategoryChange(values?.category) },
          toggleDrawer,
          fetchContactList
        );
      }
    },
  });

  const handleImages = (val) => {
    formik.setFieldValue("documents", [...formik.values.documents, ...val]);
  };

  // Handle removing an image
  const handleRemoveImage = (index) => {
    if (edit) {
      let updatedSelectedImages = [...formik.values.documents];
      if (typeof index === "string") {
        handleImageDelete(index, edit?._id, setShowdelete);
        updatedSelectedImages = formik?.values?.documents?.filter(
          (item) => item !== index
        );
      } else {
        updatedSelectedImages.splice(index, 1);
      }
      formik.setFieldValue("documents", updatedSelectedImages);
      setShowdelete(false);
    } else {
      const updatedSelectedImages = [...formik.values.documents];
      updatedSelectedImages.splice(index, 1);
      formik.setFieldValue("documents", updatedSelectedImages);
      setShowdelete(false);
    }
  };

  const convertBranchArrayToLabelValue = (array) => {
    return array?.map((item) => ({
      label: item?.branchName,
      value: item?._id,
    }));
  };

  useEffect(
    () => {
      fetchAssignUsers();
      handleSuggestionList();
    }, // eslint-disable-next-line
    []
  );




  useEffect(
    () => {
      if (edit) {
        formik.setValues(edit);
        formik.setFieldValue('countryCode', edit?.countryCode || "+44");
        formik.setFieldValue('phone', edit?.phone || "");
        formik.setFieldValue('branch', edit?.branch?._id || "")
        formik.setFieldValue('tags', chips);
      }
    }, // eslint-disable-next-line
    [edit]
  );

  return (
    <div>
      <div className="container-fluid">
        <Row>
          <Col sm="12">
            <div className="py-3">
              <Form className={Styles.form_wrapper + " v-right-sidepoup"}>
                <div
                  className="v-create-ticket-height pb-5"
                  style={{
                    height: "100%",
                    overflow: "hidden auto",
                    maskImage: "linear-gradient(black 100%, transparent 100%)",
                  }}
                >
                  <Box sx={{ flexGrow: 1 }} className="">
                    <Grid container spacing={1} className="mt-2">
                      <Grid item xs={12} sm={12} md={12} className="mb-2">
                        <div className="">
                          <label className={Styles.form_label}>Name <AstericSymbol /></label>
                          <TextInput
                            customClass={Styles.input_custom + " w-100 "}
                            type="text"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            placeholder="Name"
                            size={"small"}
                          />
                          <ErrorMsg
                            hasError={formik.touched.name && formik.errors.name}
                          >
                            {formik.errors.name}
                          </ErrorMsg>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} className="mb-2">
                        <div className="">
                          <label className={Styles.form_label}>Email <AstericSymbol /></label>
                          <TextInput
                            customClass={Styles.input_custom + " w-100 "}
                            type="text"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            placeholder="Email"
                            size={"small"}
                          />
                          <ErrorMsg
                            hasError={
                              formik.touched.email && formik.errors.email
                            }
                          >
                            {formik.errors.email}
                          </ErrorMsg>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} className="mb-2">
                        <div className="">
                          <label className={Styles.form_label}> Phone Number <AstericSymbol /></label>
                          <div className="d-flex align-items-center">
                            <div style={{ width: "25%" }}>
                              <CountryCode
                                name={`Country Code`}
                                // label={"Country Code"}
                                placeholder={"Country Code"}
                                options={countryCodes}
                                selectedValue={formik.values.countryCode}
                                onChange={(val) => {
                                  formik.setFieldValue("countryCode", val)
                                }}
                                noOptionText={"No Country Code found."}

                              />
                            </div>
                            <div style={{ marginLeft: "10px", width: "100%" }}>
                              <PhoneNumberWithCountryCode
                                name="phone"
                                countryCode={formik.values.countryCode}
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                placeholder="Phone"
                                // label="Phone"
                                size={"small"}
                              />
                            </div>
                          </div>
                          <ErrorMsg hasError={formik.touched.phone && formik.errors.phone}>{formik.errors.phone}</ErrorMsg>
                          <ErrorMsg hasError={formik.touched.countryCode && formik.errors.countryCode}>{formik.errors.countryCode}</ErrorMsg>

                        </div>
                      </Grid>
                      {userObj?.role === "companyadmin" && <Grid item xs={12} sm={12} md={12} className={Styles.customelect + " mb-2"}>
                        <label className={Styles.form_label}>Branch</label>
                        <SelectWithSearch
                          name="branch"
                          options={branchList?.length > 0 ? convertBranchArrayToLabelValue(branchList) : []}
                          value={formik.values.branch}
                          onChange={(e, obj) => { formik.setFieldValue("branch", obj); }}
                          size={"small"}
                          // disabled={ userObj?.role === "companymanager"}
                          noOptionsText={"No branch found."}
                          placeholder="branch"
                        />
                        <ErrorMsg hasError={formik.touched.branch && formik.errors.branch} >{formik.errors.branch}</ErrorMsg>
                      </Grid>}

                      {!edit && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          className={Styles.custom_select + " mb-2"}
                        >
                          <label className={Styles.form_label}>Category <AstericSymbol /></label>
                          <SelectWithSearch
                            name="category"
                            options={getCategoryObject(userObj?.userCategory)}
                            value={formik.values.category}
                            onChange={(e, obj) => {
                              formik.setFieldValue("category", obj);
                            }}
                            size={"small"}
                            noOptionsText={"No category found."}
                          />
                          <ErrorMsg
                            hasError={
                              formik.touched.category && formik.errors.category
                            }
                          >
                            {formik.errors.category}
                          </ErrorMsg>
                        </Grid>
                      )}
                      {edit && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          className={Styles.custom_select + " mb-2"}
                        >
                          <label className={Styles.form_label}>Category <AstericSymbol /></label>
                          <MultiSelect2
                            name="category"
                            options={getCategoryObject(userObj?.userCategory)}
                            value={formik.values.category}
                            onSelectionChange={(val) => {
                              formik.setFieldValue("category", val);
                            }}
                            size={"small"}
                            customClass={"p-1"}
                          />
                          <ErrorMsg
                            hasError={
                              formik.touched.category && formik.errors.category
                            }
                          >
                            {formik.errors.category}
                          </ErrorMsg>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={12} className="mb-2">
                        <div className="">
                          <label className={Styles.form_label}>
                            Company Name
                          </label>
                          <TextInput
                            customClass={Styles.input_custom + " w-100 "}
                            type="text"
                            name="companyName"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            placeholder="Company Name"
                            // label="Company Name"
                            size={"small"}
                          />
                          <ErrorMsg
                            hasError={
                              formik.touched.companyName &&
                              formik.errors.companyName
                            }
                          >
                            {formik.errors.companyName}
                          </ErrorMsg>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} className="mb-2">
                        <Typography body gutterBottom className={Styles.form_label}>
                          Tags
                        </Typography>
                        <div className={Styles.tag_css}>
                          <TagsInput
                            tags={formik.values.tags}
                            setTags={handleTagsChange}
                            fullWidth
                            variant="outlined"
                            id="tags"
                            name="tags"
                            placeholder="add tags.."
                            rows={3}
                            customClass={Styles.tag_box}
                            handleDelete={(e) => handleDeleteTags(e)}
                            suggestions={suggestionList?.map((item) => item.name)}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} className="mb-3">
                        <hr />
                        <label className={Styles.form_label}>
                          Upload Document
                        </label>
                        <div className="w-100 mb-4">
                          <input
                            type="file"
                            ref={fileInputRef}
                            multiple
                            onChange={(e) => {
                              const selectedFiles = e.currentTarget.files;
                              handleImages(Array.from(selectedFiles));
                            }}
                            hidden
                          />
                          <div
                            onClick={handleClick}
                            className={Style.upload_img}
                          >
                            <div style={{ textAlign: "center" }}>
                              <CloudUploadIcon />
                              <p className="mb-0">Upload your file here</p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Row>
                            {formik.values?.documents?.map((image, index) => {
                              return (
                                <Col md="4" className="mb-3">
                                  <ImageDisplay
                                    image={image}
                                    index={index}
                                    setShowdelete={(val) => setShowdelete(val)}
                                    setDeletedImageUrl={(val) => {
                                      setDeletedImageUrl(val);
                                    }}
                                    edit={edit}
                                    Style={Style}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
                <ErrorMsg hasError={formik.errors.submit}>
                  {formik.errors.submit}
                </ErrorMsg>
                <div
                  className={
                    Styles.form_bottom_btn +
                    " d-flex align-items-center gap-2 justify-content-end"
                  }
                >
                  <button
                    onClick={() =>
                      edit ? toggleDrawer() : formik.handleReset()
                    }
                    type="reset"
                    className={Styles.form_reset_btn + " btn btn-dark px-4"}
                  >
                    {edit ? "Cancel" : "Reset"}
                  </button>
                  <ButtonLoader
                    type="button"
                    color="primary "
                    onClickhandle={() => {
                      formik.handleSubmit();
                    }}
                    buttonName={edit ? "Save" : "Create"}
                    isLoading={isAddLoading}
                    loaderColor={"white"}
                    style={{
                      marginLeft: "12px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                    className={Styles.form_submit_btn + " btn btn-dark px-4"}
                    disabled={isAddLoading}
                  ></ButtonLoader>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      <DeleteModal
        open={showdelete}
        text={"document"}
        closeModal={() => {
          setShowdelete(false);
        }}
        handleDelete={() => {
          handleRemoveImage(deletedImageUrl);
        }}
      />
    </div>
  );
};
export default AddContact;
