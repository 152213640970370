import React, { useState } from "react";
import Style from "./broadcast.module.css";
import moment from "moment";
import useBroadcast from "./hooks/useBroadcast";
import StatusBar from "src/assets/images/mobile/StatusBar.svg";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import Logo from "src/assets/images/header/logo.png";
import { IoVideocamOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import BottomBar from "src/assets/images/mobile/bottom_bar.svg";
import PdfFile from "src/assets/images/mobile/pdf.svg";

const BroadCastPreview = ({ values, selectedTemplate, replaceVariables, selectedFile, detail }) => {

  function convertToKB(bytes) {
    if (bytes === 0) return '0 KB';
    const kilobytes = bytes / 1024;
    return `${kilobytes.toFixed(2)} KB`;
  }

  return (
    <>
      {!detail && <div className={Style.prev_heading + " mb-3"} style={{ padding: "0.5rem 2rem" }}> Preview </div>}
      <div className={detail ? Style.mobile_detail_div : Style.mobile_div}>
        <div className={Style.upper_div}>
          <img src={StatusBar} className="w-100" />
          <div className={Style.mobile_bar}>
            <div className="d-flex align-items-center">
              <ArrowBackIosNewSharpIcon sx={{ color: "#0E7EF8" }} />
              <img src={Logo} className={Style.preview_logo_img} style={{ width: "30px", height: "30px", marginRight: "5px", }} />
              <span className={Style.agency_name}>Propertyjinni</span>
            </div>
            <div className="d-flex align-items-center">
              <IoVideocamOutline />
              <IoCallOutline />
            </div>
          </div>
        </div>
        <div className={Style.mobile_btn}></div>
        <div className={Style.mobile_btn2}></div>
        <div className={Style.mobile_btn3}></div>
        <div className={Style.mobile_btn4}></div>
        <div className={" position-relative "} style={{ height: detail ? "calc(100vh - 57px - 90px - 54px - 50px)" : "calc(100vh - 57px - 90px - 54px - 90px)" }}>
          <div className={Style.background_preview + " " + Style.custom_scroll2 + " h-100"}>
            <div className="position-relative " style={{ padding: "1rem" }}>
              {((values.template_id && values.property) || detail) ? (
                <div className={Style.preview_card + " w-100"}>
                  <div className={Style.preview_text}>
                    {selectedTemplate?.header_type === "TEXT" ? (
                      <div className={Style.preview_header}>
                        {replaceVariables(selectedTemplate?.header_value,
                          values.template_info?.parameters?.find((param) => param.type === "header")?.variables
                        ) === "null" || null ? null : replaceVariables(selectedTemplate?.header_value,
                          values.template_info?.parameters?.find((param) => param.type === "header")?.variables
                        )}
                      </div>
                    ) : (

                      <div>
                        {selectedFile && (
                          !selectedFile?.url ? (
                            selectedFile.type?.startsWith("image/") ? (
                              <img src={URL.createObjectURL(selectedFile)} alt="Preview" className={Style.preview_img2} />
                            ) : selectedFile.type?.startsWith("video/") ? (
                              <video controls src={URL.createObjectURL(selectedFile)} className={Style.preview_video} />
                            ) : selectedFile.type === "application/pdf" ? (
                              <div className={Style.pdf_preview_box}>
                                <img src={PdfFile} alt="PDF Preview" />
                                <div>
                                  <h6>{selectedFile?.name}</h6>
                                  <div className="d-flex align-items-center">
                                    <p>{selectedFile?.size}</p>
                                    <span className={Style.dot}></span>
                                    <p>PDF</p>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : (
                            selectedFile?.url?.endsWith(".pdf") ? (
                              <div className={Style.pdf_preview_box}>
                                <img src={PdfFile} alt="PDF Preview" />
                                <div>
                                  <h6>{selectedFile?.name}</h6>
                                  <div className="d-flex align-items-center">
                                    <p>{convertToKB(selectedFile?.size)}</p>
                                    <span className={Style.dot}></span>
                                    <p>PDF</p>
                                  </div>
                                </div>
                              </div>
                            ) : selectedFile?.url?.match(/\.(jpg|jpeg|png|gif)$/) ? (
                              <img src={selectedFile?.url} alt="Image Preview" className={Style.preview_img2} />
                            ) : selectedFile?.url?.match(/\.(mp4|webm|ogg)$/) ? (
                              <video controls src={selectedFile?.url} className={Style.preview_video} />
                            ) : null
                          )
                        )}
                      </div>
                    )}
                    <div className={Style.preview_body + " mt-3"}>
                      {replaceVariables(selectedTemplate?.body_message,
                        values.template_info?.parameters?.find((param) => param.type === "body")?.variables
                      )}
                    </div>
                    <div className={Style.preview_footer}>
                      {selectedTemplate?.footer_message}
                    </div>
                    <div className={Style.preview_time}>
                      {moment().format("hh:mm a")}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={Style.preview_card + " w-100"}>
                  <div className={Style.no_template}>No template selected </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={Style.bootom_bar}>
          <img src={BottomBar} className="w-100" />
        </div>
      </div>
    </>
  );
};

export default BroadCastPreview;
