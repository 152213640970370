import { useState } from "react";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useTemplateData = () => {
  const userObj = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const [templateData, setTemplateData] = useState(null);
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailLoading, setIsDetailLoading] = useState(false);

  const fetchTemplates = async (agency_id, name, status, refresh) => {
    setIsLoading(true);
    try {
      let params ={
        agency_id: agency_id || "master",
        name: name, 
        status: status === "all" ? "" : status,
        refresh : refresh || false
      }
      const response = await Axios.get(`/whatsapp/template`,{params});
      setTemplateData(response?.data?.data || []);
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };

  const fetchById = async(id) => {
    setIsDetailLoading(true);
    try {
      const response = await Axios.get(`/whatsapp/template/${id}`);
      setTemplate(response?.data?.data || []);
      setIsDetailLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsDetailLoading(false);
    }
  }

const handleAddTemplate = async(formData) => {

    setIsLoading(true);
    try {
      await Axios.post(`whatsapp/template`, formData);
      Toast('Template Added successfully.', "success");
      navigate('/templates');
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
    
  };

  const handleUpdateTemplate = async(id, formData) => {
    setIsLoading(true);
    try {
      await Axios.put(`whatsapp/template/${id}`, formData);
      Toast('Template updated successfully.', "success");
        navigate('/templates');
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };

  return {
    handleAddTemplate,
    fetchTemplates,
    isLoading,
    templateData,
    fetchById,
    isDetailLoading,
    template,
    handleUpdateTemplate
  };
};
export default useTemplateData;
