import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import StepConnector from '@mui/material/StepConnector';
import { fontWeight } from '@mui/system';

const steps = [
  'Agency and Branch Details',
  'User Details',
  'Chatbot Settings',
  'Chatbot Builder',
  'Connect Channels'
];

export default function Index({ activeStep }) {

  const CustomStepper = styled(Stepper)({
    '& .MuiStepIcon-root': {
      '&.Mui-active': {
        color: '#933EFF !important', // Color for the active step icon
      },
      '&.Mui-completed': {
        color: '#933EFF !important', // Color for the completed step icon
      },
    },
    '& .MuiStepLabel-label': {
      color: '#A9A9AE ', // Default color for all step labels
      fontSize: "15.57px",
      fontWeight: "700 !important",
      fontFamily: "Inter",
    },
    '& .MuiStepLabel-root.Mui-active .MuiStepLabel-label': {
      color: '#933EFF !important', // Color for the active step label text
      fontWeight: "700 !important"
    },
    '& .MuiStepLabel-root.Mui-completed .MuiStepLabel-label': {
      color: '#933EFF !important', // Color for the completed step label text
      fontWeight: "700 !important"
    },
  });

  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    '& .MuiStepConnector-line': {
      borderWidth: 4,
      borderColor: '#eaeaf0', // Default color for all connectors
    },
    '&.Mui-completed .MuiStepConnector-line': {
      borderColor: '#933EFF', // Change border color for the completed step connectors
    },
    [`&.Mui-active .MuiStepConnector-line`]: {
      borderColor: activeStep > 0 ? '#933EFF' : '#eaeaf0', // Change border color only when active step is greater than 0
    },
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <CustomStepper
        activeStep={activeStep}
        connector={<CustomStepConnector />}
        alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </CustomStepper>
    </Box>
  );
}
