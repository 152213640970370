import React, { useEffect, useState } from 'react';
import styles from './contract.module.css';
import ContactEdit from './ContactEdit';
import { useParams } from 'react-router-dom';
import useHandleData from './hooks/useHandleData';
import moment from 'moment';
import Loader from 'src/layouts/loader/Loader';
import { Document, Page, pdfjs } from 'react-pdf';
import SubscriptionContractDetails from './payment/SubscriptionDetails';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ContractDetails = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  let { id } = useParams();
  const [previewHtml, setPreviewHtml] = useState();

  const { fetchPreviewData, previewData, previewLoading, setPreviewData, subscription, transactions } = useHandleData();

  useEffect(() => {
    fetchPreviewData(id);
  }, [id]);

  const updateHtml = (htmlData) => {
    let newHtmlData = htmlData;
    newHtmlData = newHtmlData.replaceAll('[DATED]', moment(previewData?.contractDate).format("DD/MM/YYYY"));
    newHtmlData = newHtmlData.replaceAll('[CUSTOMER]', previewData?.customerName);
    newHtmlData = newHtmlData.replaceAll('[DATE]', moment(previewData?.agreementDate).format("DD/MM/YYYY"));
    newHtmlData = newHtmlData.replaceAll('[FULL COMPANY NAME]', previewData?.companyName);
    newHtmlData = newHtmlData.replaceAll('[NUMBER]', previewData?.companyNumber);
    newHtmlData = newHtmlData.replaceAll('[REGISTERED OFFICE ADDRESS]', previewData?.registeredOffice);
    newHtmlData = newHtmlData.replaceAll('[SETUP FEES]', previewData?.setupFees);
    newHtmlData = newHtmlData.replaceAll('<a href="mailto:[CUSTOMER EMAIL]">[CUSTOMER EMAIL]</a>', `<a href="mailto:${previewData?.customerEmail}">${previewData?.customerEmail}</a>`);
    newHtmlData = newHtmlData.replaceAll('[SET UP FEES]', previewData?.setupFees);
    newHtmlData = newHtmlData.replaceAll('[NAME OF DIRECTOR]', previewData?.partyDirector);
    newHtmlData = newHtmlData.replaceAll('[DIRECTOR NAME]', previewData?.directorName);
    newHtmlData = newHtmlData.replaceAll('[SUBSCRIPTION AMOUNT]', previewData?.totalSubsFees);
    newHtmlData = newHtmlData.replaceAll('[BRANCH AMOUNT]', previewData?.branchFees);
    newHtmlData = newHtmlData.replaceAll('[NOTICE PERIOD]', previewData?.noticePeriod);
    newHtmlData = newHtmlData.replaceAll('[TRIAL PERIOD]', previewData?.trialPeriod)
    return newHtmlData;
  };

  useEffect(() => {
    if (previewData) {
      if (previewData.status === 1) {
        setPreviewHtml(previewData?.contractHtml);
      }

      if (previewData.status === 0) {
        setPreviewHtml(updateHtml(previewData?.contractMasterId?.contractHtml));
      }

      if (previewData.status === 2) {
        setPreviewHtml(updateHtml(previewData?.contractHtml));
      }
    }
  }, [previewData]);

  return (
    <>
      {previewLoading ? (
        <Loader size={"medium"} />
      ) : (
        <div className="d-flex h-100">
          <div className={`${styles.contract_preview} overflow-auto`} style={{ maxHeight: '100vh', position: "relative" }}>
            {previewData?.pdfUrl ?
              <Document file={previewData?.pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    className={styles.page}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    customTextRenderer={false}
                  />
                ))}
              </Document>
              : <div className={styles.contract_preview_box} dangerouslySetInnerHTML={{ __html: previewHtml }}></div>
            }
          </div>
          <div className="w-50 h-100">
            <div className={styles?.contract_wrapper + "  px-5 bg-white pb-3"}>
              <ContactEdit
                previewData={previewData}
                setPreviewData={setPreviewData}
                previewHtml={previewHtml}
                previewLoading={previewLoading}
              />
              {previewData?.status === 2 && <SubscriptionContractDetails subscription={subscription} transactionList={transactions} />}
            </div>  </div>
        </div>
      )}
    </>
  );
};

export default ContractDetails;
