import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { MenuItem, InputLabel, FormControl, TextField, Popover, ClickAwayListener, InputAdornment, IconButton, styled } from '@mui/material';

// Custom styled MenuItem to highlight selected item
const StyledMenuItem = styled(MenuItem)(({ theme, selected }) => ({
    backgroundColor: selected ? "#E2ECF8" : 'transparent',
    '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const MultiSelectWithoutSearchBox = ({
    allFeature, column, options, label, size, selectedValues, onChange,
    placeholder, noBorder, position, anchorPosition, transformPosition,
    arrowPosition, popupMargin, count, noOptionText, maxWidth
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    // Handle opening the Popover
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    // Handle closing the Popover
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    // Handle selecting or deselecting an option
    const handleSelectChange = (value) => {
        const newSelectedValues = selectedValues?.includes(value)
            ? selectedValues.filter(val => val !== value)
            : selectedValues?.length > 0 ? [...selectedValues, value] : [value];
        onChange(newSelectedValues);
    };

    // Handle clearing all selected values
    const handleClear = (event) => {
        event.stopPropagation();
        onChange([]); // Clear all selected values
        handleClose();
    };

    // Handle "Select All" functionality
    const handleSelectAll = () => {
        if (selectedValues.length === options.length) {
            onChange([]); // Deselect all if all are selected
        } else {
            const allOptionValues = options.map(option => option.value);
            onChange(allOptionValues); // Select all
        }
    };

    // Get the count of the selected values
    const selectedCount = selectedValues?.length;

    const formatSelectedValues = () => {
        const selectedLabels = selectedValues.map(val => options.find(option => option.value === val)?.label).filter(Boolean);
        return selectedLabels.join(', ');
    };

    // Determine if "Select All" should be checked
    const isAllSelected = selectedValues.length === options.length;

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>{label}</InputLabel>
            <TextField
                label={label}
                value={selectedCount ? formatSelectedValues() : ''}
                onClick={handleClick}
                readOnly
                autoComplete="off"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {(noBorder && selectedCount > 0) && (
                                <IconButton onClick={handleClear} size="small">
                                    <CloseIcon fontSize='small' />
                                </IconButton>
                            )}
                            {!noBorder && <ArrowDropDownIcon sx={{ color: '#156BDA' }} />}
                        </InputAdornment>
                    ),
                    startAdornment: (
                        noBorder && (<InputAdornment position="start">
                            <span style={{ color: "#156BDA", fontWeight: "500", cursor: "pointer", }}>{selectedCount ? `(${selectedCount}) ${placeholder}` : `${placeholder}`}</span>
                            <ArrowDropDownIcon sx={{ color: '#156BDA', marginLeft: '4px' }} />
                        </InputAdornment>)
                    ),
                    placeholder: noBorder ? '' : placeholder,
                }}
                InputLabelProps={{ style: { color: '#fff', padding: 0 } }}
                size={size || 'small'}
                variant="outlined"
                fullWidth
                sx={{
                    ...(!noBorder && { background: "#F5F8FA" }),
                    ...(noBorder && {
                        "& .MuiInputBase-root": {
                            padding: 0,
                            background: "white",
                        }
                    }),
                    cursor: "pointer",
                    input: {
                        cursor: "pointer",
                        color: noBorder ? '#156BDA' : "#000",
                        fontWeight: 600,
                        fontFamily: "inter",
                        fontSize: "14px",
                        height: "1.5rem",
                        background: "white",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        ...(noBorder && { display: noBorder ? "none" : "" }),
                        "&::placeholder": {
                            opacity: 1,
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        display: 'flex',
                        alignItems: 'center',
                        background: "white",
                        border: "2px solid #CBD6E2"
                    },
                    '& .MuiInputAdornment-root': {
                        marginLeft: 'auto',
                        background: "white",
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: "none"
                    }
                }}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorPosition || { vertical: 'bottom', horizontal: position || 'center' }}
                transformOrigin={transformPosition || { vertical: 'top', horizontal: position || 'center' }}
                style={popupMargin ? popupMargin : { marginTop: 14 }}
                slotProps={{
                    paper: {
                        sx: {
                            overflow: "visible", boxShadow: "none",
                            "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                ...(arrowPosition ? arrowPosition :
                                    {
                                        top: 0,
                                        right: position ? "80%" : "50%",
                                        borderTop: "2px solid #CBD6E2",
                                        borderLeft: "2px solid #CBD6E2",
                                        background: "white",
                                    }),
                                width: 16,
                                height: 16,
                                transform: "translateY(-50%) rotate(45deg)",
                                boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                            },
                        },
                    },
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div style={{ minWidth: 200, border: "2px solid #CBD6E2", borderRadius: "4px", boxShadow: "none" }}>
                        <div style={{ maxHeight: 200, overflow: 'auto', padding: "8px 0px", maxWidth: maxWidth || "500px" }} className='row'>
                            {/* "Select All" Option */}
                            <div className={column === 2 ? "col-6" : "col-12"}>

                                <StyledMenuItem
                                    onClick={handleSelectAll}
                                    style={{ background: isAllSelected ? "#E2ECF8" : "#fff", color: "#33475B", fontFamily: "Inter", fontSize: "14px", fontWeight: "600", textAlign: "left", textTransform: "capitalize" }}
                                >
                                    <Checkbox
                                        checked={isAllSelected}
                                        onChange={handleSelectAll}
                                        size="small"
                                        style={{ marginRight: 8, padding: 0 }}
                                    />
                                    Select All
                                </StyledMenuItem>
                            </div>

                            {/* Render individual options */}
                            {options?.length > 0 ? (options.map((option) => (
                                <div className={column === 2 ? "col-6" : "col-12"}>
                                    <StyledMenuItem
                                        key={option.value}
                                        selected={selectedValues?.includes(option.value)}
                                        onClick={() => handleSelectChange(option.value)}
                                        style={{ background: selectedValues?.includes(option.value) ? "#E2ECF8" : "#fff", color: "#33475B", fontFamily: "Inter", fontSize: "14px", fontWeight: "600", textAlign: "left", textTransform: "capitalize" }}
                                    >
                                        <Checkbox
                                            checked={selectedValues?.includes(option.value)}
                                            onChange={() => handleSelectChange(option.value)}
                                            size="small"
                                            style={{ marginRight: 8, padding: 0 }}
                                        />
                                        {option.label}
                                    </StyledMenuItem>
                                </div>
                            )))
                                : (<div style={{ textAlign: "center", color: "darkgray", fontWeight: "500", padding: "1rem" }}>{noOptionText ? noOptionText : "No Options"}</div>)
                            }
                        </div>
                    </div>
                </ClickAwayListener>
            </Popover>
        </FormControl>
    );
};

export default MultiSelectWithoutSearchBox;
