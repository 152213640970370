export const CHATBOT_NODE_TYPE = [
    { name: 'Greeting', value: 'greeting', visible: false },
    { name: 'Message', value: 'message', visible: true },
    { name: 'Question', value: 'question', visible: true },
    { name: 'Capture Visitor', value: 'capture-visitor', visible: true },
    { name: 'Capture Property', value: 'capture-property', visible: true },
    { name: 'User Details', value: 'capture-user-details', visible: true },
    { name: 'Location', value: 'capture-location', visible: true },
    { name: 'Live Chat', value: 'live-chat', visible: true },
    { name: 'Lead - Valuation', value: 'schedule-valuation', visible: true },
    { name: 'Lead - Viewing', value: 'property-viewing', visible: true },
    { name: 'Lead - Callback', value: 'arrange-callback', visible: true },
    { name: 'Option', value: 'option', visible: false },
    { name: 'Close chat', value: 'closing', visible: true },
];

export const svgPath = "M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z";


export const CUSTOM_FIELD_SELECTION = [
    { label: "Min Budget", value: "minBudget" },
    { label: "Max Budget", value: "maxBudget" },
    { label: "Type of Property", value: "property-type" },
    { label: "Man Bedroom", value: "minBedroom" },
    { label: "Mix Bedroom", value: "maxBedroom" },
    { label: "Man Bathroom", value: "minBathroom" },
    { label: "Mix Bathroom", value: "maxBathroom" },
    { label: "Location", value: "location" },
    { label: "Property Area (Sqft)", value: "property-area" },
    { label: "Radius", value: "radius" },
    { label: "Furnish Type", value: "furnish-type" },
    { label: "Other", value: "other" },
]

export const CUSTOM_FIELD_SELECTION_VIEW = [
    { label: "Budget (£)", value: "budget" },
    { label: "Type of Property", value: "property-type" },
    { label: "Bedroom", value: "bedroom" },
    { label: "Bathroom", value: "bathroom" },
    { label: "Location", value: "location" },
    { label: "Property Area (Sqft)", value: "property-area" },
    { label: "Radius (Miles)", value: "radius" },
    { label: "Furnish Type", value: "furnish-type" },
    { label: "Other", value: "other" },
]
