import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import Style from "./broadcast.module.css";
import { Table } from "reactstrap";
import BroadCastCard from "./BroadCastCard";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import TextInput from "src/components/forms/TextInput";
import { debounce } from "src/helper/commonHelp";
import SelectInput from "src/components/forms/SelectInput";
import {
  dateRangeOptions,
  sortByOptions,
} from "src/constants/formPicker";
import BasicPagination from "src/components/custom/pagination";
import { useNavigate } from "react-router-dom";
import useBroadcast from "./hooks/useBroadcast";
import Loader from "src/layouts/loader/Loader";
import NoDataFound2 from "src/components/custom/NoDataFound2";
import NoUsersFound from "src/assets/images/noDataFound/NoUsersFound.png";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { LuReplyAll } from "react-icons/lu";
import { MdOutlineCancelScheduleSend } from "react-icons/md";
import DateInput from "src/components/forms/DateInput";
import CachedIcon from "@mui/icons-material/Cached";

const Broadcast = () => {
  const navigation = useNavigate();
  const { fetchBroadcastList, isLoading, broadcastList, broadcastCount, broadcastTotalCount,  handleDelete, isDeleting } = useBroadcast();

  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [searchVal, setSearchVal] = useState("");
  const [sortBy, setSortBy] = useState("latest");
  const [selectedRange, setSelectedRange] = useState("7");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);


  const userTxtChangeDebounce = useCallback(
    debounce((txtval) => {
      setPage(1);
      fetchBroadcastList(txtval, sortBy, selectedRange, fromDate, toDate, page, rowPerPage);
    }, 1000),
    []
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleBroadcastData = () => {
    fetchBroadcastList(searchVal, sortBy, selectedRange, fromDate, toDate, page, rowPerPage)
  };

  useEffect(() => {
    if (selectedRange === 'custom') {
      console.log("123");
      if (fromDate && toDate) {
        handleBroadcastData();
      }
    }
    else {
      console.log("456");
      setFromDate(null);
      setToDate(null);
      handleBroadcastData();
    }
  }, [sortBy, page, rowPerPage, selectedRange, fromDate, toDate]);

  // useEffect(() => {
  //   if(selectedRange !== 'custom'){
  //     setFromDate(null);
  //     setToDate(null);
  //     handleBroadcastData();
  //   } 
  // }, [selectedRange]);

  // useEffect(() => {
  //   if(fromDate && toDate){
  //     handleBroadcastData();
  //   } 
  // }, [fromDate, toDate]);

  return (
    <div
      className="py-4 px-5"
      style={{ background: "#F8FAFE", minHeight: "calc(100vh - 56px)" }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.title}>Broadcast</div>
        <button
          className={Style.add_btn}
          onClick={() => {
            navigation("/broadcast/add");
          }}
        >
          <span>
            {" "}
            <AddIcon sx={{ fontSize: "16px" }} />{" "}
          </span>
          <span>New Broadcast</span>
        </button>
      </div>
      <div className="d-flex align-items-center">
        <div className={Style.subtitle}>The stats are displayed for</div>
        <div className="d-flex align-items-center w-100">
          <div style={{ marginLeft: "20px", width: "15%" }}>
            <SelectInput
              name="selectedRange"
              options={dateRangeOptions}
              selectedValue={selectedRange}
              onChange={(e) => {
                setSelectedRange(e.target.value);
              }}
              size={"small"}
              customClass={Style.custom_date_range_select}
              sx={{ backgroundColor: "#F5F8FA" }}
              noOptionText={"No option found."}
            />
          </div>
       
          {selectedRange === 'custom' && <div style={{ marginLeft: "20px" }}>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center" style={{ marginRight: "10px" }}>
                <div className={Style.date_label} style={{ marginRight: "5px" }}>From</div>
                <DateInput
                  label={""}
                  name="startDate"
                  value={fromDate}
                  onChange={(date) => { setFromDate(date) }}
                  customClass={Style.custom_date + " w-100 "}
                  size={"small"}
                  past="false"
                />
              </div>
              <div className="d-flex align-items-center"  >
                <div className={Style.date_label} style={{ marginRight: "5px" }}>To</div>
                <DateInput
                  label={""}
                  name="startDate"
                  value={toDate}
                  onChange={(date) => { setToDate(date) }}
                  customClass={Style.custom_date + " w-100 "}
                  size={"small"}
                  filterDate={fromDate}
                /> 
              </div>
            </div>
          </div>}
         
          <div style={{ marginLeft: "20px" }}>
            <CachedIcon
              sx={{ color: "#2379F0", cursor: "pointer" }}
              onClick={() => {
                fetchBroadcastList(searchVal, sortBy, selectedRange, fromDate, toDate, page, rowPerPage)
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className={Style.subheading}>Overview</div>
      {isLoading ? (
        <Loader borderWidth={"4px"} />
      ) :
        <div>
          <div className="mb-4">
            <Box className="">
              <Grid container spacing={3} className="mt-1">
              <Grid item xs={12} sm={12} md={2} lg={2}>
                  <div className={Style.overview_box}>
                    <div className="">
                      <h3>{broadcastCount?.total_sent || 0}</h3>
                      <p>All Recipient</p>
                    </div>
                    <div className={Style.icon}>
                      <CheckIcon />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <div className={Style.overview_box}>
                    <div className="">
                      <h3>{broadcastCount?.is_sent_count || 0}</h3>
                      <p>Sent</p>
                    </div>
                    <div className={Style.icon}>
                      <CheckIcon />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <div className={Style.overview_box}>
                    <div className="">
                      <h3>{broadcastCount?.is_delivered_count || 0}</h3>
                      <p>Delivered</p>
                    </div>
                    <div className={Style.icon}>
                      <DoneAllIcon />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <div className={Style.overview_box}>
                    <div className="">
                      <h3>{broadcastCount?.is_read_count || 0}</h3>
                      <p>Read</p>
                    </div>
                    <div className={Style.icon}>
                      <RemoveRedEyeOutlinedIcon />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <div className={Style.overview_box}>
                    <div className="">
                      <h3>{broadcastCount?.is_replied_count || 0}</h3>
                      <p>Replied</p>
                    </div>
                    <div className={Style.icon}>
                      <LuReplyAll />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <div className={Style.overview_box}>
                    <div className="">
                      <h3>{broadcastCount?.is_failed_count || 0}</h3>
                      <p>Failed</p>
                    </div>
                    <div className={Style.icon}>
                      <MdOutlineCancelScheduleSend />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className={Style.filter + " my-4"}>
            <div className="d-md-flex d-block  align-items-center ">
              <div className={Style.filter_heading}> Broadcast List:</div>
              <div className={Style.filter_subheading}> Sorted By:</div>
              <div style={{ width: "15%" }}>
                <SelectInput
                  name="sortBy"
                  // label="Agency"
                  options={sortByOptions}
                  selectedValue={sortBy}
                  onChange={(e) => {
                    setSortBy(e.target.value);
                  }}
                  size={"small"}
                  customClass={Style.custom_select}
                  sx={{ backgroundColor: "#F5F8FA" }}
                  noOptionText={"No option found."}
                />
              </div>
              <div
                style={{ position: "relative", marginLeft: "20px", width: "15%" }}
              >
                <TextInput
                  customClass={Style.custom_search}
                  type="text"
                  name="searchValue"
                  value={searchVal}
                  onChange={(e) => {
                    setSearchVal(e.target.value.trim());
                    userTxtChangeDebounce(e.target.value.trim());
                  }}
                  variant="outlined"
                  placeholder="Search... "
                  size={"small"}
                />
                {searchVal ? (
                  <div
                    className={Style.reset_btn}
                    onClick={() => {
                      setSearchVal("");
                      userTxtChangeDebounce("");
                    }}
                  >
                    <HighlightOffIcon
                      size={14}
                      className=" text-muted"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ) : (
                  <div className={Style.search_icon}>
                    {" "}
                    <SearchIcon currentPage />{" "}
                  </div>
                )}
              </div>
            </div>
          </div>

          <Table
            bordered
            className={Style.template_table + " mt-3 border position-relative"}
          >
            <thead>
              <tr>
                <th>Broadcast name</th>
                <th>Scheduled</th>
                <th style={{ textAlign: "center" }}>Successful</th>
                <th style={{ textAlign: "center" }}>Read</th>
                <th style={{ textAlign: "center" }}>Replied</th>
                <th>Recipient</th>
                <th>Failed</th>
                <th>Status</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            {broadcastList && broadcastList?.length > 0 ? (
              <tbody>
                {broadcastList?.map((template, index) => {
                  return (
                    <BroadCastCard
                      data={template}
                      page={page}
                      rowPerPage={rowPerPage}
                      index={index}
                      handleDelete={handleDelete}
                      isDeleting={isDeleting}
                      handleBroadcastData={handleBroadcastData}
                    />
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="9">
                    <NoDataFound2
                      width={"180px"}
                      text="No Data Found!"
                      image={NoUsersFound}
                    />
                  </td>
                </tr>
              </tbody>
            )}
          </Table>

          <div className="mt-2 pagination-position mb-3">
            <BasicPagination
              count={Math.ceil(broadcastTotalCount / rowPerPage)}
              currentPage={page}
              onChange={handlePageChange}
              rowPerPage={rowPerPage}
              onRowChange={(e) => {
                setRowPerPage(e.target.value);
                setPage(1);
              }}
              totalCount={broadcastTotalCount}
            />
          </div>
        </div>}
    </div>
  );
};

export default Broadcast;
