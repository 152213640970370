import React, { useState } from "react";

import Style from "./templates.module.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdModeEditOutline } from "react-icons/md";
import DeleteModal from "src/components/custom/DeleteModal";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";
import { useNavigate } from "react-router-dom";
import { style } from "@mui/system";
import VisibilityIcon from '@mui/icons-material/Visibility';
const TemplateCard = ({ data, index, rowPerPage, page, fetchTemplates }) => {

  const navigate = useNavigate();
  const [showdelete, setShowdelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);

    let params = {
      template_name: data?.name,
    };

    Axios.delete(`/whatsapp/template/${data?._id}`, { data: params })
      .then(() => {
        fetchTemplates();
        setShowdelete(false);
        Toast("Template deleted successfully.", "success");
      })
      .catch((err) => {
        Toast(err, "error");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const replaceUnderscoreWithSpace = (str) => {
    return str.replace(/_/g, ' ');
  }

  return (
    <tr>
      <td>{(page - 1) * rowPerPage + index + 1}</td>
      <td style={{ textTransform: "capitalize" }}>{replaceUnderscoreWithSpace(data?.name) || "NA"}</td>
      <td className={Style.templateBody} title={data?.body_message} >{data?.body_message || "NA"}
      </td>
      <td>{data?.agency || "NA"}</td>
      <td>{data?.category}</td>
      <td>
        <div className="d-flex align-items-center ">
          <div
            className={
              data?.status === "REJECTED"
                ? Style.rejected
                : data?.status === "APPROVED"
                  ? Style.approved
                  : // : data?.status === "PENDING"
                  Style.pending
              // : ""
            }
          ></div>
          <div style={{ fontSize: "12.5px" }}>
            {data?.status === "REJECTED"
              ? "Rejected"
              : data?.status === "APPROVED"
                ? "Approved"
                : "Pending"}
          </div>
        </div>
      </td>
      <td>{data?.template_id || "NA"}</td>
      <td>
        <div className="d-flex align-items-center">
          <div className={Style.action_btn} onClick={() => { navigate(`/templates/edit/${data?._id}`) }}
          >
            <VisibilityIcon style={{}} />
          </div>
          <div className={Style.action_btn} onClick={() => { setShowdelete(true); }} style={{ cursor: data?.status === "Pending" ? 'not-allowed' : 'pointer', }}>
            <RiDeleteBin6Line />
          </div>
        </div>
      </td>
      <DeleteModal
        open={showdelete}
        text={"temaplate"}
        closeModal={() => {
          setShowdelete(false);
        }}
        handleDelete={() => {
          handleDelete();
        }}
        isDeleting={isDeleting}
      />
    </tr>
  );
};

export default TemplateCard;
