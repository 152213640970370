import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useEffect } from "react";
import DrawerHeading from "./DrawerHeading";

const Drawer = ({
  isDrawerOpen,
  anchor,
  handleDrawer,
  children,
  drawerTitle,
}) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 600 }}
      role="presentation"
    >
      {children}
    </Box>
  );

  useEffect(
    () => {
      toggleDrawer(anchor, isDrawerOpen)();
    }, // eslint-disable-next-line
    [isDrawerOpen]
  );

  return (
    <>
      {isDrawerOpen && (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={() => {
              handleDrawer(false);
              toggleDrawer(anchor, false)();
            }}
            onOpen={toggleDrawer(anchor, true)}
          >
            <DrawerHeading
              title={drawerTitle}
              toggleDrawer={() => {
                handleDrawer(false);
                toggleDrawer(anchor, false)();
              }}
            />
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      )}
    </>
  );
};

export default Drawer;
