import { ButtonLoader,ButtonLoaderLeft } from "src/components/forms/ButtonLoader";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Style from "../AccountSetup2.module.css";

const Index = ( {handleNext,handleBack, isLoading}) => {
    return (
      <>
      <div 
          className="  w-100 d-flex align-items-center justify-content-end bg-wite px-5 mx-3" 
          style={{ bottom: 0 }}
        >
          <div >
          <ButtonLoaderLeft
            onClickhandle={()=>{handleBack()}}
            // isLoading={isLoading}
            buttonName="Back"
            loaderColor={"white"}
            className={Style.back_btn}
            icon={<KeyboardArrowLeftIcon />}
          ></ButtonLoaderLeft>
          </div>
          <div >

          <ButtonLoader
            onClickhandle={()=>{handleNext()}}
            isLoading={isLoading}
            buttonName="Next"
            loaderColor={"white"}
            className={Style.save_btn}
            icon={<KeyboardArrowRightIcon />}
          ></ButtonLoader>
          </div>
        </div>
       
      </>
    );
  }
  
  export default Index;
  