import React, { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import Downshift from "downshift";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./TagsInput.module.css";
import SuggestionPopover from "../../custom/SuggestionList";

export default function TagsInput({
  suggestions,
  tags,
  handleDelete,
  addChatTag,
  setTags,
  callApiToAddTag,
  rows,
  multiline,
  handleText,
  customClass,
  ...props
}) {
  const { placeholder, ...other } = props;

  const CustomChip = styled(Chip)({
    backgroundColor: "#4EA3FF42",
    color: "#00336B",
    fontFamily: "poppins",
    fontWeight: "500",
    fontSize: "12px",
    marginRight: "5px",
  });
  const CloseStyle = styled(CloseIcon)({
    fill: "#00336B", // Specify the color you want
    fontSize: "10px",
    width: "18px",
  });

  const [inputValue, setInputValue] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [listData, setListData] = useState(null)
  const [highlightedIndex1, setHighlightedIndex1] = useState(0);
  const handleSelectSuggestion = (suggestion) => {
    // Set selected suggestion in the input field
    const trimmedValue = suggestion?.trim();
    const existingValue = tags.filter((item) => item === trimmedValue);
    if (existingValue?.length > 0) {

    } else {
      setTags([...tags, trimmedValue]);
    }

    setInputValue("");
    setAnchorEl(null); // Close the popover
  };

  const handleClosePopover = () => {
    setAnchorEl(null); // Close the popover
  };


  async function handleKeyDown(event) {
    const suggestionsCount = listData?.length || 0;
    if (event.key === "ArrowUp") {
      setHighlightedIndex1(prevIndex => {
        const newIndex = prevIndex === 0 ? suggestionsCount - 1 : prevIndex - 1;
        return newIndex;
      });
      event.preventDefault();
    }

    if (event.key === "ArrowDown") {
      setHighlightedIndex1(prevIndex => {
        const newIndex = prevIndex === suggestionsCount - 1 ? 0 : prevIndex + 1;
        return newIndex;
      });
      event.preventDefault();
    }

    if (event.key === "Enter") {
      setInputValue("");
      const trimmedValue = highlightedIndex1 >= 0 && highlightedIndex1 < suggestionsCount ? listData[highlightedIndex1] : event.target.value.trim();
      if (!trimmedValue?.length) {
        return;
      }
      const matchingSuggestion = suggestions?.find(
        (suggestion) =>
          suggestion?.toLowerCase() === trimmedValue?.toLowerCase()
      );

      if (matchingSuggestion) {
        if (!tags?.includes(matchingSuggestion)) {
          setTags([...tags, trimmedValue]);
          // addChatTag(trimmedValue);
        }
      } else {
        // callApiToAddTag(trimmedValue);
        if (!tags?.includes(trimmedValue)) {
          console.log(tags);

          setTags([...tags, trimmedValue]);
        }
      }
      setHighlightedIndex1(0)
      setListData(null)
    }

    if (tags?.length && !inputValue?.length && event.key === "Backspace") {
      handleDelete(tags[tags?.length - 1]);
    }
  }

  function handleChange(item) {
    setInputValue("");
    if (!tags?.includes(item)) {
      setTags([...tags, item]);
      addChatTag(item);
    }
  }

  const DataItemHandler = (data) => {
    if (data) {
      setListData(data);
    }
  }

  useEffect(() => {
    DataItemHandler(listData);
  }, [tags, suggestions, highlightedIndex1]);



  return (
    <React.Fragment>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        tags={tags}
      >
        {({
          getInputProps,
          inputValue: downshiftInputValue,
        }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          });

          return (
            <div className={styles.demo}>
              <TextField
                InputProps={{
                  disableUnderline: true, // <== added this
                  startAdornment: tags?.map((item) => (
                    <CustomChip
                      key={item}
                      tabIndex={-1}
                      label={item?.split('-')
                        ?.map((word, index) => {
                          return word?.toString()?.charAt(0)?.toUpperCase() + word?.slice(1);
                        })?.join(' ')}
                      onDelete={() => handleDelete(item)}
                      deleteIcon={<CloseStyle />}
                    />
                  )),

                  onBlur,
                  onChange: (event) => {
                    setAnchorEl(event.currentTarget);
                    setInputValue(event.target.value);
                    onChange(event);
                  },
                  onFocus,
                }}
                {...other}
                {...inputProps}
                size="small"
                multiline={multiline ? true : false}
                minRows={rows}
                className={customClass}
              />

              <SuggestionPopover
                inputText={inputValue}
                suggestions={suggestions}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onSelect={handleSelectSuggestion}
                onClose={handleClosePopover}
                handleKeyDown={DataItemHandler}
                highlightedIndex={highlightedIndex1}
                setHighlightedIndex={setHighlightedIndex1}
              />
            </div>
          );
        }}
      </Downshift>
    </React.Fragment>
  );
}
