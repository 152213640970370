import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Style from "./AddProperty.module.css";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import questionImg from "src/assets/images/property/questionImg.svg";
import TextInput from "src/components/forms/TextInput";
import AstericSymbol from "src/components/custom/astericSymbol";
import SelectInput from "src/components/forms/SelectInput";
import ButtonGroup from '@mui/material/ButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import * as Yup from "yup";
import ErrorMsg from "src/components/custom/ErrorMsg";
import DateInput from 'src/components/forms/DateInput';
import { useSelector } from "react-redux";
import SelectWithSearch from "src/components/forms/SelectWithSearch";
import CommonCheckbox from 'src/components/forms/CommonCheckbox';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { PriceQualifireData, CurrencyData, priceOptions, TentureOption } from "src/constants/formPicker";
const PropertyInformation = ({ handleNext, handleBack, PropertyInfromationService, propertyData, propertyCategory, listingType, MasterListData,
    fetchMasterList, StatusData, fetchStatusList, fetchFeaturesList, FeatureList, singleData, fetchSingleListId, contactName, ReferenceId }) => {
    const branchList = useSelector((state) => state.auth.branchList);

    useEffect(() => {
        if (singleData?.rentInfo || singleData?.price) {
            formik.setFieldValue('reference', singleData?.reference);
            formik.setFieldValue('agencyBranch', singleData?.agencyBranch?._id);
            formik.setFieldValue('agentName', singleData?.agentName);
            formik.setFieldValue('propertyType', singleData?.propertyType);
            formik.setFieldValue('propertyStatus', singleData?.propertyStatus);
            formik.setFieldValue('onTheMarket', singleData?.onTheMarket);
            if (propertyCategory === "residential" && (listingType === "sell" || listingType === "let")) {
                formik.setFieldValue('noOfBedroom', singleData?.noOfBedroom);
                formik.setFieldValue('noOfBathroom', singleData?.noOfBathroom);
                formik.setFieldValue('noOfReception', singleData?.noOfReception);
            }
            if ((propertyCategory === "residential" && listingType === "sell") ||
                (propertyCategory === "commercial" && listingType === "sell")) {
                formik.setFieldValue('tenure', singleData?.tenure);
            }
            formik.setFieldValue('floorArea', singleData?.floorArea);
            formik.setFieldValue('priceLabel', singleData?.price?.symbol);
            formik.setFieldValue('price1', singleData?.price?.price);
            formik.setFieldValue('fromPriceCurrency', singleData?.valuationPrice?.fromPrice);
            formik.setFieldValue('ToPriceCurrency', singleData?.valuationPrice?.toPrice);
            formik.setFieldValue('minPrice', singleData?.minAcceptablePrice?.amount);
            formik.setFieldValue('dateAvailable', singleData?.dateAvailable);
            formik.setFieldValue('ToPriceCurrency', singleData?.valuationPrice?.toPrice);
            formik.setFieldValue('minPrice', singleData?.minAcceptablePrice?.amount);
            formik.setFieldValue('tenureAdditionalInformation', singleData?.tenureAdditionalInformation);
            formik.setFieldValue('PriceQulifire', singleData?.price?.PriceQualifier);
            formik.setFieldValue('price', singleData?.price?.symbol);
            formik.setFieldValue('priceLabel', singleData?.price?.currency);

            formik.setFieldValue('RentAmount', singleData?.rentInfo?.rent?.amount);
            formik.setFieldValue('Rentcurrency', singleData?.rentInfo?.rent?.currency);
            formik.setFieldValue('RentType', singleData?.rentInfo?.rent?.type);
            formik.setFieldValue('minRentPrice', singleData?.rentInfo?.minimumAcceptableRent?.amount);
            formik.setFieldValue('minvaluationRentcurrency', singleData?.rentInfo?.minimumAcceptableRent?.currency);
            formik.setFieldValue('HmoCheck', singleData?.isHmo);
            formik.setFieldValue('depositamount', singleData?.rentInfo?.deposit?.amount);
            formik.setFieldValue('depositamountCurrency', singleData?.rentInfo?.deposit?.currency);
            formik.setFieldValue('holdingDepositPrice', singleData?.rentInfo?.holdingDeposit?.amount);
            formik.setFieldValue('holdingDepositCurrency', singleData?.rentInfo?.holdingDeposit?.currency);
            formik.setFieldValue('ValualationRentfromPrice', singleData?.rentInfo?.valuationRent?.fromPrice);
            formik.setFieldValue('ValualationRenttoPrice', singleData?.rentInfo?.valuationRent?.toPrice);
            formik.setFieldValue('valuationRentcurrency', singleData?.rentInfo?.valuationRent?.currency);

            if (singleData?.features?.length > 0) {
                formik.setFieldValue('features', singleData.features);
            }
        }




    }, [singleData])

    const convertBranchArrayToLabelValue = (array) => {
        return array?.map((item) => ({
            label: item?.branchName,
            value: item?._id,
        }));
    };
    const validationSchema = Yup.object({
        reference: Yup.string().required("Reference is required."),
        agencyBranch: Yup.string().required("Agency branch is required."),
        agentName: Yup.string()
            .matches(/^[a-zA-Z ]+$/, "Agent name  can only contain alphabetical characters.")
            .required("Agent name is required.")
            .test(
                "not-start-with-space",
                "Agent name cannot start with a space.",
                (value) => {
                    return !value || !value.startsWith(" ");
                }
            ).min(2, "Agent name must be at least 2 characters long.")
            .max(30, "Agemt name cannot be more than 30 characters long."),
        propertyType: Yup.string().required("Property type is required."),
        propertyStatus: Yup.string().required("Property status is required."),
        noOfBedroom: propertyCategory === "residential" && (listingType === "sell" || listingType === "let")
            ? Yup.string().required("Bedrooms is required.")
            : Yup.string(),

        noOfBathroom: propertyCategory === "residential" && (listingType === "sell" || listingType === "let")
            ? Yup.string().required("Bathroom is required.")
            : Yup.string(),
        noOfReception: propertyCategory === "residential" && (listingType === "sell" || listingType === "let")
            ? Yup.string().required("Reception is required.")
            : Yup.string(),
        floorArea: Yup.string().required("Floor area  is required."),
        tenure: ((propertyCategory === "residential" && listingType === "sell") ||
            (propertyCategory === "commercial" && listingType === "sell")) ? Yup.string().required("Tenure is required.") : Yup.string(),

        RentAmount: ((propertyCategory === "residential" && listingType === "let") ||
            (propertyCategory === "commercial" && listingType === "let")) ? Yup.string().required("Rent is required.") : Yup.string(),
        price1: ((propertyCategory === "residential" && listingType === "sell") ||
            (propertyCategory === "commercial" && listingType === "sell")) ? Yup.string().required("Price is required.") : Yup.string(),
        ToPriceCurrency: Yup.string()
            .when("fromPriceCurrency", (fromPriceCurrency, schema) => {
                return schema.test({
                    test: function (value) {
                        if (Number(value) < Number(fromPriceCurrency)) {
                            return this.createError({
                                message: "To price must be greater than or equal to from price.",
                            });
                        }
                        return true;
                    },
                });
            }),
        features: Yup.array()
            .of(Yup.string().required("Each feature must be a string.")) // Ensures each item is a string
            .required("Features is required.") // Ensures the array itself is required
            .min(1, "Features is required."),// Ensures the array contains at least one feature

        ValualationRenttoPrice: Yup.string()
            .when("ValualationRentfromPrice", (ValualationRentfromPrice, schema) => {
                return schema.test({
                    test: function (value) {
                        if (Number(value) < Number(ValualationRentfromPrice)) {
                            return this.createError({
                                message: "To rent must be greater than or equal to from rent.",
                            });
                        }
                        return true;
                    },
                });
            }),


    });
    useEffect(() => {
        formik.setFieldValue('reference', ReferenceId?.propertyId);
    }, [ReferenceId?.propertyId])
    const formik = useFormik({
        initialValues: {
            reference: "",
            agencyBranch: "",
            agentName: "",
            propertyType: "",
            propertyStatus: "",
            onTheMarket: "No",
            noOfBedroom: "",
            noOfBathroom: "",
            noOfReception: "",
            floorArea: "",
            price: "Pound",
            priceLabel: "Pound",
            PriceQulifire: "",
            stepCompleted: 1,
            price1: "",
            valuecurrency: "Pound",
            fromPriceCurrency: "",
            ToPriceCurrency: "",
            minCurrency: "Pound",
            minPrice: "",
            dateAvailable: null,
            tenure: "",
            tenureAdditionalInformation: "",
            features: [],
            RentType: "",
            Rentcurrency: "Pound",
            RentAmount: "",
            isPriceOnApplication: 'false',
            holdingDepositCurrency: "Pound",
            holdingDepositPrice: "",
            depositamount: "",
            depositamountCurrency: "Pound",
            valuationRentcurrency: "Pound",
            valuationRentFromPrice: "",
            valuationRentToPrice: "",
            minRentPrice: "",
            minvaluationRentcurrency: "Pound",
            ValualationRenttoPrice: "",
            ValualationRentfromPrice: "",
            valuationRentcurrency: "Pound",
            HmoCheck: "false"
        },
        validationSchema,
        validateOnChange: true,
        onSubmit: async (values) => {
            const price = {
                currency: values?.price,
                price: values?.price1,
                symbol: values?.priceLabel,
                PriceQualifier: values?.PriceQulifire
            }
            const valuationPrice = {
                currency: values.valuecurrency,
                fromPrice: values.fromPriceCurrency,
                toPrice: values.ToPriceCurrency
            }
            const minAcceptablePrice = {
                currency: values.minCurrency,
                amount: values.minPrice,

            }

            const RentValue = {
                rent: {
                    type: values.RentType,
                    currency: values.Rentcurrency,
                    amount: values.RentAmount
                },
                valuationRent: {
                    currency: values?.valuationRentcurrency,
                    fromPrice: values.ValualationRentfromPrice,
                    toPrice: values.ValualationRenttoPrice

                },
                minimumAcceptableRent: {
                    currency: values.minvaluationRentcurrency,
                    amount: values.minRentPrice
                },
                deposit: {
                    currency: values.depositamountCurrency,
                    amount: values.depositamount
                },
                holdingDeposit: {
                    currency: values.holdingDepositCurrency,
                    amount: values.holdingDepositPrice
                }
            }
            handleNext()
            const formData = new FormData();
            formData.append("reference", values.reference);
            formData.append("agentName", values.agentName);
            formData.append("agencyBranch", values.agencyBranch);
            formData.append("propertyType", values.propertyType);
            formData.append("propertyStatus", values.propertyStatus);
            formData.append("onTheMarket", values.onTheMarket);
            formData.append("floorArea", values.floorArea);


            if (
                (propertyCategory === "commercial" && listingType === "let")) {
                formData.append("rentInfo", JSON.stringify(RentValue));
                formData.append("isPriceOnApplication", values.isPriceOnApplication);
            }

            if ((propertyCategory === "commercial" && listingType === "sell")) {

                formData.append("price", JSON.stringify(price));
                formData.append("valuationPrice", JSON.stringify(valuationPrice));
                formData.append("minAcceptablePrice", JSON.stringify(minAcceptablePrice));
                formData.append("tenure", values.tenure);
                formData.append("tenureAdditionalInformation", values.tenureAdditionalInformation);

            }

            if (propertyCategory === "residential" && listingType === "let") {
                formData.append("rentInfo", JSON.stringify(RentValue));
                formData.append("noOfBedroom", values.noOfBedroom);
                formData.append("noOfBathroom", values.noOfBathroom);
                formData.append("noOfReception", values.noOfReception);
                formData.append("isHmo", values.HmoCheck);

            }

            if (propertyCategory === "residential" && listingType === "sell") {
                formData.append("noOfBedroom", values.noOfBedroom);
                formData.append("noOfBathroom", values.noOfBathroom);
                formData.append("noOfReception", values.noOfReception);

                formData.append("price", JSON.stringify(price));
                formData.append("valuationPrice", JSON.stringify(valuationPrice));
                formData.append("minAcceptablePrice", JSON.stringify(minAcceptablePrice));
                formData.append("dateAvailable", values.dateAvailable);
                formData.append("tenure", values.tenure);
                formData.append("tenureAdditionalInformation", values.tenureAdditionalInformation);
            }


            values.features.forEach((feature, index) => {

                formData.append(`features[]`, feature);
            });

            formData.append("propertyId", propertyData);
            PropertyInfromationService(formData, 2);
        },
    });

    useEffect(() => {
        fetchMasterList("propertytype", `${listingType + "-" + propertyCategory}`);
        fetchStatusList("propertystatus", `${listingType + "-" + propertyCategory}`);
        fetchFeaturesList("features")
    }, []);



    const convertPropertyToLabelValue = (array) => {
        return array?.map((item) => ({
            label: item?.label,
            value: item?.value,
        }));
    };


    const handleFeatureChange = (event) => {
        const { value } = event.target; // Get the checkbox value (ID)
        const { features } = formik.values; // Extract the features array

        if (features.includes(value)) {
            // Remove unchecked feature by ID
            formik.setFieldValue(
                'features',
                features.filter((featureId) => featureId !== value)
            );
        } else {
            // Add checked feature by ID
            formik.setFieldValue('features', [...features, value]);
        }
    };



    const handleBackButton = () => {
        fetchSingleListId(propertyData);
        handleBack()
    }



    function TooltipSpilitName(name, maxLength) {
        if (name.length > maxLength) {
            return name.substring(0, maxLength - 3) + "...";
        } else {
            return name;
        }
    }


    return (
        <div>
            <div style={{ padding: '10px 40px' }}>
                <Box className={Style.lisitngDetailHeader}>
                    <Box>
                        <Typography variant="h6" className={Style.listDetailsFont}>Property Information</Typography>
                    </Box>
                    <Box
                    >
                        <div className={Style.propertycss1 + " " + Style.button_nav}>
                            <div style={{ marginRight: '12px' }}>
                                <Button color="secondary"
                                    className={Style.delete_btn + " " + Style.edit_btn} onClick={handleBackButton}>
                                    <KeyboardArrowLeftIcon /> Back
                                </Button>
                            </div>
                            <div>
                                <ButtonLoader
                                    type={"button"}
                                    buttonName="Next"
                                    loaderColor={"white"}
                                    className={Style.Add_btn}
                                    onClickhandle={formik.handleSubmit}
                                    icon={<KeyboardArrowRightIcon />}

                                />

                            </div>
                        </div>
                    </Box>
                </Box>
                <hr />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <label className={Style.labelform}>
                            Refrence <AstericSymbol />
                        </label>
                        <TextInput
                            customClass={Style.input_custom + " w-100 "}
                            type="text"
                            name="reference"
                            value={formik.values.reference || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            placeholder="Reference"
                            size={"small"}
                        />
                        <ErrorMsg hasError={formik.touched.reference && formik.errors.reference}>
                            {formik.errors.reference}
                        </ErrorMsg>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} className="pt-0">
                        <label className={Style.labelform}>
                            Branch <AstericSymbol />
                        </label>

                        <SelectWithSearch
                            name="agencyBranch"
                            options={branchList?.length > 0 ? convertBranchArrayToLabelValue(branchList) : []}
                            value={formik.values.agencyBranch}
                            onChange={(e, obj) => { formik.setFieldValue("agencyBranch", obj); }}
                            size={"small"}
                            noOptionsText={"No branch found."}
                            placeholder={"Select"}
                            className={Style.select_input}
                        />
                        <ErrorMsg hasError={formik.touched.agencyBranch && formik.errors.agencyBranch}>
                            {formik.errors.agencyBranch}
                        </ErrorMsg>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pt-0">
                        <label className={Style.labelform}>
                            Agent Name <AstericSymbol />
                        </label>
                        <TextInput
                            customClass={Style.input_custom + " w-100 "}
                            type="text"
                            name="agentName"
                            value={formik.values.agentName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            placeholder="Agent name"
                            size={"small"}
                        />
                        <ErrorMsg hasError={formik.touched.agentName && formik.errors.agentName}>
                            {formik.errors.agentName}
                        </ErrorMsg>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}> Type of Property  <AstericSymbol /> </label>
                        <div className="w-100">
                            <SelectInput
                                name="propertyType"
                                options={MasterListData?.length > 0 ? convertPropertyToLabelValue(MasterListData) : []}
                                selectedValue={formik.values.propertyType}
                                onChange={(e) => formik.setFieldValue("propertyType", e.target.value)}
                                size={"small"}
                                customClass={Style.custom_select}
                                noOptionText={"No template found."}
                                placeholder={"Select type of property"}
                            />
                            <ErrorMsg hasError={formik.touched.propertyType && formik.errors.propertyType} > {formik.errors.propertyType} </ErrorMsg>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}>Property Status  <AstericSymbol /> </label>
                        <div className="w-100">
                            <SelectInput
                                name="propertyStatus"
                                options={StatusData?.length > 0 ? convertPropertyToLabelValue(StatusData) : []}
                                selectedValue={formik.values.propertyStatus}
                                onChange={(e) => formik.setFieldValue("propertyStatus", e.target.value)}
                                size={"small"}
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: "#F5F8FA" }}
                                noOptionText={"No template found."}
                                placeholder={"Select property staus"}
                            />
                            <ErrorMsg hasError={formik.touched.propertyStatus && formik.errors.propertyStatus} > {formik.errors.propertyStatus} </ErrorMsg>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className='pt-0'>
                        <label className={Style.labelform}>
                            On The Market
                            <ButtonGroup
                                disableElevation
                                variant="outlined"
                                aria-label="On The Market Button Group"
                                sx={{
                                    borderColor: '#C9CDDB',
                                    borderWidth: '1.15px',
                                    marginLeft: '15px'
                                }}
                            >
                                <Button
                                    sx={{
                                        backgroundColor: formik.values.onTheMarket === "Yes" ? '#DEE2E6' : '#f8f8f8',
                                        borderColor: '#C9CDDB',
                                        borderWidth: '1.15px',
                                        textTransform: 'capitalize',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#DEE2E6',
                                            borderColor: '#C9CDDB',
                                        },
                                    }}
                                    onClick={() => formik.setFieldValue('onTheMarket', 'Yes')} // Set value to Yes
                                >
                                    Yes
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: formik.values.onTheMarket === "No" ? '#DEE2E6' : '#f8f8f8',
                                        borderColor: '#C9CDDB',
                                        borderWidth: '1.15px',
                                        textTransform: 'capitalize',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#DEE2E6',
                                            borderColor: '#C9CDDB',
                                        },
                                    }}
                                    onClick={() => formik.setFieldValue('onTheMarket', 'No')} // Set value to No
                                >
                                    No
                                </Button>
                            </ButtonGroup>
                        </label>
                    </Grid>
                    {propertyCategory === "residential" && listingType === "sell" || (propertyCategory === "residential" && listingType === "let") ?
                        <>
                            <Grid item xs={12} sm={12} md={4} className="pt-0">
                                <label className={Style.labelform}>
                                    Bedrooms <AstericSymbol />
                                </label>
                                <TextInput
                                    customClass={Style.input_custom + " w-100 "}
                                    type="text"
                                    name="noOfBedroom"
                                    variant="outlined"
                                    placeholder="Bedrooms"
                                    size={"small"}
                                    value={formik.values.noOfBedroom}
                                    // onChange={formik.handleChange}

                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (/^\d*$/.test(value)) {
                                            formik.setFieldValue("noOfBedroom", value);
                                        }
                                    }}
                                    onBlur={formik.handleBlur} />
                                <ErrorMsg hasError={formik.touched.noOfBedroom && formik.errors.noOfBedroom}>
                                    {formik.errors.noOfBedroom}
                                </ErrorMsg>
                            </Grid><Grid item xs={12} sm={12} md={4} className="pt-0">
                                <label className={Style.labelform}>
                                    Bathrooms <AstericSymbol />
                                </label>
                                <TextInput
                                    customClass={Style.input_custom + " w-100 "}
                                    type="text"
                                    name="noOfBathroom"
                                    variant="outlined"
                                    placeholder="Bathrooms"
                                    size={"small"}
                                    value={formik.values.noOfBathroom}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (/^\d*$/.test(value)) {
                                            formik.setFieldValue("noOfBathroom", value);
                                        }
                                    }}
                                    onBlur={formik.handleBlur} />
                                <ErrorMsg hasError={formik.touched.noOfBathroom && formik.errors.noOfBathroom}>
                                    {formik.errors.noOfBathroom}
                                </ErrorMsg>
                            </Grid><Grid item xs={12} sm={12} md={4} className="pt-0">
                                <label className={Style.labelform}>
                                    Reception <AstericSymbol />
                                </label>
                                <TextInput
                                    customClass={Style.input_custom + " w-100 "}
                                    type="text"
                                    name="noOfReception"
                                    variant="outlined"
                                    placeholder="Reception"
                                    size={"small"}
                                    value={formik.values.noOfReception}
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (/^\d*$/.test(value)) {
                                            formik.setFieldValue("noOfReception", value);
                                        }
                                    }}
                                    onBlur={formik.handleBlur} />
                                <ErrorMsg hasError={formik.touched.noOfReception && formik.errors.noOfReception}>
                                    {formik.errors.noOfReception}
                                </ErrorMsg>
                            </Grid></> : ""}

                    <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}>
                            Floor Area<AstericSymbol />  <Tooltip title={TooltipSpilitName("Provide area in any valid format (eg 100ft, 100ft2, 100sqft, 9.29m2, 9.29m.", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                        <TextInput
                            customClass={Style.input_custom + " w-100 "}
                            type="text"
                            name="floorArea"
                            value={formik.values.floorArea}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            placeholder=" Floor area"
                            size={"small"}
                        />
                        <ErrorMsg hasError={formik.touched.floorArea && formik.errors.floorArea}>
                            {formik.errors.floorArea}
                        </ErrorMsg>
                    </Grid>
                    {propertyCategory === "residential" && listingType === "let" ? <>
                        <Grid item xs={12} sm={12} md={12} className="pt-0" style={{ marginTop: '15px' }}>
                            <FormGroup>
                                <CommonCheckbox
                                    label="Is HMO?"
                                    checked={formik.values.HmoCheck}
                                    onChange={(e) => formik.setFieldValue('HmoCheck', e.target.checked)}
                                />
                            </FormGroup>
                        </Grid>
                    </> : ""}

                    {propertyCategory === "residential" && listingType === "let" || propertyCategory === "commercial" && listingType === "let" ? <>
                        <Grid item xs={12}>
                            <label className={Style.labelform}>
                                Rent <AstericSymbol />
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} className="pt-0" >

                            <div className="w-100">
                                <SelectInput
                                    name="Rentcurrency"
                                    options={CurrencyData}
                                    selectedValue={formik.values.Rentcurrency}
                                    onChange={(e) => {
                                        const selectedOption = CurrencyData.find(option => option.value === e.target.value);
                                        formik.setFieldValue("Rentcurrency", selectedOption.value);
                                        formik.setFieldValue("priceLabel", selectedOption.label);
                                    }}
                                    size={"small"}
                                    customClass={Style.custom_select}
                                    sx={{ backgroundColor: "#F5F8FA" }}
                                    noOptionText={"No template found."}
                                    placeholder={"£"}


                                />

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4.5} className="pt-0" >

                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="RentAmount"
                                variant="outlined"
                                placeholder="Rent price"
                                size={"small"}
                                value={formik.values.RentAmount}
                                // onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^\d*$/.test(value)) {
                                        formik.setFieldValue("RentAmount", value);
                                    }
                                }}

                            />
                            <ErrorMsg hasError={formik.touched.RentAmount && formik.errors.RentAmount}>
                                {formik.errors.RentAmount}
                            </ErrorMsg>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6.5} className="pt-0">

                            <div className="w-100">
                                <SelectInput
                                    name="RentType"
                                    options={priceOptions}
                                    selectedValue={formik.values.RentType}
                                    onChange={(e) => formik.setFieldValue("RentType", e.target.value)}
                                    size={"small"}
                                    customClass={Style.custom_select}
                                    sx={{ backgroundColor: "#F5F8FA" }}
                                    noOptionText={"No template found."}
                                    placeholder={"Select"} />
                            </div>
                        </Grid>



                    </> : <>

                        <Grid item md={12}>
                            <label className={Style.labelform}>
                                Price <AstericSymbol />
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} className="pt-0" >
                            <div className="w-100">
                                <SelectInput
                                    name="price"
                                    options={CurrencyData}
                                    selectedValue={formik.values.price}
                                    onChange={(e) => {
                                        const selectedOption = CurrencyData.find(option => option.value === e.target.value);
                                        formik.setFieldValue("price", selectedOption.value);
                                        formik.setFieldValue("priceLabel", selectedOption.label);
                                    }}
                                    size={"small"}
                                    customClass={Style.custom_select}
                                    sx={{ backgroundColor: "#F5F8FA" }}
                                    noOptionText={"No template found."}
                                    placeholder={"£"} />

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4.5} className="pt-0" >

                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="price1"
                                variant="outlined"
                                placeholder="Price"
                                size={"small"}
                                value={formik.values.price1}
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^\d*$/.test(value)) {
                                        formik.setFieldValue("price1", value);
                                    }
                                }}
                                onBlur={formik.handleBlur} />
                            <ErrorMsg hasError={formik.touched.price1 && formik.errors.price1}>
                                {formik.errors.price1}
                            </ErrorMsg>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6.5} className="pt-0">

                            <div className="w-100">
                                <SelectInput
                                    name="PriceQulifire"
                                    options={PriceQualifireData}
                                    selectedValue={formik.values.PriceQulifire}
                                    onChange={(e) => formik.setFieldValue("PriceQulifire", e.target.value)}
                                    size={"small"}
                                    customClass={Style.custom_select}
                                    sx={{ backgroundColor: "#F5F8FA" }}
                                    noOptionText={"No template found."}
                                    placeholder={"Select"} />
                            </div>
                        </Grid>

                    </>}

                    {listingType === "let" && propertyCategory === "commercial" ? <>
                        <Grid item xs={12} sm={12} md={12} className="pt-0" style={{ marginTop: '15px' }}>
                            <FormGroup>
                                <CommonCheckbox
                                    label="Price on application?"
                                    checked={formik.values.isPriceOnApplication}
                                    onChange={(e) => formik.setFieldValue('isPriceOnApplication', e.target.checked)}
                                />
                            </FormGroup>
                        </Grid>
                    </> : ""}
                    {propertyCategory === "commercial" && listingType === "let"
                        ? <>
                            <Grid item xs={12} sm={12} md={12} className="pt-0">
                                <label className={Style.labelform}>
                                    Valuation  Rent

                                </label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} className="pt-0">
                                <div className="w-100">
                                    <SelectInput
                                        name="valuationRentcurrency"
                                        options={CurrencyData}
                                        selectedValue={formik.values.valuationRentcurrency}
                                        onChange={(e) => formik.setFieldValue("valuationRentcurrency", e.target.value)}
                                        size={"small"}
                                        customClass={Style.custom_select}
                                        sx={{ backgroundColor: "#F5F8FA" }}
                                        noOptionText={"No template found."}
                                        placeholder={"£"}

                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4.5} className="pt-0">
                                <TextInput
                                    customClass={Style.input_custom + " w-100 "}
                                    type="text"
                                    name="ValualationRentfromPrice"
                                    variant="outlined"
                                    placeholder="Price"
                                    size={"small"}
                                    value={formik.values.ValualationRentfromPrice}
                                    // onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (/^\d*$/.test(value)) {
                                            formik.setFieldValue("ValualationRentfromPrice", value);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} className="pt-0" style={{ marginTop: '10px', paddingLeft: '40px' }}>
                                <span className={Style.range_text}>To</span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} className="pt-0">
                                <div className="w-100">
                                    <SelectInput
                                        name="valuationRentcurrency"
                                        options={CurrencyData}
                                        selectedValue={formik.values.valuationRentcurrency}
                                        onChange={(e) => formik.setFieldValue("valuationRentcurrency", e.target.value)}
                                        size={"small"}
                                        customClass={Style.custom_select}
                                        sx={{ backgroundColor: "#F5F8FA" }}
                                        noOptionText={"No template found."}
                                        placeholder={"£"}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4.5} className="pt-0">

                                <TextInput
                                    customClass={Style.input_custom + " w-100 "}
                                    type="text"
                                    name="ValualationRenttoPrice"
                                    variant="outlined"
                                    placeholder="Price"
                                    size={"small"}
                                    value={formik.values.ValualationRenttoPrice}
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (/^\d*$/.test(value)) {
                                            formik.setFieldValue("ValualationRenttoPrice", value);
                                        }
                                    }}


                                />
                                <ErrorMsg hasError={formik.touched.ValualationRenttoPrice && formik.errors.ValualationRenttoPrice}>
                                    {formik.errors.ValualationRenttoPrice}
                                </ErrorMsg>
                            </Grid>
                        </>
                        : propertyCategory === "residential" && listingType === "let"
                            ? ""
                            : <>
                                <Grid item xs={12} sm={12} md={12} className="pt-0">
                                    <label className={Style.labelform}>
                                        Valuation  Price

                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} className="pt-0">
                                    <div className="w-100">
                                        <SelectInput
                                            name="valuecurrency"
                                            options={CurrencyData}
                                            selectedValue={formik.values.valuecurrency}
                                            onChange={(e) => formik.setFieldValue("valuecurrency", e.target.value)}
                                            size={"small"}
                                            customClass={Style.custom_select}
                                            sx={{ backgroundColor: "#F5F8FA" }}
                                            noOptionText={"No template found."}
                                            placeholder={"£"}

                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4.5} className="pt-0">
                                    <TextInput
                                        customClass={Style.input_custom + " w-100 "}
                                        type="text"
                                        name="fromPriceCurrency"
                                        variant="outlined"
                                        placeholder="Price"
                                        size={"small"}
                                        value={formik.values.fromPriceCurrency}
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (/^\d*$/.test(value)) {
                                                formik.setFieldValue("fromPriceCurrency", value);
                                            }
                                        }}
                                    />
                                </Grid>



                                <Grid item xs={12} sm={12} md={1} className="pt-0" style={{ marginTop: '10px', paddingLeft: '40px' }}>
                                    <span>To</span>

                                </Grid>
                                <Grid item xs={12} sm={12} md={1} className="pt-0">
                                    <div className="w-100">
                                        <SelectInput
                                            name="valuecurrency"
                                            options={CurrencyData}
                                            selectedValue={formik.values.valuecurrency}
                                            onChange={(e) => formik.setFieldValue("valuecurrency", e.target.value)}
                                            size={"small"}
                                            customClass={Style.custom_select}
                                            sx={{ backgroundColor: "#F5F8FA" }}
                                            noOptionText={"No template found."}
                                            placeholder={"£"}
                                        />
                                    </div>


                                </Grid>

                                <Grid item xs={12} sm={12} md={4.5} className="pt-0">

                                    <TextInput
                                        customClass={Style.input_custom + " w-100 "}
                                        type="text"
                                        name="ToPriceCurrency"
                                        variant="outlined"
                                        placeholder="Price"
                                        size={"small"}
                                        value={formik.values.ToPriceCurrency}
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (/^\d*$/.test(value)) {
                                                formik.setFieldValue("ToPriceCurrency", value);
                                            }
                                        }}
                                    />
                                    <ErrorMsg hasError={formik.touched.ToPriceCurrency && formik.errors.ToPriceCurrency}>
                                        {formik.errors.ToPriceCurrency}
                                    </ErrorMsg>
                                </Grid>
                            </>}


                    {propertyCategory === "residential" && listingType === "let" || propertyCategory === "commercial" && listingType === "let" ?
                        <>
                            <Grid item xs={12} sm={12} md={12} className="pt-0">
                                <label className={Style.labelform}>
                                    Minimum acceptable Rent
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} className="pt-0">
                                <div className="w-100">
                                    <SelectInput
                                        name="minvaluationRentcurrency"
                                        options={CurrencyData}
                                        selectedValue={formik.values.minvaluationRentcurrency}
                                        size={"small"}
                                        customClass={Style.custom_select}
                                        sx={{ backgroundColor: "#F5F8FA" }}
                                        noOptionText={"No template found."}
                                        placeholder={"£"}

                                    />                        </div>

                            </Grid>


                            <Grid item xs={12} sm={12} md={11} className="pt-0">

                                <TextInput
                                    customClass={Style.input_custom + " w-100 "}
                                    type="text"
                                    name="minRentPrice"
                                    variant="outlined"
                                    placeholder="Minimum Rent"
                                    size={"small"}
                                    value={formik.values.minRentPrice}
                                    // onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (/^\d*$/.test(value)) {
                                            formik.setFieldValue("minRentPrice", value);
                                        }
                                    }}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12} sm={12} md={12} className="pt-0">
                                <label className={Style.labelform}>
                                    Minimum acceptable Price
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} className="pt-0">
                                <div className="w-100">
                                    <SelectInput
                                        name="minCurrency"
                                        options={CurrencyData}
                                        selectedValue={formik.values.minCurrency}
                                        onChange={(e) => formik.setFieldValue("minCurrency", e.target.value)}
                                        size={"small"}
                                        customClass={Style.custom_select}
                                        sx={{ backgroundColor: "#F5F8FA" }}
                                        noOptionText={"No template found."}
                                        placeholder={"£"}

                                    />                        </div>

                            </Grid>


                            <Grid item xs={12} sm={12} md={11} className="pt-0">

                                <TextInput
                                    customClass={Style.input_custom + " w-100 "}
                                    type="text"
                                    name="minPrice"
                                    variant="outlined"
                                    placeholder="Price"
                                    size={"small"}
                                    value={formik.values.minPrice}
                                    // onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (/^\d*$/.test(value)) {
                                            formik.setFieldValue("minPrice", value);
                                        }
                                    }}
                                />
                            </Grid>
                        </>
                    }


                    {propertyCategory === "residential" && listingType === "let" || propertyCategory === "commercial" && listingType === "let" ? <>
                        <Grid item xs={12} sm={12} md={12} className="pt-0">
                            <label className={Style.labelform}>
                                Deposit
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} className="pt-0">
                            <div className="w-100">
                                <SelectInput
                                    name="depositamountCurrency"
                                    options={CurrencyData}
                                    selectedValue={formik.values.depositamountCurrency}
                                    onChange={(e) => formik.setFieldValue("depositamountCurrency", e.target.value)}
                                    size={"small"}
                                    customClass={Style.custom_select}
                                    sx={{ backgroundColor: "#F5F8FA" }}
                                    noOptionText={"No template found."}
                                    placeholder={"£"}


                                />                        </div>

                        </Grid>


                        <Grid item xs={12} sm={12} md={11} className="pt-0">

                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="depositamount"
                                variant="outlined"
                                placeholder="Deposit Amount"
                                size={"small"}
                                value={formik.values.depositamount}
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^\d*$/.test(value)) {
                                        formik.setFieldValue("depositamount", value);
                                    }
                                }}
                            />
                        </Grid>

                    </> : ""}

                    {propertyCategory === "residential" && listingType === "let" || propertyCategory === "commercial" && listingType === "let" ? <>
                        <Grid item xs={12} sm={12} md={12} className="pt-0">
                            <label className={Style.labelform}>
                                Holding Deposit
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} className="pt-0">
                            <div className="w-100">
                                <SelectInput
                                    name="holdingDepositCurrency"
                                    options={CurrencyData}
                                    selectedValue={formik.values.holdingDepositCurrency}
                                    onChange={(e) => formik.setFieldValue("holdingDepositCurrency", e.target.value)}
                                    size={"small"}
                                    customClass={Style.custom_select}
                                    sx={{ backgroundColor: "#F5F8FA" }}
                                    noOptionText={"No template found."}
                                    placeholder={"£"}
                                />                        </div>

                        </Grid>


                        <Grid item xs={12} sm={12} md={11} className="pt-0">

                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="holdingDepositPrice"
                                variant="outlined"
                                placeholder="Holding deposit"
                                size={"small"}
                                value={formik.values.holdingDepositPrice}
                                // onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^\d*$/.test(value)) {
                                        formik.setFieldValue("holdingDepositPrice", value);
                                    }
                                }}
                            />
                        </Grid>

                    </> : ""}


                    <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}>
                            Date available
                        </label>
                        <DateInput
                            name="dateAvailable"
                            value={formik.values.dateAvailable}
                            onChange={(date) => formik.setFieldValue('dateAvailable', date)}
                            customClass={Style.time_label + " small-input w-100"}
                            size="small"
                            filterDate={new Date()}


                        />



                    </Grid>
                    {propertyCategory === "residential" && listingType === "let" || propertyCategory === "commercial" && listingType === "let" ? "" : <><Grid item xs={12} sm={12} md={4} className="pt-0">
                        <label className={Style.labelform}>
                            Tenure <AstericSymbol />
                        </label>
                        <div className="w-100">
                            <SelectInput
                                name="TentureOption"
                                options={TentureOption}
                                selectedValue={formik.values.tenure}
                                onChange={(e) => formik.setFieldValue("tenure", e.target.value)}
                                size={"small"}
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: "#F5F8FA" }}
                                noOptionText={"No template found."}
                                placeholder={"Select"}
                            />                        </div>
                        <ErrorMsg hasError={formik.touched.tenure && formik.errors.tenure}>
                            {formik.errors.tenure}
                        </ErrorMsg>
                    </Grid><Grid item xs={12} sm={12} md={8} className="pt-0">
                            <label className={Style.labelform}>
                                Additional tenure information
                            </label>
                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="tenureAdditionalInformation"
                                value={formik.values.tenureAdditionalInformation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                placeholder="Additional tenure information"
                                size={"small"} />

                        </Grid></>}


                    <hr />
                    <div>

                    </div>

                    <div className={Style.amanities_wrapper}>
                        <Grid item xs={12} sm={12} md={2} style={{ backgroundColor: '#F5F8FA' }} className={Style.aminities_wrap1}>
                            <Typography variant="h6" className={Style.listDetailsFont}>Features <AstericSymbol /></Typography>
                            <ErrorMsg hasError={formik.touched.features && formik.errors.features}>
                                {formik.errors.features}
                            </ErrorMsg>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} style={{ backgroundColor: '#F5F8FA' }} className={Style.aminities_wrap2}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Grid container spacing={2}>
                                                {FeatureList?.map((amenity, index) => (
                                                    <Grid item xs={12} sm={6} md={4} key={`${amenity.id}-${index}`} className='aminities_single'>
                                                        <Grid container alignItems="center">
                                                            <Grid item>
                                                                <Checkbox
                                                                    checked={formik.values.features.includes(amenity.value)}
                                                                    onChange={handleFeatureChange}
                                                                    value={amenity.value}
                                                                />
                                                            </Grid>
                                                            <Grid item style={{ padding: 0, margin: 0 }} >
                                                                <Tooltip title={amenity.label.toString()}>
                                                                    <span className={Style.TootltipName}>
                                                                        <p className={Style.aminities_text}> {TooltipSpilitName(amenity.label.toString().toLowerCase(), 20)}
                                                                        </p>
                                                                    </span>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </div>
                </Grid>
                <div className={Style.propertycss1 + " " + Style.button_nav}>
                    <div style={{ marginRight: '12px' }}>
                        <Button color="secondary"
                            className={Style.delete_btn + " " + Style.edit_btn} onClick={handleBackButton}>
                            <KeyboardArrowLeftIcon /> Back
                        </Button>
                    </div>
                    <div>
                        <ButtonLoader
                            type={"button"}
                            buttonName="Next"
                            loaderColor={"white"}
                            className={Style.Add_btn}
                            onClickhandle={formik.handleSubmit}

                            icon={<KeyboardArrowRightIcon />}

                        />

                    </div>
                </div>
            </div>

        </div>
    )
}

export default PropertyInformation