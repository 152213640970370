export const PropertyTypes = [
    { label: "All Types", value: "" },
    { label: "Detached House", value: "detached" },
    { label: "Semi-detached House", value: "semi-detached" },
    { label: "Terraced House", value: "terraced" },
    { label: "Flat", value: "flat" },
];

export const BedroomOptions = [
    { label: "Any", value: 0 },
    { label: "1 Bedroom", value: 1 },
    { label: "2 Bedrooms", value: 2 },
    { label: "3 Bedrooms", value: 3 },
    { label: "4 Bedrooms", value: 4 },
    { label: "5 Bedrooms", value: 5 },
    { label: "6 Bedrooms", value: 6 },
    { label: "7 Bedrooms", value: 7 },
    { label: "8 Bedrooms", value: 8 },
    { label: "9 Bedrooms", value: 9 },
    { label: "10 Bedrooms", value: 10 },
];

export const BathroomOptions = [
    { label: "Any", value: 0 },
    { label: "1 Bathroom", value: 1 },
    { label: "2 Bathrooms", value: 2 },
    { label: "3 Bathrooms", value: 3 },
    { label: "4 Bathrooms", value: 4 },
    { label: "5 Bathrooms", value: 5 },
    { label: "6 Bathrooms", value: 6 },
    { label: "7 Bathrooms", value: 7 },
    { label: "8 Bathrooms", value: 8 },
    { label: "9 Bathrooms", value: 9 },
    { label: "10 Bathrooms", value: 10 },
];

export const RadiusOption = [
    { label: "This Area only", value: 0 },
    { label: "+0.25 Miles", value: 0.25 },
    { label: "+0.5 Miles", value: 0.5 },
    { label: "+1 Miles", value: 1 },
    { label: "+3 Miles", value: 3 },
    { label: "+5 Miles", value: 5 },
    { label: "+10 Miles", value: 10 },
    { label: "+20 Miles", value: 20 },
    { label: "+40 Miles", value: 40 },
   
];


export const StatusOptions = [
    { label: "All", value: "" },
    { label: "Available", value: "available" },
    { label: "Sold", value: "sold" },
    { label: "Under Offer", value: "under-offer" },
    { label: "Rented", value: "rented" },
    { label: "Pending", value: "pending" },
];
export const FurnishOptions = [
    { label: "Any", value: "" },
    { label: "Furnished", value: "furnished" },
    { label: "Post Furnished", value: "post-furnished" },
    { label: "Unfurnished", value: "unfurnished" },
];


export const MaxPriceRange = [
    { label: "£100", value: "100" },
    { label: "£150", value: "150" },
    { label: "£200", value: "200" },
    { label: "£250", value: "250" },
    { label: "£300", value: "300" },
    { label: "£350", value: "350" },
    { label: "£400", value: "400" },
    { label: "£450", value: "450" },
    { label: "£500", value: "500" },
    { label: "£600", value: "600" },
    { label: "£700", value: "700" },
    { label: "£800", value: "800" },
    { label: "£900", value: "900" },
    { label: "£1,000", value: "1000" },
    { label: "£1,250", value: "1250" },
    { label: "£1,500", value: "1500" },
    { label: "£1,750", value: "1750" },
    { label: "£2,000", value: "2000" },
    { label: "£50,000", value: "50000" },
    { label: "£100,000", value: "100000" },
    { label: "£125,000", value: "125000" },
    { label: "£150,000", value: "150000" },
    { label: "£175,000", value: "175000" },
    { label: "£200,000", value: "200000" },
    { label: "£225,000", value: "225000" },
    { label: "£250,000", value: "250000" },
    { label: "£275,000", value: "275000" },
    { label: "£300,000", value: "300000" },
    { label: "£325,000", value: "325000" },
    { label: "£350,000", value: "350000" },
    { label: "£375,000", value: "375000" },
    { label: "£400,000", value: "400000" },
    { label: "£425,000", value: "425000" },
    { label: "£450,000", value: "450000" },
    { label: "£475,000", value: "475000" },
    { label: "£500,000", value: "500000" },
    { label: "No Max", value: "" },
];

export const MinPriceRange = [
    { label: "No Min", value: "" },
    { label: "£100", value: "100" },
    { label: "£150", value: "150" },
    { label: "£200", value: "200" },
    { label: "£250", value: "250" },
    { label: "£300", value: "300" },
    { label: "£350", value: "350" },
    { label: "£400", value: "400" },
    { label: "£450", value: "450" },
    { label: "£500", value: "500" },
    { label: "£600", value: "600" },
    { label: "£700", value: "700" },
    { label: "£800", value: "800" },
    { label: "£900", value: "900" },
    { label: "£1,000", value: "1000" },
    { label: "£1,250", value: "1250" },
    { label: "£1,500", value: "1500" },
    { label: "£1,750", value: "1750" },
    { label: "£2,000", value: "2000" },
    { label: "£50,000", value: "50000" },
    { label: "£100,000", value: "100000" },
    { label: "£125,000", value: "125000" },
    { label: "£150,000", value: "150000" },
    { label: "£175,000", value: "175000" },
    { label: "£200,000", value: "200000" },
    { label: "£225,000", value: "225000" },
    { label: "£250,000", value: "250000" },
    { label: "£275,000", value: "275000" },
    { label: "£300,000", value: "300000" },
    { label: "£325,000", value: "325000" },
    { label: "£350,000", value: "350000" },
    { label: "£375,000", value: "375000" },
    { label: "£400,000", value: "400000" },
    { label: "£425,000", value: "425000" },
    { label: "£450,000", value: "450000" },
    { label: "£475,000", value: "475000" },
    { label: "£500,000", value: "500000" },

];

// src/constants/sortOptions.js
export const RESIDENTAIL_SORT_OPTIONS = [
    { field: 'price', label: 'Price' },
    { field: 'reference', label: 'Reference' },
    { field: 'propertyAddress', label: 'Address' },
    { field: 'propertyAddress.displayAddress', label: 'Display Address' },
    { field: 'propertyStatus', label: 'Status' },
    { field: 'noOfBedroom', label: 'Beds' },
];

// src/constants/sortOptions.js
export const COMMERCIAL_SORT_OPTIONS = [
    { field: 'price', label: 'Price' },
    { field: 'reference', label: 'Reference' },
    { field: 'propertyAddress', label: 'Address' },
    { field: 'propertyAddress.displayAddress', label: 'Display Address' },
    { field: 'propertyStatus', label: 'Status' },
    { field: 'noOfBedroom', label: 'Beds' },
];


export const MENU_ITEMS_SALES = [
    { name: 'Dashboard', disabled: false, tab: "dashboard" },
    { name: 'Listing Details', disabled: false, tab: "listingDetails" },
    { name: 'Property Information', disabled: false, tab: "propertyInformation" },
    { name: 'Property Address', disabled: false, tab: "propertyAddress" },
    { name: 'Property Details and Description', disabled: false, tab: "propertyDetails" },
    { name: 'Property Pictures', disabled: false, tab: "propertyPictures" },
    { name: 'Brochure and Window Cards', disabled: false, tab: "brochureAndWindow" },
    { name: 'Marketing Data', disabled: true },
    { name: 'Rooms', disabled: true },
    { name: 'Features & Restrictions', disabled: true },
    { name: 'Keys & Security Codes', disabled: true },
    { name: 'Board', disabled: true },
    { name: 'Documents', disabled: true },
    { name: 'Letters & Emails', disabled: true },
    { name: 'Templates', disabled: true },
    { name: 'Tasks', disabled: true },
    { name: 'Checklists', disabled: true },
    { name: 'Notes', disabled: true },
    { name: 'Audit Trail', disabled: true },
];


export const MENU_ITEMS_LETTINGS = [
    { name: 'Dashboard', disabled: false, tab: "dashboard" },
    { name: 'Listing Details', disabled: false, tab: "listingDetails" },
    { name: 'Property Information', disabled: false, tab: "propertyInformation" },
    { name: 'Property Address', disabled: false, tab: "propertyAddress" },
    { name: 'Property Details and Description', disabled: false, tab: "propertyDetails" },
    { name: 'Property Pictures', disabled: false, tab: "propertyPictures" },
    { name: 'Letting Fees and Management', disabled: false, tab: "propertyLettingFees" },
    { name: 'Brochure and Window Cards', disabled: false, tab: "brochureAndWindow" },
    { name: 'Marketing Data', disabled: true },
    { name: 'Rooms', disabled: true },
    { name: 'Features & Restrictions', disabled: true },
    { name: 'Keys & Security Codes', disabled: true },
    { name: 'Board', disabled: true },
    { name: 'Documents', disabled: true },
    { name: 'Letters & Emails', disabled: true },
    { name: 'Templates', disabled: true },
    { name: 'Tasks', disabled: true },
    { name: 'Checklists', disabled: true },
    { name: 'Notes', disabled: true },
    { name: 'Audit Trail', disabled: true },
];

export const MENU_ITEMS = [
    { name: 'Applicant Search', disabled: true },
    { name: 'Marketing Alerts', disabled: true },
    { name: 'Interested Applicants', disabled: true },
    { name: 'Valuations', disabled: true },
    { name: 'Viewings', disabled: true },
    { name: 'Offers', disabled: true },
    { name: 'Sale Progression', disabled: true },
    { name: 'Chain', disabled: true },
    { name: 'Sale Details', disabled: true },
];