import React, { useState } from 'react'
import VersionCard from './VersionCard';
import NoDataFound2 from 'src/components/custom/NoDataFound2';
import NoContactFound from "src/assets/images/noDataFound/NoContactFound.png";

const VersioningList = ({ chatBotData, setchatBotData, chatbotPageType, chatbotId, versionId, }) => {
  
    return (
        <div className={'h-100'}>
            {chatBotData?.chatbotVersions?.slice().reverse().length > 0 ? chatBotData?.chatbotVersions?.slice().reverse().map((data, index) => {
                return <VersionCard index={index} chatbotId={chatbotId} versionId={versionId} data={data} setchatBotData={setchatBotData} chatBotData={chatBotData} />
            }) :
                <NoDataFound2 width={"180px"} text="No version Found!" image={NoContactFound} />
            }
        </div>
    )
}

export default VersioningList;