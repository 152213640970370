import { useState } from "react";
import Axios from "src/utils/axios";
import { CHATBOT_NODE_TYPE } from "src/constants/chatbotConstants";
import Toast from "src/components/custom/Toast";

const useChatbotTree = () => {

  const [treeData, setTreeData] = useState([]);
  const [chatBotData, setchatBotData] = useState([]);
  const [isTreeLoading, setTreeLoading] = useState(false);
  const [isTreeDeleting, setTreeDeleting] = useState(false);
  const [isPublishLoading, setisPublishLoading] = useState(false);
  const [publishedChatbot, setPublishedChatbot] = useState(false);
  const [captureFields, setcaptureFiedls] = useState(false);

  const getChatbotTreeByUser = async (chatbotId, versionId = "") => {
    setTreeLoading((prev) => true);
    try {
      let botTreeData = {};

      if (versionId) {
        botTreeData = await Axios.get(`/chatbots/getChatbotVersion?chatbotId=${chatbotId}&versionId=${versionId}`);

        if (botTreeData?.data?.chatbotTree) {
          setTreeData([{ ...botTreeData.data.chatbotTree }]);
          setchatBotData({ draftMetaData: botTreeData.data.metaData, chatbotVersions: botTreeData.data.chatbotVersions, name: botTreeData.data.name, channels: botTreeData.data.channels });
        }
      } else {
        botTreeData = await Axios.get(`/chatbots/tree/user?chatbotId=${chatbotId}`);

        if (botTreeData?.data?.data) {
          setTreeData(botTreeData.data.data);
          setchatBotData(botTreeData.data.chatbotData);
        }
      }

    } catch (err) {
      console.log(`Error occured while fetching chatbot. ${err.message}`);
    } finally {
      setTreeLoading((prev) => false);

    }
  };

  const getNodeLabel = (nodeValue) => {
    const nodeLblObj = CHATBOT_NODE_TYPE.find((el) => el.value === nodeValue);
    return nodeLblObj?.name;
  };

  const deleteNode = (tree, targetNode, newNode) => {
    if (!tree) return tree;
    if (!tree) return tree;

    return tree.map((node) => {
      if (node._id === targetNode.parentId) {
        return {
          ...node,
          children: newNode?._id ? [{ ...newNode }] : [],
        };
      } else if (node.children) {
        return {
          ...node,
          children: deleteNode(node.children, targetNode, newNode),
        };
      }
      return node;
    });
  };

  const deleteNodeFromDb = async (tree, targetNode) => {
    try {
      setTreeDeleting(true);
      const deletedNodeResp = await Axios.delete(`/chatbots/${targetNode?._id}`);
      if (deletedNodeResp?.data?.success) {
        Toast("Node deleted successfully.", "success");
        const updatedData = deleteNode(tree, targetNode, deletedNodeResp?.data?.tree);
        return updatedData;
      }

    } catch (err) {
      throw new Error(`Error occured while deleting the node!! ${err.message}`);
    } finally {
      setPublishedChatbot(false)
      setTreeDeleting(false);
    }
  };

  const publishChatbotTree = async (setPublishModal, chatbotId, setchatBotData) => {
    try {
      setisPublishLoading(true);
      let publish = await Axios.post(`/chatBot/publish/${chatbotId}`);
      if (publish) {
        Toast("Chatbot published successfully.", "success");
        setPublishedChatbot(true);
        setchatBotData(publish.data.data);
        setPublishModal(false);
      }
    } catch (error) {
      setPublishModal(true);
    } finally {
      setisPublishLoading(false);
    }
  };

  const handleRestore = async (setShowdelete, chatbotId, versionId) => {
    try {
      setisPublishLoading(true);
      let reStored = await Axios.post(`/chatBot/restoreChatbot/${chatbotId}`, { versionId: versionId });
      if (reStored) {
        Toast("Chatbot restored successfully.", "success");
        setShowdelete(false);
      }
    } catch (error) {
      setShowdelete(false);
    } finally {
      setisPublishLoading(false);
    }
  };

  const mergeNodetoTree = (tree, targetNode) => {
    if (!tree) return tree;
    return tree.map((node) => {
      if (node._id === targetNode._id) {
        node.children = targetNode.children;
        return { ...node };
      } else if (node.children) {
        return { ...node, children: mergeNodetoTree(node.children, targetNode) };
      }
      return node;
    });
  };

  const toggleAllChildren = (parentNode, visiblity = true) => {
    const traverse = (node) => {
      if (node.children && node.children.length) {
        node.children.forEach((child) => {
          if (child.isVisible === undefined) {
            child.isVisible = !visiblity;
          } else if (child.isVisible === false) {
            child.isVisible = !visiblity;
          } else {
            child.isVisible = !visiblity;
          }
          traverse(child);
        });
      }
    };
    traverse(parentNode);
    parentNode.isVisible = true
    return parentNode;
  };

  const getcaptureFields = async () => {
    try {
      const captureFieldsData = await Axios.get(`/chatbotCaptureFields/getCaptureField`);

      if (captureFieldsData?.data?.success) {
        setcaptureFiedls(captureFieldsData?.data?.data)
      }

    } catch (error) {

      console.log("error in capture fields");

    }
  }

  return {
    treeData,
    isTreeLoading,
    getChatbotTreeByUser,
    getNodeLabel,
    isTreeDeleting,
    deleteNode,
    deleteNodeFromDb,
    isPublishLoading,
    publishChatbotTree,
    publishedChatbot,
    setPublishedChatbot,
    toggleAllChildren,
    mergeNodetoTree,
    chatBotData,
    setchatBotData,
    handleRestore,
    captureFields,
    getcaptureFields
  };
};

export default useChatbotTree;
