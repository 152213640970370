


import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Style from "./AddProperty.module.css";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import questionImg from "src/assets/images/property/questionImg.svg";
import TextInput from "src/components/forms/TextInput";
import SelectInput from "src/components/forms/SelectInput";
import FormGroup from '@mui/material/FormGroup';
import CommonCheckbox from 'src/components/forms/CommonCheckbox';
import DateInput from 'src/components/forms/DateInput';
import { useFormik } from 'formik';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { PropertyServiceOption, LettingService, RentFrequencyOptions, GasCertificateOptions, PropertyCertificateOptions, ManationRespoOptions, optionLisenseType } from "src/constants/formPicker";

const LettingFees = ({ handleNext, handleBack, PropertyLettingFeeservice, PropertyDescriptionData, singleData, fetchSingleListId }) => {
    useEffect(() => {
        if (singleData?.lettingServicesAndFees) {
            formik.setFieldValue('name', singleData?.lettingServicesAndFees?.name);
            formik.setFieldValue('amount', singleData?.lettingServicesAndFees?.lettingFees?.amount);
            formik.setFieldValue('period', singleData?.lettingServicesAndFees?.lettingFees?.period);
            formik.setFieldValue('type', singleData?.lettingServicesAndFees?.lettingFees?.type);
            formik.setFieldValue('managementFees', singleData?.lettingServicesAndFees?.managementFees?.amount);
            formik.setFieldValue('managementperiod', singleData?.lettingServicesAndFees?.managementFees?.period);
            formik.setFieldValue('managementPercent', singleData?.lettingServicesAndFees?.managementFees?.type);
            formik.setFieldValue('hasGas', singleData?.lettingServicesAndFees?.hasGas);
            formik.setFieldValue('expiryDate', singleData?.lettingServicesAndFees?.expiryDates[0]?.expiryDate);
            formik.setFieldValue('expiryDatesresponsibility', singleData?.lettingServicesAndFees?.expiryDates[0]?.responsibility);
            formik.setFieldValue('PATexpiryDate', singleData?.lettingServicesAndFees?.expiryDates[1]?.expiryDate);
            formik.setFieldValue('PATresponsibility', singleData?.lettingServicesAndFees?.expiryDates[1]?.responsibility);
            formik.setFieldValue('ElectricityexpiryDate', singleData?.lettingServicesAndFees?.expiryDates[2]?.expiryDate);
            formik.setFieldValue('Electricityresponsibility', singleData?.lettingServicesAndFees?.expiryDates[2]?.responsibility);
            formik.setFieldValue('EPCexpiryDate', singleData?.lettingServicesAndFees?.expiryDates[3]?.expiryDate);
            formik.setFieldValue('EPCresponsibilityEPC', singleData?.lettingServicesAndFees?.expiryDates[3]?.responsibility);
            formik.setFieldValue('InsuranceexpiryDate', singleData?.lettingServicesAndFees?.expiryDates[4]?.expiryDate);
            formik.setFieldValue('Insuranceresponsibility', singleData?.lettingServicesAndFees?.expiryDates[4]?.responsibility);
            formik.setFieldValue('AssessmentexpiryDate', singleData?.lettingServicesAndFees?.expiryDates[5]?.expiryDate);
            formik.setFieldValue('Assessmentresponsibility', singleData?.lettingServicesAndFees?.expiryDates[5]?.responsibility);
            formik.setFieldValue('SmokeexpiryDate', singleData?.lettingServicesAndFees?.expiryDates[6]?.expiryDate);
            formik.setFieldValue('Smokeresponsibility', singleData?.lettingServicesAndFees?.expiryDates[6]?.responsibility);
            formik.setFieldValue('PropertyexpiryDate', singleData?.lettingServicesAndFees?.expiryDates[7]?.expiryDate);
            formik.setFieldValue('Propertyresponsibility', singleData?.lettingServicesAndFees?.expiryDates[7]?.responsibility);
            formik.setFieldValue('propertyLicenseNote', singleData?.propertyLicenseNote);
            formik.setFieldValue('propertyLicenseType', singleData?.propertyLicenseType);
            formik.setFieldValue('inspectionGroup', singleData?.inspectionGroup);
            formik.setFieldValue('defaultTenantInvoiceType', singleData?.defaultTenantInvoiceType);
            formik.setFieldValue('landlordStatementPaymentRef', singleData?.landlordStatementPaymentRef);
            formik.setFieldValue('rentOverrideDescription', singleData?.rentOverrideDescription);
            formik.setFieldValue('maintenanceNotes', singleData?.maintenanceNotes);
            formik.setFieldValue('maintenanceResponsibility', singleData?.maintenanceResponsibility);
            formik.setFieldValue('rightToRentCheckResponsibility', singleData?.rightToRentCheckResponsibility);
        }
    }, [singleData])


    const formik = useFormik({
        initialValues: {
            name: "",
            amount: "",
            type: "",
            period: "",
            managementFees: "",
            managementPercent: "",
            managementperiod: "",
            hasGas: false,
            expiryDatesresponsibility: "",
            gasCertificateLabel: "Gas certificate expiry date?",
            expiryDate: null,
            PATresponsibility: "",
            PATlabel: "PAT test expiry date",
            PATexpiryDate: null,
            Electricityresponsibility: "",
            Electricitylabel: "Electricity certificate expiry date",
            ElectricityexpiryDate: null,
            EPCresponsibilityEPC: "",
            EPClabel: "EPC expiry date",
            EPCexpiryDate: null,
            Insuranceresponsibility: "",
            Insurancelabel: "Insurance expiry date",
            InsuranceexpiryDate: null,
            Assessmentresponsibility: "",
            Assessmentlabel: "Legionella risk assessment date",
            AssessmentexpiryDate: null,
            Smokeresponsibility: "",
            Smokelabel: "Smoke & CO alarm expiry date",
            SmokeexpiryDate: null,
            Propertyresponsibility: "",
            Propertylabel: "Property licence expiry date",
            PropertyexpiryDate: null,
            viewingAgreement: "",
            additionalInformation: "",
            propertyDescription: "",
            propertyLicenseType: "",
            propertyLicenseNote: "",
            rightToRentCheckResponsibility: "",
            maintenanceResponsibility: "",
            maintenanceNotes: "",
            rentOverrideDescription: "",
            defaultTenantInvoiceType: "",
            inspectionGroup: "",
            landlordStatementPaymentRef: ""
        },

        onSubmit: (values) => {
            const lettingServicesAndFees = {
                name: values?.name,
                managementFees: {
                    amount: values?.managementFees,
                    type: values?.managementPercent,
                    period: values?.managementperiod,

                },
                lettingFees: {
                    amount: values?.amount,
                    type: values?.type,
                    period: values?.period
                },
                hasGas: values.hasGas,
                expiryDates: [
                    {
                        responsibility: values?.expiryDatesresponsibility,
                        label: values?.gasCertificateLabel,
                        expiryDate: values?.expiryDate
                    },
                    {
                        responsibility: values?.PATresponsibility,
                        label: values?.PATlabel,
                        expiryDate: values?.PATexpiryDate
                    },
                    {
                        responsibility: values?.Electricityresponsibility,
                        label: values?.Electricitylabel,
                        expiryDate: values?.ElectricityexpiryDate
                    },
                    {
                        responsibility: values?.EPCresponsibilityEPC,
                        label: values?.EPClabel,
                        expiryDate: values?.EPCexpiryDate
                    },
                    {
                        responsibility: values?.Insuranceresponsibility,
                        label: values?.Insurancelabel,
                        expiryDate: values?.InsuranceexpiryDate
                    },
                    {
                        responsibility: values?.Assessmentresponsibility,
                        label: values?.Assessmentlabel,
                        expiryDate: values?.AssessmentexpiryDate
                    },
                    {
                        responsibility: values?.Smokeresponsibility,
                        label: values?.Smokelabel,
                        expiryDate: values?.SmokeexpiryDate
                    },
                    {
                        responsibility: values?.Propertyresponsibility,
                        label: values?.Propertylabel,
                        expiryDate: values?.PropertyexpiryDate
                    },
                ]
            }
            handleNext()
            const formData = new FormData();
            formData.append("lettingServicesAndFees", JSON.stringify(lettingServicesAndFees));
            formData.append("propertyLicenseType", values.propertyLicenseType);
            formData.append("propertyLicenseNote", values.propertyLicenseNote);
            formData.append("rightToRentCheckResponsibility", values.rightToRentCheckResponsibility);
            formData.append("maintenanceResponsibility", values.maintenanceResponsibility);
            formData.append("maintenanceNotes", values.maintenanceNotes);
            formData.append("rentOverrideDescription", values.rentOverrideDescription);
            formData.append("landlordStatementPaymentRef", values.landlordStatementPaymentRef);
            formData.append("defaultTenantInvoiceType", values.defaultTenantInvoiceType);
            formData.append("inspectionGroup", values.inspectionGroup);
            formData.append("hasGas", values?.hasGas);

            formData.append("propertyId", PropertyDescriptionData);
            PropertyLettingFeeservice(formData, 5);
        },
    });

    function TooltipSpilitName(name, maxLength) {
        if (name.length > maxLength) {
            return name.substring(0, maxLength - 3) + "...";
        } else {
            return name;
        }
    }
    const handleBackButton = () => {
        fetchSingleListId(PropertyDescriptionData);
        handleBack()
    }
    return (
        <div>
            <div style={{ padding: '10px 40px' }}>


                <Box className={Style.lisitngDetailHeader}>
                    <Box>
                        <Typography variant="h6" className={Style.listDetailsFont}>Letting Service and Fees</Typography>
                    </Box>
                    <Box
                    >
                        <div className={Style.propertycss1 + " " + Style.button_nav}>
                            <div style={{ marginRight: '12px' }}>
                                <Button color="secondary"
                                    className={Style.delete_btn + " " + Style.edit_btn} onClick={handleBackButton}>
                                    <KeyboardArrowLeftIcon />
                                    Back
                                </Button>
                            </div>
                            <div onClick={handleNext}>
                                <ButtonLoader
                                    type={"button"}
                                    buttonName="Next"
                                    loaderColor={"white"}
                                    className={Style.Add_btn}
                                    onClickhandle={formik.handleSubmit}
                                    icon={<KeyboardArrowRightIcon />}
                                >
                                </ButtonLoader>
                            </div>
                        </div>
                    </Box>
                </Box>
                <hr />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <label className={Style.labelform}>
                            Letting service
                        </label>
                        <div className="w-100">
                            <SelectInput
                                name="name"
                                options={LettingService}
                                selectedValue={formik.values.name}
                                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder="Select"
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}>
                            Letting Fee
                        </label>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} className="pt-0">

                        <div >
                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="amount"
                                value={formik.values.amount}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                placeholder="Enter letting fee"
                                size={"small"}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^\d*$/.test(value)) {
                                        formik.setFieldValue("amount", value);
                                    }
                                }}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} className="pt-0" >
                        <div className="w-100">
                            <SelectInput
                                name="type"
                                options={PropertyServiceOption}
                                selectedValue={formik.values.type}
                                onChange={(e) => formik.setFieldValue('type', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder="Select"
                            />

                        </div>

                    </Grid>
                    {formik.values.type === "Percent" ? <>
                        <Grid item xs={12} sm={12} md={1} className="pt-0" style={{ paddingLeft: '30px' }}>
                            <span className={Style.font_bold}>of rent</span>

                        </Grid>
                        <Grid item xs={12} sm={12} md={2} className="pt-0" >
                            <div className="w-100">
                                <SelectInput
                                    name="period"
                                    options={RentFrequencyOptions}
                                    selectedValue={formik.values.period}
                                    onChange={(e) => formik.setFieldValue('period', e.target.value)}
                                    size="small"
                                    customClass={Style.custom_select}
                                    sx={{ backgroundColor: '#F5F8FA' }}
                                    placeholder={"Select"}
                                />

                            </div>

                        </Grid>
                    </> : ""}


                    <Grid item xs={12} sm={12} md={7} className="pt-0">
                        <label className={Style.labelform}>
                            Management Fee
                        </label>
                        <TextInput
                            customClass={Style.input_custom + " w-100 "}
                            type="text"
                            name="managementFees"
                            value={formik.values.managementFees}
                            onChange={(e) => {
                                const { value } = e.target;
                                if (/^\d*$/.test(value)) {
                                    formik.setFieldValue("managementFees", value);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            placeholder="Enter management fee"
                            size={"small"}
                        />

                    </Grid>

                    <Grid item xs={12} sm={12} md={2} className="pt-0" style={{ marginTop: '45px' }}>
                        <div className="w-100">
                            <SelectInput
                                name="managementPercent"
                                options={PropertyServiceOption}
                                selectedValue={formik.values.managementPercent}
                                onChange={(e) => formik.setFieldValue('managementPercent', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder="Select"
                            />

                        </div>

                    </Grid>
                    {formik.values.managementPercent === "Percent" ? <>
                        <Grid item xs={12} sm={12} md={1} className="pt-0" style={{ marginTop: '50px', paddingLeft: '30px' }}>
                            <span className={Style.font_bold}>of rent</span>

                        </Grid>
                        <Grid item xs={12} sm={12} md={2} className="pt-0" style={{ marginTop: '45px' }}>
                            <div className="w-100">
                                <SelectInput
                                    name="managementperiod"
                                    options={RentFrequencyOptions}
                                    selectedValue={formik.values.managementperiod}
                                    onChange={(e) => formik.setFieldValue('managementperiod', e.target.value)}
                                    size="small"
                                    customClass={Style.custom_select}
                                    sx={{ backgroundColor: '#F5F8FA' }}
                                    placeholder={"Select"}
                                />

                            </div>

                        </Grid>
                    </> : ""}


                    <Grid item xs={12} sm={12} md={12}>
                        <Box>
                            <Typography variant="h6" className={Style.listDetailsFont}>Lettings Management</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className="pt-4">
                        <FormGroup>
                            <CommonCheckbox
                                label="Has Gas?"
                                checked={formik.values.hasGas}
                                onChange={(e) => formik.setFieldValue('hasGas', e.target.checked)}
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}>
                            Gas certificate expiry date

                            <Tooltip title={TooltipSpilitName("Date the current gas safety certificate expires", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} className="pt-0">

                        <DateInput
                            name="expiryDate"
                            value={formik.values.expiryDate}
                            onChange={(date) => formik.setFieldValue('expiryDate', date)}
                            customClass={Style.time_label + " small-input w-100"}
                            size="small"
                            past={true}
                            filterDate={new Date()}

                        />
                    </Grid>


                    <Grid item xs={12} sm={12} md={1.5} className="pt-0" style={{ paddingLeft: '30px' }} >
                        <span className={Style.font_bold}>Responsibility </span>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} className="pt-0" >
                        <div className="w-100">
                            <SelectInput
                                name="expiryDatesresponsibility"
                                options={GasCertificateOptions}
                                selectedValue={formik.values.expiryDatesresponsibility}
                                onChange={(e) => formik.setFieldValue('expiryDatesresponsibility', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder="Select"
                                disabled={formik.values.expiryDate === null ? true : ""}
                            />
                        </div>

                    </Grid>


                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}>
                            PAT test expiry date
                            <Tooltip title={TooltipSpilitName("Date the current PAT safety certificate expires", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} className="pt-0">

                        <DateInput
                            name="PATexpiryDate"
                            value={formik.values.PATexpiryDate}
                            onChange={(date) => formik.setFieldValue('PATexpiryDate', date)}
                            customClass={Style.time_label + " small-input w-100"}
                            size="small"
                            filterDate={new Date()}

                        />
                    </Grid>


                    <Grid item xs={12} sm={12} md={1.5} className="pt-0" style={{ paddingLeft: '30px' }}>
                        <span className={Style.font_bold}>Responsibility </span>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} className="pt-0" >
                        <div className="w-100">
                            <SelectInput
                                name="PATresponsibility"
                                options={PropertyCertificateOptions}
                                selectedValue={formik.values.PATresponsibility}
                                onChange={(e) => formik.setFieldValue('PATresponsibility', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder={"Select"}
                                disabled={formik.values.PATexpiryDate === null ? true : ""}
                            />
                        </div>

                    </Grid>

                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}>
                            Electricity certificate expiry date
                            <Tooltip title={TooltipSpilitName("Date the current electrical safety certificate expires", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>

                        </label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} className="pt-0">

                        <DateInput
                            name="ElectricityexpiryDate"
                            value={formik.values.ElectricityexpiryDate}
                            onChange={(date) => formik.setFieldValue('ElectricityexpiryDate', date)}
                            customClass={Style.time_label + " small-input w-100"}
                            size="small"
                            filterDate={new Date()}

                        />

                    </Grid>


                    <Grid item xs={12} sm={12} md={1.5} className="pt-0" style={{ paddingLeft: '30px' }}>
                        <span className={Style.font_bold}>Responsibility </span>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} className="pt-0">
                        <div className="w-100">
                            <SelectInput
                                name="Electricityresponsibility"
                                options={PropertyCertificateOptions}
                                selectedValue={formik.values.Electricityresponsibility}
                                onChange={(e) => formik.setFieldValue('Electricityresponsibility', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder={"Select"}
                                disabled={formik.values.ElectricityexpiryDate === null ? true : ""}

                            />
                        </div>

                    </Grid>

                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}>
                            EPC expiry date
                            <Tooltip title={TooltipSpilitName("Date the current EPC certificate expires", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} className="pt-0">

                        <DateInput
                            name="EPCexpiryDate"
                            value={formik.values.EPCexpiryDate}
                            onChange={(date) => formik.setFieldValue('EPCexpiryDate', date)}
                            customClass={Style.time_label + " small-input w-100"}
                            size="small"
                            filterDate={new Date()}

                        />

                    </Grid>


                    <Grid item xs={12} sm={12} md={1.5} className="pt-0" style={{ paddingLeft: '30px' }}>
                        <span className={Style.font_bold}>Responsibility </span>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} className="pt-0" >
                        <div className="w-100">
                            <SelectInput
                                name="EPCresponsibilityEPC"
                                options={PropertyCertificateOptions}
                                selectedValue={formik.values.EPCresponsibilityEPC}
                                onChange={(e) => formik.setFieldValue('EPCresponsibilityEPC', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder={"Select"}
                                disabled={formik.values.EPCexpiryDate === null ? true : ""}


                            />
                        </div>

                    </Grid>


                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}>
                            Insurance expiry date
                            <Tooltip title={TooltipSpilitName("Date the insurance cover expires", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} className="pt-0">

                        <DateInput
                            name="InsuranceexpiryDate"
                            value={formik.values.InsuranceexpiryDate}
                            onChange={(date) => formik.setFieldValue('InsuranceexpiryDate', date)}
                            customClass={Style.time_label + " small-input w-100"}
                            size="small"
                            filterDate={new Date()}
                        />

                    </Grid>


                    <Grid item xs={12} sm={12} md={1.5} className="pt-0" style={{ paddingLeft: '30px' }}>
                        <span className={Style.font_bold}>Responsibility </span>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} className="pt-0" >
                        <div className="w-100">
                            <SelectInput
                                name="Insuranceresponsibility"
                                options={PropertyCertificateOptions}
                                selectedValue={formik.values.Insuranceresponsibility}
                                onChange={(e) => formik.setFieldValue('Insuranceresponsibility', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder={"Select"}
                                disabled={formik.values.InsuranceexpiryDate === null ? true : ""}

                            />
                        </div>

                    </Grid>

                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}>
                            Legionella risk assessment date

                            <Tooltip title={TooltipSpilitName("Date of the risk assessment", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} className="pt-0">

                        <DateInput
                            name="AssessmentexpiryDate"
                            value={formik.values.AssessmentexpiryDate}
                            onChange={(date) => formik.setFieldValue('AssessmentexpiryDate', date)}
                            customClass={Style.time_label + " small-input w-100"}
                            size="small"
                            filterDate={new Date()}

                        />


                    </Grid>


                    <Grid item xs={12} sm={12} md={1.5} className="pt-0" style={{ paddingLeft: '30px' }}>
                        <span className={Style.font_bold}>Responsibility </span>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} className="pt-0" >
                        <div className="w-100">
                            <SelectInput
                                name="Assessmentresponsibility"
                                options={PropertyCertificateOptions}
                                selectedValue={formik.values.Assessmentresponsibility}
                                onChange={(e) => formik.setFieldValue('Assessmentresponsibility', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder={"Select"}
                                disabled={formik.values.AssessmentexpiryDate === null ? true : ""}

                            />
                        </div>

                    </Grid>

                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}>
                            Smoke & CO alarm expiry date
                            <Tooltip title={TooltipSpilitName("Date of smoke or carbon monoxide alarm expiry", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>

                        </label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} className="pt-0">

                        <DateInput
                            name="SmokeexpiryDate"
                            value={formik.values.SmokeexpiryDate}
                            onChange={(date) => formik.setFieldValue('SmokeexpiryDate', date)}
                            customClass={Style.time_label + " small-input w-100"}
                            size="small"
                            filterDate={new Date()}

                        />

                    </Grid>


                    <Grid item xs={12} sm={12} md={1.5} className="pt-0" style={{ paddingLeft: '30px' }}>
                        <span className={Style.font_bold}>Responsibility </span>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} className="pt-0" >
                        <div className="w-100">
                            <SelectInput
                                name="Smokeresponsibility"
                                options={PropertyCertificateOptions}
                                selectedValue={formik.values.Smokeresponsibility}
                                onChange={(e) => formik.setFieldValue('Smokeresponsibility', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder={"Select"}
                                disabled={formik.values.SmokeexpiryDate === null ? true : ""}

                            />
                        </div>

                    </Grid>

                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}>
                            Property licence expiry date
                            <Tooltip title={TooltipSpilitName("Date the current property licence expires", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>

                        </label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} className="pt-0">

                        <DateInput
                            name="PropertyexpiryDate"
                            value={formik.values.PropertyexpiryDate}
                            onChange={(date) => formik.setFieldValue('PropertyexpiryDate', date)}
                            customClass={Style.time_label + " small-input w-100"}
                            size="small"
                            filterDate={new Date()}

                        />

                    </Grid>


                    <Grid item xs={12} sm={12} md={1.5} className="pt-0" style={{ paddingLeft: '30px' }}>
                        <span className={Style.font_bold}>Responsibility </span>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} className="pt-0" >
                        <div className="w-100">
                            <SelectInput
                                name="Propertyresponsibility"
                                options={PropertyCertificateOptions}
                                selectedValue={formik.values.Propertyresponsibility}
                                onChange={(e) => formik.setFieldValue('Propertyresponsibility', e.target.value)}
                                size="small"
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: '#F5F8FA' }}
                                placeholder="Select"
                                disabled={formik.values.PropertyexpiryDate === null ? true : ""}

                            />
                        </div>

                    </Grid>


                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}> Property licence type
                            <Tooltip title={TooltipSpilitName("Specify who is responsible for organising any maintenance work at the property", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className="pt-0  mb-0">

                        <div className="w-100">
                            <SelectInput
                                name="propertyLicenseType"
                                options={optionLisenseType}
                                selectedValue={formik.values.propertyLicenseType}
                                onChange={(e) => formik.setFieldValue("propertyLicenseType", e.target.value)}
                                size={"small"}
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: "#F5F8FA" }}
                                noOptionText={"No template found."}
                                placeholder={"Select"}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}>Property licence notes</label>
                        <textarea
                            rows="4"
                            name="propertyLicenseNote"
                            // className="form-control"
                            placeholder="Insert Property licence notes.."
                            value={formik.values.propertyLicenseNote}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={Style.custom_textarea + " w-100"}
                            style={{ backgroundColor: '#F5F8FA', border: '1px solid #CBD6E2' }}
                        />
                    </Grid>

                    <Grid item sx={12} className='pt-0'>
                        <label className={Style.labelform}> Right to Rent Check Responsibility  </label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className="pt-0">

                        <div className="w-100">
                            <SelectInput
                                name="rightToRentCheckResponsibility"
                                options={ManationRespoOptions}
                                selectedValue={formik.values.rightToRentCheckResponsibility}
                                onChange={(e) => formik.setFieldValue("rightToRentCheckResponsibility", e.target.value)}
                                size={"small"}
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: "#F5F8FA" }}
                                noOptionText={"No template found."}
                                placeholder={"Select "}

                            />
                        </div>
                    </Grid>


                    <Grid item xs={12} className='pt-0'>
                        <label className={Style.labelform}> Maintenance Responsibility
                            <Tooltip title={TooltipSpilitName("Specify who is responsible for organising any maintenance work at the property", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className="pt-0">

                        <div className="w-100">
                            <SelectInput
                                name="maintenanceResponsibility"
                                options={ManationRespoOptions}
                                selectedValue={formik.values.maintenanceResponsibility}
                                onChange={(e) => formik.setFieldValue("maintenanceResponsibility", e.target.value)}
                                size={"small"}
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: "#F5F8FA" }}
                                noOptionText={"No template found."}
                                placeholder={"Select"}
                            />
                        </div>
                    </Grid>




                    <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}>
                            Maintenance Notes
                            <Tooltip title={TooltipSpilitName("Provide any additional notes or information regarding maintenance at this property. These notes will be visible on the maintenance job pages.", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                        <textarea
                            rows="4"
                            name="maintenanceNotes"
                            // className="form-control"
                            placeholder="Insert maintenance notes.."
                            value={formik.values.maintenanceNotes}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={Style.custom_textarea + " w-100"}
                            style={{ backgroundColor: '#F5F8FA', border: '1px solid #CBD6E2' }}
                        />

                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}>
                            Rent Description Override
                            <Tooltip title={TooltipSpilitName("Used to override the standard description for a rent period", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>

                        </label>
                        <TextInput
                            customClass={Style.input_custom + " w-100 "}
                            type="text"
                            name="rentOverrideDescription"
                            variant="outlined"
                            placeholder=" Rent description override"
                            size={"small"}
                            value={formik.values.rentOverrideDescription}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Grid> */}

                    {/* <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}>
                            Landlord Statement Payment Ref
                            <Tooltip title={TooltipSpilitName("The default payment ref to be used when settling letting-level statements for this landlord. Can be overridden on a per letting basis if required. If unset, will use the value set on the landlord.", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                        <TextInput
                            customClass={Style.input_custom + " w-100 "}
                            type="text"
                            name="landlordStatementPaymentRef"
                            variant="outlined"
                            placeholder="Price"
                            size={"small"}
                            value={formik.values.landlordStatementPaymentRef}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Grid> */}


                    {/* <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}> Default Tenant Invoice Template
                            <Tooltip title={TooltipSpilitName("The default template to use when invoicing tenants at this property", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                        <div className="w-100">
                            <SelectInput
                                name="defaultTenantInvoiceType"
                                options={TenantInvoiceTypeOption}
                                selectedValue={formik.values.defaultTenantInvoiceType}
                                onChange={(e) => formik.setFieldValue("defaultTenantInvoiceType", e.target.value)}
                                size={"small"}
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: "#F5F8FA" }}
                                noOptionText={"No template found."}
                                placeholder={"Select"}
                            />
                        </div>
                    </Grid> */}


                    {/* <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <label className={Style.labelform}> Inspection Group
                            <Tooltip title={TooltipSpilitName("Customisable grouping of inspections (eg by priority, zone). Any letting at this property will default to the grouping set here on the property, but the value can be overridden on a per letting basis. Possible values can be set under Admin -> System Data.", 150)} placement="top">
                                <img src={questionImg} alt="Info" />
                            </Tooltip>
                        </label>
                        <div className="w-100">
                            <SelectInput
                                name="inspectionGroup"
                                options={inspectionGroupOption}
                                selectedValue={formik.values.inspectionGroup}
                                onChange={(e) => formik.setFieldValue("inspectionGroup", e.target.value)}
                                size={"small"}
                                customClass={Style.custom_select}
                                sx={{ backgroundColor: "#F5F8FA" }}
                                noOptionText={"No template found."}
                                placeholder={"Select"}
                            />
                        </div>
                    </Grid> */}








                </Grid>
                <hr />
                <div className={Style.propertycss1 + " " + Style.button_nav}>
                    <div style={{ marginRight: '12px' }}>
                        <Button color="secondary"
                            className={Style.delete_btn + " " + Style.edit_btn} onClick={handleBackButton}>
                            <KeyboardArrowLeftIcon /> Back
                        </Button>
                    </div>
                    <div onClick={handleNext}>
                        <ButtonLoader
                            type={"button"}
                            buttonName="Next"
                            loaderColor={"white"}
                            className={Style.Add_btn}
                            onClickhandle={formik.handleSubmit}
                            icon={<KeyboardArrowRightIcon />}


                        >
                        </ButtonLoader>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default LettingFees