import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';

import Style from "./AccountSetup2.module.css";
import Logo from "src/assets/images/logos/PropertyJinni-Logo.png";
import ProgressBar from "src/components/custom/ProgressBar";
import AgencyWebsite from "./AgencyWebsiteForm";
import BranchSelection from "./BranchSelection";
import BranchDetails from "./BranchDetails";
import DirectorManagement from "./DirectorManagement";
import TeamManagement from "./TeamsManagement";
import useAgencyData from "./hooks/useAgencyData";

const AccountSetup2 = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [customError, setCustomError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [agency, setAgency] = useState({});
  const [branchCount, setBranchCount] = useState(agency?.branchCount || 0);
  const { getAgencyDetails} = useAgencyData();

  const progressValue = ((step - 1) / 3) * 100;

  useEffect(() => {
    getAgencyDetails(setAgency,setStep,setBranchCount);
  }, []);
  
  
  return (
    <>
      <div className={Style.background_img + "  min-vh-100"} style={{ overflowX: "hidden"}}>
        <ProgressBar color={"#a259ff"} value={progressValue} />
        <div className="m-5">
          <div className="d-flex align-items-center">
            <img src={Logo}  alt="user"  className={Style.logo_img}/>
            {customError &&  <div className={Style.custom_error_box + " d-flex align-items-center justify-content-between"}>
              <div >{errorText}</div>
              <CloseIcon sx={{color:"#5B656E", fontSize:"20px", fontWeight:"700", cursor:"pointer"}} onClick={() => {setCustomError(false)}} />
            </div>
            }
          </div>
          {step > 1 && <div className="d-flex mt-3" style={{color:"#2379F0", cursor:"pointer"}} onClick={() => {setStep(prevStep => prevStep - 1); setCustomError(false); setErrorText("");}} > <KeyboardArrowLeftIcon /> Back</div>}
        </div>
        {/* {step === 1 && (<div className="">
          <AgencyWebsite 
            handleNext={() => {setStep(2)}} 
            handleAgencyData={(val) => {setAgency(val)}} 
            agency={agency}
          />
        </div>)} */}
        {/* {step === 2 && 
          <BranchSelection  
            handleNext={() => {setStep(3)}} 
            handleBranchValue={(val) => {setBranchCount(val)}} 
            branchCount={branchCount} 
            handleAgencyData={(val) => {setAgency(val)}} 
            agency={agency}
          />} */}
        {step === 1 && 
          <BranchDetails 
            handleNext={() => {setStep(2)}} 
            branches={branchCount} 
            handleAgencyData={(val) => {setAgency(val)}} 
            agency={agency}
          />}
        {step === 2 && 
          <DirectorManagement 
            handleNext={() => {setStep(3)}} 
            handleError={(text) => {setCustomError(true); setErrorText(text);}} 
            handleAgencyData={(val) => {setAgency(val)}} 
            agency={agency}
            closeError={() => {setCustomError(false); setErrorText(""); }}
          />}
        {step === 3 && 
          <TeamManagement 
            handleNext={() => {setStep(4)}} 
            branches={branchCount} 
            handleError={(text) => { setCustomError(true); setErrorText(text);}} 
            handleAgencyData={(val) => {setAgency(val)}} 
            agency={agency}
            closeError={() => {setCustomError(false); setErrorText(""); }}
          />}
      </div>
    </>
  );
};

export default AccountSetup2;
