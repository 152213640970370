import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Style from "./templates.module.css";
import { Box, Grid } from "@mui/material";
import TextInput from "src/components/forms/TextInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import ErrorMsg from "src/components/custom/ErrorMsg";
import { templateHeaderType, TemplateLabelOptions, templateType } from "src/constants/formPicker";
import SelectInput from "src/components/forms/SelectInput";
import WhatsappBackground from "src/assets/images/social-media/whatsapp_background.png";
import moment from "moment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import useTemplateData from "./hooks/useTemplateData";
import { FiFileText } from "react-icons/fi";
import LaunchIcon from '@mui/icons-material/Launch';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PdfFile from "src/assets/images/mobile/pdf.svg";
import { BiSolidFilePdf } from "react-icons/bi";

const AddTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { handleAddTemplate, fetchById, template, isDetailLoading, isLoading, handleUpdateTemplate } = useTemplateData();
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(null);
  const [base64Image, setBase64Image] = useState('');
  const [selectedFile, setSelectedFile] = useState('');

  const handleClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required("Name is required.")
      .test(
        "not-start-with-space",
        "Name cannot start with a space.",
        (value) => !value || !value.startsWith(" ")
      ),
    category: Yup.string().required("Category is required."),
    header_value: Yup.string()
      .trim()
      .nullable()
      .when('header_type', {
        is: 'TEXT',
        then: () => Yup.string().nullable()
          .max(60, "Header value cannot exceed 60 characters."),
        otherwise: () => Yup.string()
      }),
    header_variables: Yup.array()
      .of(
        Yup.object().shape({
          placeholder: Yup.string().required("Placeholder is required."),
          example: Yup.string().required("Value is required."),
          label: Yup.string().required("Label is required."),
        })
      )
      .test(
        "header-variables-required",
        "Header variables are required.",
        function (value) {
          return value?.length === 0 || (value?.length > 0 && value[0]?.placeholder !== "");
        }
      )
      .max(1, "You can only add one header variable."),
    body_message: Yup.string()
      .trim()
      .required("Body message is required.")
      .max(1024, "Body message cannot exceed 1024 characters."),
    body_variables: Yup.array()
      .of(
        Yup.object().shape({
          placeholder: Yup.string().required("Placeholder is required."),
          example: Yup.string().required("Value is required."),
          label: Yup.string().required("Label is required."),
        })
      )
      .test(
        "body-variables-required",
        "Body variables are required.",
        function (value) {
          return value?.length === 0 || (value?.length > 0 && value?.every(v => v.placeholder !== ""));
        }
      ),
    footer_message: Yup.string()
      .trim()
      .nullable()
      .max(60, "Footer value cannot exceed 60 characters."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "MARKETING",
      header_type: "TEXT",
      header_value: null,
      header_variables: [{ placeholder: "", example: "", label: "" }],
      body_message: "",
      body_variables: [{ placeholder: "", example: "", label: "" }],
      // footer_type: "TEXT",
      footer_message: null,
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {

      const formData = new FormData();
      formData.append("name", values.name.toLowerCase().replace(/\s+/g, '_'));
      formData.append("category", values.category);
      formData.append("header_type", values.header_type);
      if (id) {
        formData.append(values.header_type === 'TEXT' ? "header_value" : "files", values?.header_value === null || values?.header_value === "null" ? null:values?.header_value );
        formData.append("template_id", values?.template_id);
      }
      else {
        formData.append(values.header_type === 'TEXT' ? "header_value" : "files", values?.header_value === null || values?.header_value === "null" ? null:values?.header_value);
      }


      // If header_variables is an array, you need to handle it as well
      values.header_variables.forEach((variable, index) => {
        formData.append(`header_variables[${index}][placeholder]`, variable.placeholder);
        formData.append(`header_variables[${index}][example]`, variable.example);
        formData.append(`header_variables[${index}][label]`, variable.label);
      });

      formData.append("body_message", values.body_message);

      // Handle body_variables similarly
      values.body_variables.forEach((variable, index) => {
        formData.append(`body_variables[${index}][placeholder]`, variable.placeholder);
        formData.append(`body_variables[${index}][example]`, variable.example);
        formData.append(`body_variables[${index}][label]`, variable.label);
      });

      formData.append("footer_message",  values.footer_message === null || values.footer_message === "null" ? null:values.footer_message);

      if (id) {
        handleUpdateTemplate(id, formData);
      }
      else {
        handleAddTemplate(formData);
      }

    },
  });

  console.log("===========id", id)

  const replaceUnderscoreWithSpace = (str) => {
    return str.replace(/_/g, ' ');
  }

  const convertToKB = (bytes) => {
    if (bytes === 0) return '0 KB';
    const kilobytes = bytes / 1024;
    return `${kilobytes.toFixed(2)} KB`;
  }

  const extractVariables = (value, existingVariables = []) => {

    const regex = /{{\d+}}/g;
    const variables = value?.match(regex) || [];

    return variables.map((variable) => {
      const existingVariable = existingVariables.find(
        (v) => v.placeholder === variable
      );

      return {
        placeholder: variable,
        example: existingVariable?.example || "",
        label: existingVariable?.label || "",
      };
    });
  };

  const replaceVariables = (text, variables) => {
    let updatedText = text;
    if (variables) {
      variables?.forEach((variable) => {
        updatedText = updatedText?.replace(variable.placeholder, variable.example);
      });
    }

    return updatedText;
  };

  const handleCloseFile = () => {
    setFileName("");
    formik.setFieldValue("header_value", "");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setBase64Image(reader.result);
        // formik.setFieldValue("header_value", reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const renderHeaderContent = () => {
    const { header_type, header_value } = formik.values;

    switch (header_type) {
      case "TEXT":
        return (
          <TextInput
            customClass={Style.custom_search + " w-100"}
            type="text"
            name="header_value"
            value={header_value}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
            size={"small"}
            multiline={true}
            rows={5}
            sx={{ marginTop: "1rem", width: "80% !important" }}
            placeholder={"Enter Header"}
          />
        );
      case "IMAGE":
        return fileName ? (
          <div className={" d-flex align-items-center  w-100 mb-3 mt-1"}>
            <div className={Style.file_name}>{fileName}</div>
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleCloseFile();
              }}
            />
          </div>
        ) : (
          <div className="w-100 mb-3 mt-1">
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={(e) => {
                handleFileChange(e);
                formik.setFieldValue("header_value", e.currentTarget.files[0]);
                setSelectedFile(e.currentTarget.files[0]);
              }}
              hidden
            />
            <div onClick={handleClick} className={Style.upload_img}>
              <div style={{ textAlign: "center" }}>
                <CloudUploadIcon />
                <p className="mb-0">Upload your file here</p>
              </div>
            </div>
          </div>
        );
      case "VIDEO":
        return fileName ? (
          <div className={" d-flex align-items-center w-100 mb-3 mt-1"}>
            <div className={Style.file_name}>{fileName}</div>
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleCloseFile();
              }}
            />
          </div>
        ) : (
          <div className="w-100 mb-3 mt-1">
            <input
              type="file"
              ref={fileInputRef}
              multiple
              onChange={(e) => {
                handleFileChange(e);
                formik.setFieldValue("header_value", e.currentTarget.files[0]);
                setSelectedFile(e.currentTarget.files[0]);
              }}
              hidden
              accept="video/*"
            />
            <div onClick={handleClick} className={Style.upload_img}>
              <div style={{ textAlign: "center" }}>
                <CloudUploadIcon />
                <p className="mb-0">Upload your file here</p>
              </div>
            </div>
          </div>
        );
      case "DOCUMENT":
        return fileName ? (
          <div className={" d-flex align-items-center w-100 mb-3 mt-1"}>
            <div className={Style.file_name}>{fileName}</div>
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleCloseFile();
              }}
            />
          </div>
        ) : (
          <div className="w-100 mb-3 mt-1">
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => {
                handleFileChange(e);
                formik.setFieldValue("header_value", e.currentTarget.files[0]);
                setSelectedFile(e.currentTarget.files[0]);
              }}
              hidden
              accept=".pdf,.doc,.docx,.xls,.xlsx"
            />
            <div onClick={handleClick} className={Style.upload_img}>
              <div style={{ textAlign: "center" }}>
                <CloudUploadIcon />
                <p className="mb-0">Upload your document here</p>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }

  };

  useEffect(() => {
    if (formik.values.header_type === "TEXT") {
      const headerVariables = extractVariables(formik.values.header_value, formik.values.header_variables);
      formik.setFieldValue("header_variables", headerVariables);
    } else {
      setFileName(formik.values.header_value?.name);
    }
  }, [formik.values.header_value]);

  useEffect(() => {
    const bodyVariables = extractVariables(formik.values.body_message, formik.values.body_variables);
    formik.setFieldValue("body_variables", bodyVariables);
  }, [formik.values.body_message]);

  useEffect(() => {
    if (id) {
      fetchById(id);
    }
  }, [id]);


  useEffect(() => {
    if (template) {
      console.log('template.header_variables', template.header_variables);

      formik.setValues((prevValues) => ({
        ...prevValues,
        name: template.name || "",
        category: template.category || "MARKETING",
        language: template.language || "en",
        header_type: template.header_type || "TEXT",
        header_value: template.header_value === "null" || template.header_value === null ? null : template.header_value,
        header_variables: template.header_variables || [{ placeholder: "", example: "", label: "" }],
        body_message: template.body_message || "",
        body_variables: template.body_variables || [{ placeholder: "", example: "", label: "" }],
        footer_message: template.footer_message === "null" || template.footer_message === null ? null : template.footer_message,
        template_id: template.template_id || ""
      }));
    }
  }, [template]);

  return (
    <div
      style={{
        padding: "0rem 5rem",
        background: "#F8FAFE",
        minHeight: "calc(100vh - 57px)",
        height: "calc(100vh - 57px)",
        overflow: "hidden"
      }}
    >
      <div className="pt-4"

      >
        <div className="d-flex align-items-center justify-content-between" >

          <div className={Style.title}>  <span>
            {" "}
            <span>
              {" "}
              <ArrowBackIosIcon sx={{ cursor: "pointer" }} onClick={() => { navigate('/templates') }} />

            </span>
          </span> {id ? "Edit" : "New"} template</div>
          {!id ? <>
            <ButtonLoader
              type="button"
              color="primary "
              onClickhandle={formik.handleSubmit}
              buttonName={"Send For Approval"}
              isLoading={isLoading}
              loaderColor={"white"}
              style={{
                marginLeft: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
              className={Style.send_btn}
              disabled={isLoading}
            ></ButtonLoader>
          </> : ""}

        </div>
        <div className={Style.divide_border2}></div>
        <Box className="mt-3">
          <Grid container spacing={8} className="mt-1">
            <Grid item xs={12} sm={12} md={8} className={Style.custom_scroll} style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} className="pt-0">
                  <div className="w-100">
                    <label className={Style.form_label}>Message template name</label>
                    <TextInput
                      customClass={Style.custom_search + " w-100"}
                      type="text"
                      name="name"
                      value={replaceUnderscoreWithSpace(formik.values.name)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size={"small"}
                      sx={{ marginTop: "1rem", width: "80% !important" }}
                      placeholder={"Enter Name"}
                    />
                    <ErrorMsg
                      hasError={formik.touched.name && formik.errors.name}
                    >
                      {" "}
                      {formik.errors.name}{" "}
                    </ErrorMsg>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="pt-0">
                  <label className={Style.form_label}>Category</label>
                  <div className="w-100">
                    <SelectInput
                      name="category"
                      options={templateType}
                      selectedValue={formik.values.category}
                      onChange={(e) =>
                        formik.setFieldValue("category", e.target.value)
                      }
                      size={"small"}
                      customClass={Style.custom_select}
                      sx={{ backgroundColor: "#F5F8FA" }}
                      noOptionText={"No category found."}
                    />
                    <ErrorMsg
                      hasError={formik.touched.category && formik.errors.category}
                    >
                      {formik.errors.category}
                    </ErrorMsg>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div className="d-flex align-items-center">
                    <div className={Style.sub_heading}>Header</div>
                    <div className={Style.optional}>Optional</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={Style.sub_heading2 + " mb-2"}>Title</div>
                  <div className="mb-3" style={{ width: "20%" }}>
                    <SelectInput
                      name="header_type"
                      options={templateHeaderType}
                      selectedValue={formik.values.header_type}
                      onChange={(e) => {
                        formik.setFieldValue("header_type", e.target.value);
                        setFileName("");
                        formik.setFieldValue("header_value", "");
                        formik.setFieldValue("header_variables", []);
                      }}
                      size={"small"}
                      customClass={Style.custom_select}
                      sx={{ backgroundColor: "#F5F8FA" }}
                      noOptionText={"No category found."}
                    />
                    <ErrorMsg
                      hasError={
                        formik.touched.header_type && formik.errors.header_type
                      }
                    >
                      {" "}
                      {formik.errors.header_type}{" "}
                    </ErrorMsg>
                  </div>
                  {renderHeaderContent()}
                  <ErrorMsg
                    hasError={
                      formik.touched.header_value && formik.errors.header_value
                    }
                  >
                    {" "}
                    {formik.errors.header_value}{" "}
                  </ErrorMsg>
                  {formik.values.header_type === 'TEXT' && <> <p className={Style.max_text}>Max 60 characters</p>
                    {(formik.values?.header_variables && formik.values?.header_variables?.length > 0) && (
                      <div className={Style.variable_box}>
                        <h5>Sample value for the header text.</h5>
                        <h6>
                          Add these sample parameters to assist Meta in the process of
                          reviewing the Template.
                        </h6>
                        {formik.values?.header_variables?.map((variable, index) => (
                          <Grid container spacing={0} className="mt-3 align-items-center" key={index}>
                            <Grid item xs={12} sm={12} md={1}>
                              <div>{variable.placeholder}</div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={11}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                  <SelectInput
                                    name={`header_variables[${index}].label`}
                                    options={TemplateLabelOptions}
                                    selectedValue={variable.label}
                                    onChange={(e) => {
                                      const updatedVariables = formik.values.header_variables.map((v, i) =>
                                        i === index ? { ...v, label: e.target.value } : v
                                      );
                                      formik.setFieldValue("header_variables", updatedVariables);
                                    }}
                                    size={"small"}
                                    customClass={Style.custom_select}
                                    sx={{ backgroundColor: "#F5F8FA" }}
                                    noOptionText={"No labels found."}
                                  />
                                  {/* <TextInput
                                customClass={`${Style.custom_search} w-100`}
                                type="text"
                                name={`header_variables[${index}].label`}
                                value={variable.label}
                                onChange={(e) => {
                                  const updatedVariables = formik.values.header_variables.map((v, i) =>
                                    i === index ? { ...v, label: e.target.value } : v
                                  );
                                  formik.setFieldValue("header_variables", updatedVariables);
                                }}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size="small"
                                sx={{ marginTop: "1rem", width: "80% !important" }}
                                placeholder="label"
                              /> */}
                                  <ErrorMsg hasError={formik.touched.header_variables?.[index]?.label && formik.errors.header_variables?.[index]?.label}>
                                    {formik.errors.header_variables?.[index]?.label}
                                  </ErrorMsg>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <TextInput
                                    customClass={`${Style.custom_search} w-100`}
                                    type="text"
                                    name={`header_variables[${index}].example`}
                                    value={variable.example}
                                    onChange={(e) => {
                                      const updatedVariables = formik.values.header_variables.map((v, i) =>
                                        i === index ? { ...v, example: e.target.value } : v
                                      );
                                      formik.setFieldValue("header_variables", updatedVariables);
                                    }}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginTop: "1rem", width: "80% !important" }}
                                    placeholder="value"
                                  />
                                  <ErrorMsg hasError={formik.touched.header_variables?.[index]?.example && formik.errors.header_variables?.[index]?.example}>
                                    {formik.errors.header_variables?.[index]?.example}
                                  </ErrorMsg>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}

                      </div>
                    )}
                  </>
                  }
                  <div className={Style.divide_border}></div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={Style.sub_heading}>Body</div>
                  <div className="mt-3">
                    <TextInput
                      customClass={Style.custom_search + " w-100"}
                      type="text"
                      name="body_message"
                      value={formik.values.body_message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size={"small"}
                      sx={{ marginTop: "1rem", width: "80% !important" }}
                      multiline={true}
                      rows={5}
                      placeholder={"Enter Body Message"}
                    />
                  </div>
                  <ErrorMsg
                    hasError={
                      formik.touched.body_message && formik.errors.body_message
                    }
                  >
                    {formik.errors.body_message}{" "}
                  </ErrorMsg>
                  <p className={Style.max_text}>Max 1024 characters</p>
                  {(formik.values?.body_variables && formik.values?.body_variables?.length > 0) && (
                    <div className={Style.variable_box}>
                      <h5>Sample value for the body text.</h5>
                      <h6>
                        Add these sample parameters to assist Meta in the process of
                        reviewing the Template.
                      </h6>
                      {formik.values.body_variables.map((variable, index) => (
                        <Grid
                          container
                          spacing={0}
                          className="mt-3 align-items-center"
                          key={index}
                        >
                          <Grid item xs={12} sm={12} md={1}>
                            {variable.placeholder}{" "}
                          </Grid>
                          <Grid item xs={12} sm={12} md={11}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={6}>
                                <SelectInput
                                  name={`body_variables[${index}].label`}
                                  options={TemplateLabelOptions}
                                  selectedValue={variable.label}
                                  onChange={(e) => {
                                    const updatedVariables =
                                      formik.values?.body_variables?.map((v, i) =>
                                        i === index
                                          ? { ...v, label: e.target.value }
                                          : v
                                      );
                                    formik.setFieldValue(
                                      "body_variables",
                                      updatedVariables
                                    );
                                  }}
                                  size={"small"}
                                  customClass={Style.custom_select}
                                  sx={{ backgroundColor: "#F5F8FA" }}
                                  noOptionText={"No labels found."}
                                />
                                {/* <TextInput
                                  customClass={Style.custom_search + " w-100"}
                                  type="text"
                                  name={`body_variables[${index}].label`}
                                  value={variable.label}
                                  onChange={(e) => {
                                    const updatedVariables =
                                      formik.values?.body_variables?.map((v, i) =>
                                        i === index
                                          ? { ...v, label: e.target.value }
                                          : v
                                      );
                                    formik.setFieldValue(
                                      "body_variables",
                                      updatedVariables
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  variant="outlined"
                                  size={"small"}
                                  sx={{
                                    marginTop: "1rem",
                                    width: "80% !important",
                                  }}
                                  placeholder={"label"}
                                /> */}
                                <ErrorMsg hasError={formik.touched.body_variables?.[index]?.label && formik.errors.body_variables?.[index]?.label}>
                                  {formik.errors.body_variables?.[index]?.label}
                                </ErrorMsg>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                  customClass={Style.custom_search + " w-100"}
                                  type="text"
                                  name={`body_variables[${index}].example`}
                                  value={variable.example}
                                  onChange={(e) => {
                                    const updatedVariables =
                                      formik.values?.body_variables?.map((v, i) =>
                                        i === index
                                          ? { ...v, example: e.target.value }
                                          : v
                                      );
                                    formik.setFieldValue(
                                      "body_variables",
                                      updatedVariables
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  variant="outlined"
                                  size={"small"}
                                  sx={{
                                    marginTop: "1rem",
                                    width: "80% !important",
                                  }}
                                  placeholder={"value"}
                                />
                                <ErrorMsg hasError={formik.touched.body_variables?.[index]?.example && formik.errors.body_variables?.[index]?.example}>
                                  {formik.errors.body_variables?.[index]?.example}
                                </ErrorMsg>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  )}
                  <div className={Style.divide_border}></div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div className="d-flex align-items-center ">
                    <div className={Style.sub_heading}>Footer</div>
                    <div className={Style.optional}>Optional</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {/* <div className="mb-3" style={{ width: "15%" }}>
                    <SelectInput
                      name="footer_type"
                      options={templateHeaderType}
                      selectedValue={formik.values.footer_type}
                      onChange={(e) => {
                        formik.setFieldValue("footer_type", e.target.value);
                      }}
                      size={"small"}
                      customClass={Style.custom_select}
                      sx={{ backgroundColor: "#F5F8FA" }}
                      noOptionText={"No tupe found."}
                      disabled={true}
                    />
                    <ErrorMsg
                      hasError={
                        formik.touched.footer_type && formik.errors.footer_type
                      }
                    >
                      {" "}
                      {formik.errors.footer_type}
                    </ErrorMsg>
                  </div> */}
                  <div>
                    <TextInput
                      customClass={Style.custom_search + " w-100"}
                      type="text"
                      name="footer_message"
                      value={formik.values.footer_message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size={"small"}
                      sx={{ marginTop: "1rem", width: "80% !important" }}
                      multiline={true}
                      rows={5}
                      placeholder={"Enter Footer Message"}
                    />
                    <ErrorMsg hasError={formik.touched.footer_message && formik.errors.footer_message} >
                      {formik.errors.footer_message}
                    </ErrorMsg>
                    <p className={Style.max_text}>Max 60 characters</p>
                  </div>
                </Grid>
                {!id ? <>
                  <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>

                    <ButtonLoader
                      type="button"
                      color="primary "
                      onClickhandle={formik.handleSubmit}
                      buttonName={"Send For Approval"}
                      isLoading={isLoading}
                      loaderColor={"white"}
                      style={{
                        marginLeft: "12px",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                      }}
                      className={Style.send_btn}
                      disabled={isLoading}
                    ></ButtonLoader>
                  </Grid>
                </> : ""}

              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} style={{ textAlign: "right", paddingTop: "30px" }}>
              <div className="position-relative " style={{ height: "calc(100vh - 57px - 170px)" }}>
                {/* <img className={Style.preview_img +" " + Style.custom_scroll2 + " w-100 h-100"} src={WhatsappBackground}  /> */}
                <div className={Style.preview_img + " " + Style.custom_scroll2 + " w-100 h-100"}>

                  {(formik.values.header_value || formik.values.body_message || formik.values.footer_message) && (
                    <div className="position-relative " style={{ padding: "2rem" }}>
                      <div className={Style.preview_card + " w-100"}>
                        <div className={Style.preview_text}>
                          <div className={Style.preview_header}>
                            {formik.values.header_type === "TEXT"
                              ? replaceVariables(
                                formik.values.header_value,
                                formik.values.header_variables
                              )
                              : formik.values.header_type === "IMAGE"
                                ? formik.values.header_value && (
                                  selectedFile ?
                                    <img
                                      src={URL.createObjectURL(
                                        selectedFile
                                      )}
                                      alt={`Preview`}
                                      className={Style.preview_img2}
                                    />
                                    :
                                    <img
                                      src={template?.media?.url}
                                      alt={`Preview`}
                                      className={Style.preview_img2}
                                    />
                                )
                                : formik.values.header_type === "VIDEO"
                                  ? formik.values.header_value && (
                                    selectedFile ?
                                      <video
                                        controls
                                        src={URL.createObjectURL(
                                          formik.values.header_value
                                        )}
                                        className={Style.preview_video}
                                      />
                                      :
                                      <video
                                        controls
                                        src={template.media?.url}
                                        className={Style.preview_video}
                                      />

                                  ) :
                                  formik.values.header_type === "DOCUMENT"
                                    ? formik.values.header_value && selectedFile ? (
                                      // <div className={Style.document_preview}> <FiFileText /> </div>
                                      <div className={Style.pdf_preview_box}>
                                        <BiSolidFilePdf style={{ color: "red", fontSize: "30px" }} />
                                        <div>
                                          <h6>{selectedFile?.fileName}</h6>
                                          <div className="d-flex align-items-center">
                                            <p>{selectedFile?.size}</p>
                                            <span className={Style.dot}></span>
                                            <p>PDF</p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                      :
                                      <div className={Style.pdf_preview_box}>
                                        <BiSolidFilePdf style={{ color: "red", fontSize: "30px" }} />
                                        <div>
                                          <h6>{template?.media?.filename}</h6>
                                          <div className="d-flex align-items-center">
                                            <p>{convertToKB(template?.media?.size)}</p>
                                            <span className={Style.dot}></span>
                                            <p>PDF</p>
                                          </div>
                                        </div>
                                      </div>
                                    :
                                    ""}
                          </div>
                          <div className={Style.preview_body}>
                            {replaceVariables(
                              formik.values.body_message,
                              formik.values.body_variables
                            )}
                          </div>
                          <div className={Style.preview_footer}>
                            {formik.values.footer_message}
                          </div>
                          <div className={Style.preview_time}>
                            {moment().format("hh:mm a")}
                          </div>
                          <div className={Style.not_interested_div}>
                            <button type="button" className={Style.not_interested_btn}><NotInterestedIcon /> <span>Not Interested</span> </button>
                          </div>
                          <div className={Style.chat_btn_div}>
                            <button type="button" className={Style.chat_btn}><LaunchIcon /> <span>Start Chat</span> </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default AddTemplate;
