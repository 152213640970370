import React, { useEffect, useState, useRef } from "react";
import Style from "./AccountSetup2.module.css";
import TextInput from "src/components/forms/TextInput";
import PhoneNumberWithCountryCode from "src/components/forms/PhoneNumberWithCountryCode";
import { countryCodes } from "src/constants/formPicker";
import CountryCode from "src/components/forms/CountriesCode";
import ErrorMsg from "src/components/custom/ErrorMsg";
import { getIn } from "formik";
import { RiDeleteBin6Line } from "react-icons/ri";

const DirectorForm = ({ index, directors, errors, touched, handleChange, closeError, setFieldValue,removeDirector }) => {


  return (
    <>
      {directors?.map((director, directorIndex) => (
        <div key={directorIndex} style={{position:"relative"}}>
          {directorIndex > 0 && <div className="w-100 " style={{textAlign: "right",position: "absolute",top: "50%",  right:"-18px"}}>
                <RiDeleteBin6Line style={{color:"red", fontSize:"16px", cursor:"pointer" }} onClick={() => {removeDirector(directorIndex)}}/>
              </div>}
              <div  className="d-flex  justify-content-between mb-3" >

          <div className="col-md-4 p-2">
            <div className="d-grid gap-2  ">
              <label className={Style.custom_label}> Full Name<span className="red_required_span">{" "} *{" "}</span> </label>
              <TextInput
                name={`directors.${directorIndex}.name`}
                customClass={Style.input_custom + " w-100"}
                type="text"
                value={director.name}
                onChange={handleChange}
                variant="outlined"
                size={"small"}
              />
            </div>
            <ErrorMsg  hasError={ getIn( touched,`directors.${directorIndex}.name`) && getIn( errors,`directors.${directorIndex}.name`)}>
                        {getIn( errors,`directors.${directorIndex}.name`)}  </ErrorMsg>
                         </div>

          <div className="col-md-4 p-2">
            <div className={" d-grid gap-2"}>
              <label className={Style.custom_label}> Email Address<span className="red_required_span">{" "} *{" "}</span> </label>
              <TextInput
                customClass={Style.input_custom + " w-100"}
                type="text"
                name={`directors[${directorIndex}].email`}
                value={director.email}
                onChange={handleChange}
                variant="outlined"
                size={"small"}
              />
            </div>
            <ErrorMsg  hasError={ getIn( touched,`directors.${directorIndex}.email`) && getIn( errors,`directors.${directorIndex}.email`)}>
                        {getIn( errors,`directors.${directorIndex}.email`)}  </ErrorMsg>
          </div>

          <div className="col-md-4 p-2">
            <div className="d-grid gap-2  ">
              <label className={Style.custom_label}> Phone Number<span className="red_required_span">{" "} *{" "}</span> </label>
            
            <div className={Style.phone_field + " d-flex align-items-center"}>
              <div style={{ width: "75%" }}>
                <CountryCode
                  customClass={Style.custom_textfield + " w-100"}
                  name={`directors[${directorIndex}].countryCode`}
                  placeholder={"Country Code"}
                  options={countryCodes}
                  selectedValue={director.countryCode}
                  noOptionText={"No Country Code found."}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue(`directors[${directorIndex}].countryCode`, e)
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <PhoneNumberWithCountryCode
                  customClass={Style.custom_textfield + " w-100"}
                  countryCode={director.countryCode}
                  name={`directors[${directorIndex}].phone`}
                  value={director.phone}
                  variant="outlined"
                  placeholder="Phone"
                  size={"small"}
                  onChange={handleChange}
                />
              </div>
            </div>
            </div>
            <ErrorMsg hasError={errors?.directors?.[directorIndex]?.countryCode }>{errors?.directors?.[directorIndex]?.countryCode} </ErrorMsg>

            <ErrorMsg  hasError={ getIn( touched,`directors.${directorIndex}.phone`) && getIn( errors,`directors.${directorIndex}.phone`)}>
            {getIn( errors,`directors.${directorIndex}.phone`)}  </ErrorMsg>
            {/* <ErrorMsg hasError={errors?.directors?.[directorIndex]?.phone }>{errors?.directors?.[directorIndex]?.phone} </ErrorMsg> */}
          </div>
        </div>
        </div>
        
      ))}
    </>
  );
};

export default DirectorForm;
