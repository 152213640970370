import React, { useEffect, useState } from "react";
import { useFormik, FormikProvider, FieldArray } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as Yup from "yup";
import styles from "../chatbot.module.css";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, } from "@mui/material";
import useChatbotAction from "../hooks/useChatbotAction";
import { CHATBOT_NODE_TYPE } from "src/constants/chatbotConstants";
import CustomIconButton from "src/components/custom/CustomIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { WaitingTime, agentType, propertyType } from "src/constants/formPicker";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import RestoreIcon from "@mui/icons-material/Restore";
import { Col, Row } from "reactstrap";
import MenuIcon from '@mui/icons-material/Menu';
import { removeTextInParentheses } from "src/helper/commonHelp";

const EditNodeModal = ({
  toggleEditChildForm,
  orgData,
  selectedNode,
  closeModal,
  onSuccess,
  setPublishedChatbot,
  categories,
  categoriesProperty,
  chatbotId,
  captureFields
}) => {

  const { updateNodetoDB, loading } = useChatbotAction();
  const [updatedCustomFields, setUpdatedCustomFields] = useState([])

  const handleEditChild = async (values, { resetForm }) => {
    const updatedNodeArr = await updateNodetoDB(orgData, values, selectedNode, chatbotId);
    if (updatedNodeArr) {
      onSuccess(updatedNodeArr);
      setPublishedChatbot(false);
      resetForm();
    }
  };

  const editNodeValidateSchema = Yup.object().shape({
    nodeDesc: Yup.string().when("nodeType", {
      is: (value) => value === "live-chat",
      then: () => Yup.string().required("Online message is required.")
        .test(
          "not-start-with-space",
          "Online message cannot start with a space.",
          (value) => {
            return !value || !value.startsWith(" ");
          }
        ),
      otherwise: () => Yup.string().when("nodeType", {
        is: (value) => value !== "capture-user-details",
        then: () => Yup.string().required("Description is required.")
          .test(
            "not-start-with-space",
            "Description cannot start with a space.",
            (value) => {
              return !value || !value.startsWith(" ");
            }
          ),
        otherwise: () => Yup.string().notRequired()
      }),
    }),
    offlineMessage: Yup.string().when("nodeType", {
      is: (value) => value === "live-chat",
      then: () => Yup.string().required("Offline message is required.")
        .test(
          "not-start-with-space",
          "Offline message cannot start with a space.",
          (value) => {
            return !value || !value.startsWith(" ");
          }
        ),
      otherwise: () => Yup.string().notRequired(), // Node Description is not required
    }),
    waitingTime: Yup.string().when("nodeType", {
      is: (value) => value === "live-chat",
      then: () => Yup.number().min(1, 'Waiting time must be at least 1 minute.').required('Waiting time is required for live chat.'),
      otherwise: () => Yup.string().notRequired(), // Node Description is not required
    }),
    nodeType: Yup.string().required("Type is required."),
    questionOptions: Yup.array().when("nodeType", {
      is: (value) => ["question", "capture-visitor", "capture-property"].includes(value),
      then: () =>
        Yup.array().of(
          Yup.object().shape({
            questionDesc: Yup.string().required("Option is required.").test(
              'is-trimmed',
              'Option should not have leading or trailing spaces.',
              value => value === value.trim()
            ),
          })
        ).min(1, "At least one option is required."),
    }),
    attributes: Yup.object().shape({
    }),
    customFieldTitle: Yup.string().when("isCustomField", {
      is: true,
      then: () => Yup.string().required("Capture lable is required."),
      otherwise: () => Yup.string().notRequired(), // Node custom field is not required
    }),
  });

  const initialEditValues = {
    nodeDesc:
      selectedNode && selectedNode.nodeDesc ? selectedNode.nodeDesc : "",
    nodeType:
      selectedNode && selectedNode.nodeType ? selectedNode.nodeType : "",

    questionOptions:
      selectedNode && selectedNode?.children?.length > 0
        ? selectedNode?.children?.map((item) => ({
          questionDesc: item.nodeDesc,
          childId: item._id,
          nodeType: item.nodeType,
          questionType: selectedNode.nodeType === 'capture-visitor' ? item?.visitorCategory : item?.propertyCategory
        }))
        : [{ questionDesc: "", questionType: "" }],
    attributes: {
      isEmail:
        selectedNode && selectedNode.attributes
          ? selectedNode.attributes?.isEmail
          : false,
      emailAddress:
        selectedNode && selectedNode.attributes
          ? selectedNode.attributes?.emailAddress
          : "",
    },
    waitingTime:
      selectedNode && selectedNode.waitingTime ? selectedNode.waitingTime : 0,
    offlineMessage:
      selectedNode && selectedNode.offlineMessage
        ? selectedNode.offlineMessage
        : "",
    isCustomField: selectedNode.customFieldTitle ? true : false,
    customFieldTitle: selectedNode.customFieldTitle
  };

  const formikEditChild = useFormik({
    initialValues: initialEditValues,
    validationSchema: editNodeValidateSchema,
    onSubmit: handleEditChild,
  });

  const handleAddOption = () => {
    formikEditChild.setValues({
      ...formikEditChild.values,
      questionOptions: [
        ...formikEditChild.values.questionOptions,
        { questionDesc: "", questionType: (formikEditChild.values.nodeType === "capture-property" && categoriesProperty?.length === 1) ? categoriesProperty[0] : "" },
      ],
    });
  };

  const handleRemoveOption = (index) => {
    const newOptions = [...formikEditChild.values.questionOptions];
    newOptions.splice(index, 1); // Remove the option at the specified index
    formikEditChild.setFieldValue("questionOptions", newOptions); // Update formik values with the new options
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const questionOptionsCopy = [...formikEditChild.values.questionOptions];
    const [removed] = questionOptionsCopy.splice(result.source.index, 1);
    questionOptionsCopy.splice(result.destination.index, 0, removed);
    formikEditChild.setFieldValue("questionOptions", questionOptionsCopy);
  };

  const findNodeById = (data, id) => {
    for (let node of data) {
      if (node._id === id) { return node };
      if (node.children) {
        const result = findNodeById(node.children, id);
        if (result) { return result };
      }
    }
    return null;
  }

  const getNodeVisitorValue = async (selectedNode) => {
    let currentNode = selectedNode;

    while (currentNode.parentId) {
      const parentNode = findNodeById(orgData, currentNode.parentId);
      if (parentNode) {
        if (parentNode.nodeType === 'capture-visitor') {
          return currentNode?.visitorCategory;
        };
        currentNode = parentNode;
      } else {
        break;
      }
    }
    return false;
  }

  const updatecaptureFields = async () => {
    if (captureFields && captureFields?.length > 0) {
      const visitorType = await getNodeVisitorValue(selectedNode);

      if (visitorType) {
        setUpdatedCustomFields(captureFields?.filter((data) => data?.userType?.includes(visitorType))?.map((data) => {
          return { ...data, displayField: { ...data.displayField, label: removeTextInParentheses(data.displayField.label) } }
        }))
      } else {
        setUpdatedCustomFields(captureFields)
      }
    }
  }

  useEffect(() => {
    updatecaptureFields()
  }, [captureFields, selectedNode])

  return (
    <Dialog
      open={toggleEditChildForm}
      onClose={closeModal}
      className={styles.modal_wrapper}
    >
      <FormikProvider value={formikEditChild}>
        <form onSubmit={formikEditChild.handleSubmit}>
          <div
            className={styles.modal_cancel}
            onClick={() => closeModal(false)}
          >
            <CloseOutlinedIcon />
          </div>
          <DialogTitle className={styles.modal_head}>Update Option</DialogTitle>
          <DialogContent className={styles.modal_inner}>
            {selectedNode?.nodeType !== "greeting" ? (
              <>
                <label className={styles.custom_label}>Select Type</label>
                <TextField
                  select
                  label=""
                  variant="outlined"
                  name="nodeType"
                  autoComplete="off"
                  disabled
                  className={styles.custom_field}
                  value={formikEditChild.values.nodeType}
                  onChange={formikEditChild.handleChange}
                  error={
                    formikEditChild.touched.nodeType &&
                    Boolean(formikEditChild.errors.nodeType)
                  }
                  helperText={
                    formikEditChild.touched.nodeType &&
                    formikEditChild.errors.nodeType
                  }
                  fullWidth
                  SelectProps={{ MenuProps: { sx: { height: "300px" } } }}
                >
                  {CHATBOT_NODE_TYPE.map((row, index) =>
                    row.visible ? (
                      <MenuItem key={`ndtk${index}`} value={row.value}>
                        {row.name}
                      </MenuItem>
                    ) : null
                  )}
                </TextField>
              </>
            ) : null}


            {(formikEditChild.values.nodeType === "message") && <label title="Click to save the response for future use. Please enter a descriptive title in the field below to identify the response easily." className={styles.custom_label + " pointer d-flex align-items-center mt-1 mb-2"}>
              <input type="checkbox" name='isCustomField' className="me-2"
                checked={formikEditChild?.values?.isCustomField}
                onChange={formikEditChild.handleChange} />
              Capture Field <ReportGmailerrorredIcon style={{ fontSize: "18px", marginLeft: "3px" }} />
            </label>}

            {formikEditChild?.values?.isCustomField && <div>
              <TextField
                select
                label=""
                variant="outlined"
                name={`customFieldTitle`}
                autoComplete="off"
                value={formikEditChild.values.customFieldTitle}
                onChange={formikEditChild.handleChange}
                placeholder="Add the field name"
                error={
                  formikEditChild.touched?.customFieldTitle &&
                  Boolean(formikEditChild.errors?.customFieldTitle)
                }
                helperText={
                  formikEditChild.touched?.customFieldTitle &&
                  formikEditChild.errors?.customFieldTitle
                }
                defaultValue=""
                className={styles.custom_field}
                fullWidth
                SelectProps={{ MenuProps: { sx: { height: "300px" } } }}
              >
                <MenuItem value="">Select Field</MenuItem>
                {updatedCustomFields?.map((row, index) =>
                  <MenuItem key={`ndtk${index}`} value={row?._id}>
                    {row?.displayField?.label}
                  </MenuItem>
                )}
              </TextField>
            </div>
            }
            {formikEditChild.values.nodeType !== "capture-user-details" && (
              <div
                className={
                  formikEditChild.values.nodeType === "live-chat"
                    ? styles.livechat_msg + " d-flex mt-2 position-relative"
                    : "mt-3"
                }
              >
                {formikEditChild.values.nodeType === "live-chat" && (
                  <ModeCommentIcon />
                )}
                <div className="w-100">
                  {formikEditChild.values.nodeType === "live-chat" && (
                    <label className={styles.custom_label}>
                      Online Message
                    </label>
                  )}
                  <TextField
                    name={`nodeDesc`}
                    className={styles.custom_error}
                    value={formikEditChild.values.nodeDesc}
                    onChange={formikEditChild.handleChange}
                    fullWidth
                    autoComplete="off"
                    rows={3}
                    multiline
                    placeholder={
                      formikEditChild.values.nodeType === "live-chat"
                        ? "Enter online message"
                        : "Description"
                    }
                    error={
                      formikEditChild.touched?.nodeDesc &&
                      Boolean(formikEditChild.errors?.nodeDesc)
                    }
                    helperText={
                      formikEditChild.touched?.nodeDesc &&
                      formikEditChild.errors?.nodeDesc
                    }
                  />
                </div>
                {formikEditChild.values.nodeType === "live-chat" && (
                  <div className={styles.line}></div>
                )}
              </div>
            )}

            {formikEditChild.values.nodeType === "live-chat" && (
              <>
                <div
                  className={
                    styles.livechat_msg + " d-flex mt-3 position-relative"
                  }
                >
                  <RestoreIcon />
                  <div className="w-100">
                    <label className={styles.custom_label}>Waiting Time</label>
                    <TextField
                      select
                      label=""
                      variant="outlined"
                      name="waitingTime"
                      autoComplete="off"
                      value={formikEditChild.values.waitingTime}
                      onChange={formikEditChild.handleChange}
                      error={
                        formikEditChild.touched.waitingTime &&
                        Boolean(formikEditChild.errors.waitingTime)
                      }
                      helperText={
                        formikEditChild.touched.waitingTime &&
                        formikEditChild.errors.waitingTime
                      }
                      defaultValue=""
                      className={styles.custom_field}
                      fullWidth
                      SelectProps={{ MenuProps: { sx: { height: "300px" } } }}
                    >
                      <MenuItem value={0}>Select time</MenuItem>
                      {WaitingTime.map((row, index) => (
                        <MenuItem key={`ndtkc${index}`} value={row.value}>
                          {row.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className={styles.dashed_line}></div>
                </div>
                <div className={styles.livechat_msg + " d-flex mt-2"}>
                  <ModeCommentIcon />
                  <div className="w-100">
                    <label className={styles.custom_label}>
                      Offline Message
                    </label>
                    <TextField
                      name={`offlineMessage`}
                      // className={styles.option_field}
                      className={"mt-2 " + styles.custom_error}
                      value={formikEditChild.values.offlineMessage}
                      onChange={formikEditChild.handleChange}
                      fullWidth
                      autoComplete="off"
                      rows={3}
                      multiline
                      placeholder="Enter offline message"
                      error={
                        formikEditChild.touched?.offlineMessage &&
                        Boolean(formikEditChild.errors?.offlineMessage)
                      }
                      helperText={
                        formikEditChild.touched?.offlineMessage &&
                        formikEditChild.errors?.offlineMessage
                      }
                    />
                  </div>
                </div>
              </>
            )}

            {(formikEditChild.values.nodeType === "question" || formikEditChild.values.nodeType === "capture-visitor" || formikEditChild.values.nodeType === "capture-property") && (
              <>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="questionOptions">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <FieldArray name="questionOptions">
                          {({ remove }) => (
                            <div>
                              {formikEditChild.values.questionOptions.map(
                                (option, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={`questionDesc-${index}`}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={styles.edit_wrap}
                                      >
                                        <div className={styles.modal_main + " d-flex align-items-center mt-3"}>
                                          <div className="me-1 d-flex align-items-center ">
                                            <CustomIconButton
                                              size={22}
                                              fontSize={15}
                                              color="#454545"
                                              icon={<MenuIcon />}
                                            />
                                          </div>
                                          <div className="w-100">
                                            <Row className="  align-items-center">
                                              <Col >
                                                <TextField
                                                  name={`questionOptions.${index}.questionDesc`}
                                                  className={styles.custom_field}
                                                  // className={styles.option_field}
                                                  value={option.questionDesc}
                                                  onChange={formikEditChild.handleChange}
                                                  autoComplete="off"
                                                  error={
                                                    formikEditChild.touched
                                                      .questionOptions &&
                                                    formikEditChild.errors
                                                      .questionOptions &&
                                                    formikEditChild.errors
                                                      .questionOptions[index]
                                                      ?.questionDesc
                                                  }
                                                  helperText={
                                                    (formikEditChild.touched
                                                      .questionOptions &&
                                                      formikEditChild.errors
                                                        .questionOptions &&
                                                      formikEditChild.errors
                                                        .questionOptions[index]
                                                        ?.questionDesc) ||
                                                    ""
                                                  }
                                                  fullWidth
                                                  size="small"
                                                />
                                              </Col>
                                              {formikEditChild.values.nodeType !== "question" && <Col xs="12" md="5" lg="5" className="ps-0 ">
                                                <TextField
                                                  className={styles.custom_field}
                                                  select
                                                  label=""
                                                  variant="outlined"
                                                  name={`questionOptions.${index}.questionType`}
                                                  autoComplete="off"
                                                  value={option.questionType}
                                                  onChange={formikEditChild.handleChange}
                                                  error={
                                                    formikEditChild.touched
                                                      .questionOptions &&
                                                    formikEditChild.errors
                                                      .questionOptions &&
                                                    formikEditChild.errors
                                                      .questionOptions[index]
                                                      ?.questionType
                                                  }
                                                  helperText={
                                                    (formikEditChild.touched
                                                      .questionOptions &&
                                                      formikEditChild.errors
                                                        .questionOptions &&
                                                      formikEditChild.errors
                                                        .questionOptions[index]
                                                        ?.questionType) ||
                                                    ""
                                                  }
                                                  defaultValue=""
                                                  fullWidth
                                                  SelectProps={{ MenuProps: { sx: { height: "300px" } } }}
                                                  size="small"
                                                >
                                                  <MenuItem value="">Select {formikEditChild.values.nodeType === "capture-visitor" ? "customer type" : "property type"}</MenuItem>
                                                  {formikEditChild.values.nodeType === "capture-visitor" ? agentType?.map((row, index) => (
                                                    categories.includes(row?.type) && <MenuItem key={`ndtkc${index}`} value={row.value}>
                                                      {row.label}
                                                    </MenuItem>
                                                  )) : propertyType?.map((row, index) => (
                                                    categoriesProperty.includes(row?.value) && <MenuItem key={`ndtkc${index}`} value={row.value}>
                                                      {row.label}
                                                    </MenuItem>
                                                  ))}
                                                </TextField>
                                              </Col>}



                                            </Row>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center">
                                            <CustomIconButton
                                              size={22}
                                              fontSize={18}
                                              color="#454545"
                                              icon={<DeleteIcon />}
                                              onClick={() =>
                                                handleRemoveOption(index)
                                              }
                                              disabled={
                                                formikEditChild.values
                                                  .questionOptions.length === 1
                                              }
                                            />
                                          </div>
                                        </div>

                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                            </div>
                          )}
                        </FieldArray>
                        {(!Array.isArray(formikEditChild?.errors?.questionOptions)) && formikEditChild?.errors?.questionOptions && (
                          <div style={{ color: "#e52d2d", fontSize: "0.95rem" }}>
                            {formikEditChild?.errors?.questionOptions}
                          </div>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {(formikEditChild.values.nodeType !== "capture-property" || categoriesProperty?.length !== 1 || formikEditChild?.values?.questionOptions?.length === 0) && <Button
                  type="button"
                  onClick={handleAddOption}
                  className={styles.add_option}
                >
                  {" "}
                  + Add option
                </Button>}

              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button type="submit" className={styles.modal_btn} disabled={loading}>
              Save
            </Button>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
};

export default EditNodeModal;
