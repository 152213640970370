import React, { useCallback, useEffect, useState } from "react";
import style from "./broadcast.module.css"; // Import the CSS module
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { debounce } from "src/helper/commonHelp";
import useAddBroadCast from "./hooks/useAddBroadCast";
import styles from 'src/views/apps/broadcast/filter.module.css';
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import usePropertyList from "src/components/apps/property/hooks/usePropertyList";
import SelectInput from 'src/components/forms/SelectInput';
import { BathroomOptions, BedroomOptions, FurnishOptions, RadiusOption } from "src/constants/propertyConstant";
import { MAX_PRICE_RANGE_SALE, MIN_PRICE_RANGE_SALE, MAX_PRICE_RANGE_LET, MIN_PRICE_RANGE_LET, PRICE_PER } from "src/constants/broadcastConstants";
import BroadcastTag from "./BroadcastTag";
import MultiSelectWithoutSearchBox from "src/components/forms/multiSelectwithoutSearchbox";
import ArraowPopover from "src/components/forms/ArraowPopover";
import SelectWithOutSearchBox from "src/components/forms/SelectWithoutSearchbox";

const BroadCastFilter = ({ contactfilters, setContactfilters, }) => {

    const { fetchPlacesList, placesData, setPlacesData, fetchPlaceDetail } = useAddBroadCast();

    const { propertytypeData, getPropertyTypeMaster } = usePropertyList();

    const [showList, setShowList] = useState(false);
    const [error, setError] = useState("");

    const [locationMap, setLocationMap] = useState(contactfilters?.displayCity || "")

    const userTxtChangeDebounce = useCallback(
        debounce((txtval) => fetchPlacesList(txtval), 800),
        []
    );

    const onChangeAddress = (e) => {
        setLocationMap(e.target.value);
        if (e.target.value.length > 0) {
            userTxtChangeDebounce(e.target.value);
            setShowList(true);
        }
    };

    const handleLocationClick = (location) => {
        if (location) {
            fetchPlaceDetail(location?.place_id, setContactfilters, setLocationMap);
        }
        setPlacesData([]);
        setShowList(false);
    };

    useEffect(() => {
        getPropertyTypeMaster()
    }, [])

    useEffect(() => {
        setLocationMap(contactfilters?.displayCity)
    }, [contactfilters?.displayCity])

    const customPrice = (
        <div className={styles.nonClickableWrapper}>
            <SelectInput
                // disabled
                customClass={styles.custom_select + " " + style.bgWhite}
                name="price"
                options={contactfilters?.filterType.includes("lettings") ? MAX_PRICE_RANGE_LET : MAX_PRICE_RANGE_SALE}
                selectedValue={contactfilters.maxPrice}
                size={"small"}
                noOptionsText={"No price option found."}
                placeholder={"Price"}
            />
        </div>
    )

    const customBedRoom = (
        <div className={styles.nonClickableWrapper}>
            <SelectInput customClass={styles.custom_select + " " + style.bgWhite}
                name="bedroom"
                options={BedroomOptions}
                selectedValue={contactfilters.maxBedroom}
                onChange={(e) => setContactfilters({ ...contactfilters, maxBedroom: e.target.value })}
                size={"small"}
                noOptionsText={"No bedroom option found."}
                placeholder={"Bedroom"}
            />
        </div>
    )

    const customBathRoom = (
        <div className={styles.nonClickableWrapper}>
            <SelectInput
                customClass={styles.custom_select + " " + style.bgWhite}
                name="price"
                options={BathroomOptions}
                selectedValue={contactfilters.maxBathroom}
                size={"small"}
                noOptionsText={"No price option found."}
                placeholder={"Price"}
            />
        </div>
    )

    return (
        contactfilters?.filterType !== "" &&

        <form className={`row g-3 mb-4 mt-0 ${style.form}`}>
            <div className="col-3 mb-1">
                <label htmlFor="status" className={styles.filterLabel}>Location</label>
                <div className={style.bgWhite + " " + styles.searchAddress + " " + styles.BotFilter_search_box + " position-relative"}>
                    <input
                        type="text"
                        placeholder="Search address or postcode..."
                        value={locationMap}
                        onChange={onChangeAddress}
                    />

                    {locationMap ? (
                        <div
                            className="reset_btn"
                            onClick={() => {
                                setLocationMap("");
                                setContactfilters((prev) => ({ ...prev, postCode: "" }))
                                setContactfilters((prev) => ({ ...prev, displayCity: "" }))
                                setContactfilters((prev) => ({ ...prev, location: "" }))
                            }}
                        >
                            <HighlightOffIcon
                                size={14}
                                className=" text-muted"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    ) : (
                        <span>
                            <SearchIcon />
                        </span>
                    )}

                    {showList &&
                        (placesData?.length > 0 ? (
                            <div className={style.place_card} onMouseLeave={()=>setShowList(false)}>
                                {placesData?.map((place, index) => (
                                    <div
                                        className={style.placeList}
                                        key={index}
                                    >
                                        <p
                                            className="mb-0"
                                            key={index}
                                            onClick={() => handleLocationClick(place)}
                                        >
                                            {place.description}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            error && (
                                <div className={style.place_card}>
                                    {" "}
                                    <div className={style.placeList}>
                                        {error}
                                    </div>
                                </div>
                            )
                        ))}
                </div>
            </div>


            {/* Bedroom Filter */}
            {<div className={styles.filterGroup + " col-3 mb-1"}>
                <label htmlFor="radius" className={styles.filterLabel}>Radius</label>

                <SelectWithOutSearchBox
                    customClass={styles.custom_select + " " + style.bgWhite}
                    name="radius"
                    options={RadiusOption}
                    selectedValue={contactfilters.radius}
                    onChange={(e) => setContactfilters({ ...contactfilters, radius: e })}
                    size={"small"}
                    popupMargin={{ marginTop: 15 }}
                    count={false}
                    noOptionsText={"No radius option found."}
                />
            </div>}

            {contactfilters?.filterType.includes("residential") && <div className={styles.filterGroup + " col-3 mb-1"}>
                <label htmlFor="price" className={styles.filterLabel}>Bedroom</label>

                <ArraowPopover
                    content={customBedRoom}
                    popupMargin={{ marginTop: 10 }}
                    arrowPosition={{ top: "1px", left: "40%", borderTop: "2px solid #CBD6E2", borderLeft: "2px solid #CBD6E2", background: "#FFF", }}
                >
                    <div className="row g-3 p-2" style={{ width: "350px" }} >
                        <div className="col-6 pt-1">
                            <label htmlFor="minPrice" className={styles.filterLabel}>Min beds</label>
                            <SelectInput
                                customClass={styles.custom_select + " " + style.bgWhite}
                                name="minBedroom"
                                options={BedroomOptions}
                                selectedValue={contactfilters.minBedroom}
                                onChange={(e) => setContactfilters({ ...contactfilters, minBedroom: e.target.value })}
                                size={"small"}
                                noOptionsText={"No price option found."}
                                placeholder={"Min beds"}
                            />
                        </div>
                        <div className="col-6 pt-1">
                            <label htmlFor="maxPrice" className={styles.filterLabel}>Max beds</label>
                            <SelectInput customClass={styles.custom_select + " " + style.bgWhite}
                                name="maxPrice"
                                options={BedroomOptions}
                                selectedValue={contactfilters.maxBedroom || 0}
                                onChange={(e) => setContactfilters({ ...contactfilters, maxBedroom: e.target.value })}
                                size={"small"}
                                noOptionsText={"No price option found."}
                                placeholder={"Max beds"}
                            />
                        </div>
                        {contactfilters.minBedroom >= contactfilters.maxBedroom && contactfilters.minBedroom !== 0 && contactfilters.maxBedroom !== 0 && <div className="text-danger">{"Min beds should less then max beds"}</div>}

                    </div>
                </ArraowPopover>
            </div>}

            {contactfilters?.filterType.includes("residential") && <div className={styles.filterGroup + " col-3 mb-1"}>
                <label htmlFor="price" className={styles.filterLabel}>Bathroom</label>

                <ArraowPopover
                    content={customBathRoom}
                    popupMargin={{ marginTop: 10 }}
                    arrowPosition={{ top: "1px", left: "40%", borderTop: "2px solid #CBD6E2", borderLeft: "2px solid #CBD6E2", background: "#FFF", }}
                >
                    <div className="row g-3 p-2" style={{ width: "350px" }} >
                        <div className="col-6 pt-1">
                            <label htmlFor="minBathroom" className={styles.filterLabel}>Min baths</label>
                            <SelectInput
                                customClass={styles.custom_select + " " + style.bgWhite}
                                name="minBathroom"
                                options={BathroomOptions || 0}
                                selectedValue={contactfilters.minBathroom}
                                onChange={(e) => setContactfilters({ ...contactfilters, minBathroom: e.target.value })}
                                size={"small"}
                                noOptionsText={"No baths option found."}
                                placeholder={"Min baths"}
                            />
                        </div>
                        <div className="col-6 pt-1">
                            <label htmlFor="maxBathroom" className={styles.filterLabel}>Max baths</label>
                            <SelectInput customClass={styles.custom_select + " " + style.bgWhite}
                                name="maxBathroom"
                                options={BathroomOptions}
                                selectedValue={contactfilters.maxBathroom || 0}
                                onChange={(e) => setContactfilters({ ...contactfilters, maxBathroom: e.target.value })}
                                size={"small"}
                                noOptionsText={"No baths option found."}
                                placeholder={"Max baths"}
                            />
                        </div>
                        {contactfilters.minBathroom >= contactfilters.maxBathroom && contactfilters.minBathroom !== 0 && contactfilters.maxBathroom !== 0 && <div className="text-danger">{"Min baths should less then max baths"}</div>}
                    </div>
                </ArraowPopover>
            </div>}
            {<div className={styles.filterGroup + " col-3 mb-1"}>
                <label htmlFor="price" className={styles.filterLabel}>Price</label>
                <ArraowPopover
                    content={customPrice}
                    popupMargin={{ marginTop: 10 }}
                    arrowPosition={{ top: "1px", left: "10%", borderTop: "2px solid #CBD6E2", borderLeft: "2px solid #CBD6E2", background: "#FFF", }}
                >
                    <div className="row g-3 p-2" style={{ width: contactfilters?.filterType.includes("lettings") ? "550px" : "400px" }} >
                        <div className={contactfilters?.filterType.includes("lettings") ? "col-4 pt-1" : "col-6 pt-1"}>
                            <label htmlFor="minPrice" className={styles.filterLabel}>Min price</label>
                            <SelectInput
                                customClass={styles.custom_select + " " + style.bgWhite}
                                name="minPrice"
                                selectedValue={contactfilters.minPrice}
                                options={contactfilters?.filterType.includes("lettings") ? MIN_PRICE_RANGE_LET : MIN_PRICE_RANGE_SALE}
                                onChange={(e) => setContactfilters({ ...contactfilters, minPrice: e.target.value })}
                                size={"small"}
                                noOptionsText={"No price option found."}
                                placeholder={"Min price"}
                            />
                        </div>
                        <div className={contactfilters?.filterType.includes("lettings") ? "col-4 pt-1" : "col-6 pt-1"}>
                            <label htmlFor="maxPrice" className={styles.filterLabel}>Max price</label>
                            <SelectInput customClass={styles.custom_select + " " + style.bgWhite}
                                name="maxPrice"
                                options={contactfilters?.filterType.includes("lettings") ? MAX_PRICE_RANGE_LET : MAX_PRICE_RANGE_SALE}
                                selectedValue={contactfilters.maxPrice}
                                onChange={(e) => setContactfilters({ ...contactfilters, maxPrice: e.target.value })}
                                size={"small"}
                                noOptionsText={"No price option found."}
                                placeholder={"Max price"}
                            />
                        </div>

                        {contactfilters?.filterType.includes("lettings") && <div className="col-4 pt-1">
                            <label htmlFor="priceType" className={styles.filterLabel}>Price per</label>
                            <SelectInput customClass={styles.custom_select + " " + style.bgWhite}
                                name="priceType"
                                options={PRICE_PER}
                                selectedValue={contactfilters.priceType || ""}
                                onChange={(e) => setContactfilters({ ...contactfilters, priceType: e.target.value })}
                                size={"small"}
                                noOptionsText={"No price option found."}
                                placeholder={"Price per"}
                            /></div>}
                        {contactfilters.minPrice >= contactfilters.maxPrice && contactfilters.minPrice !== 0 && contactfilters.maxPrice !== 0 && <div className="text-danger">{"Min price should less then max price"}</div>}
                    </div>
                </ArraowPopover>
            </div>}
            {/* Property Type Filter */}
            <div className={styles.filterGroup + " col-3 mb-1"}>
                <label htmlFor="propertyType" className={styles.filterLabel}>Property Type</label>
                <MultiSelectWithoutSearchBox
                    customClass={styles.custom_select + " " + style.bgWhite}
                    name="propertyType"
                    options={propertytypeData}
                    allFeature={true}
                    onChange={(e) => setContactfilters({ ...contactfilters, propertyType: e || [] })}
                    size={"small"}
                    noOptionsText={"No property type found."}
                    placeholder={"Property Type"}
                    selectedValues={contactfilters.propertyType}
                    popupMargin={{ marginTop: 15 }}
                    count={false}
                    noOptionText={"No owner found."}
                    column={2}
                />
            </div>

            {contactfilters?.filterType.includes("lettings") && <div className={styles.filterGroup + " col-3 mb-1"}>
                <label htmlFor="furnishing" className={styles.filterLabel}>Furnishing</label>

                <SelectWithOutSearchBox
                    customClass={styles.custom_select + " " + style.bgWhite}
                    name="furnishing"
                    options={FurnishOptions}
                    selectedValue={contactfilters.furnishType}
                    onChange={(e) => { setContactfilters({ ...contactfilters, furnishType: e }) }}
                    size={"small"}
                    placeholder={"Furnishing"}
                    popupMargin={{ marginTop: 15 }}
                    count={false}
                    noOptionText={"No option found."}
                />
            </div>}

            <div className={styles.filterGroup + (contactfilters?.filterType.includes("lettings") ? contactfilters?.filterType.includes("residential") ? " col-3 mb-1" : " col-9 mb-1" : contactfilters?.filterType.includes("residential") ? " col-6 mb-1" : " col-6 mb-1")}>
                <label htmlFor="tag" className={styles.filterLabel}>Tags</label>
                <div className="position-relative">
                    <BroadcastTag
                        tags={contactfilters?.tags}
                        setValue={(val) => {
                            console.log(val);
                            setContactfilters({ ...contactfilters, tags: [...contactfilters.tags, val] })
                        }}
                        fullWidth
                        variant="outlined"
                        id="tags"
                        name="tags"
                        placeholder="Add tags"
                        rows={3}
                        customClass={style.custom_search_tag}
                        handleDelete={(val) => setContactfilters({ ...contactfilters, tags: contactfilters?.tags?.filter((tag) => tag !== val) })}
                    />
                </div>
            </div>
        </form >

    );
};

export default BroadCastFilter;
