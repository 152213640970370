import * as React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Button } from "reactstrap";
import { ButtonLoader } from "../forms/ButtonLoader";
import styles from './DeleteModal.module.css'
import { RxCross2 } from "react-icons/rx";
import deleteIcon from '../../assets/images/modal/trash.svg';

export default function ConfirmationModal({
  closeModal,
  handleComfirm,
  text,
  open,
  isLoading,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >


      <Box sx={style} className={styles.delete_wrap}>
        <div className={styles.modal_wrap + " modal_box position-relative"} >
          {/* <div style={{ fontSize: '50px' }}>
            <FaExclamationCircle />
          </div> */}
          <img src={deleteIcon} className={styles.modal_icon} />
          <h3>Are You Sure </h3>
          <p>{text}</p>

          <div className="modal-btn mt-3 text-end d-flex gap-2">
            <Button color="secondary" onClick={closeModal} isLoading={isLoading}
              loaderColor={"white"} className={styles.delete_btn + " " + styles.edit_btn}>
              Cancel
            </Button>
            <ButtonLoader
              color="white "
              onClickhandle={(e) => handleComfirm(e)}
              buttonName="Confirm"
              isLoading={isLoading}
              loaderColor={"white"}

              className={styles.delete_btn}
            >
              Confirm
            </ButtonLoader>



          </div>

          <div className={styles.close_modal}>
            <RxCross2 onClick={closeModal} />
          </div>

        </div>
      </Box>
    </Modal>
  );
}
