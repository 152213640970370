import Toast from 'src/components/custom/Toast'
import Axios from 'src/utils/axios'

const useChatbotVersion = () => {

    const changeVersionTitle = (title, chatbotId, versionId, setchatBotData) => {
        Axios.post(`/chatBot/updateVersionDetailsChatbot/${chatbotId}`, { versionId: versionId, title: title })
            .then((res) => {
                setchatBotData(res.data?.data);
            }).catch((err) => {
                Toast(err, "error");
            })
    }

    const changeChatbotName = (title, chatbotId, setchatBotData) => {
        Axios.post(`/chatBot/updateChatbot/${chatbotId}`, { name: title })
            .then((res) => {
                setchatBotData(res.data?.data);
            }).catch((err) => {
                Toast(err, "error");
            })
    }

  
    return {
        changeVersionTitle,
        changeChatbotName
    }
}

export default useChatbotVersion