import { ButtonLoader, ButtonLoaderLeft } from "src/components/forms/ButtonLoader";
import Channels from "src/views/connectChat/channels";
import Style from "./AccountSetup2.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";


const SocialChannels = ({ handleNext, isLoading }) => {
    return <div style={{backgroundColor:"whitesmoke", minHeight:"calc(100vh - 115px)"}}>
    <div className={Style.custom_page_margin} >
        <Channels isAccountSetup={true} />
        <div className="d-flex align-items-center justify-content-end mb-3">
            <ButtonLoaderLeft
                // isLoading={isLoading}
                onClickhandle={() => { handleNext("back") }}
                buttonName="Back"
                loaderColor={"white"}
                className={Style.back_btn}
                icon={<KeyboardArrowLeftIcon />}
            ></ButtonLoaderLeft>
            <ButtonLoader
                isLoading={isLoading}
                onClickhandle={() => { handleNext() }}
                buttonName="Finish Setup"
                loaderColor={"white"}
                className={Style.save_btn}
                icon={<KeyboardArrowRightIcon />}
            ></ButtonLoader>
        </div>
    </div>
    </div>
}

export default SocialChannels;