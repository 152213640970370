import React, { useRef, useState } from 'react'
import styles from "./chatbot.module.css";
import moment from 'moment';
import useChatbotVersion from './hooks/useChatbotVersion';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const VersionCard = ({ data, setchatBotData, chatBotData, chatbotId, versionId, index }) => {

    const [isTextField, setIsTextField] = useState(false);
    const [versionTitle, setVersionTitle] = useState(data?.versionTitle || moment(data.publishedAt).format('DD/MM/YY - hh:mm A'));
    const inputRef = useRef(null);
    const { changeVersionTitle } = useChatbotVersion();
    const navigate = useNavigate()

    const handleFocusOut = (event) => {
        // Check if the click is outside the input field
        if (inputRef.current && !inputRef.current.contains(event.relatedTarget)) {
            setIsTextField(false)
            changeVersionTitle(versionTitle, chatbotId, data._id, setchatBotData);
        }
    };

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            try {
                setIsTextField(false)
                changeVersionTitle(versionTitle, chatbotId, data._id, setchatBotData);
            } catch (error) {
                console.error('Error updating version title:', error);
            }
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "mouse-over-popover" : "undefined";
    return (
        <div className={data._id === versionId ? styles.selectVersionCard + " " + styles.versionCard : styles.versionCard + " w-100"} >
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex'>
                    {!isTextField ?
                        <div onClick={() => { setIsTextField(true) }} className={styles.versionDate}>{versionTitle}  </div>
                        :
                        <input
                            value={versionTitle}
                            className={styles.versionTitleInput}
                            onChange={(e) => {   setVersionTitle(e.target.value)   }}
                            onBlur={handleFocusOut} // Handles the focus out event
                            ref={inputRef}
                            onKeyPress={handleKeyPress} // Handles key press events
                            autoFocus
                            type="text" name="" id="" />
                    }

                    {index === 0 && <span className='mx-2 pb-1' onMouseEnter={handleClick} onMouseLeave={handleClose}>  <InfoIcon style={{ fontSize: "20px" }} /> </span>}

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        sx={{ pointerEvents: 'none', }}
                        slotProps={{
                            paper: {
                                sx: {
                                    overflow: "visible", boxShadow: "none", borderRadius: "5px",
                                    "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 23,
                                        right: "49%",
                                        width: 11,
                                        height: 11,
                                        background: "#425B76",
                                        transform: "translateY(-50%) rotate(225deg)",
                                        boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                                        borderTop: "2px solid #425B76",
                                        borderLeft: "2px solid #425B76",
                                    },
                                },
                            },
                        }}
                    >
                        <div className={styles.done_popover}>
                            This is your last published version.
                        </div>
                    </Popover>
                </div>
                <div className={styles.versionSmallDate}>{moment(data.publishedAt).format('ddd DD MMM - HH:mm')}</div>
            </div>
            <div className={styles.publishedBy}>
                by {data?.publishedBy.name}
            </div>
            {data._id !== versionId ? <div className='d-flex align-items-center justify-content-between'>
                <button onClick={() => {
                    const url = `/chatbot/compare?chatbot=${chatbotId}&version=${data?._id}`;
                    const windowFeatures = "toolbar=no, menubar=no, resizable=yes, width=800, height=600";
                    window.open(url, '_blank', windowFeatures);
                }}
                    className={styles.compareButton}>Compare <LaunchIcon style={{fontSize:"16px"}} /></button>

                <button onClick={() => { navigate(`/chatbot/restore?chatbot=${chatbotId}&version=${data?._id}`) }}
                    className={styles.selectButton}>{data._id === versionId ? "Selected" : "Select"}</button>

            </div> : <div className='mb-4'>
            </div>}
        </div>
    )
}

export default VersionCard