import React, { useState } from 'react'
import Toast from 'src/components/custom/Toast';
import Axios from 'src/utils/axios';

const useAddBroadCast = () => {
    const [placesData, setPlacesData] = useState([])

    const fetchPlacesList = (place) => {
        Axios.get(`/places/address-autocomplete?text=${place}`)
            .then((res) => {
                setPlacesData(res?.data?.data);
            })
            .catch((err) => {
                setPlacesData([]);
                Toast(err, "error");
                // setError(err);
            });
    };
    const fetchPlaceDetail = (place_id, setContactfilters) => {
        Axios.get(`/places/place-detail-by-address-id?place_id=${place_id}`)
            .then((res) => {
                setContactfilters((prev) => ({ ...prev, postCode: res?.data?.data?.address?.zip }))
                setContactfilters((prev) => ({ ...prev, displayCity: res?.data?.data?.address?.City || res?.data?.data?.address?.locName}))
                setContactfilters((prev) => ({ ...prev, location: res?.data?.data?.address?.coordinates?.lat + "," + res?.data?.data?.address?.coordinates?.lng }))
            })
            .catch((err) => {
                Toast(err, "error");
            });
    };
    return { fetchPlacesList, placesData, setPlacesData,  fetchPlaceDetail }
}

export default useAddBroadCast