import Residential_buyer from "src/assets/images/contact/Residential_buyer.svg";
import Residential_landlord from "src/assets/images/contact/Residential_landlord.svg";
import Residential_seller from "src/assets/images/contact/Residential_seller.svg";
import Residential_tenant from "src/assets/images/contact/Residential_tenant.svg";
import Commercial_buyer from "src/assets/images/contact/commercial_buyer.svg";
import Commercial_landlord from "src/assets/images/contact/commercial_landloard.svg";
import Commercial_seller from "src/assets/images/contact/commercial_seller.svg";
import Commercial_tenant from "src/assets/images/contact/commercial_tenant.svg";

export const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", disabled: true },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];

export const flavourOptions = [
  { value: "vanilla", label: "Vanilla", rating: "safe" },
  { value: "chocolate", label: "Chocolate", rating: "good" },
  { value: "strawberry", label: "Strawberry", rating: "wild" },
  { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" },
];

export const stateOptions = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FM", label: "Federated States Of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VI", label: "Virgin Islands" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const optionLength = [
  { value: 1, label: "general" },
  {
    value: 2,
    label:
      "Evil is the moment when I lack the strength to be true to the Good that compels me.",
  },
  {
    value: 3,
    label:
      "It is now an easy matter to spell out the ethic of a truth: 'Do all that you can to persevere in that which exceeds your perseverance. Persevere in the interruption. Seize in your being that which has seized and broken you.",
  },
];

// let bigOptions = [];
// for (let i = 0; i < 10000; i++) {
// 	bigOptions = bigOptions.concat(colourOptions);
// }

export const groupedOptions = [
  {
    label: "Colours",
    options: colourOptions,
  },
  {
    label: "Flavours",
    options: flavourOptions,
  },
];

export const leadStatusOption = [
  { value: "new", label: "New" },
  { value: "waiting-on-contact", label: "Waiting On Contact" },
  { value: "waiting-on-us", label: "Waiting On Us" },
  { value: "closed", label: "Closed" },
  // { value: "merged", label: "Merged" },
];

export const sourceList = [
  { value: "Website", label: "Website" },
  { value: "Whatsapp", label: "Whatsapp" },
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "Telegram", label: "Telegram" },
  // { value: "Walk-in", label: "Walk-in" },
  // { value: "Google-ads", label: "Google-ads" },
  // { value: "Marketing-site", label: "Marketing-site" },
];

export const leadOwnerList = [
  { value: "Gaurav Lalwani", label: "Gaurav Lalwani" },
  { value: "Sachin Kumar", label: "Sachin Kumar" },
];

export const priorityList = [
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
];

export const reviewOptions2 = [
  { value: "Sales", label: "Sales" },
  { value: "Lettings", label: "Lettings" },
  { value: "Customer Support", label: "Customer Support" },
];

export const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "In-Active" },
];

export const contractStatusOptions = [
  { value: 0, label: "Pending" },
  { value: 1, label: "Signed" },
  { value: 2, label: "Completed" },
];

export const agencySource = [
  { value: 'Web', label: "Web" },
  { value: 'Contract', label: "Contract" },
];

export const departmentType = [
  { value: "residential", label: "Residential" },
  { value: "commercial", label: "Commercial" },
  { value: "both", label: "Both" },
];

export const propertyType = [
  { value: "residential", label: "Residential" },
  { value: "commercial", label: "Commercial" },
];

export const departmentRole = [
  { value: "sales", label: "Sales" },
  { value: "lettings", label: "Lettings" },
  { value: "both", label: "Both" },
];

export const titles = [
  { value: "Mr.", label: "Mr." },
  { value: "Miss.", label: "Miss." },
  { value: "Dr.", label: "Dr." },
];

export const agentTypes = [
  { value: "companymanager", label: "Manager" },
  { value: "agent", label: "Agent" },
];

export const RolesForSuperAdmin = [
  { value: "all", label: "All" },
  { value: "companyadmin", label: "Company Admin" },
  { value: "companymanager", label: "Company Manager" },
  { value: "agent", label: "Agent" },
];

export const RolesForCompanyAdmin = [
  { value: "all", label: "All" },
  { value: "companymanager", label: "Company Manager" },
  { value: "agent", label: "Agent" },
];

export const TypesForSuperAdmin = [
  { value: "companyadmin", label: "Company Admin" },
  { value: "companymanager", label: "Company Manager" },
  { value: "agent", label: "Agent" },
];

export const TypesForCompanyAdmin = [
  { value: "companymanager", label: "Company Manager" },
  { value: "agent", label: "Agent" },
];

export const RowsPerPageList = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 75, label: "75" },
  { value: 100, label: "100" },
];

export const contactCategory = [
  { label: "Residential Sales", value: "residential-sales" },
  { label: "Residential Lettings", value: "residential-lettings" },
  { label: "Residential Support", value: "residential-support" },
  { label: "Commercial Sales", value: "commercial-sales" },
  { label: "Commercial Lettings", value: "commercial-lettings" },
  { label: "Commercial Support", value: "commercial-support" },
];

export const agentType = [
  { label: "Buyer", value: "buyer", type: "sales" },
  { label: "Seller", value: "seller", type: "sales" },
  { label: "Landlord", value: "landlord", type: "lettings" },
  { label: "Tenant", value: "tenant", type: "lettings" },
];

export const LeadStatus = [
  { label: "New", value: "New" },
  { label: "Open", value: "Open" },
  { label: "In progress", value: "In progress" },
  { label: "Open Deal", value: "Open Deal" },
  { label: "Unqualified", value: "Unqualified" },
  { label: "Attempt to contact", value: "Attempt to contact" },
  { label: "Connected", value: "Connected" },
  { label: "Bad timings", value: "Bad timings" },
];

export const AssociateData = [
  {
    label: "Contact",
    value: "Contact",
  },
  {
    label: "Leads",
    value: "Leads",
  },
];

export const TodoType = [
  {
    label: "Call",
    value: "Call",
  },
  {
    label: "Email",
    value: "Email",
  },
  {
    label: "Meeting",
    value: "Meeting",
  },
  {
    label: "Todo",
    value: "Todo",
  },
];

export const PriorityList = [
  {
    label: "High",
    value: "high",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Low",
    value: "low",
  },
];

export const ReminderList = [
  {
    label: "No reminder",
    value: "No Reminder",
  },
  {
    label: "At task due time",
    value: "At task due time",
  },
  {
    label: "30 min before",
    value: "30 Min before",
  },
  {
    label: "1 day before",
    value: "1 day before",
  },
];

export const noOfBrach = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
];

export const trialPeriod = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
]


export const TodoTitle = [
  {
    label: "Appointment",
    value: "appointment",
  },
  {
    label: "Task",
    value: "task",
  },
];

export const filterTypes = [
  { value: "today", label: "Today" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

export const ContactData = [
  { value: "today", label: "Today" },
  { value: "week", label: "Week" },
];

export const todoStatus = [
  { value: "pending", label: "Pending" },
  { value: "important", label: "Important" },
  { value: "completed", label: "Completed" },
];

export const leadMotive = [
  { value: "Buying", label: "Buy" },
  { value: "Selling", label: "Sell" },
  { value: "Lettings", label: "Lettings" },
  { value: "Others", label: "Others" },
];

export const customerCategory = [
  { value: "buyer", label: "Buyer" },
  { value: "seller", label: "Seller" },
  { value: "landlord", label: "Landlord" },
  { value: "tenant", label: "Tenant" },
];

export const outcomesDataForMeetingLog = [
  { value: "Scheduled", label: "Scheduled" },
  { value: "Completed", label: "Completed" },
  { value: "Rescheduled", label: "Rescheduled" },
  { value: "No Show", label: "No Show" },
  { value: "Canceled", label: "Canceled" },
];

export const outcomesDataForCallLog = [
  { value: "Busy", label: "Busy" },
  { value: "Connected", label: "Connected" },
  { value: "Left Live Message", label: "Left Live Message" },
  { value: "Left Voicemail", label: "Left Voicemail" },
  { value: "No Answer", label: "No Answer" },
  { value: "Wrong Number", label: "Wrong Number" },
];

export const leadFilterOption = [
  { value: "Chat", label: "Chats" },
  { value: "Note", label: "Notes" },
  { value: "Task", label: "Tasks" },
  { value: "Appointment", label: "Appointments" },
  { value: "Log", label: "Logs" },
  { value: "Activity", label: "Lead Activities" },
];

export const WaitingTime = [
  { label: "1 minute", value: 1, visible: false },
  { label: "2 minutes", value: 2, visible: true },
  { label: "3 minutes", value: 3, visible: true },
  { label: "4 minutes", value: 4, visible: true },
  { label: "5 minutes", value: 5, visible: true },
];

export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const categories = [
  {
    label: "Residential Seller",
    value: "residential-seller",
    color: "#2962FF",
    icon: Residential_seller
  },
  {
    label: "Residential Buyer",
    value: "residential-buyer",
    color: "#3699FF",
    icon: Residential_buyer
  },
  {
    label: "Residential Tenant",
    value: "residential-tenant",
    color: "#F64E60",
    icon: Residential_tenant
  },
  {
    label: "Residential Landlord",
    value: "residential-landlord",
    color: "#0BB7AF",
    icon: Residential_landlord
  },
  {
    label: "Commercial Seller",
    value: "commercial-seller",
    color: "#EE9D01",
    icon: Commercial_seller
  },
  {
    label: "Commercial Buyer",
    value: "commercial-buyer",
    color: "#001E3C",
    icon: Commercial_buyer
  },
  {
    label: "Commercial Tenant",
    value: "commercial-tenant",
    color: "#57BC46",
    icon: Commercial_tenant
  },
  {
    label: "Commercial Landlord",
    value: "commercial-landlord",
    color: "#9D51FE",
    icon: Commercial_landlord
  },
];

export const categoriesAll = [
  // {
  //   label: "All",
  //   value: "all",
  //   color: "#2962FF",
  //   icon: Residential_seller
  // },
  {
    label: "Residential Seller",
    value: "residential-seller",
    color: "#2962FF",
    icon: Residential_seller
  },
  {
    label: "Residential Buyer",
    value: "residential-buyer",
    color: "#3699FF",
    icon: Residential_buyer
  },
  {
    label: "Residential Tenant",
    value: "residential-tenant",
    color: "#F64E60",
    icon: Residential_tenant
  },
  {
    label: "Residential Landlord",
    value: "residential-landlord",
    color: "#0BB7AF",
    icon: Residential_landlord
  },
  {
    label: "Commercial Seller",
    value: "commercial-seller",
    color: "#EE9D01",
    icon: Commercial_seller
  },
  {
    label: "Commercial Buyer",
    value: "commercial-buyer",
    color: "#001E3C",
    icon: Commercial_buyer
  },
  {
    label: "Commercial Tenant",
    value: "commercial-tenant",
    color: "#57BC46",
    icon: Commercial_tenant
  },
  {
    label: "Commercial Landlord",
    value: "commercial-landlord",
    color: "#9D51FE",
    icon: Commercial_landlord
  },
];

export const calendarTypes = [
  { label: "Team Calendar", value: "team" },
  { label: "My Calendar", value: "my" },
];
export const locationType = [
  { label: "Post Code", value: "zip" },
  { label: "Location Name", value: "loc" },
];

export const leadTypes = [
  { label: "Team Leads", value: "team" },
  { label: "My Leads", value: "my" },
];


export const WorkingDayOptions = [
  { label: "Everyday", value: "everyday" },
  { label: "Mon - Fri", value: "mon-fri" },
  { label: "Sat - Sun", value: "sat-sun" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Sunday", value: "sunday" },
];


export const countryCodes = [
  { name: 'United Kingdom', code: '+44', shortCode: 'GB' },
  { name: 'Afghanistan', code: '+93', shortCode: 'AF' },
  { name: 'Albania', code: '+355', shortCode: 'AL' },
  { name: 'Algeria', code: '+213', shortCode: 'DZ' },
  { name: 'Andorra', code: '+376', shortCode: 'AD' },
  { name: 'Angola', code: '+244', shortCode: 'AO' },
  { name: 'Antigua and Barbuda', code: '+1268', shortCode: 'AG' },
  { name: 'Argentina', code: '+54', shortCode: 'AR' },
  { name: 'Armenia', code: '+374', shortCode: 'AM' },
  { name: 'Australia', code: '+61', shortCode: 'AU' },
  { name: 'Austria', code: '+43', shortCode: 'AT' },
  { name: 'Azerbaijan', code: '+994', shortCode: 'AZ' },
  { name: 'Bahamas', code: '+1-242', shortCode: 'BS' },
  { name: 'Bahrain', code: '+973', shortCode: 'BH' },
  { name: 'Bangladesh', code: '+880', shortCode: 'BD' },
  { name: 'Barbados', code: '+1-246', shortCode: 'BB' },
  { name: 'Belarus', code: '+375', shortCode: 'BY' },
  { name: 'Belgium', code: '+32', shortCode: 'BE' },
  { name: 'Belize', code: '+501', shortCode: 'BZ' },
  { name: 'Benin', code: '+229', shortCode: 'BJ' },
  { name: 'Bhutan', code: '+975', shortCode: 'BT' },
  { name: 'Bolivia', code: '+591', shortCode: 'BO' },
  { name: 'Bosnia and Herzegovina', code: '+387', shortCode: 'BA' },
  { name: 'Botswana', code: '+267', shortCode: 'BW' },
  { name: 'Brazil', code: '+55', shortCode: 'BR' },
  { name: 'Brunei', code: '+673', shortCode: 'BN' },
  { name: 'Bulgaria', code: '+359', shortCode: 'BG' },
  { name: 'Burkina Faso', code: '+226', shortCode: 'BF' },
  { name: 'Burundi', code: '+257', shortCode: 'BI' },
  { name: 'Cabo Verde', code: '+238', shortCode: 'CV' },
  { name: 'Cambodia', code: '+855', shortCode: 'KH' },
  { name: 'Cameroon', code: '+237', shortCode: 'CM' },
  { name: 'Canada', code: '+1', shortCode: 'CA' },
  { name: 'Central African Republic', code: '+236', shortCode: 'CF' },
  { name: 'Chad', code: '+235', shortCode: 'TD' },
  { name: 'Chile', code: '+56', shortCode: 'CL' },
  { name: 'China', code: '+86', shortCode: 'CN' },
  { name: 'Colombia', code: '+57', shortCode: 'CO' },
  { name: 'Comoros', code: '+269', shortCode: 'KM' },
  { name: 'Congo, Democratic Republic of the', code: '+243', shortCode: 'CD' },
  { name: 'Congo, Republic of the', code: '+242', shortCode: 'CG' },
  { name: 'Costa Rica', code: '+506', shortCode: 'CR' },
  { name: 'Croatia', code: '+385', shortCode: 'HR' },
  { name: 'Cuba', code: '+53', shortCode: 'CU' },
  { name: 'Cyprus', code: '+357', shortCode: 'CY' },
  { name: 'Czech Republic', code: '+420', shortCode: 'CZ' },
  { name: 'Denmark', code: '+45', shortCode: 'DK' },
  { name: 'Djibouti', code: '+253', shortCode: 'DJ' },
  { name: 'Dominica', code: '+1-767', shortCode: 'DM' },
  { name: 'Dominican Republic', code: '+1-809', shortCode: 'DO' },
  { name: 'Ecuador', code: '+593', shortCode: 'EC' },
  { name: 'Egypt', code: '+20', shortCode: 'EG' },
  { name: 'El Salvador', code: '+503', shortCode: 'SV' },
  { name: 'Equatorial Guinea', code: '+240', shortCode: 'GQ' },
  { name: 'Eritrea', code: '+291', shortCode: 'ER' },
  { name: 'Estonia', code: '+372', shortCode: 'EE' },
  { name: 'Eswatini', code: '+268', shortCode: 'SZ' },
  { name: 'Ethiopia', code: '+251', shortCode: 'ET' },
  { name: 'Fiji', code: '+679', shortCode: 'FJ' },
  { name: 'Finland', code: '+358', shortCode: 'FI' },
  { name: 'France', code: '+33', shortCode: 'FR' },
  { name: 'Gabon', code: '+241', shortCode: 'GA' },
  { name: 'Gambia', code: '+220', shortCode: 'GM' },
  { name: 'Georgia', code: '+995', shortCode: 'GE' },
  { name: 'Germany', code: '+49', shortCode: 'DE' },
  { name: 'Ghana', code: '+233', shortCode: 'GH' },
  { name: 'Greece', code: '+30', shortCode: 'GR' },
  { name: 'Grenada', code: '+1-473', shortCode: 'GD' },
  { name: 'Guatemala', code: '+502', shortCode: 'GT' },
  { name: 'Guinea', code: '+224', shortCode: 'GN' },
  { name: 'Guinea-Bissau', code: '+245', shortCode: 'GW' },
  { name: 'Guyana', code: '+592', shortCode: 'GY' },
  { name: 'Haiti', code: '+509', shortCode: 'HT' },
  { name: 'Honduras', code: '+504', shortCode: 'HN' },
  { name: 'Hungary', code: '+36', shortCode: 'HU' },
  { name: 'Iceland', code: '+354', shortCode: 'IS' },
  { name: 'India', code: '+91', shortCode: 'IN' },
  { name: 'Indonesia', code: '+62', shortCode: 'ID' },
  { name: 'Iran', code: '+98', shortCode: 'IR' },
  { name: 'Iraq', code: '+964', shortCode: 'IQ' },
  { name: 'Ireland', code: '+353', shortCode: 'IE' },
  { name: 'Israel', code: '+972', shortCode: 'IL' },
  { name: 'Italy', code: '+39', shortCode: 'IT' },
  { name: 'Jamaica', code: '+1-876', shortCode: 'JM' },
  { name: 'Japan', code: '+81', shortCode: 'JP' },
  { name: 'Jordan', code: '+962', shortCode: 'JO' },
  { name: 'Kazakhstan', code: '+7', shortCode: 'KZ' },
  { name: 'Kenya', code: '+254', shortCode: 'KE' },
  { name: 'Kiribati', code: '+686', shortCode: 'KI' },
  { name: 'Korea, North', code: '+850', shortCode: 'KP' },
  { name: 'Korea, South', code: '+82', shortCode: 'KR' },
  { name: 'Kosovo', code: '+383', shortCode: 'XK' },
  { name: 'Kuwait', code: '+965', shortCode: 'KW' },
  { name: 'Kyrgyzstan', code: '+996', shortCode: 'KG' },
  { name: 'Laos', code: '+856', shortCode: 'LA' },
  { name: 'Latvia', code: '+371', shortCode: 'LV' },
  { name: 'Lebanon', code: '+961', shortCode: 'LB' },
  { name: 'Lesotho', code: '+266', shortCode: 'LS' },
  { name: 'Liberia', code: '+231', shortCode: 'LR' },
  { name: 'Libya', code: '+218', shortCode: 'LY' },
  { name: 'Liechtenstein', code: '+423', shortCode: 'LI' },
  { name: 'Lithuania', code: '+370', shortCode: 'LT' },
  { name: 'Luxembourg', code: '+352', shortCode: 'LU' },
  { name: 'Madagascar', code: '+261', shortCode: 'MG' },
  { name: 'Malawi', code: '+265', shortCode: 'MW' },
  { name: 'Malaysia', code: '+60', shortCode: 'MY' },
  { name: 'Maldives', code: '+960', shortCode: 'MV' },
  { name: 'Mali', code: '+223', shortCode: 'ML' },
  { name: 'Malta', code: '+356', shortCode: 'MT' },
  { name: 'Marshall Islands', code: '+692', shortCode: 'MH' },
  { name: 'Mauritania', code: '+222', shortCode: 'MR' },
  { name: 'Mauritius', code: '+230', shortCode: 'MU' },
  { name: 'Mexico', code: '+52', shortCode: 'MX' },
  { name: 'Micronesia', code: '+691', shortCode: 'FM' },
  { name: 'Moldova', code: '+373', shortCode: 'MD' },
  { name: 'Monaco', code: '+377', shortCode: 'MC' },
  { name: 'Mongolia', code: '+976', shortCode: 'MN' },
  { name: 'Montenegro', code: '+382', shortCode: 'ME' },
  { name: 'Morocco', code: '+212', shortCode: 'MA' },
  { name: 'Mozambique', code: '+258', shortCode: 'MZ' },
  { name: 'Myanmar', code: '+95', shortCode: 'MM' },
  { name: 'Namibia', code: '+264', shortCode: 'NA' },
  { name: 'Nauru', code: '+674', shortCode: 'NR' },
  { name: 'Nepal', code: '+977', shortCode: 'NP' },
  { name: 'Netherlands', code: '+31', shortCode: 'NL' },
  { name: 'New Zealand', code: '+64', shortCode: 'NZ' },
  { name: 'Nicaragua', code: '+505', shortCode: 'NI' },
  { name: 'Niger', code: '+227', shortCode: 'NE' },
  { name: 'Nigeria', code: '+234', shortCode: 'NG' },
  { name: 'North Macedonia', code: '+389', shortCode: 'MK' },
  { name: 'Norway', code: '+47', shortCode: 'NO' },
  { name: 'Oman', code: '+968', shortCode: 'OM' },
  { name: 'Pakistan', code: '+92', shortCode: 'PK' },
  { name: 'Palau', code: '+680', shortCode: 'PW' },
  { name: 'Palestine', code: '+970', shortCode: 'PS' },
  { name: 'Panama', code: '+507', shortCode: 'PA' },
  { name: 'Papua New Guinea', code: '+675', shortCode: 'PG' },
  { name: 'Paraguay', code: '+595', shortCode: 'PY' },
  { name: 'Peru', code: '+51', shortCode: 'PE' },
  { name: 'Philippines', code: '+63', shortCode: 'PH' },
  { name: 'Poland', code: '+48', shortCode: 'PL' },
  { name: 'Portugal', code: '+351', shortCode: 'PT' },
  { name: 'Qatar', code: '+974', shortCode: 'QA' },
  { name: 'Romania', code: '+40', shortCode: 'RO' },
  { name: 'Russia', code: '+7', shortCode: 'RU' },
  { name: 'Rwanda', code: '+250', shortCode: 'RW' },
  { name: 'Saint Kitts and Nevis', code: '+1-869', shortCode: 'KN' },
  { name: 'Saint Lucia', code: '+1-758', shortCode: 'LC' },
  { name: 'Saint Vincent and the Grenadines', code: '+1-784', shortCode: 'VC' },
  { name: 'Samoa', code: '+685', shortCode: 'WS' },
  { name: 'San Marino', code: '+378', shortCode: 'SM' },
  { name: 'Sao Tome and Principe', code: '+239', shortCode: 'ST' },
  { name: 'Saudi Arabia', code: '+966', shortCode: 'SA' },
  { name: 'Senegal', code: '+221', shortCode: 'SN' },
  { name: 'Serbia', code: '+381', shortCode: 'RS' },
  { name: 'Seychelles', code: '+248', shortCode: 'SC' },
  { name: 'Sierra Leone', code: '+232', shortCode: 'SL' },
  { name: 'Singapore', code: '+65', shortCode: 'SG' },
  { name: 'Slovakia', code: '+421', shortCode: 'SK' },
  { name: 'Slovenia', code: '+386', shortCode: 'SI' },
  { name: 'Solomon Islands', code: '+677', shortCode: 'SB' },
  { name: 'Somalia', code: '+252', shortCode: 'SO' },
  { name: 'South Africa', code: '+27', shortCode: 'ZA' },
  { name: 'South Sudan', code: '+211', shortCode: 'SS' },
  { name: 'Spain', code: '+34', shortCode: 'ES' },
  { name: 'Sri Lanka', code: '+94', shortCode: 'LK' },
  { name: 'Sudan', code: '+249', shortCode: 'SD' },
  { name: 'Suriname', code: '+597', shortCode: 'SR' },
  { name: 'Sweden', code: '+46', shortCode: 'SE' },
  { name: 'Switzerland', code: '+41', shortCode: 'CH' },
  { name: 'Syria', code: '+963', shortCode: 'SY' },
  { name: 'Taiwan', code: '+886', shortCode: 'TW' },
  { name: 'Tajikistan', code: '+992', shortCode: 'TJ' },
  { name: 'Tanzania', code: '+255', shortCode: 'TZ' },
  { name: 'Thailand', code: '+66', shortCode: 'TH' },
  { name: 'Timor-Leste', code: '+670', shortCode: 'TL' },
  { name: 'Togo', code: '+228', shortCode: 'TG' },
  { name: 'Tonga', code: '+676', shortCode: 'TO' },
  { name: 'Trinidad and Tobago', code: '+1-868', shortCode: 'TT' },
  { name: 'Tunisia', code: '+216', shortCode: 'TN' },
  { name: 'Turkey', code: '+90', shortCode: 'TR' },
  { name: 'Turkmenistan', code: '+993', shortCode: 'TM' },
  { name: 'Tuvalu', code: '+688', shortCode: 'TV' },
  { name: 'Uganda', code: '+256', shortCode: 'UG' },
  { name: 'Ukraine', code: '+380', shortCode: 'UA' },
  { name: 'United Arab Emirates', code: '+971', shortCode: 'AE' },
  { name: 'United States', code: '+1', shortCode: 'US' },
  { name: 'Uruguay', code: '+598', shortCode: 'UY' },
  { name: 'Uzbekistan', code: '+998', shortCode: 'UZ' },
  { name: 'Vanuatu', code: '+678', shortCode: 'VU' },
  { name: 'Vatican City', code: '+379', shortCode: 'VA' },
  { name: 'Venezuela', code: '+58', shortCode: 'VE' },
  { name: 'Vietnam', code: '+84', shortCode: 'VN' },
  { name: 'Yemen', code: '+967', shortCode: 'YE' },
  { name: 'Zambia', code: '+260', shortCode: 'ZM' },
  { name: 'Zimbabwe', code: '+263', shortCode: 'ZW' }
];


export const templatStatusOption = [
  {
    label: "All",
    value: "all"
  },
  {
    label: "Approved",
    value: "APPROVED"
  },
  {
    label: "Rejected",
    value: "REJECTED"
  },
  {
    label: "Pending",
    value: "PENDING"
  },
]

export const templateType = [
  {
    label: "Marketing",
    value: "MARKETING"
  },
]

export const templateHeaderType = [
  { label: "Text", value: "TEXT" },
  { label: "Image", value: "IMAGE" },
  { label: "Video", value: "VIDEO" },
  { label: "Document", value: "DOCUMENT" }
]

export const sortByOptions = [
  {
    label: "Latest",
    value: "latest"
  },
  {
    label: "Oldest",
    value: "oldest"
  },
]

export const dateRangeOptions = [
  {
    label: "Last 7 days",
    value: "7"
  },
  {
    label: "Last 30 days",
    value: "30"
  },
  {
    label: "Custom range",
    value: "custom",
  }
]

export const TemplateLabelOptions = [
  { label: "Contact Name", value: "contact name" },
  { label: "Property Type", value: "property type" },
  { label: "Location", value: "location" },
  { label: "Price", value: "price" },
  { label: "Key Feature", value: "key feature" },
  // { label: "Property Image", value: "property image"},
  { label: "Agency Name", value: "agency name" },
  { label: "Address", value: "address" },
  { label: "Contact Number", value: "contact number" },
  { label: "Details", value: "details" },
  { label: "Property Name", value: "property name" },
  { label: "Bedrooms", value: "bedrooms" },
  { label: "Bathrooms", value: "bathrooms" },
  { label: "Receptions", value: "receptions" }
];

export const BroadcastStatusOptions = [
  { "label": "All", "value": "all" },
  { "label": "Sent", "value": "SENT" },
  { "label": "Delivered", "value": "DELIVERED" },
  { "label": "Read", "value": "READ" },
  { "label": "Replied", "value": "REPLIED" },
  { "label": "Failed", "value": "FAILED" },
]

export const Labels = [
  { label: "contact name", value: "REPLACE_WITH_NAME" },
  { label: "property type", value: "propertyType" },
  { label: "location", value: "address.addressLine1" },
  { label: "price", value: "price" },
  { label: "key feature", value: "keyFeature" },
  // { label: "property image", value: "propertyImage"},
  { label: "agency name", value: "agencyName" },
  { label: "address", value: "address" },
  { label: "contact number", value: "contactNumber" },
  { label: "details", value: "details" },
  { label: "property name", value: "propertyName" },
  { label: "bedrooms", value: "bedrooms" },
  { label: "bathrooms", value: "bathrooms" },
  { label: "receptions", value: "receptions" }
];


export const PriceQualifireData = [
  { label: 'Price', value: 'Price' },
  { label: 'POA', value: 'POA' },
  { label: 'Guide Price', value: 'Guide Price' },
  { label: 'Fixed Price', value: 'Fixed Price' },
  { label: 'Offers Over', value: 'Offers Over' },
  { label: 'OIRO', value: 'OIRO' },
  { label: 'From', value: 'From' },
  { label: 'Sale by Tender', value: 'Sale by Tender' },
]

export const CurrencyData = [{
  label: '£', value: 'Pound',
},
]

export const priceOptions = [
  {
      label: 'pcm', value: 'pcm',
  },
  {
      label: 'pw', value: 'pw',
  },
  {
      label: 'per term', value: 'per term',
  },

];

export const TentureOption = [
  { label: "Leasehold", value: 'Leasehold' },
  { label: "Share Of Freehold", value: 'Share Of Freehold' },
  { label: "Commanhold", value: 'Commanhold' },
  { label: "Freehold", value: 'Freehold' },
]

export const optionProperty = [
  { label: 'Auction Property', value: 'Auction Property' },
  { label: 'Below Market Value', value: 'Below Market Value' },
  { label: 'Buy to Let', value: 'Buy to Let' },
  { label: 'New Build', value: 'New Build' },
  { label: 'Property to Convert', value: 'Property to Convert' },
  { label: 'Property to Refurbish', value: 'Property to Refurbish' },
];

export const CommercialPropertyDetail = [
  { label: '(A1) Shops', value: '(a1)-shops' },
  { label: '(A2) Financial and Professional Services', value: '(a2)-financial-and-professional-services' },
  { label: '(A3) Food and Drink', value: '(a3)-food-and-drink' },
  { label: '(B1) Business', value: '(b1)-business' },
  { label: '(B2) General Industrial', value: '(b2)-general-industrial' },
  { label: '(B8) Storage and Distribution', value: '(b8)-storage-and-distribution' },
  { label: '(C1) Hotels', value: '(c1)-hotels' },
  { label: '(C2) Residential Institutions', value: '(c2)-residential-institutions' },
  { label: '(C3) Dwelling Houses', value: '(c3)-dwelling-houses' },
  { label: '(D1) Non-residential Institutions', value: '(d1)-non-residential-institutions' },
  { label: '(D2) Assembly and Leisure', value: '(d2)-assembly-and-leisure' },
];

export const PaymentFrequencyOptions = [
  { label: 'per year', value: 'per-year' },
  { label: 'per month', value: 'per-month' },
  { label: 'per week', value: 'per-week' },
  { label: 'per quarter', value: 'per-quarter' },
];

export const EPCRating = [
  { label: 'England & Wales', value: 'England & Wales' },
  { label: 'Scotland', value: 'Scotland' },
];


export const LocalAuthorityBusiness = [
  { label: 'Barking and Dagenham Borough Council', value: 'Barking and Dagenham Borough Council' },
  { label: 'Barnet Borough Council', value: 'Barnet Borough Council' },
  { label: 'Bexley Borough Council', value: 'Bexley Borough Council' },
  { label: 'Brent Borough Council', value: 'Brent Borough Council' },
  { label: 'Bromley Borough Council', value: 'Bromley Borough Council' },
  { label: 'Camden Borough Council', value: 'Camden Borough Council' },
  { label: 'Croydon Borough Council', value: 'Croydon Borough Council' },
  { label: 'Ealing Borough Council', value: 'Ealing Borough Council' },
  { label: 'Enfield Borough Council', value: 'Enfield Borough Council' },
  { label: 'Greenwich Borough Council', value: 'Greenwich Borough Council' },
  { label: 'Hackney Borough Council', value: 'Hackney Borough Council' },
  { label: 'Hammersmith and Fulham Borough Council', value: 'Hammersmith and Fulham Borough Council' },
  { label: 'Haringey Borough Council', value: 'Haringey Borough Council' },
  { label: 'Harrow Borough Council', value: 'Harrow Borough Council' },
  { label: 'Havering Borough Council', value: 'Havering Borough Council' },
  { label: 'Hillingdon Borough Council', value: 'Hillingdon Borough Council' },
  { label: 'Hounslow Borough Council', value: 'Hounslow Borough Council' },
  { label: 'Islington Borough Council', value: 'Islington Borough Council' },
  { label: 'Kensington and Chelsea Borough Council', value: 'Kensington and Chelsea Borough Council' },
  { label: 'Kingston upon Thames Borough Council', value: 'Kingston upon Thames Borough Council' },
  { label: 'Lambeth Borough Council', value: 'Lambeth Borough Council' },
  { label: 'Lewisham Borough Council', value: 'Lewisham Borough Council' },
  { label: 'Merton Borough Council', value: 'Merton Borough Council' },
  { label: 'Newham Borough Council', value: 'Newham Borough Council' },
  { label: 'Redbridge Borough Council', value: 'Redbridge Borough Council' },
  { label: 'Richmond upon Thames Borough Council', value: 'Richmond upon Thames Borough Council' },
  { label: 'Southwark Borough Council', value: 'Southwark Borough Council' },
  { label: 'Sutton Borough Council', value: 'Sutton Borough Council' },
  { label: 'Tower Hamlets Borough Council', value: 'Tower Hamlets Borough Council' },
  { label: 'Waltham Forest Borough Council', value: 'Waltham Forest Borough Council' },
  { label: 'Wandsworth Borough Council', value: 'Wandsworth Borough Council' },
  { label: 'Westminster City Council', value: 'Westminster City Council' }
];

export const optioncurrentOccupant = [
  { label: 'Vacant', value: 'Vacant' },
  { label: 'Vendor', value: 'Vendor' },
  { label: 'External Tenant', value: 'External Tenant'},
]

export const optioncurrentLet = [
  { label: 'Vacant', value: 'Vacant' },
  { label: 'Landlord', value: 'Landlord' },
  { label: 'External Tenant', value: 'External Tenant' },
]

export   const PropertyServiceOption = [{
  label: 'Percent', value: 'Percent',
},
{
  label: 'Flat fee', value: 'Flat fee',
},
]
export const LettingService = [
  {
      label: 'Introduction only', value: 'Introduction only',
  },
  {
      label: 'Introduction with rent collection', value: 'Introduction with rent collection',
  },
  {
      label: 'Managed no rent collection', value: 'Managed no rent collection',
  },
  {
      label: 'Fully managed', value: 'Fully managed',
  },
]
export  const RentFrequencyOptions = [
  { label: 'psa', value: 'psa' },
  { label: 'pq', value: 'pq' },
  { label: 'pcm', value: 'pcm' },
  { label: 'pw', value: 'pw' },
  { label: 'p2w', value: 'p2w' },
  { label: 'p4w', value: 'p4w' },
  { label: 'pd', value: 'pd' },
  { label: 'per term', value: 'per term' },
  { label: 'p2cm', value: 'p2cm' },
];

export const GasCertificateOptions = [
  { label: 'Agent', value: 'Agent' },
  { label: 'Landlord', value: 'Landlord' },
  { label: 'Tenant', value: 'Tenant' },
];

export const PropertyCertificateOptions = [
  { label: 'Agent', value: 'Agent' },
  { label: 'Landlord', value: 'Landlord' },
];
export const ManationRespoOptions = [
  { label: 'Agent', value: 'Agent' },
  { label: 'Landlord', value: 'Landlord' },
  { label: 'Other', value: 'Other' },

];
export const optionLisenseType = [
  { label: 'Selective Licence', value: 'Selective Licence' },
  { label: 'HMO Additional', value: 'HMO Additional' },
  { label: 'HMO Mandatory', value: 'HMO Mandatory' },
  { label: 'No Licence Required', value: 'No Licence Required' },
];

