import React from "react";
import { getIn } from "formik";
import ErrorMsg from "src/components/custom/ErrorMsg";
import TextInput from "src/components/forms/TextInput";
import SelectInput from "src/components/forms/SelectInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import { agentTypes, countryCodes, departmentRole, departmentType, } from "src/constants/formPicker";
import styles from "./AccountSetup.module.css";
import styles2 from "./AccountSetup2.module.css";
import PhoneNumberWithCountryCode from "src/components/forms/PhoneNumberWithCountryCode";
import CountryCode from "src/components/forms/CountriesCode";
import { RiDeleteBin6Line } from "react-icons/ri";

const AgentListNew = ({
  pushAgent,
  removeAgent,
  branch,
  branchIndex,
  handleChange,
  errors,
  touched,
  values,
  branchCount
}) => {

  return (
    <>
      {branch?.agents?.map((agent, agentIndex) => (
        <div>
          <div key={agentIndex} className={styles.card_body + `   `}>
            {agentIndex === 0 && (
              <div className={styles.card_header + " d-grid d-lg-flex align-items-center gap-3 justify-content-center justify-content-lg-between py-2"} >
                <div className="d-flex gap-2 align-items-center justify-content-center justify-content-lg-start text-capitalize mb-3" style={{ fontWeight: "700" }}>
                  <CheckCircleIcon
                    sx={{
                      fontSize: "27px",
                      marginRight: "8px",
                      color: agent?.designation !== "" && agent?.name !== "" && agent?.email !== "" ? "#00A7BB" : "#E3E3E5",
                    }}
                  />{" "}
                  {branch.branchName}
                </div>
              </div>
            )}
            <div className={styles.agent_details + "  "}>
              <div className={styles.agent_details_form + " w-100"}>
                {(agentIndex > 0 || branchCount === 1) && <div className="w-100 " style={{ textAlign: "right" }}>
                  <RiDeleteBin6Line style={{ color: "red", fontSize: "16px", cursor: "pointer" }} onClick={() => { removeAgent(agentIndex) }} />
                </div>}

                <div className="row gy-2 gx-2 ">
                  <div className="col-md-4 mb-2">
                    <div className={styles.input_design + " "}>
                      <div className="d-grid gap-2">
                        <label>
                          Full Name <span className="red_required_span">*</span>
                        </label>
                        <TextInput
                          customClass={styles2.input_custom + " w-100"}
                          type="text"
                          name={`branches.${branchIndex}.agents.${agentIndex}.name`}
                          value={agent?.name}
                          onChange={handleChange}
                          variant="outlined"
                          placeholder="Agent Name"
                          size={"small"}
                        />
                        <ErrorMsg
                          hasError={
                            getIn(touched, `branches.${branchIndex}.agents.${agentIndex}.name`) &&
                            getIn(errors, `branches.${branchIndex}.agents.${agentIndex}.name`)
                          }
                        >
                          {getIn(errors, `branches.${branchIndex}.agents.${agentIndex}.name`)}
                        </ErrorMsg>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className={styles.input_design + " "}>
                      <div className="d-grid gap-2">
                        <label>
                          Email Address
                          <span className="red_required_span">*</span>
                        </label>
                        <TextInput
                          customClass={styles2.input_custom + " w-100"}
                          type="text"
                          name={`branches.${branchIndex}.agents.${agentIndex}.email`}
                          value={agent?.email}
                          onChange={handleChange}
                          variant="outlined"
                          placeholder="Agent Email"
                          size={"small"}
                        />
                        <ErrorMsg
                          hasError={
                            getIn(touched, `branches.${branchIndex}.agents.${agentIndex}.email`) &&
                            getIn(errors, `branches.${branchIndex}.agents.${agentIndex}.email`)
                          }
                        >
                          {getIn(errors, `branches.${branchIndex}.agents.${agentIndex}.email`)}
                        </ErrorMsg>
                      </div>
                    </div>
                  </div>
                  <div className={" col-md-4 mb-2 "} >
                    <div className={styles.inputesign + ""}>
                      <div className="d-grid gap-2">
                        <label className={styles2.custom_label}>Phone Number <span className="red_required_span"> * </span>{" "}</label>

                        <div className={styles2.phone_field + " d-flex align-items-center "} >
                          <div style={{ width: "75%" }}>
                            <CountryCode
                              customClass={styles2.custom_textfield + " w-100"}
                              name={`branches.${branchIndex}.agents.${agentIndex}.countryCode`}
                              placeholder={"Country Code"}
                              options={countryCodes}
                              selectedValue={agent.countryCode}
                              noOptionText={"No Country Code found."}
                              onChange={(e) => { handleChange(e); }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <PhoneNumberWithCountryCode
                              customClass={styles2.custom_textfield + " w-100"}
                              countryCode={agent.countryCode}
                              name={`branches.${branchIndex}.agents.${agentIndex}.phone`}
                              value={agent.phone}
                              variant="outlined"
                              placeholder="Phone"
                              size={"small"}
                              onChange={(e) => { handleChange(e); }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMsg
                      hasError={
                        getIn(
                          touched,
                          `branches.${branchIndex}.agents.${agentIndex}.phone`
                        ) &&
                        getIn(
                          errors,
                          `branches.${branchIndex}.agents.${agentIndex}.phone`
                        )
                      }
                    >
                      {getIn(
                        errors,
                        `branches.${branchIndex}.agents.${agentIndex}.phone`
                      )}
                    </ErrorMsg>
                  </div>

                  <div className="col-xl-4">
                    <div className={styles.input_design + " "}>
                      <div className="d-grid gap-2">
                        <label>
                          Designation <span className="red_required_span">*</span>
                        </label>
                        <SelectInput
                          name={`branches.${branchIndex}.agents.${agentIndex}.designation`}
                          placeholder="Designation"
                          size={"small"}
                          options={agentTypes}
                          selectedValue={agent?.designation}
                          onChange={(value) => {
                            handleChange(
                              `branches.${branchIndex}.agents.${agentIndex}.designation`
                            )(value);
                          }}
                          customClass={styles2.custom_select}
                        />
                        <ErrorMsg
                          hasError={
                            getIn(
                              touched,
                              `branches.${branchIndex}.agents.${agentIndex}.designation`
                            ) &&
                            getIn(
                              errors,
                              `branches.${branchIndex}.agents.${agentIndex}.designation`
                            )
                          }
                        >
                          {getIn(
                            errors,
                            `branches.${branchIndex}.agents.${agentIndex}.designation`
                          )}
                        </ErrorMsg>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className={styles.input_design + " "}>
                      <div className="d-grid gap-2">
                        <label>
                          Department <span className="red_required_span">*</span>
                        </label>
                        <SelectInput
                          name={`branches.${branchIndex}.agents.${agentIndex}.departmentType`}
                          placeholder="Department"
                          size={"small"}
                          options={departmentType}
                          selectedValue={(branch?.dealsWith && branch?.dealsWith?.length === 1) ? branch?.dealsWith[0] : agent?.departmentType} //   branch-changed
                          onChange={(value) => {
                            handleChange(
                              `branches.${branchIndex}.agents.${agentIndex}.departmentType`
                            )(value);
                          }}
                          disabled={
                            branch?.dealsWith && branch?.dealsWith?.length === 1
                          }
                          customClass={styles2.custom_select}
                        />
                        <ErrorMsg
                          hasError={
                            getIn(
                              touched,
                              `branches.${branchIndex}.agents.${agentIndex}.departmentType`
                            ) &&
                            getIn(
                              errors,
                              `branches.${branchIndex}.agents.${agentIndex}.departmentType`
                            )
                          }
                        >
                          {getIn(
                            errors,
                            `branches.${branchIndex}.agents.${agentIndex}.departmentType`
                          )}
                        </ErrorMsg>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className={styles.input_design + " "}>
                      <div className="d-grid gap-2">
                        <label>
                          Department Role{" "}
                          <span className="red_required_span">*</span>
                        </label>
                        <SelectInput
                          name={`branches.${branchIndex}.agents.${agentIndex}.departmentRole`}
                          placeholder={"Department Role"}
                          size={"small"}
                          options={departmentRole}
                          selectedValue={(branch?.typeOfAgency && branch?.typeOfAgency?.length === 1) ? branch?.typeOfAgency[0] : agent?.departmentRole} //   branch-changed
                          onChange={(value) => {
                            handleChange(
                              `branches.${branchIndex}.agents.${agentIndex}.departmentRole`
                            )(value);
                          }}
                          disabled={
                            branch?.typeOfAgency &&
                            branch?.typeOfAgency?.length === 1
                          }
                          customClass={styles2.custom_select}
                        />
                        <ErrorMsg
                          hasError={
                            getIn(
                              touched,
                              `branches.${branchIndex}.agents.${agentIndex}.departmentRole`
                            ) &&
                            getIn(
                              errors,
                              `branches.${branchIndex}.agents.${agentIndex}.departmentRole`
                            )
                          }
                        >
                          {getIn(
                            errors,
                            `branches.${branchIndex}.agents.${agentIndex}.departmentRole`
                          )}
                        </ErrorMsg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {agentIndex !== (branch?.agents?.length - 1) && <hr style={{ margin: "2rem 0rem 2rem 0rem" }} />}
        </div>
      ))}



      <div className="d-flex gap-2 align-items-center justify-content-start ">
        <button
          type="button"
          className={styles.add_btn}
          // className={ styles.v_btn_primary  + " d-flex gap-1 align-items-center"}
          onClick={() =>
            pushAgent({
              branchId: branch?.branchId,
              name: "",
              email: "",
              phone: "",
              countryCode: "+44",
              designation: "",
              departmentType: "residential", // branch.dealsWith === "both" ? "" : branch.dealsWith,
              departmentRole: "both", // branch.typeOfAgency === "both" ? "" : branch.typeOfAgency,
            })
          }
        >
          <AddIcon
            sx={{ color: "#2E66F5", fontSize: "18px", cursor: "pointer" }}
          />
          <p>Add Agent/Manager</p>
        </button>
      </div>
    </>
  );
};

export default AgentListNew;
