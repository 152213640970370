import React, { useState } from "react";
import BranchDetailCard from "./BranchDetailCard";

const BranchDetails = ({ handleNext, branches, handleAgencyData, agency, settings }) => {
  const branchesArray = Array.from({ length: branches });

  const [currentBranch, setCurrentBranch] = useState(0);
  const handleBranchSubmit = () => { setCurrentBranch((prev) => prev + 1) };

  return (

    <BranchDetailCard
      settings={settings}
      totalCount={branches}
      handleNext={handleNext}
      handleBranchSubmit={handleBranchSubmit}
      // isDisabled={index !== currentBranch}
      currentBranch={currentBranch}
      handleAgencyData={handleAgencyData}
      agency={agency}
      branch={branchesArray}
      handleOpenBranch={(val) => { setCurrentBranch(val); }}
    />

  );
};

export default BranchDetails;
