import React, { useState } from "react";
import moment from "moment";
import { RiDeleteBin6Line } from "react-icons/ri";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import Style from "./broadcast.module.css";
import ProgressCircle from "./ProgressCircle";
import { useNavigate } from "react-router-dom";
import DeleteModal from "src/components/custom/DeleteModal";
import useBroadcast from "./hooks/useBroadcast";


const BroadCastCard = ({ data,handleDelete, isDeleting, handleBroadcastData }) => {

  const navigate = useNavigate();
  const [showdelete, setShowdelete] = useState(false);

  return (
    <tr>
      <td style={{ textTransform: "capitalize" }}>{data?.name || "NA"}</td>
      <td>{data?.scheduled_at ? moment(data?.scheduled_at).format('DD MMM, YYYY hh:mm A') : "NA"}</td>
      <td>
        <div>
          <ProgressCircle percentage={data?.percentage?.is_sent ? parseInt(data?.percentage?.is_sent?.replace('%', ''), 10) : 0} />
        </div>
      </td>
      <td>
        <div>
          <ProgressCircle percentage={data?.percentage?.is_read ? parseInt(data?.percentage?.is_read?.replace('%', ''), 10) : 0} />
        </div>
      </td>
      <td> 
        <div>
          <ProgressCircle percentage={data?.percentage?.message_sent ? parseInt(data?.percentage?.is_replied?.replace('%', ''), 10) : 0} />
        </div>
      </td>
      <td>{data?.total_histories || 0} Contact</td>
      <td>{data?.histories?.is_failed || 0} Contact</td>
      <td>
        <div className="d-flex align-items-center ">
          <div className={ data?.status === "FAILED" ? Style.rejected : data?.status === "COMPLETED" ? Style.approved : Style.pending}></div>
          <div style={{ fontSize: "12.5px" }}> {data?.status === "FAILED" ? "Failed" : data?.status === "COMPLETED" ? "Completed" : "Running"}</div>
        </div>
      </td>
      <td style={{textAlign:"center"}}>
        <div className="d-flex align-items-center justify-content-center">
          <div  className={Style.action_btn} onClick={() => { navigate(`/broadcast/detail/${data?._id}`); }}>
            <VisibilityOutlinedIcon />
          </div>
          <div className={`${Style.action_btn} ${data?.status !== "COMPLETED" ? Style.disabled :""}`} onClick={() => { if(data?.status === "COMPLETED" ) {setShowdelete(true);} }}>
            <RiDeleteBin6Line  />
          </div> 
        </div>
      </td>
      <DeleteModal
        open={showdelete}
        text={"broadcast"}
        closeModal={() => {
          setShowdelete(false);
        }}
        handleDelete={() => {
          handleDelete(data?._id,setShowdelete, handleBroadcastData);
        }}
        isDeleting={isDeleting}
      />
    </tr>
  );
};

export default BroadCastCard;
