import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./Models.module.css";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "0px solid #fff !important",
  boxShadow: 24,
  borderRadius: "8px",
};

const NoSubscriptionModal = ({ userdata }) => {

  const navigate = useNavigate();
  return (
    <div>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.session_modal_header}>
            <div className={styles.modal_header_title}>Subscription</div>
            <button
              className={styles.session_close_btn}
              onClick={() => { navigate(`/subscriptions/${userdata?.subscription?.plan}?branch=${userdata?.subscription?.numberOfBranch || 1}`) }}
            >
              x
            </button>
          </div>
          <div className="p-3 text-center">
            <div className={styles.session_modal_title}>
              Your payment is still pending.
            </div>
            <p className={styles.session_modal_summary}>
              Please go & make a payment for further process.
            </p>
            <button
              type="button"
              className={styles.login_btn}
              onClick={() => {
                navigate(`/subscriptions/${userdata?.subscription?.plan}?branch=${userdata?.subscription?.numberOfBranch || 1}`)

              }}
            >
              Make Payment
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NoSubscriptionModal;
