import * as React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Axios from "../../../utils/axios";

import { ButtonLoader } from "../../forms/ButtonLoader";
import Toast from "../Toast";

const ConnecttelegramModal = ({
  open,
  closeModal,
  setUserdata,
  userdata,
  checkIntegration,
  chatBotData,
  chatbotId,
  connectChatbotChannels,
  setchatBotData
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };
  const [err, setErr] = React.useState("")
  const [token, setToken] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const Connect = () => {


    if (!userdata?.integrationKeys.telegram.isActive) {
      if (token == "") {
        setErr("Enter Your Bot Token", "error");
        return
      }
    }
    setErr("")
    setIsLoading(true);

    Axios.post(`/telegram/telegram-connect`, {
      token: userdata?.integrationKeys?.telegram?.isActive ? userdata?.integrationKeys?.telegram?.token : token,
      connectTelegram: !userdata?.integrationKeys?.telegram?.isActive,
    })
      .then((res) => {
        closeModal();
        setUserdata({
          integrationKeys: res?.data?.data?.integrationKeys,
          subscription: res?.data?.data?.subscription[0],
        });
        setToken("");
        checkIntegration({
          integrationKeys: res?.data?.data?.integrationKeys,
          subscription: res?.data?.data?.subscription,
        });
        
        if (!userdata?.integrationKeys.telegram.isActive) {
          let updatedChannels = [...chatBotData?.channels, "telegram"];
          connectChatbotChannels(updatedChannels, chatbotId, setchatBotData);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        Toast(err, "error");
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal_box text-left">
          <div className="mb-2">
            <img src="/images/telegram.png" alt="t-ligram" className="rounded-pill mr-4" width={"30px"} height={"30px"} />{" "}
            Telegram
          </div>

          <p>
            Open Your Telegram Start a chat with{" "}
            <span className="text-primary">@BotFather</span> on Telegram.
          </p>
          <p>
            Use the <span className="text-primary">/newbot</span> command to
            create a new bot.{" "}
          </p>
          <p>BotFather to choose a name and username for your bot.</p>
          <p>
            Bot is created ! After that BotFather is return token with message{" "}
          </p>

          <div className="row d-flex justify-content-between">
            <div className="col-8 p-0">
              <input
                type="text"
                name="token"
                placeholder={userdata?.integrationKeys.telegram.isActive ? userdata?.integrationKeys.telegram.token : "Enter Your Bot Token"}
                className="form-control shadow-lg border border-3 rounded-0  "
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
              <small style={{ color: "red", fontSize: "13px", marginLeft: "2px" }}><b>{err}</b></small>
            </div>
            <div className="col-4 p-0">
              <ButtonLoader
                buttonName={userdata?.integrationKeys.telegram.isActive ? "Disconnect" : "Connect"}
                isLoading={isLoading}
                loaderColor={"white"}
                style={{
                  marginLeft: "12px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
                className="btn btn-primary px-4 mx-3"
                onClickhandle={Connect}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ConnecttelegramModal;
