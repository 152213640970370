import * as React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Button } from "reactstrap";
import { ButtonLoader } from "../../forms/ButtonLoader";
export default function DisconnectModel({
  closeModal,
  handleDelete,
  text,
  open,
  isLoading,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal_box" style={{ textAlign: "center" }}>
          <div style={{ fontSize: "50px" }}>
            <FaExclamationCircle />
          </div>
          <h3>Are You Sure </h3>
          <p>You want to disconnect {text}?</p>
          <div className="modal-btn mt-3 ">
            <Button color="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <ButtonLoader
              onClickhandle={handleDelete}
              color="primary "
              buttonName={"Disconnect"}
              isLoading={isLoading}
              loaderColor={"white"}
              style={{
                marginLeft: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
              className="btn btn-primary px-4 mx-3"
            >
              DisConnect
            </ButtonLoader>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
