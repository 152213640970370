import { useEffect, useState, version } from "react";
import ChatBotTree from "./ChatBotTree";
import useChatbotTree from "./hooks/useChatbotTree";
import useConnectChannels from "../connectChat/hooks/useConnectChannels";
import Loader from "src/layouts/loader/Loader";
import { getAppHost } from "src/constants/constants";
import { useLocation } from "react-router-dom";

const ChatBotBuilder = ({ handleNext, isAccountSetup }) => {

  const { treeData, isTreeLoading, getChatbotTreeByUser, chatBotData, setchatBotData } = useChatbotTree();
  const { userdata, getUserListData, isLoading } = useConnectChannels();

  const [chatbotId, setChatbotId] = useState("");
  const [versionId, setVersionId] = useState("");
  const [chatbotPageType, setChatbotPageType] = useState("chatbot");

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/account-setup") {
      getChatbotTreeByUser();
    } else {
      setChatbotPageType(location.pathname === "/chatbot/compare" ? "compare" : location.pathname === "/chatbot/restore" ? "restore" : "chatbot")
      setChatbotId(new URLSearchParams(location.search)?.get("chatbot"))
      if (new URLSearchParams(location.search)?.get("version")) {
        setVersionId(new URLSearchParams(location.search)?.get("version"))
      } else {
        setVersionId("")
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (chatbotId && versionId) {
      getChatbotTreeByUser(chatbotId, versionId);
    } else if (chatbotId) {
      getChatbotTreeByUser(chatbotId);
    }
  }, [chatbotId, versionId]);


  return (
    <>{isTreeLoading ? <Loader /> : <ChatBotTree agencyData={userdata} chatbotPageType={chatbotPageType} chatbotId={chatbotId || chatBotData._id} versionId={versionId} treeData={treeData} setchatBotData={setchatBotData} chatBotData={chatBotData} getChatbotTreeByUser={getChatbotTreeByUser} getUserListData={getUserListData} isLoading={isLoading} isAccountSetup={isAccountSetup} handleNext={handleNext} />}

      {chatbotPageType === "chatbot" && (chatbotId || chatBotData._id) && <react-counter
        id="react-counter"
        adminid={userdata?._id}
        name={chatBotData?.draftMetaData?.chatbotName}
        user_image={chatBotData?.draftMetaData?.chatbotImage}
        bot_id={userdata?.integrationKeys?.chatbot?.encryptedKey}
        chatbot={chatbotId || chatBotData._id}
        app_host={getAppHost()}>
      </react-counter>}
    </>
  );
};

export default ChatBotBuilder;
