import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";


import dayjs from "dayjs";
import moment from "moment";

const TimePickerInput = ({ customClass, label, value, onChange, size, cssValue, minstep,placeholder }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} className="p-0" >
      <DemoContainer components={["TimePicker"]} className={"p-0"}>
        <TimePicker
          sx={{
            ".MuiOutlinedInput-input": {
              padding: cssValue === "property" ? '11.5px 14px' : '9.5px 14px',
              fontSize: "13px",

            },
          }}
          className={customClass + " p-0"}
          // label={hideLabel ? '' : label || "date"}
          label={label}
          value={value ? dayjs(value) : value}
          onChange={(value) => { onChange(value ? new Date(value).toISOString() : null); }}
          size={"small"}
          minutesStep={minstep === "everyminute" ? 1 : 30}

          slotProps={{
            textField: {
              placeholder: placeholder,
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default TimePickerInput;


