import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toast from 'src/components/custom/Toast';
import Axios from 'src/utils/axios';

const usePropertyList = () => {
    const [properties, setProperties] = useState([]);
    const [propertytypeData, setPropertytypeData] = useState([]);
    const [propertystatusData, setPropertystatusData] = useState([]);
    const [propertyCount, setPropertyCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isDeleting, setisDeleting] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate()

    const getPropertyList = async (page, limit, filters = {}, sorting = {}, search = '', propertyCategory = "residential") => {
        setLoading(true);
        setError(null);
        const params = new URLSearchParams({
            sortBy: sorting.field || 'createdAt',
            sortDirection: sorting.direction || -1,
            address: search || '',
            propertyCategory: propertyCategory,
            page: page,
            limit: limit,
            ...filters,
        }).toString();

        try {
            const response = await Axios.get(`/property/getAllProperty?${params}`);
            if (response.status === 200) {
                setProperties(response.data.data);
                setPropertyCount(response.data.pagination);
            } else {
                Toast(response.data.message || 'Something went wrong', "error");
                setError(response.data.message || 'Something went wrong');
            }
        } catch (err) {
            Toast(err.message || 'An error occurred while fetching properties', "error");
            setError(err.response?.data?.message || 'An error occurred while fetching properties');
        } finally {
            setLoading(false);
        }
    };

    // Delete property function
    const deleteProperty = async (propertyId, setShowDelete, setPropertyData = false) => {
        setisDeleting(true);
        setError(null);
        try {
            const response = await Axios.post(`/property/delete`, { propertyId: propertyId });
            if (response.status === 200) {
                // Remove the deleted property from the properties array
                if (setPropertyData) {
                    navigate('/property')
                } else {
                    setProperties(prevProperties => prevProperties.filter(property => property._id !== propertyId));
                }

                Toast(response.data.message || 'Property deleted successfully', "success");
            } else {
                Toast(response.data.message || 'Failed to delete property', "error");
                setError(response.data.message || 'Failed to delete property');
            }
        } catch (err) {
            Toast(err.message || 'An error occurred while deleting the property', "error");
            setError(err.response?.data?.message || 'An error occurred while deleting the property');
        } finally {
            setisDeleting(false);
            setShowDelete(false)
        }
    };

    const updatePropertyStatus = async (propertyId, newStatus, setShowConfirm, setPropertyData = false) => {
        setisDeleting(true);
        setError(null);
        try {
            const response = await Axios.put(`/property/update-onthemarket`, { propertyId: propertyId, onTheMarket: newStatus });
            if (response.status === 200) {
                // Update the specific property in the properties array
                if (setPropertyData) {
                    setPropertyData(response?.data?.data)
                } else {

                    setProperties(prevProperties =>
                        prevProperties.map((property, index) =>
                            property._id === propertyId ? { ...property, onTheMarket: newStatus } : property
                        )
                    );
                }

                Toast(response.data.message || 'Property status updated successfully', "success");
            } else {
                Toast(response.data.message || 'Failed to update property status', "error");
                setError(response.data.message || 'Failed to update property status');
            }
        } catch (err) {
            Toast(err.message || 'An error occurred while updating property status', "error");
            setError(err.response?.data?.message || 'An error occurred while updating property status');
        } finally {
            setisDeleting(false);
            setShowConfirm(false)
        }
    };


    const AproveProperty = async (propertyId, newStatus,  setPropertyData = false) => {
        setisDeleting(true);
        setError(null);
        try {
            const response = await Axios.put(`/property/approve`, { propertyId: propertyId, status: newStatus });
            if (response.status === 200) {
                // Update the specific property in the properties array
                if (setPropertyData) {
                    setPropertyData(response?.data?.data)
                } else {

                    setProperties(prevProperties =>
                        prevProperties.map((property, index) =>
                            property._id === propertyId ? { ...property, status: newStatus } : property
                        )
                    );
                }

                Toast(response.data.message || 'Property status updated successfully', "success");
            } else {
                Toast(response.data.message || 'Failed to update property status', "error");
                setError(response.data.message || 'Failed to update property status');
            }
        } catch (err) {
            Toast(err.message || 'An error occurred while updating property status', "error");
            setError(err.response?.data?.message || 'An error occurred while updating property status');
        } finally {
            setisDeleting(false);
        }
    };

    const getPropertyTypeMaster = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await Axios.get('/property/master?type=propertytype');

            if (response.status === 200) {
                setPropertytypeData(response.data.data);
            } else {
                Toast(response.data.message || 'Failed to fetch master data', "error");
                setError(response.data.message || 'Failed to fetch master data');
            }
        } catch (err) {
            Toast(err, "error");
            setError(err.response?.data?.message || 'An error occurred while fetching master data');
        } finally {
            setLoading(false);
        }
    };

    function getUniqueByValue(arr) {
        // Create a Map to hold unique items by the 'value' field
        const uniqueMap = new Map();

        // Loop through the array and use 'value' as the key in the Map
        arr.forEach(item => {
            if (!uniqueMap.has(item.value)) {
                uniqueMap.set(item.value, item);
            }
        });

        // Convert the Map values back to an array
        return Array.from(uniqueMap.values());
    }

    const getpropertystatusMaster = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await Axios.get('/property/master?type=propertystatus');

            if (response.status === 200) {
                setPropertystatusData(getUniqueByValue(response.data.data));
            } else {
                Toast(response.data.message || 'Failed to fetch master data', "error");
                setError(response.data.message || 'Failed to fetch master data');
            }
        } catch (err) {
            Toast(err, "error");
            setError(err.response?.data?.message || 'An error occurred while fetching master data');
        } finally {
            setLoading(false);
        }
    };



    return {
        properties,
        propertyCount,
        loading,
        error,
        getPropertyList,
        deleteProperty, // Return the delete function
        setLoading,
        isDeleting,
        updatePropertyStatus,
        AproveProperty,
        propertytypeData,
        propertystatusData,
        getpropertystatusMaster, getPropertyTypeMaster
    };
};

export default usePropertyList;
