import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { MenuItem, InputLabel, FormControl, TextField, Popover, ClickAwayListener, InputAdornment, IconButton, styled } from '@mui/material';

// Custom styled MenuItem to highlight selected item
const StyledMenuItem = styled(MenuItem)(({ theme, selected }) => ({
    backgroundColor: selected ? "#E2ECF8" : 'transparent',
    '&:hover': { backgroundColor: theme.palette.action.hover },
}));


const SelectWithOutSearchBox = ({ options, label, size, selectedValue, onChange, placeholder, noOptionText, disabled }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    // Handle opening the Popover
    const handleClick = (event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }

    };

    // Handle closing the Popover
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    // Handle selecting an option
    const handleSelectChange = (value) => {
        onChange(value);
        handleClose();
    };

    // Find the label for the selected value
    const selectedLabel = options.find(option => option.value === selectedValue)?.label || '';

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>{label}</InputLabel>
            <TextField
                label={label}
                value={selectedLabel} // Display the label of the selected value
                onClick={handleClick}
                autoComplete="off"
                readOnly
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <ArrowDropDownIcon />
                        </InputAdornment>
                    ),
                    placeholder: placeholder,
                }}
                size={size || 'small'}
                variant="outlined"
                fullWidth
                style={{ color: "#000" }}
                disabled={disabled}
                sx={{
                    background: "#ffffff",
                    input: {
                        cursor: "pointer",
                        color: "#000",
                        fontWeight: 600,
                        fontFamily: "inter",
                        fontSize: "14px",
                        height: "1.5rem",
                        background: "white",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        "&::placeholder": {
                            opacity: 1,
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        display: 'flex',
                        alignItems: 'center',
                        background: "white",
                        border: "2px solid #CBD6E2"
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: "none"
                    }
                }}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                style={{ marginTop: 14 }}
                slotProps={{
                    paper: {
                        sx: {
                            overflow: "visible", boxShadow: "none",
                            "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 50,
                                width: 16,
                                height: 16,
                                background: "#ffffff",
                                transform: "translateY(-50%) rotate(45deg)",
                                boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                                borderTop: "2px solid #CBD6E2",
                                borderLeft: "2px solid #CBD6E2",
                            },
                        },
                    },
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div style={{ minWidth: 250, border: "2px solid #CBD6E2", borderRadius: "4px", boxShadow: "none", }}>

                        <div style={{ maxHeight: 200, overflow: 'auto', padding: "8px 0px" }}>
                            {options?.length > 0 ? (options.map((option) => (
                                <StyledMenuItem
                                    key={option.value}
                                    selected={option.value === selectedValue}
                                    onClick={() => handleSelectChange(option.value)}
                                    style={{ background: option.value === selectedValue ? "#E2ECF8" : "#fff", color: "#33475B", fontFamily: "Inter", fontSize: "14px", fontWeight: "600", textAlign: "left" }}
                                >
                                    {option.label}
                                </StyledMenuItem>
                            )))
                                : (<div style={{ textAlign: "center", color: "darkgray", fontWeight: "500", padding: "1rem" }}>{noOptionText ? noOptionText : "No Options"}</div>)
                            }
                        </div>
                    </div>
                </ClickAwayListener>
            </Popover>
        </FormControl>
    );
};

export default SelectWithOutSearchBox;











