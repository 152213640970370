import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';

const StyledIcon = styled.span`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
  & .MuiSvgIcon-root {
    font-size: ${({ fontSize }) => `${fontSize}px`};
    color: ${({ color }) => color};
  }
`;

const CustomIconButtonComp = styled(IconButton)`
  margin: 0px !important;
  padding: 0px !important;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

const CustomIconButton = ({ size = 18, fontSize = 18, color, icon, onClick }) => {
  return (
    <CustomIconButtonComp size={String(size)} onClick={onClick}>
      <StyledIcon fontSize={fontSize} color={color}>
        {icon}
      </StyledIcon>
    </CustomIconButtonComp>
  );
};

export default CustomIconButton;