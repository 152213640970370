import React from "react";
import useChatbotTree from "../hooks/useChatbotTree";
import DeleteModal from "src/components/custom/DeleteModal";
 
const DeleteNodeModal = ({isOpen, setDeleteToggle, orgData, setOrgData, targetNode ,  setPublishedChatbot})=>{
    
    const {isTreeDeleting, deleteNodeFromDb  } = useChatbotTree();
 
    const handleDelete = async () => {
        const updatedTree = await deleteNodeFromDb(orgData, targetNode);
        setOrgData(updatedTree);
        setDeleteToggle(false);
        setPublishedChatbot(false)
    };
      
    return (
        <DeleteModal
            open={isOpen}
            isDeleting={isTreeDeleting}
            text="Node"
            closeModal={() => {
                setDeleteToggle(false);
            }}
            handleDelete={handleDelete}
            // warning={(targetNode && targetNode?.children && targetNode?.children?.length > 0) ? true : false}
            // text={targetNode && targetNode?.children && targetNode?.children?.length > 0 ?'Deleting this option will also remove subsequent actions in the chatbot flow.' : "option"}
        />
    );
}
export default DeleteNodeModal;