export const MAX_PRICE_RANGE_SALE = [
    { label: "£50,000", value: 50000 },
    { label: "£100,000", value: 100000 },
    { label: "£125,000", value: 125000 },
    { label: "£150,000", value: 150000 },
    { label: "£175,000", value: 175000 },
    { label: "£200,000", value: 200000 },
    { label: "£225,000", value: 225000 },
    { label: "£250,000", value: 250000 },
    { label: "£275,000", value: 275000 },
    { label: "£300,000", value: 300000 },
    { label: "£325,000", value: 325000 },
    { label: "£350,000", value: 350000 },
    { label: "£375,000", value: 375000 },
    { label: "£400,000", value: 400000 },
    { label: "£425,000", value: 425000 },
    { label: "£450,000", value: 450000 },
    { label: "£475,000", value: 475000 },
    { label: "£500,000", value: 500000 },
    { label: "No Max", value: 0 },
];

export const PRICE_PER = [
    { label: "Month", value: "month" },
    { label: "Week", value: "week" },
];

export const MIN_PRICE_RANGE_SALE = [
    { label: "No Min", value: 0 },
    { label: "£50,000", value: 50000 },
    { label: "£100,000", value: 100000 },
    { label: "£125,000", value: 125000 },
    { label: "£150,000", value: 150000 },
    { label: "£175,000", value: 175000 },
    { label: "£200,000", value: 200000 },
    { label: "£225,000", value: 225000 },
    { label: "£250,000", value: 250000 },
    { label: "£275,000", value: 275000 },
    { label: "£300,000", value: 300000 },
    { label: "£325,000", value: 325000 },
    { label: "£350,000", value: 350000 },
    { label: "£375,000", value: 375000 },
    { label: "£400,000", value: 400000 },
    { label: "£425,000", value: 425000 },
    { label: "£450,000", value: 450000 },
    { label: "£475,000", value: 475000 },
    { label: "£500,000", value: 500000 },
];

export const MAX_PRICE_RANGE_LET = [
    { label: "£100", value: 100 },
    { label: "£150", value: 150 },
    { label: "£200", value: 200 },
    { label: "£250", value: 250 },
    { label: "£300", value: 300 },
    { label: "£350", value: 350 },
    { label: "£400", value: 400 },
    { label: "£450", value: 450 },
    { label: "£500", value: 500 },
    { label: "£600", value: 600 },
    { label: "£700", value: 700 },
    { label: "£800", value: 800 },
    { label: "£900", value: 900 },
    { label: "£1,000", value: 1000 },
    { label: "£1,250", value: 1250 },
    { label: "£1,500", value: 1500 },
    { label: "£1,750", value: 1750 },
    { label: "£2,000", value: 2000 },
    { label: "£50,000", value: 50000 },
    { label: "No Max", value: 0 },
];

export const MIN_PRICE_RANGE_LET = [
    { label: "No Min", value: 0 },
    { label: "£100", value: 100 },
    { label: "£150", value: 150 },
    { label: "£200", value: 200 },
    { label: "£250", value: 250 },
    { label: "£300", value: 300 },
    { label: "£350", value: 350 },
    { label: "£400", value: 400 },
    { label: "£450", value: 450 },
    { label: "£500", value: 500 },
    { label: "£600", value: 600 },
    { label: "£700", value: 700 },
    { label: "£800", value: 800 },
    { label: "£900", value: 900 },
    { label: "£1,000", value: 1000 },
    { label: "£1,250", value: 1250 },
    { label: "£1,500", value: 1500 },
    { label: "£1,750", value: 1750 },
    { label: "£2,000", value: 2000 },
    { label: "£50,000", value: 50000 },
];
