import React from 'react';
import Style from './broadcast.module.css';

const ProgressCircle = ({ percentage }) => {
  const radius = 25; // Radius of the circle
  const strokeWidth = 3.5;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <div className={Style.progress_container}>
      <svg width="60" height="60" className={Style.progress_circle}>
        <circle
          className={Style.progress_background}
          cx="30" // Center X
          cy="30" // Center Y
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className={Style.progress_foreground}
          cx="30"
          cy="30"
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        <text x="30" y="34" textAnchor="middle" fontSize="11" fill="#5C5D5C" fontWeight="700">
          {`${percentage}%`}
        </text>
      </svg>
    </div>
  );
};

export default ProgressCircle;
