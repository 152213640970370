import Stepper from "./Stepper";
import Style from "./AccountSetup2.module.css";
import Logo from "src/assets/images/logos/PropertyJinni-Logo.png";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import useAgencyData from "./hooks/useAgencyData";
import BranchDetails from "./BranchDetails";
import DirectorManagement from "./DirectorManagement";
import Profile from "./Profile";
import SocialChannels from "./SocialChannels";
import { useNavigate } from "react-router-dom";
import ChatBotBuilder from "src/views/chatbotBuilder";


const Index = () => {

  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [customError, setCustomError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [agency, setAgency] = useState({});
  const [branchCount, setBranchCount] = useState(agency?.branchCount || 0);
  const { getAgencyDetails, updateAgencyStep, updateBackStep, isLoading } = useAgencyData();

  useEffect(() => {
    getAgencyDetails(setAgency, setStep, setBranchCount);
  }, []);
  return <>
    <div className={Style.background_img + "  min-vh-100"} style={{ overflowX: "hidden" }}>
      <div className="py-4 px-5">
        <Stepper activeStep={step} />
      </div>
      {<div className={(step === 3 || step === 4) ? Style.logo_margin2 : step === 2 ? Style.chatbot_logo_margin : Style.logo_margin}>
        <div className="d-flex align-items-center">
          <img src={Logo} alt="user" className={Style.logo_img} />
          {customError && <div className={Style.custom_error_box + " d-flex align-items-center justify-content-between"}>
            <div >{errorText}</div>
            <CloseIcon sx={{ color: "#5B656E", fontSize: "20px", fontWeight: "700", cursor: "pointer" }} onClick={() => { setCustomError(false) }} />
          </div>
          }
        </div>
      </div>}
      {step === 0 && <BranchDetails
        handleNext={async () => {
          updateAgencyStep(1);
          await getAgencyDetails(setAgency, setStep, setBranchCount);
          setStep(1);
        }}
        branches={branchCount}
        handleAgencyData={(val) => { setAgency(val) }}
        agency={agency}
      />}

      {step === 1 && <DirectorManagement

        handleNext={async (back) => {
          if (back === "back") {
            updateBackStep(1);
            getAgencyDetails(setAgency, setStep, setBranchCount);
            setStep(1);
          } else {
            updateAgencyStep(2);
            await getAgencyDetails(setAgency, setStep, setBranchCount);
            setStep(2);
          }
        }}

        handleError={(text) => { setCustomError(true); setErrorText(text); }}
        handleAgencyData={(val) => { setAgency(val) }}
        agency={agency}
        closeError={() => { setCustomError(false); setErrorText(""); }}
      />}
      {step === 2 && <Profile
        isLoading={isLoading}
        isSetup={true}
        handleNext={async (back) => {
          updateAgencyStep(3);
          await getAgencyDetails(setAgency, setStep, setBranchCount);
          setStep(3);
        }}
        handleBack={async (back) => {
          updateBackStep(1);
          getAgencyDetails(setAgency, setStep, setBranchCount);
          setStep(1);
        }}
      />}

      {step === 3 && <ChatBotBuilder
        btnLoading={isLoading}
        isAccountSetup={true}
        handleNext={async (back) => {
          if (back === "back") {
            updateBackStep(1);
            getAgencyDetails(setAgency, setStep, setBranchCount);
            setStep(2);
          } else {
            updateAgencyStep(4);
            await getAgencyDetails(setAgency, setStep, setBranchCount);
            setStep(4);
          }
        }}

      />
      }

      {step === 4 && <SocialChannels isLoading={isLoading}
        handleNext={async (back) => {
          if (back === "back") {
            updateBackStep(1);
            getAgencyDetails(setAgency, setStep, setBranchCount);
            setStep(3);
          } else {
            updateAgencyStep(4);
            navigate("/dashboard")
          }
        }}
      />}
    </div>
  </>
}
export default Index;