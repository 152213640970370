import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { Button } from 'reactstrap';
import styles from './DeleteModal.module.css'
import { RxCross2 } from "react-icons/rx";
import deleteIcon from '../../assets/images/modal/trash.svg'

export default function PublishModal({ closeModal, open, }) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.delete_modal}
    >
      <Box sx={style} className={styles.delete_wrap}>
        <div className={styles.modal_wrap + " modal_box position-relative"} >

          <img src={deleteIcon} className={styles.modal_icon} />

          <p className={styles.warning_text} style={{ fontSize: "14px" }}><b>Attention! </b>In every chatbot flow involving a 'Live chat' or 'Lead' action, include the following prompts
            <b> EXACTLY ONCE EACH. </b></p>

          <ul className='text-start' style={{ fontSize: "12px" }}>
            <li>Location </li>
            <li>Question of customer category (e.g., seller, landlord, buyer, tenant)</li>
            <li>Question of property type (e.g., residential, commercial)</li>
          </ul>

          <div className={styles.close_modal}>
            <RxCross2 onClick={closeModal} />
          </div>
        </div>
      </Box>
    </Modal>
  );
}
