import { Button, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import Style from "./AddProperty.module.css";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useFormik } from 'formik';
import * as Yup from "yup";
import ImageDisplay from "src/components/custom/ImageDisplay";
import DeleteModal from "src/components/custom/DeleteModal";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Toast from 'src/components/custom/Toast';
import AstericSymbol from "src/components/custom/astericSymbol";

const PropertyPicture = ({ handleBack, PropertyPictureService, PropertyDescriptionData, fetchSingleListId, listingType }) => {
    const [deletedImageUrl, setDeletedImageUrl] = useState("");
    const [showdelete, setShowdelete] = useState(false);
    const [showdelete1, setShowdelete1] = useState(false);
    const [deletedImageUrl1, setDeletedImageUrl1] = useState("");
    const [uploadAttempted, setUploadAttempted] = useState(false);
    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const [deleteType, setDeleteType] = useState("");  // Track whether deleting a property or floor image
    const navigate = useNavigate();
    // Ensure click works and logs file input click
    const handleClick = (event) => {
        event.preventDefault();
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleClick1 = (event) => {
        event.preventDefault();
        if (fileInputRef1 && fileInputRef1.current) {
            fileInputRef1.current.click();
        }
    };

    const validationSchema = Yup.object({
        propertypictures: Yup.array()
            .min(5, "Please upload at least 5 images.")
            .required("Property pictures are required."),

    });
    const formik = useFormik({
        initialValues: {
            propertypictures: [],
            propetyfloormap: [],
        },
        validationSchema,
        validateOnChange: true,
        onSubmit: async (values) => {
            const formData = new FormData();
            if (Array.isArray(values.propertypictures)) {
                values.propertypictures.forEach((file) => {
                    formData.append("propertypictures", file);
                });
            }
            if (Array.isArray(values.propetyfloormap)) {
                values.propetyfloormap.forEach((file) => {
                    formData.append("propetyfloormap", file);
                });
            }
            formData.append("propertyId", PropertyDescriptionData);
            if (listingType === "sell") {
                PropertyPictureService(formData, 5);
                navigate("/property");
            }
            else {
                PropertyPictureService(formData, 6);
                navigate("/property");
            }
        },
    });





    const handleImages = (val) => {
        const imageFiles = val.filter(file => file.type.startsWith('image/'));
        if (imageFiles.length !== val.length) {
            Toast("Please only upload images", "error");

        }
        formik.setFieldValue("propertypictures", [...formik.values.propertypictures, ...imageFiles]);
    };

    const handleImagesData = (val) => {
        const imageFiles = val.filter(file => file.type.startsWith('image/'));
        if (imageFiles.length !== val.length) {
            Toast("Please only upload images", "error");
        }
        formik.setFieldValue("propetyfloormap", [...formik.values.propetyfloormap, ...imageFiles]);
    };

    const handleRemoveImage = (index) => {
        const updatedSelectedImages = [...formik.values.propertypictures];
        updatedSelectedImages.splice(index, 1);
        formik.setFieldValue("propertypictures", updatedSelectedImages);
        setShowdelete(false);
    };

    const handleRemoveImage1 = (index) => {
        const updatedSelectedImages1 = [...formik.values.propetyfloormap];
        updatedSelectedImages1.splice(index, 1);
        formik.setFieldValue("propetyfloormap", updatedSelectedImages1);
        setShowdelete1(false);
    };

    const handleBackButton = () => {
        fetchSingleListId(PropertyDescriptionData);
        handleBack()
    }


    return (
        <div>
            <div style={{ padding: '10px 40px' }}>
                <div>
                    <Typography variant="h6" className={Style.listDetailsFont}>Property Pictures  <AstericSymbol /> </Typography>
                </div>
                <hr className={Style.custom_hr} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className="w-100">
                            <input
                                type="file"
                                ref={fileInputRef}
                                multiple
                                accept="image/*"

                                onChange={(e) => {
                                    const selectedFiles = Array.from(e.currentTarget.files);
                                    const imageFiles = selectedFiles.filter(file => file.type.startsWith('image/'));
                                    if (imageFiles.length !== selectedFiles.length) {
                                        Toast("Please only upload images", "error");

                                    }
                                    if (imageFiles.length > 0) {
                                        handleImages(imageFiles);
                                        setUploadAttempted(true);
                                    }
                                }}
                                hidden
                            />
                            <div
                                onClick={handleClick}
                                className={Style.upload_img}
                            >
                                <div style={{ textAlign: "center" }}>
                                    <CloudUploadIcon />
                                    <p className="mb-0">Upload your image here</p>
                                </div>
                            </div>
                            <span className={Style.picture_span}
                                style={{ color: (uploadAttempted || formik.submitCount > 0) && formik.values.propertypictures.length < 5 || (uploadAttempted && formik.values.propertypictures.length < 1) ? 'red' : 'inherit' }}>
                                Please upload minimum 5 images for better understanding.
                            </span>
                        </div>
                    </Grid>

                    <Grid container spacing={2} justifyContent="flex-start">
                        {formik.values?.propertypictures?.map((image, index) => (
                            <Grid
                                item
                                xs={12} sm={6} md={2.5}  // Adjust width for different screen sizes
                                key={index}
                                className="mb-3"
                                style={{ padding: '10px', marginTop: '20px', marginLeft: '21px' }}
                            >
                                <ImageDisplay
                                    image={image}
                                    index={index}
                                    setShowdelete={(val) => setShowdelete(val)}
                                    setDeletedImageUrl={(val) => setDeletedImageUrl(val)}
                                    Style={Style}
                                    setDeleteType={() => setDeleteType("property")}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <div className='mt-5'>
                    <Typography variant="h6" className={Style.listDetailsFont}>Floor Map</Typography>
                </div>
                <hr className={Style.custom_hr} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className="w-100">
                            <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef1}
                                multiple
                                onChange={(e) => {
                                    const selectedFiles = Array.from(e.currentTarget.files);
                                    const imageFiles = selectedFiles.filter(file => file.type.startsWith('image/'));
                                    if (imageFiles.length !== selectedFiles.length) {
                                        Toast("Please only upload images", "error");
                                    }
                                    if (imageFiles.length > 0) {
                                        handleImagesData(imageFiles);
                                        setUploadAttempted(true);

                                    }
                                }}

                                hidden
                            />
                            <div
                                onClick={handleClick1}
                                className={Style.upload_img}
                            >
                                <div style={{ textAlign: "center" }}>
                                    <CloudUploadIcon />
                                    <p className="mb-0">Upload your image here</p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid container spacing={2} justifyContent="flex-start">
                        {formik.values?.propetyfloormap?.map((image, index) => (
                            <Grid
                                item
                                xs={12} sm={6} md={2.5}  // Adjust width for different screen sizes
                                key={index}
                                className="mb-3"
                                style={{ padding: '10px', marginTop: '20px', marginLeft: '21px' }}  // Add padding to create a gap between items
                            >
                                <ImageDisplay
                                    image={image}
                                    index={index}
                                    setShowdelete={(val) => setShowdelete1(val)}
                                    setDeletedImageUrl={(val) => setDeletedImageUrl1(val)}
                                    Style={Style}
                                    setDeleteType={() => setDeleteType("floor")}
                                />
                            </Grid>
                        ))}
                    </Grid>

                </Grid>
                <hr className={Style.custom_hr} />
                <div className={Style.propertycss1 + " " + Style.button_nav}>
                    <div style={{ marginRight: '12px' }}>
                        <Button color="secondary"
                            className={Style.delete_btn + " " + Style.edit_btn}
                            onClick={handleBackButton}
                        >
                            <KeyboardArrowLeftIcon /> Back
                        </Button>
                    </div>
                    <div>
                        <ButtonLoader
                            type={"button"}
                            buttonName="Save"
                            loaderColor={"white"}
                            className={Style.Add_btn}
                            onClickhandle={formik.handleSubmit}
                            icon={<KeyboardArrowRightIcon />}
                        >
                        </ButtonLoader>
                    </div>
                </div>
            </div>
            <DeleteModal
                open={showdelete}
                text={"document"}
                closeModal={() => {
                    setShowdelete(false);
                }}
                handleDelete={() => {
                    handleRemoveImage(deletedImageUrl);  // Pass both the type and index
                }}
            />

            <DeleteModal
                open={showdelete1}
                text={"document"}
                closeModal={() => {
                    setShowdelete1(false);
                }}
                handleDelete={() => {
                    handleRemoveImage1(deletedImageUrl1);  // Pass both the type and index
                }}
            />
        </div>
    );
};

export default PropertyPicture;
