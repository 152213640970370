import React from "react";
import Style from "src/components/apps/ticket/ticket.module.css";

const ImageDisplay = ({
  image,
  setShowdelete,
  edit,
  setDeletedImageUrl,
  index,
  view
}) => {
  return (
    // <div>
    //   {typeof image === "string" ? (
    //     image.endsWith(".pdf") ? (
    //       <div className=" position-relative">
    //         <a href={image} download>
    //           <img
    //             className={Style.lead_img + " w-100 image-object"}
    //             style={{
    //               width: "100px",
    //             }}
    //             src="/pdfimage.png"
    //             alt="PDF"
    //           />
    //         </a>
    //         <button
    //           type="button"
    //           className={Style.img_del_btn}
    //           style={{}}
    //           onClick={() => {
    //             setShowdelete(true);
    //             if (edit) {
    //               if (typeof image === "string") {
    //                 setDeletedImageUrl(image);
    //               } else {
    //                 setDeletedImageUrl(index);
    //               }
    //             } else {
    //               setDeletedImageUrl(index);
    //             }
    //           }}
    //         >
    //           x
    //         </button>
    //       </div>
    //     ) : (
    //       <div className=" position-relative">
    //         <a href={image} download>
    //           <img
    //             className={Style.lead_img + " w-100 image-object"}
    //             src={image}
    //             alt="Wrong Url"
    //           />
    //         </a>
    //         <button
    //           type="button"
    //           className={Style.img_del_btn}
    //           style={{}}
    //           onClick={() => {
    //             setShowdelete(true);
    //             if (edit) {
    //               if (typeof image === "string") {
    //                 setDeletedImageUrl(image);
    //               } else {
    //                 setDeletedImageUrl(index);
    //               }
    //             } else {
    //               setDeletedImageUrl(index);
    //             }
    //           }}
    //         >
    //           x
    //         </button>
    //       </div>
    //     )
    //   ) : image?.type === "application/pdf" ? (
    //     <div className=" position-relative">
    //       <img
    //         className={Style.lead_img + " w-100 image-object"}
    //         style={{
    //           width: "100px",
    //         }}
    //         src="/pdfimage.png"
    //         alt="PDF"
    //       />
    //       <button
    //         type="button"
    //         className={Style.img_del_btn}
    //         style={{}}
    //         onClick={() => {
    //           setShowdelete(true);
    //           if (edit) {
    //             if (typeof image === "string") {
    //               setDeletedImageUrl(image);
    //             } else {
    //               setDeletedImageUrl(index);
    //             }
    //           } else {
    //             setDeletedImageUrl(index);
    //           }
    //         }}
    //       >
    //         x
    //       </button>
    //     </div>
    //   ) : (
    //     <div className=" position-relative">
    //       <img
    //         className={Style.lead_img + " w-100 image-object"}
    //         src={URL.createObjectURL(image)}
    //         alt="Selected Image Preview"
    //       />
    //       <button
    //         type="button"
    //         className={Style.img_del_btn}
    //         style={{}}
    //         onClick={() => {
    //           setShowdelete(true);
    //           if (edit) {
    //             if (typeof image === "string") {
    //               setDeletedImageUrl(image);
    //             } else {
    //               setDeletedImageUrl(index);
    //             }
    //           } else {
    //             setDeletedImageUrl(index);
    //           }
    //         }}
    //       >
    //         x
    //       </button>
    //     </div>
    //   )}
    // </div>
    <div>
      {typeof image === "string" ? (
        image.endsWith(".pdf") ? (
          <div className="position-relative">
            <a href={image} download>
              <img
                className={Style.lead_img + " w-100 image-object"}
                style={{
                  width: "100px",
                }}
                src="/images/pdfimage.png"
                alt="PDF"
              />
            </a>
            <button
              type="button"
              disabled={view}
              className={Style.img_del_btn}
              onClick={() => {
                setShowdelete(true);
                if (edit) {
                  if (typeof image === "string") {
                    setDeletedImageUrl(image);
                  } else {
                    setDeletedImageUrl(index);
                  }
                } else {
                  setDeletedImageUrl(index);
                }
              }}
            >
              x
            </button>
          </div>
        ) : image.endsWith(".doc") || image.endsWith(".docx") ? (
          <div className="position-relative">
            <a href={image} download>
              <img
                className={Style.lead_img + " w-100 image-object"}
                src="/images/wordimage.png"
                alt="Word Document"
              />
            </a>
            <button
              type="button"
              disabled={view}
              className={Style.img_del_btn}
              onClick={() => {
                setShowdelete(true);
                if (edit) {
                  if (typeof image === "string") {
                    setDeletedImageUrl(image);
                  } else {
                    setDeletedImageUrl(index);
                  }
                } else {
                  setDeletedImageUrl(index);
                }
              }}
            >
              x
            </button>
          </div>
        ) : image.endsWith(".xls") || image.endsWith(".xlsx") ? (
          <div className="position-relative">
            <a href={image} download>
              <img
                className={Style.lead_img + " w-100 image-object"}
                src="/images/excelimage.png"
                alt="Excel Document"
              />
            </a>
            <button
              type="button"
              disabled={view}
              className={Style.img_del_btn}
              onClick={() => {
                setShowdelete(true);
                if (edit) {
                  if (typeof image === "string") {
                    setDeletedImageUrl(image);
                  } else {
                    setDeletedImageUrl(index);
                  }
                } else {
                  setDeletedImageUrl(index);
                }
              }}
            >
              x
            </button>
          </div>
        ) : (
          <div className="position-relative">
            <a href={image} download>
              <img
                className={Style.lead_img + " w-100 image-object"}
                src={image}
                alt="Wrong Url"
              />
            </a>
            <button
            disabled={view}
              type="button"
              className={Style.img_del_btn}
              onClick={() => {
                setShowdelete(true);
                if (edit) {
                  if (typeof image === "string") {
                    setDeletedImageUrl(image);
                  } else {
                    setDeletedImageUrl(index);
                  }
                } else {
                  setDeletedImageUrl(index);
                }
              }}
            >
              x
            </button>
          </div>
        )
      ) : image?.type === "application/pdf" ? (
        <div className="position-relative">
          <img
            className={Style.lead_img + " w-100 image-object"}
            style={{
              width: "100px",
            }}
            src="/images/pdfimage.png"
            alt="PDF"
          />
          <button
            type="button"
            disabled={view}
            className={Style.img_del_btn}
            onClick={() => {
              setShowdelete(true);
              if (edit) {
                if (typeof image === "string") {
                  setDeletedImageUrl(image);
                } else {
                  setDeletedImageUrl(index);
                }
              } else {
                setDeletedImageUrl(index);
              }
            }}
          >
            x
          </button>
        </div>
      ) : image?.type === "application/msword" ||
        image?.name?.endsWith(".docx") ? (
        <div className="position-relative">
          {/* Add appropriate icon/image for Word document */}
          <img
            className={Style.lead_img + " w-100 image-object"}
            src="/images/wordimage.png"
            alt="Word Document"
          />
          <button
            type="button"
            disabled={view}
            className={Style.img_del_btn}
            onClick={() => {
              setShowdelete(true);
              if (edit) {
                if (typeof image === "string") {
                  setDeletedImageUrl(image);
                } else {
                  setDeletedImageUrl(index);
                }
              } else {
                setDeletedImageUrl(index);
              }
            }}
          >
            x
          </button>
        </div>
      ) : image?.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        image?.name?.endsWith(".xlsx") ? (
        <div className="position-relative">
          {/* Add appropriate icon/image for Excel document */}
          <img
            className={Style.lead_img + " w-100 image-object"}
            src="/images/excelimage.png"
            alt="Excel Document"
          />
          <button
            type="button"
            disabled={view}
            className={Style.img_del_btn}
            onClick={() => {
              setShowdelete(true);
              if (edit) {
                if (typeof image === "string") {
                  setDeletedImageUrl(image);
                } else {
                  setDeletedImageUrl(index);
                }
              } else {
                setDeletedImageUrl(index);
              }
            }}
          >
            x
          </button>
        </div>
      ) : (
        <div className="position-relative">
          <img
            className={Style.lead_img + " w-100 image-object"}
            src={URL.createObjectURL(image)}
            alt="Selected Image Preview"
          />
          <button
            type="button"
            disabled={view}
            className={Style.img_del_btn}
            onClick={() => {
              setShowdelete(true);
              if (edit) {
                if (typeof image === "string") {
                  setDeletedImageUrl(image);
                } else {
                  setDeletedImageUrl(index);
                }
              } else {
                setDeletedImageUrl(index);
              }
            }}
          >
            x
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageDisplay;
