const capitalizeFirstLetterOfEachWord = (str) => {
  return str?.replace(/\b\w/g, function (char) {
    return char?.toUpperCase();
  });
};

export default capitalizeFirstLetterOfEachWord;



export const pickFirstLetters = (name) => {
  const words = name?.split(" ");
  const initials = words?.map((word) => word[0]?.toUpperCase()).join("");
  return initials;
};


export const getUniqueTag = (tags) => {
  let uniqueTags = new Set();

  const result = tags.filter(tag => {
    const keyValuePair = `${tag.label}:${tag.value}`; // Access the fields directly

    if (uniqueTags.has(keyValuePair)) {
      return false; // Duplicate found, filter it out
    } else {
      uniqueTags.add(keyValuePair); // Add unique combination to the set
      return true; // Keep the unique tag
    }
  });


  return result;
}


export const slugToLabel = (slug) => {
  return slug
    .split('-') // Split the slug by hyphens
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back with spaces
};



// export const removeNumericBeforeComma = (sentence) => {
//   // This regex matches any numeric value followed by a comma at the start of the sentence
//   return sentence.replaceAll(/\d+/g, '').replaceAll(" ,", ",").replaceAll("  ", " ");
// }


export const removeNumericBeforeComma = (sentence) => {
  return sentence
    .replace(/\b\d+(st|nd|rd|th)?\b/gi, '')
    .replace(/\d+/g, '')
    .replace(/\s+/g, ' ')
    .replaceAll(" ,", ",")
    .replaceAll("  ", " ")
    .trim();
}