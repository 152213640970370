import { useState } from "react";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";

const useHandleData = () => {
  const [contactData, setContactData] = useState(null);
  const [allContactCount, setAllContactCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [allAssignUser, setallAssignUser] = useState([]);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [ContactTagData, setContactTagData] = useState(null)
  
  const fetchContactList = async (page, rowPerPage, searchValue, branch) => {
    setIsLoading(true);
    var params = {
      page: page,
      limit: rowPerPage,
      searchValue: searchValue,
      branch, branch
    };
    try {
      const response = await Axios.get(`/contact/list`, { params });
      setContactData(response?.data?.data || []);
      setAllContactCount(response?.data?.total || 0);
    } catch (error) {
      Toast(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const changeRoleLabel = (val) => {
    if (val === "companyadmin") {
      return "Company Admin";
    } else if (val === "agent") return "Agent";
    else if (val === "companymanager") {
      return "Company Manager";
    }
  };

  const convertArrayToLabelValue = (array) => {
    return array?.map((item) => ({
      label: `${item?.name} ( ${changeRoleLabel(item?.userType)} )`,
      value: item?._id,
    }));
  };

  const fetchAssignUsers = async () => {
    try {
      const response = await Axios.get(`/user/get-users`);
      if (response?.data?.success) {
        setallAssignUser(response?.data?.data || []);
      }
    } catch (error) {
      Toast(error, "error");
    }
  };

  const addContact = async (formValues, toggleDrawer, fetchContactList) => {
    try {
      setIsAddLoading(true);
      console.log('formValues', formValues);

      const formData = new FormData();
      for (const key in formValues) {
        if (Object.prototype?.hasOwnProperty?.call(formValues, key)) {
          const value = formValues[key];
          if (key === "documents") {
            for (const imgVal in formValues["documents"]) {
              formData.append("documents", formValues.documents[imgVal]);
            }
          } else if (key === "category") {
            for (const categoryVal in formValues["category"]) {
              formData.append("category", formValues.category[categoryVal]);
            }
          } else if (key === "createdBy" || key === "assignHistory") { }
          else if (key === "tags") {
            formValues["tags"].forEach((tag, index) => {
              formData.append(`tags[${index}]`, tag);
            });
          } else {
            formData.append(key, value);
          }
        }
      }
      const createData = await Axios.post(`/contact/create`, formData);
      if (createData?.data?.success) {
        Toast(createData?.data?.message, "success");
        toggleDrawer();
        fetchContactList();
      }
    } catch (error) {
      Toast(error, "error");
    } finally {
      setIsAddLoading(false);
    }
  };

  const updateContact = async (
    formValues,
    contactId,
    toggleDrawer,
    fetchContactList
  ) => {
    try {
      setIsAddLoading(true);
      const formData = new FormData();
      for (const key in formValues) {
        if (Object.prototype?.hasOwnProperty?.call(formValues, key)) {
          console.log(key,"key........................");
          const value = formValues[key];
          if (key === "documents") {
            for (const imgVal in formValues["documents"]) {
              formData.append("documents", formValues.documents[imgVal]);
            }
          }
          else if (key === "category") {
            for (const categoryVal in formValues["category"]) {
              formData.append("category", formValues.category[categoryVal]);
            }
          } else if (key === "createdBy" || key === "assignHistory") {
          }
          else if (key === "tags") {
            formValues["tags"].forEach((tag, index) => {
              formData.append(`tags[${index}]`, tag);
            });
          }else if (key === "location") {
            formData.append('location',JSON.stringify(formValues['location']))
          }
        else if (key === "customField") {
          formData.append('customField',JSON.stringify(formValues['customField']))
        }
          else {
            formData.append(key, value);
          }
        }
      }
      formData.append("contactId", contactId);
      const updateData = await Axios.put(`/contact/update`, formData);
      if (updateData?.data?.success) {
        Toast(updateData?.data?.message, "success");
        toggleDrawer();
        fetchContactList();
      }
    } catch (error) {
      Toast(error, "error");
    } finally {
      setIsAddLoading(false);
    }
  };

  const handleDelete = (contactId, fetchContactList, setShowdelete) => {
    setIsDeleting(true);
    Axios.delete(`contact/delete`, {
      data: { contactId: contactId, },
    })
      .then(() => {
        fetchContactList();
        setShowdelete(false);
        Toast('Contact deleted successfully.', "success");
        setIsDeleting(false);
      })
      .catch((err) => {
        Toast(err, "error");
        setIsDeleting(false);
      });
  };

  const handleImageDelete = (url, contactId, setShowdelete) => {
    Axios.post(`contact/file-delete`, {
      image: url,
      chatMetaUserId: contactId
    })
      .then(() => {
        setShowdelete(false);
        Toast('Docuent deleted successfully.', "success");
      })
      .catch((err) => {
        Toast(err, "error");
      });
  };

  const handleSuggestionList = async (chatId) => {
    try {

      const suggestionList = await Axios.get(`/tag/suggestion-list`);
      if (suggestionList?.data?.success) {
        setSuggestionList(suggestionList?.data?.data || []);
      }
    } catch (error) {
      Toast(error, "error");
    }
  };


  const AddContactTagList = async (contactId, ContactMessage) => {
    try {
      // setIsLoading(true);

      const AddContactRes = await Axios.post(`/contact/add-tag`, { contactId: contactId, tag: ContactMessage });

      if (AddContactRes?.data?.success) {
        // setIsLoading(false);
        Toast(AddContactRes?.data?.message, "success");
      } else {
        Toast("Error occured while adding Lead Tags.", "error");
        // setIsLoading(false);
      }
    } catch (err) {
      Toast(err, "error");
      // setIsLoading(false);
    }
  };

  const deleteContactTagList = async (contactId, value) => {
    try {
      var obj = {
        contactId: contactId,
        value: value,
      };

      const DeleteContact = await Axios.put(`/contact/delete-tag`, obj);
      // const filteredData = tags.filter(item => value !== item?.value);
      if (DeleteContact?.data?.success) {
        // setTags(filteredData)
        Toast(DeleteContact?.data?.message, "success");

      } else {
        Toast("Error occured while deleting Lead Tags.", "error");
      }
    } catch (err) {
      Toast(err, "error");
    }
  };

  return {
    fetchContactList,
    convertArrayToLabelValue,
    fetchAssignUsers,
    contactData,
    allContactCount,
    allAssignUser,
    isLoading,
    addContact,
    updateContact,
    isAddLoading,
    handleDelete,
    handleImageDelete,
    isDeleting,
    handleSuggestionList,
    suggestionList,
    setSuggestionList,
    AddContactTagList,
    deleteContactTagList
  };
};
export default useHandleData;
