import React from 'react';
import { Popover, } from '@mui/material';

const ArraowPopover = ({ content, position, anchorPosition, transformPosition, arrowPosition, popupMargin, children }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <div onClick={handleClick}>
                {content}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                disableEnforceFocus // Allow focusing outside the popover
                disableAutoFocus // Prevent automatic focus when opened
                anchorOrigin={anchorPosition || { vertical: 'bottom', horizontal: position || 'center' }}
                transformOrigin={transformPosition || { vertical: 'top', horizontal: position || 'center' }}
                style={popupMargin ? popupMargin : { marginTop: 14 }}
                slotProps={{
                    paper: {
                        sx: {
                            overflow: "visible", boxShadow: "none",
                            "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                ...(arrowPosition ? arrowPosition :
                                    {
                                        top: 0,
                                        right: position ? "80%" : "50%",
                                        borderTop: "2px solid #CBD6E2",
                                        borderLeft: "2px solid #CBD6E2",
                                        background: "#EAF0F6",
                                    }),
                                width: 16,
                                height: 16,
                                transform: "translateY(-50%) rotate(45deg)",
                                boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",

                            },
                        },
                    },
                }}
            >
                <div style={{ minWidth: 200, border: "2px solid #CBD6E2", borderRadius: "4px", boxShadow: "none" }}>

                    {children}

                </div>

            </Popover>
        </div>
    )
}

export default ArraowPopover