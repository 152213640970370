import { Card, CardBody, Table } from "reactstrap";
import React, { useCallback, useEffect, useState } from "react";
// import { UserCard } from "./UserCard";
import BasicPagination from "src/components/custom/pagination";
import TextInput from "src/components/forms/TextInput";
import { debounce } from "src/helper/commonHelp";
import { useSelector } from "react-redux";
import Loader from "src/layouts/loader/Loader";
import style1 from "src/components/apps/ticket/ticket.module.css";
import style2 from "./dataCount.module.css";
import NoDataFound2 from "src/components/custom/NoDataFound2";
import NoUsersFound from "src/assets/images/noDataFound/NoUsersFound.png";
import { useLocation, useNavigate } from "react-router-dom";
import { sourceList } from "src/constants/formPicker";
import DateInput from "src/components/forms/DateInput";
import SelectInput from "src/components/forms/SelectInput";
import Axios from "src/utils/axios";
const AgencyDataCount = () => {
 
  const location=useLocation();
  const agencyId = new URLSearchParams(location.search)?.get("agencyId");
  const [source, setSource] = useState("all");
  const [agency, setAgency] = useState("all");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [counts, setCounts] = useState(null);
  const [agencyList, setAgencyList] = useState([]);
 

  const fetchAgencyList = async () => {
    // setIsLoading(true);
   
    try {
      const response = await Axios.get(`/user/getagencylist`);
      setAgencyList(response?.data?.data)
      
    } catch (error) {
    //   Toast(error, "error");
    //   setIsLoading(false);
    }
  };

  const fetchCountData = async () => {
    // setIsLoading(true);
    let params ={
        agency:agency === "all" ? "" : agency, 
        start:start, 
        end:end, 
        source:source === "all" ? "" : source
    }
    try {
      const response = await Axios.get(`/user/get-agency-data-count`, {params});
      setCounts(response?.data?.data)
      
    } catch (error) {
    //   Toast(error, "error");
    //   setIsLoading(false);
    }
  };

  console.log('count', counts);

  function convertArrayToLabelValue(array) {
    const mappedArr = array?.map((item) => ({ label: item.name, value: item._id }));
    return [{label:"All", value:"all"}, ...mappedArr]
  }
 
  useEffect(
    () => {
        fetchCountData(agency, start, end, source);
    }, // eslint-disable-next-line
    [agency, start, end, source]
  );

  useEffect(() => {

    fetchAgencyList();

  },[])

  return (
    <div className="px-4 mt-3">
      <Card>
        <CardBody>
          <div className="d-md-flex d-block align-item-center justify-content-between mb-3">
            <div className={style1.title}> Agency Count List</div>
            <div className="d-flex align-item-center">
            <div style={{ width:"250px",marginRight: "10px", marginLeft: "20px" }}>
            <div className={style2.date_label} style={{ marginRight: "5px" }}>Agency</div>
                    <SelectInput
                        name="agency"
                        // label="Agency"
                        options={convertArrayToLabelValue(agencyList)}
                        selectedValue={agency}
                        onChange={(e) => {
                        setAgency(e.target.value);
                        }}
                        size={"small"}
                        customClass={style1.source_select}
                        
                    />
                </div>
                <div style={{ width:"250px",marginRight: "10px", marginLeft: "10px" }}>
                <div className={style2.date_label} style={{ marginRight: "5px" }}>Source</div>
                    <SelectInput
                        name="source"
                        // label="Source"
                        options={[{label:"All", value:"all"},...sourceList]}
                        selectedValue={source}
                        onChange={(e) => {
                        setSource(e.target.value);
                        }}
                        size={"small"}
                        
                    />
                </div>
                <div className="d-flex align-items-center">
              <div className="" style={{ marginRight: "10px" }}>
                
                <div className={style2.date_label} style={{ marginRight: "5px" }}>From</div>
                <DateInput
                  label={""}
                  name="startDate"
                  value={start}
                  onChange={(date) => { setStart(date) }}
                  customClass={style2.custom_date + " w-100 "}
                  size={"small"}
                  past="false"
                />
              </div>
              <div className=""  >
                <div className={style2.date_label} style={{ marginRight: "5px" }}>To</div>
                <DateInput
                  label={""}
                  name="endDate"
                  value={end}
                  onChange={(date) => { setEnd(date) }}
                  customClass={style2.custom_date + " w-100 "}
                  size={"small"}
                  filterDate={start}
                /> 
              </div>
            </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="align-middle">
              <thead>
                <tr>
                  <th>Leads</th>
                  <th>Chats</th>
                  <th>Contacts</th>
                </tr>
              </thead>
              { counts  ? (
                <tbody>
                  <tr >
                    <td>{counts?.leads}</td>
                    <td>{counts?.chats}</td>
                    <td>{counts?.contacts}</td>
                    </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="9">
                      <NoDataFound2 width={"180px"} text="No Data Found!" image={NoUsersFound} />
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default AgencyDataCount;
