import Styles from "./DrawerHeading.module.css"
import CloseIcon from '@mui/icons-material/Close';

const DrawerHeading = ({ title, toggleDrawer }) => {
  return (
    <div className={`${Styles.form_heading  } px-3 border-bottom  py-3 d-flex  gap-3 align-items-center`} >
      
      <h4 style={{textTransform:"capitalize"}} className="fw-bolder  ">
        {title}{" "}
        
      </h4>
      <div  onClick={() => toggleDrawer()} className={Styles.modal_right + " cursor-pointer"}>
        <CloseIcon  />
      </div>
    </div>
  );
};

export default DrawerHeading;
