import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Toast from 'src/components/custom/Toast';
import { updateUser } from 'src/store/AuthSlice';
import Axios from 'src/utils/axios';

const useAgencyData = () => {

    const userObj = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
     
    
    const getAgencyDetails = async (setAgency, setStep, setBranchCount) => {
        try {
            let response = await Axios.get(`/user/get-agency-full-details`);
            setAgency(response?.data?.data);
            setBranchCount(response?.data?.data?.branchCount);
            setStep(response?.data?.data?.formStep)
        } catch (error) {
            Toast(error, "error");
            return null;
        }
    }


    const updateAgencyStep = async (step) => {
        setLoading(true);
        try {
          const response=  await Axios.post(`/AccountSetup/update-step`, { step });
            setLoading(false);
            const data = {
                ...userObj,
                departmentType: response?.data?.data?.departmentType,
                departmentRole: response?.data?.data?.departmentRole,
                userCategory: response?.data?.data?.newCategory,
                agencyCategory: response?.data?.agencyCategory,
                accountSetupFormStep: step
              };
              dispatch(updateUser({ data: data }));
        } catch (error) {
            setLoading(false);
            Toast(error, "error");
            return null;
        }
    }

    const updateBackStep = async (step) => {
        setLoading(true);
        try {
            await Axios.post(`/AccountSetup/update-back-step`, { step });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            Toast(error, "error");
            return null;
        }
    }

    return {
        getAgencyDetails,
        updateAgencyStep,
        updateBackStep,
        isLoading,
    }
}

export default useAgencyData;
