import React, { useEffect, useState } from "react";
import Hour from "src/assets/images/accountSetup/hour.svg";
import { getIn } from "formik";

import { Box, Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import Style from "./AccountSetup2.module.css";
import moment from "moment";
import SelectInput from "src/components/forms/SelectInput";
import { WorkingDayOptions } from "src/constants/formPicker";
import ErrorMsg from "src/components/custom/ErrorMsg";
import { RiDeleteBin6Line } from "react-icons/ri";

const getSlot = () => {
    const startTime = moment().set({
      hour: 8,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  
    const endTime = moment().set({
      hour: 20,
      minute: 0, // 30 dif
      second: 0,
      millisecond: 0,
    });
  
    const timeSlots = [{ value: null, label: "Closed" }];
  
    let currentTime = startTime.clone();
    while (currentTime.isSameOrBefore(endTime)) {
      timeSlots.push({
        value: currentTime.format("HH:mm"),
        label: currentTime.format("hh:mm A"),
      });
      currentTime.add(30, "minutes");
    }
    return timeSlots;
  };

const WorkingHours = ({
  workingHours,
  pushWorkingHours,
  removeWorkingHours,
  errors,
  touched,
  handleChange,
  index,
}) => {

  

  return (
    <>
      {workingHours?.map((hoursRow, hourIndex) => (
        <Box sx={{ flexGrow: 1 }} className="mb-3">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3} >
              <SelectInput
                name={`branches[${index}].workingHours[${hourIndex}].day`}
                placeholder="Day"
                size="small"
                options={WorkingDayOptions}
                customClass={Style.hour_custom_select}
                selectedValue={hoursRow?.day}
                
                onChange={(value) => { handleChange(`branches[${index}].workingHours.${hourIndex}.day`)(value);}}
              />
            </Grid>
            <Grid item xs={12} sm={9} md={9}  >
              <Grid container spacing={1} className="align-items-center">
                <Grid item xs={12} sm={1} md={1} > <div className={Style.hour_from_label}>from</div></Grid>
                <Grid item xs={12} sm={3} md={3} >
                  <SelectInput
                    name={`branches[${index}].workingHours[${hourIndex}].opening`}
                    placeholder="Opening"
                    size="small"
                    options={getSlot()}
                    customClass={Style.hour_custom_select}
                    selectedValue={hoursRow?.opening}
                    onChange={(value) => { handleChange(`branches[${index}].workingHours.${hourIndex}.opening`)(value);}}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1} > <div className={Style.hour_from_label}>to</div></Grid>
                <Grid item xs={12} sm={3} md={3} >
                  <SelectInput
                    name={`branches[${index}].workingHours[${hourIndex}].closing`}
                    placeholder="Closing"
                    size="small"
                    options={getSlot()}
                    customClass={Style.hour_custom_select}
                    selectedValue={hoursRow?.closing}
                    onChange={(value) => { handleChange(`branches[${index}].workingHours.${hourIndex}.closing`)(value);}}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1} >
                  <RiDeleteBin6Line style={{color:"red", fontSize:"16px", cursor:"pointer" }} onClick={() => {removeWorkingHours(hourIndex);console.log(hourIndex);}}/>
                </Grid>
              </Grid>
            </Grid> 
          </Grid>
          <div className="mt-2">
            <div className="mb-2">
              <ErrorMsg 
                hasError={getIn(touched, `branches[${index}].workingHours.${hourIndex}.day`) && getIn( errors, `branches[${index}].workingHours.${hourIndex}.day`)}>
                {getIn(errors,`branches[${index}].workingHours.${hourIndex}.day`)}
              </ErrorMsg>
            </div>
            <div className="mb-2">
              <ErrorMsg className="mb-2" hasError={getIn(touched, `branches[${index}].workingHours.${hourIndex}.opening`) &&  getIn( errors, `branches[${index}].workingHours.${hourIndex}.opening`)}>
                  {getIn(errors,`branches[${index}].workingHours.${hourIndex}.opening`)}
              </ErrorMsg>
            </div>
            <div className="mb-2">
              <ErrorMsg
                className="mb-2"
                  hasError={getIn(touched, `branches[${index}].workingHours.${hourIndex}.closing`) &&
                    getIn( errors, `branches[${index}].workingHours.${hourIndex}.closing`)}
                >
                  {getIn(errors,`branches[${index}].workingHours.${hourIndex}.closing`)}
              </ErrorMsg>
            </div>
            
          </div>         
        </Box>
      ))}
      {(errors?.branches && errors?.branches[index]?.workingHours && typeof errors?.branches[index]?.workingHours === 'string') && 
      <div className="mb-2" style={{fontWeight: "500",color: "red", fontSize: "13px"}}>
        {errors?.branches[index]?.workingHours}
      </div>}
      <div className="">
        <button  type="button" className={ Style.add_btn} onClick={() => pushWorkingHours({ day: "",  opening:"", closing:"" })}>
          <AddIcon sx={{color:"#2E66F5", fontSize:"18px", cursor:"pointer" }} />
          <p>Add Hours</p>
        </button>
      </div>
    </>
  );
};

export default WorkingHours;
