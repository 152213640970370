import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Style from "./FormInput.module.css";
import moment from "moment";
import dayjs from "dayjs";

const DateInput = ({
  value,
  onChange,
  label,
  customClass,
  hideLabel,
  showSlotProps,
  disabled,
  past,
  filterDate
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={hideLabel ? "" : label}
        className={customClass ? customClass : Style.modifyDatePicker}
        value={value ? dayjs(value) : value}
        onChange={(val) => {
          onChange(moment(val.$d)?.format("YYYY-MM-DD"));
        }}
        error={false}
        slotProps={
          showSlotProps
            ? {
                inputAdornment: {
                  position: "start",
                },
              }
            : undefined
        }
        variant={"outlined"}
        size={"small"}
        disabled={disabled}
        format='DD/MM/YYYY'
        minDate={past ? "" : filterDate ? dayjs(filterDate) : moment()}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
