import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Axios from "src/utils/axios";
import { appendComments, updateMessages, } from "src/store/apps/chat/MessageSlice";
import Toast from "src/components/custom/Toast";
import useChatUser from "./useChatUser";

const useChatMessage = () => {
  const userObj = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { countChatUsers } = useChatUser();

  const [allAssignUser, setallAssignUser] = useState([]);

  const fetchAssignUsers = async () => {
    try {
      const response = await Axios.get(`/user/get-createdBy-user`);
      if (response?.data?.success) {
        setallAssignUser(response?.data?.data);
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
      Toast(error, "error")
    }
  };

  const handleAssign = async (selectedUser, id, handleUserChatList) => {
    Axios.post("/botChat/assignUser", {
      assignTo: selectedUser,
      chatConversationId: id,
    })
      .then((res) => {
        handleUserChatList();
        countChatUsers();
        Toast("Chat assigned successfully.", "success");
      })
      .catch((error) => {
        Toast(error, "error");
      });
  };

  const addComment = async (chatId, comment) => {
    try {
      var obj = {
        chatId: chatId,
        comment: comment,
      };
      const addComment = await Axios.post(`/chatuser/add-comment`, obj);

      if (addComment?.data?.success) {
        const saveCommentObj = {
          comments: addComment?.data?.comment,
          action: "save",
        };
        saveCommentObj.comments.createdBy = { name: userObj?.name };
        dispatch(updateMessages(addComment?.data?.comment?.messageId));
        dispatch(appendComments(saveCommentObj));
      } else {
        Toast("Error occured while adding comment.", "error");
      }
    } catch (err) {
      Toast(err, "error");
    }
  };

  return {
    fetchAssignUsers,
    allAssignUser,
    addComment,
    handleAssign,
  };
};
export default useChatMessage;
