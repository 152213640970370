import React, { useState } from 'react'
import Toast from 'src/components/custom/Toast';
import Axios from 'src/utils/axios';

const usePropertyList = () => {
  const [propertyData, setpropertyData] = useState(null)
  const [Loading, setIsLoading] = useState(false);
  const [propertyInfo, setpropertyInfo] = useState(null)
  const [placesData, setPlacesData] = useState([]);
  const [propertyAddress, setpropertyAddress] = useState(null)
  const [contactData, setContactData] = useState(null);
  const [PropertyDescriptionData, setPropertyDescriptionData] = useState(null)
  const [LeetingFeesData, setLeetingFeesData] = useState(null)
  const [MasterListData,setMasterListData] = useState(null)
  const [StatusData,setStatusData] = useState(null)
  const [FeatureList,setFeatureList] = useState(null);
  const [singleData,setSingleData] = useState(null);
  const [ReferenceId,setReferenceId] = useState(null);
  const PropertyFormDetails = async (values) => {
    setIsLoading(true);
    try {
      const response = await Axios.post(`/property/create`, values);
      if (response?.data?.success) {
        setpropertyData(response?.data?.data?._id);
        setReferenceId(response?.data?.data);
        // Toast(response?.data?.message, "success");
      }

    } catch (error) {
      Toast(error, "error");
    } finally {
      setIsLoading(false);
    }
  };


  const PropertyInfromationService = async (values, id) => {
    setIsLoading(true);
    try {
      const response = await Axios.put(`/property/create?stepCompleted=${id}`, values);
      if (response?.data?.success) {
        Toast(response?.data?.message, "success");
        setpropertyInfo(response?.data?.data?._id)
      }
    } catch (error) {
      Toast(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const PropertyAddressService = async (values, id) => {
    setIsLoading(true);
    try {
      const response = await Axios.put(`/property/create?stepCompleted=${id}`, values);
      if (response?.data?.success) {
        Toast(response?.data?.message, "success");
        setpropertyAddress(response?.data?.data?._id)
      }
    } catch (error) {
      Toast(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const PropertyDescriptionService = async (values, id) => {
    setIsLoading(true);
    try {
      const response = await Axios.put(`/property/create?stepCompleted=${id}`, values);
      if (response?.data?.success) {
        Toast(response?.data?.message, "success");
        setPropertyDescriptionData(response?.data?.data?._id)
      }
    } catch (error) {
      Toast(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const PropertyLettingFeeservice = async (values, id) => {
    setIsLoading(true);
    try {
      const response = await Axios.put(`/property/create?stepCompleted=${id}`, values);
      if (response?.data?.success) {
        Toast(response?.data?.message, "success");
        setLeetingFeesData(response?.data?.data?._id)
      }
    } catch (error) {
      Toast(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const PropertyPictureService = async (values, id) => {
    setIsLoading(true);
    try {
      const response = await Axios.put(`/property/create?stepCompleted=${id}`, values);
      Toast(response?.data?.message, "success");
    } catch (error) {
      Toast(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPlacesList = (place) => {
    Axios.get(`/places/address-autocomplete?text=${place}`)
      .then((res) => {
        setPlacesData(res?.data?.data);
      })
      .catch((err) => {
        setPlacesData([]);
        Toast(err, "error");
        // setError(err);
      });
  };



  const handleImageDelete = (url, contactId, setShowdelete) => {
    Axios.post(`contact/file-delete`, {
      image: url,
      chatMetaUserId: contactId
    })
      .then(() => {
        setShowdelete(false);
        Toast('Docuent deleted successfully.', "success");
      })
      .catch((err) => {
        Toast(err, "error");
      });
  };

  const fetchContactList = async (
    category
  ) => {
    var params = {
      category: category,
    };
    try {
      const response = await Axios.get(`/contact/contact-select-list`, { params });
      setContactData(response?.data?.data || []);
    } catch (error) {
      Toast(error, "error");
    }
  };

  const fetchMasterList = (value,flag) => {
    Axios.get(`/property/master?type=${value}&flag=${flag}`)
      .then((res) => {
        setMasterListData(res?.data?.data);
      })
      .catch((err) => {
        // setPlacesData([]);
        Toast(err, "error");
        // setError(err);
      });
  };

  const fetchStatusList = (value,flag) => {
    Axios.get(`/property/master?type=${value}&flag=${flag}`)
      .then((res) => {
        setStatusData(res?.data?.data);
      })
      .catch((err) => {
        // setPlacesData([]);
        Toast(err, "error");
        // setError(err);
      });
  };

  const fetchFeaturesList = (value) => {
    Axios.get(`/property/master?type=${value}`)
      .then((res) => {
        setFeatureList(res?.data?.data);
      })
      .catch((err) => {
        // setPlacesData([]);
        Toast(err, "error");
        // setError(err);
      });
  };

  const fetchSingleListId = (value) => {
    Axios.get(`/property/details?propertyId=${value}`)
      .then((res) => {
        setSingleData(res?.data?.data);
      })
      .catch((err) => {
        Toast(err, "error");
      });
  };




  return {
    propertyData,
    setpropertyData,
    PropertyFormDetails,
    Loading,
    setIsLoading,
    PropertyInfromationService,
    propertyInfo,
    PropertyAddressService,
    setPlacesData,
    placesData,
    fetchPlacesList,
    propertyAddress,
    PropertyDescriptionService,
    handleImageDelete,
    fetchContactList,
    contactData,
    PropertyPictureService,
    PropertyDescriptionData,
    LeetingFeesData,
    PropertyLettingFeeservice,
    fetchMasterList,
    MasterListData,
    fetchStatusList,
    StatusData,
    fetchFeaturesList,
    FeatureList,
    fetchSingleListId,
    singleData,
    ReferenceId,
    setReferenceId,
    setSingleData
  }
}

export default usePropertyList