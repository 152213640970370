const NameImage = ({ name, height, width, fontSize }) => {
  const pickFirstLetters = (name) => {
    const words = name?.split(" ");
    const initials = words?.map((word) => word[0]?.toUpperCase()).join("");
    return initials;
  };
  const darkColors = [
    "#ec407a",
    "#8d6e63",
    "#0288d1",
    "#ab47bc",
    "#f4511e",
    "#00897b",
    "#689f38",
    "#f6d776",
  ];

  // Function to pick a random dark color
  function pickColorByFirstLetter(str) {
    const firstLetter = str?.toString()?.charAt(0)?.toLowerCase();
    const index = firstLetter?.charCodeAt(0) % darkColors?.length;
    return darkColors[index] || "black";
  }

  return (
    <div
      style={{
        width: width || "2rem",
        height: height || "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        background: `${pickColorByFirstLetter(name)}30`,
        color: pickColorByFirstLetter(name),
        marginRight: "0.35rem",
        fontSize: fontSize || "1.1rem",
        fontWeight: 600,
        fontFamily: "Inter",
        lineHeight:"2px"
      }}
    >
      {pickFirstLetters(name)}
    </div>
  );
};

export default NameImage;
