import * as React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { FaRegCopy, FaTimes } from "react-icons/fa";
import styles from "./Models.module.css";
import { useEffect } from "react";
import { useState } from "react";
import Axios from "../../../utils/axios";
import Toast from "../Toast";
import { ButtonLoader } from "../../forms/ButtonLoader";
import { toast } from "react-toastify";

const ConnectJinniBotModal = ({
  open,
  closeModal,
  setUserdata,
  userdata,
  checkIntegration,
  chatbotId,
  chatBotData,
  connectChatbotChannels,
  setchatBotData
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };
  const [ChatbotData, setChatbotData] = useState();

  useEffect(() => {
    Axios.get(`/domainKey/viewKey`)
      .then((res) => {
        setChatbotData(res?.data?.data);
      })
      .catch((error) => {
        Toast(error, "error");
      });
  }, []);

  const handleCopyClick = (id) => {
    var copyText = document.getElementById(id).innerText;

    try {
      navigator.clipboard.writeText(copyText);
      Toast("Copied!", "success");
    } catch (err) {
      Toast("Unable to copy text to clipboard", "error");
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const connectBot = () => {
    setIsLoading(true);
    Axios.post("/domainKey/connectChatBot", {
      connectChatBot: !userdata?.integrationKeys.chatbot.isActive,
    })
      .then((res) => {
        setIsLoading(false);
        closeModal();
        setUserdata({
          integrationKeys: res?.data?.data?.integrationKeys,
          subscription: res?.data?.data?.subscription[0],
        });
        checkIntegration({
          integrationKeys: res?.data?.data?.integrationKeys,
          subscription: res?.data?.data?.subscription,
        });

        if (!userdata?.integrationKeys.chatbot.isActive) {
          let updatedChannels = [...chatBotData?.channels, "chatBot"];
          connectChatbotChannels(updatedChannels, chatbotId, setchatBotData);
        }

      })
      .catch((err) => {
        toast(err, "error");
        closeModal();
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal_box" style={{ textAlign: "center" }}>
          <div
            style={{ fontSize: "1.6rem", fontWeight: "600" }}
            className="mb-2 d-flex justify-content-between"
          >
            Connect Jinni chat bot in your website{" "}
            <span
              className="pointer"
              style={{ cursor: "pointer" }}
              onClick={closeModal}
            >
              <FaTimes />
            </span>
          </div>
          <div className={styles.connectbotStartheadline}>
            Please insert the provided code into the HTML{" "}
            <strong> body tag</strong> on your website, ensuring your bot ID
            remains linked to your domain.
          </div>

          <div className={styles.CoonectbotblackBacground + " mt-2 p-2 mb-3"}>
            <div style={{ textAlign: "right" }}>
              <span
                onClick={() => handleCopyClick("script1")}
                className={styles.copyButtonCode}
              >
                <FaRegCopy />
              </span>
            </div>
            <div>
              <span id="script1">
                {`<react-counter id="react-counter"bot_id="${ChatbotData?.encryptedKey}"> </react-counter>`}
                <br />
                {`<script src="https://dze9g4ipzy6n3.cloudfront.net/static/js/main.min.js"></script>`}
              </span>
            </div>
          </div>

          <div className={styles.connectbotStartheadline}>
            Please insert the provided code into the <strong> head tag</strong>.
          </div>

          <div className={styles.CoonectbotblackBacground + " mt-2 p-2"}>
            <div style={{ textAlign: "right" }}>
              <span
                onClick={() => handleCopyClick("script3")}
                className={styles.copyButtonCode}
              >
                <FaRegCopy />
              </span>
            </div>
            <span id="script3">
              {`<link rel="stylesheet" href="https://dze9g4ipzy6n3.cloudfront.net/static/css/main.min.css"/>`}
            </span>
          </div>

          <ButtonLoader
            buttonName={
              userdata?.integrationKeys.chatbot.isActive
                ? "Disconnect"
                : "Connect"
            }
            isLoading={isLoading}
            loaderColor={"white"}
            style={{
              marginLeft: "12px",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
            className="btn btn-primary px-4 mx-3 mt-2"
            onClickhandle={connectBot}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default ConnectJinniBotModal;
