import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";
import { InputLabel, FormControl } from "@mui/material";
import Style from 'src/components/forms/FormInput.module.css'

export default function MultiSelect2({
  customClass,
  options,
  value,
  onSelectionChange,
  label,
  size,
  icon,
  placeholder,
}) {
  const menuStyle = { maxHeight: "400px" };

  const [selectedOptions, setSelectedOptions] = useState(options.map((option) => option.value) || []);

  const handleChange = (event) => {
    const {
      target: { value: selectedValues },
    } = event;

    if (selectedValues.includes("selectAll")) {
      const isSelectAllSelected = selectedOptions.length === options.length;

      if (isSelectAllSelected) {
        setSelectedOptions([]);
        onSelectionChange([]);
      } else {
        const allOptionValues = options.map((option) => option.value);
        setSelectedOptions(allOptionValues);
        onSelectionChange(allOptionValues);
      }
    } else {
      setSelectedOptions(selectedValues);
      onSelectionChange(selectedValues);
    }
  };

  useEffect(() => {
    if (value && value.length > 0) {
      setSelectedOptions(value);
    } else {
      // const allOptionValues = options.map((option) => option.value);
      // setSelectedOptions(allOptionValues);
      // onSelectionChange(allOptionValues);
      
    }
  }, [value]);

  return (
    <FormControl fullWidth size={size ? size : "small"}>
      <InputLabel id="demo-simple-select-label" sx={{ fontSize: "0.9rem" }}>
        {label ? label : ""}
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={(selected) => {
          const selectedLabels = selectedOptions.map((value) => {
         
            const option = options.find((opt) => opt.value == value);
           
            return option ? option.label : value;
          });
          return selectedLabels.join(', ');
        }}
        className={`${customClass}` || "w-100"}
        MenuProps={{ style: menuStyle }}
        required
        sx={{
          ".MuiSelect-select": {
            padding: "2px 14px",
          },
        }}
        size="small"
      >
        <MenuItem key="selectAll" value="selectAll">
          <Checkbox
            style={{ padding: "0px 10px 0px 0px" }}
            checked={selectedOptions.length === options.length}
          />
          <ListItemText className="menu-item" primary="Select All" />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox
              style={{ padding: "0px 10px 0px 0px" }}
              checked={selectedOptions.includes(option.value)}
            />
            <ListItemText className="menu-item" primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
