import { useEffect } from "react";
import ChatbotProfile from "src/views/chatbotBuilder/ChatbotProfile";
import useChatbotTree from "src/views/chatbotBuilder/hooks/useChatbotTree";
import useConnectChannels from "src/views/connectChat/hooks/useConnectChannels";

const Profile = ({ handleNext, btnLoading, isSetup, handleBack }) => {
    const { userdata, getUserListData, isLoading } = useConnectChannels();
    const { getChatbotTreeByUser, chatBotData, setchatBotData } = useChatbotTree();

    useEffect(() => {
        getChatbotTreeByUser();
    }, []);

    return <div className="pb-5" style={isSetup ? { backgroundColor: "whitesmoke" } : {}} >
        <ChatbotProfile chatBotData={chatBotData} chatbotId={chatBotData?._id} setchatBotData={setchatBotData} getUserListData={getUserListData} isLoading={isLoading} isAccountSetup={true} isSetup={isSetup} handleNext={handleNext} btnLoading={btnLoading} handleBack={handleBack} />
    </div>
}
export default Profile;