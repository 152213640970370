import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Style from './AddProperty.module.css';
import questionImg from 'src/assets/images/property/questionImg.svg';
import SelectInput from 'src/components/forms/SelectInput';
import CommonCheckbox from 'src/components/forms/CommonCheckbox';
import DateInput from 'src/components/forms/DateInput';
import TimePickerInput from 'src/components/forms/TimePicker';
import TextInput from 'src/components/forms/TextInput';
import AstericSymbol from 'src/components/custom/astericSymbol';
import FormGroup from '@mui/material/FormGroup';
import ErrorMsg from "src/components/custom/ErrorMsg";
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  ButtonLoader,
} from "src/components/forms/ButtonLoader";
import SelectWithSearch from "src/components/forms/SelectWithSearch";
import { optionProperty, CommercialPropertyDetail, PaymentFrequencyOptions, EPCRating, LocalAuthorityBusiness, optioncurrentOccupant, optioncurrentLet, CurrencyData } from "src/constants/formPicker";


const PropertyDescription = ({ handleNext, handleBack, propertyAddress, PropertyDescriptionService, propertyCategory, listingType, fetchSingleListId, singleData

}) => {
  useEffect(() => {
    if (singleData?.epcRating || singleData?.serviceCharge) {
      formik.setFieldValue('epcRating', singleData?.epcRating?.epcTitle);
      formik.setFieldValue('epcValue', singleData?.epcRating?.epcValue);
      formik.setFieldValue('isExemptFromEPC', singleData?.isExemptFromEPC);
      formik.setFieldValue('offerClosingDate', singleData?.offerClosingDate);
      formik.setFieldValue('localAuthority', singleData?.localAuthority);
      formik.setFieldValue('councilTax', singleData?.councilTax);
      formik.setFieldValue('viewingAgreement', singleData?.viewingAgreement);
      formik.setFieldValue('additionalInformation', singleData?.additionalInformation);
      formik.setFieldValue('currentOccupant', singleData?.currentOccupant);
      formik.setFieldValue('propertyDescription', singleData?.propertyDescription);
      formik.setFieldValue('epcExemptionReason', singleData?.epcExemptionReason);
      formik.setFieldValue('ServiceChnargeCurrency', singleData?.serviceCharge?.currency);
      formik.setFieldValue('ServiceChnargeCurrencyPrice', singleData?.serviceCharge?.amount);
      formik.setFieldValue('frequency', singleData?.serviceCharge?.frequency);
      formik.setFieldValue('RelatedPrice', singleData?.relatedValue?.amount);
      formik.setFieldValue('RelatedCurrency', singleData?.relatedValue?.currency);
      formik.setFieldValue('LegalCost', singleData?.legalCosts?.amount);
      formik.setFieldValue('LegalCurrency', singleData?.legalCosts?.currency);
      formik.setFieldValue('Investment', singleData?.investmentYield);
      formik.setFieldValue('ClassOfUse', singleData?.classOfUse);
      formik.setFieldValue('Accommodation', singleData?.accommodation);
      formik.setFieldValue('Services', singleData?.services);
      formik.setFieldValue('Parking', singleData?.parking);
      formik.setFieldValue('DashboardNote', singleData?.dashboardNote);

      if ((propertyCategory === "residential" && listingType === "sell")) {
        formik.setFieldValue('salesClassified', singleData?.salesClassified);
      }


    }
  }, [singleData])

  const validationSchema =
    Yup.object({
      salesClassified: (propertyCategory === "residential" && listingType === "sell")
        ? Yup.string().required("Sales classified is required.").test(
          "not-start-with-space",
          "Reference cannot start with a space.",
          (value) => {
            return !value || !value.startsWith(" ");
          }
        ) : Yup.string(),

      epcValue: Yup.string().when("isExemptFromEPC", {
        is: false, // Check if isExemptFromEPC is false (i.e., EPC is required)
        then: () => Yup.string().required("EPC value is required."), // EPC value is required when isExemptFromEPC is false
        otherwise: () => Yup.string().notRequired(), // Not required when isExemptFromEPC is true
      }),
      propertyDescription: Yup.string().required("Property description  is required."),

    });



  const formik = useFormik({
    initialValues: {
      salesClassified: "",
      epcRating: "",
      epcValue: "",
      offerClosingDate: null,
      timeFrom: '',
      councilTax: "",
      localAuthority: "",
      isExemptFromEPC: false,
      viewingAgreement: "",
      additionalInformation: "",
      propertyDescription: "",
      currentOccupant: "",
      ServiceChnargeCurrency: "Pound",
      ServiceChnargeCurrencyPrice: "",
      frequency: "",
      isRentIncludesVat: "",
      RelatedCurrency: "Pound",
      RelatedPrice: "",
      LegalCurrency: "Pound",
      LegalCost: "",
      ClassOfUse: "",
      Investment: "",
      Accommodation: "",
      Parking: "",
      Services: "",
      epcExemptionReason: "",
      DashboardNote: "",
      currentTenant: "No"


    },
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const epcRating = {
        epcTitle: values?.epcRating,
        epcValue: values?.epcValue,
      }
      const serviceCharge = {
        frequency: values.frequency,
        currency: values.ServiceChnargeCurrency,
        amount: values.ServiceChnargeCurrencyPrice
      }
      const relatedValue = {
        currency: values.RelatedCurrency,
        amount: values.RelatedPrice,
      }
      const legalCosts = {
        currency: values.LegalCurrency,
        amount: values.LegalCost
      }
      handleNext()
      const formData = new FormData();
      formData.append("epcRating", JSON.stringify(epcRating));
      formData.append("councilTax", values.councilTax);
      formData.append("localAuthority", values.localAuthority);
      formData.append("isExemptFromEPC", values.isExemptFromEPC);
      formData.append("viewingAgreement", values.viewingAgreement);
      formData.append("additionalInformation", values.additionalInformation);
      formData.append("propertyDescription", values.propertyDescription);
      formData.append("currentOccupant", values.currentOccupant);
      formData.append("investmentYield", values?.Investment);
      formData.append("classOfUse", values.ClassOfUse);
      formData.append("Accommodation", values.Accommodation);
      formData.append("parking", values.Parking);
      formData.append("services", values.Services);
      formData.append("epcExemptionReason", values.epcExemptionReason);
      formData.append("dashboardNote", values.DashboardNote);
      formData.append("serviceCharge", JSON.stringify(serviceCharge));
      formData.append("relatedValue", JSON.stringify(relatedValue));
      formData.append("legalCosts", JSON.stringify(legalCosts));
      formData.append("propertyId", propertyAddress);
      formData.append("currentTenant", values.currentTenant);
      formData.append("offerClosingDate", values.offerClosingDate);
      if (propertyCategory === "residential" && listingType === "sell") {
        formData.append("salesClassified", values.salesClassified);
      }
      PropertyDescriptionService(formData, 4);
    },
  });



  const handleDateChange = (date) => {
    formik.setFieldValue('offerClosingDate', date);
    formik.setFieldValue('timeFrom', null);
  };

  const handleBackButton = () => {
    fetchSingleListId(propertyAddress);
    handleBack()
  }

  function TooltipSpilitName(name, maxLength) {
    if (name.length > maxLength) {
      return name.substring(0, maxLength - 3) + "...";
    } else {
      return name;
    }
  }

  const handleCheckReason = (e) => {
    const isExempt = e.target.checked;
    if (isExempt) {
      formik.setFieldValue('isExemptFromEPC', e.target.checked);
      formik.setFieldValue('epcValue', '');
      formik.setFieldValue('epcRating', '');
    } else {
      formik.setFieldValue('isExemptFromEPC', '');
    }
    formik.setFieldValue('epcExemptionReason', '');
    formik.setFieldValue('isExemptFromEPC', e.target.checked);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ padding: '10px 40px' }}>
        <Box className={Style.lisitngDetailHeader}>
          <Box>
            <Typography variant="h6" className={Style.listDetailsFont}>
              Property Details
            </Typography>
          </Box>
          <Box>
            <div className={Style.propertycss1 + " " + Style.button_nav}>
              <div style={{ marginRight: '12px' }}>
                <Button color="secondary"
                  className={Style.delete_btn + " " + Style.edit_btn} onClick={handleBackButton}>
                  <KeyboardArrowLeftIcon /> Back
                </Button>
              </div>
              <div>
                <ButtonLoader
                  type={"submit"}
                  buttonName="Next"
                  loaderColor={"white"}
                  className={Style.Add_btn}
                  onClickhandle={formik.handleSubmit}
                  icon={<KeyboardArrowRightIcon />}

                />

              </div>
            </div>
          </Box>
        </Box>
        <hr />
        <Grid container spacing={2}>
          {(propertyCategory === "commercial" && listingType === "let") ?
            <Grid item xs={12} sm={12} md={12} className="pt-4">
              <FormGroup>
                <CommonCheckbox
                  label={
                    <>
                      Rent Includes VAT?{' '}

                      <Tooltip title={TooltipSpilitName("Has the landlord opted to tax the property for VAT purposes?", 150)} placement="top">
                        <img src={questionImg} alt="Info" />
                      </Tooltip>
                    </>
                  }
                  checked={formik.values.isRentIncludesVat}
                  onChange={(e) => formik.setFieldValue('isRentIncludesVat', e.target.checked)}
                />
              </FormGroup>

            </Grid> : ""}

          {(propertyCategory === "commercial" && listingType === "let") ?
            <>
              <Grid item xs={12} sm={12} md={12} className="pt-0">
                <label className={Style.labelform}>Service Charge</label>
              </Grid>

              <Grid item xs={12} sm={12} md={1} className="pt-0">
                <div className="w-100">
                  <SelectInput
                    name="ServiceChnargeCurrency"
                    options={CurrencyData}
                    selectedValue={formik.values.ServiceChnargeCurrency}
                    onChange={(e) => formik.setFieldValue("ServiceChnargeCurrency", e.target.value)}
                    size="small"
                    customClass={Style.custom_select}
                    sx={{ backgroundColor: "#F5F8FA" }}
                    noOptionText="No template found."
                    placeholder="£"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={5} className="pt-0">
                <TextInput
                  customClass={`${Style.input_custom} w-100`}
                  type="text"
                  name="ServiceChnargeCurrencyPrice"
                  variant="outlined"
                  placeholder="Enter service charge"
                  size="small"
                  value={formik.values.ServiceChnargeCurrencyPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="pt-0">
                <div className="w-100">
                  <SelectInput
                    name="frequency"
                    options={PaymentFrequencyOptions}
                    selectedValue={formik.values.frequency}
                    onChange={(e) => formik.setFieldValue("frequency", e.target.value)}
                    size="small"
                    customClass={Style.custom_select}
                    sx={{ backgroundColor: "#F5F8FA" }}
                    noOptionText="No template found."
                    placeholder="Select"
                  />
                </div>
              </Grid>

            </> : ""}
          {propertyCategory === "commercial" ? (
            <>
              <Grid item xs={12} sm={12} md={12} className="pt-0">
                <label className={Style.labelform}>Rateable value in place of</label>
              </Grid>
              <Grid item xs={12} sm={12} md={1} className="pt-0">
                <div className="w-100">
                  <SelectInput
                    name="RelatedCurrency"
                    options={CurrencyData}
                    selectedValue={formik.values.RelatedCurrency}
                    onChange={(e) => formik.setFieldValue("RelatedCurrency", e.target.value)}
                    size="small"
                    customClass={Style.custom_select}
                    sx={{ backgroundColor: "#F5F8FA" }}
                    noOptionText="No template found."
                    placeholder={"£"}

                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={11} className="pt-0">
                <TextInput
                  customClass={`${Style.input_custom} w-100`}
                  type="text"
                  name="RelatedPrice"
                  variant="outlined"
                  placeholder="Price"
                  size="small"
                  value={formik.values.RelatedPrice}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^\d*$/.test(value)) {
                      formik.setFieldValue("RelatedPrice", value);
                    }
                  }}
                />
              </Grid>
            </>
          ) : propertyCategory === "residential" && listingType === "let" ? (
            null
          ) : <>
            <Grid item xs={12} sm={12} md={12}>
              <label className={Style.labelform}>
                Sales classified <AstericSymbol />
              </label>
              <div className="w-100">
                <SelectInput
                  name="salesClassified"
                  options={optionProperty}
                  selectedValue={formik.values.salesClassified}
                  onChange={(e) => formik.setFieldValue("salesClassified", e.target.value)}
                  size="small"
                  customClass={Style.custom_select}
                  sx={{ backgroundColor: "#F5F8FA" }}
                  placeholder={"Select"}
                />
                <ErrorMsg
                  hasError={formik.touched.salesClassified && formik.errors.salesClassified}
                >
                  {formik.errors.salesClassified}
                </ErrorMsg>
              </div>
            </Grid>
          </>}


          {propertyCategory != "commercial" ? "" : <>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <label className={Style.labelform}>
                Legal Cost
              </label>
            </Grid><Grid item xs={12} sm={12} md={1} className="pt-0">
              <div className="w-100">
                <SelectInput
                  name="LegalCurrency"
                  options={CurrencyData}
                  selectedValue={formik.values.LegalCurrency}
                  onChange={(e) => formik.setFieldValue("LegalCurrency", e.target.value)}
                  size={"small"}
                  customClass={Style.custom_select}
                  sx={{ backgroundColor: "#F5F8FA" }}
                  noOptionText={"No template found."}
                  placeholder={"£"} />
              </div>

            </Grid><Grid item xs={12} sm={12} md={11} className="pt-0">

              <TextInput
                customClass={Style.input_custom + " w-100 "}
                type="text"
                name="LegalCost"
                variant="outlined"
                placeholder="Price"
                size={"small"}
                value={formik.values.LegalCost}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  const { value } = e.target;
                  if (/^\d*$/.test(value)) {
                    formik.setFieldValue("LegalCost", value);
                  }
                }}
              />
            </Grid></>}
          {propertyCategory != "commercial" ? "" : <>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <label className={Style.labelform}>
                Investment Yield (%)
              </label>
              <TextInput
                name="Investment"
                value={formik.values.Investment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                customClass={Style.input_custom + ' w-100'}
                type="text"
                placeholder=" Investment Yield"
                size="small"
              />

            </Grid>
          </>}

          {propertyCategory != "commercial" ? "" : <>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <label className={Style.labelform}>
                Class Of Use
              </label>
              <div className="w-100">
                <SelectInput
                  name=" ClassOfUse"
                  options={CommercialPropertyDetail}
                  selectedValue={formik.values.ClassOfUse}
                  onChange={(e) => formik.setFieldValue('ClassOfUse', e.target.value)}
                  size="small"
                  customClass={Style.custom_select}
                  sx={{ backgroundColor: '#F5F8FA' }}
                  placeholder="Select  Class Of Use"
                />

              </div>
            </Grid>
          </>}

          {propertyCategory != "commercial" ? "" : <>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <label className={Style.labelform}>Accommodation</label>
              <textarea
                rows="3"
                name="Accommodation"
                placeholder="Accommodation.."
                className={Style.custom_textarea + " w-100"}
                style={{ backgroundColor: '#F5F8FA', border: '1px solid #CBD6E2' }}
                value={formik.values.Accommodation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>

            </Grid>
          </>}
          {propertyCategory != "commercial" ? "" : <>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <label className={Style.labelform}>Parking</label>
              <textarea
                rows="3"
                name="Parking"
                placeholder="Parking.."
                className={Style.custom_textarea + " w-100"}
                style={{ backgroundColor: '#F5F8FA', border: '1px solid #CBD6E2' }}
                value={formik.values.Parking}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>

            </Grid>
          </>}
          {propertyCategory != "commercial" ? "" : <>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <label className={Style.labelform}>Services</label>
              <textarea
                rows="3"
                name="Services"
                placeholder="Services.."
                className={Style.custom_textarea + " w-100"}
                style={{ backgroundColor: '#F5F8FA', border: '1px solid #CBD6E2' }}
                value={formik.values.Services}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>

            </Grid>
          </>}

          <Grid item md={12} className='pt-0'>
            <label className={Style.labelform}>
              EPC Ratings <AstericSymbol />
              <Tooltip title={TooltipSpilitName("Enter the current and potential Energy Efficiency ratings for this property (eg 44 47). The system will generate an EPC graph based on these ratings. If available you can also add the current and potential Environmental Impact Ratings and these will also be displayed on the graph (eg 44 47 48 55).", 350)} placement="top">
                <img src={questionImg} alt="Info" />
              </Tooltip>
            </label>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="pt-0" >
            <div className="w-100">
              <TextInput
                name="epcValue"
                value={formik.values.epcValue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                customClass={Style.input_custom + ' w-100'}
                type="text"
                placeholder="EPC Value"
                size="small"
                disabled={formik.values.isExemptFromEPC ? true : false}
              />
              <ErrorMsg hasError={formik.touched.epcValue && formik.errors.epcValue} > {formik.errors.epcValue} </ErrorMsg>

            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="pt-0">

            <div className="w-100">
              <SelectInput
                name="epcRating"
                options={EPCRating}
                selectedValue={formik.values.epcRating}
                onChange={(e) => formik.setFieldValue('epcRating', e.target.value)}
                size="small"
                customClass={Style.custom_select}
                sx={{ backgroundColor: '#F5F8FA' }}
                placeholder={"Select"}
                disabled={formik.values.isExemptFromEPC ? true : false}
              />
              <ErrorMsg hasError={formik.touched.epcRating && formik.errors.epcRating} > {formik.errors.epcRating} </ErrorMsg>


            </div>

          </Grid>



          <Grid item xs={12} sm={12} md={12} className="pt-0">
            <FormGroup>
              <CommonCheckbox
                label="Is exempt from an EPC?"
                checked={formik.values.isExemptFromEPC}
                className={Style.label_value}
                onChange={handleCheckReason}
              />
            </FormGroup>
          </Grid>

          {propertyCategory === "commercial" || (propertyCategory === "residential" && listingType === "let") || (propertyCategory === "residential" && listingType === "sell") ? <>

            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <label className={Style.labelform}>
                EPC Exemption Reason
              </label>
              <TextInput
                name="epcExemptionReason"
                value={formik.values.epcExemptionReason}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                customClass={Style.input_custom + ' w-100'}
                type="text"
                placeholder="EPC Exemption Reason"
                size="small"
                disabled={formik.values.isExemptFromEPC ? false : true}
              />

            </Grid>
          </> :
            ""
          }
          {(propertyCategory === "residential" && listingType === "let") ? "" : <>

            <Grid item md={12} className='pt-0'>
              <label className={Style.labelform}>Offer Closing Date</label>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={Style.time_field + " pt-0 " + Style.date_field}>

              <DateInput
                name="offerClosingDate"
                value={formik.values.offerClosingDate}
                onChange={handleDateChange}
                customClass="small-input w-100"
                size="small"
                filterDate={new Date()}
              />
              <ErrorMsg hasError={formik.touched.offerClosingDate && formik.errors.offerClosingDate}> {formik.errors.offerClosingDate} </ErrorMsg>

            </Grid>
            <Grid item xs={12} sm={12} md={6} className={Style.time_field + " pt-0 "} >
              <TimePickerInput
                name="offerClosingDate"
                value={formik.values.offerClosingDate}
                onChange={(time) => formik.setFieldValue('offerClosingDate', time)}
                customClass="small-input w-100"
                size="small"
                style={{ width: '100%' }}
                cssValue="property"
                className="pt-0"
                minstep={"everyminute"}
                // Assuming your TimePickerInput accepts a minDate prop
                minDate={formik.values.offerClosingDate}
                placeholder="HH:mm"
              />
            </Grid>
          </>
          }


          <Grid item xs={12} sm={12} md={12} className="pt-0">
            <label className={Style.labelform}>
              Local Authority
              <Tooltip title={TooltipSpilitName("Select the local authority for this property. The list of local authorities can be managed under the Address Book (Suppliers) menu.", 150)} placement="top">
                <img src={questionImg} alt="Info" />
              </Tooltip>
            </label>
            <div className="w-100">
              <SelectWithSearch
                name="localAuthority"
                options={LocalAuthorityBusiness}
                value={formik.values.localAuthority}
                onChange={(e, obj) => {
                  formik.setFieldValue("localAuthority", obj);
                }}
                size={"small"}
                noOptionsText={"No branch found."}
                placeholder={"Search local authority"}
                className={Style.select_input}
              />

            </div>
          </Grid>
          {propertyCategory != "commercial" ? <>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <label className={Style.labelform}>
                Council Tax Band
                <Tooltip title={TooltipSpilitName("Valid bands between A-I / TBC / Exempt", 150)} placement="top">
                  <img src={questionImg} alt="Info" />
                </Tooltip>
              </label>
              <TextInput
                name="councilTax"
                value={formik.values.councilTax}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                customClass={Style.input_custom + ' w-100'}
                type="text"
                placeholder="Council Tax Band"
                size="small"
              />

            </Grid>

          </> : ""}

          <Grid item xs={12} sm={12} md={12} className="pt-0">
            <label className={Style.labelform}>Viewing Arrangements</label>
            <textarea
              rows="3"
              name="viewingAgreement"
              placeholder="Insert viewing agreement details.."
              className={Style.custom_textarea + " w-100"}
              style={{ backgroundColor: '#F5F8FA', border: '1px solid #CBD6E2' }}
              value={formik.values.viewingAgreement}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
          </Grid>

          <Grid item xs={12} sm={12} md={12} className="pt-0">
            <label className={Style.labelform}> Additional Information</label>
            <textarea
              rows="3"
              name="additionalInformation"
              placeholder="Any additional information.."
              className={Style.custom_textarea + " w-100"}
              style={{ backgroundColor: '#F5F8FA', border: '1px solid #CBD6E2' }}
              value={formik.values.additionalInformation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>

          </Grid>

          {propertyCategory != "commercial" ? "" : <>

            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <label className={Style.labelform}>Dashboard Note</label>
              <textarea
                rows="3"
                name="DashboardNote"
                placeholder="Dashboard Note.."
                className={Style.custom_textarea + " w-100"}
                style={{ backgroundColor: '#F5F8FA', border: '1px solid #CBD6E2' }}
                value={formik.values.DashboardNote}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>

            </Grid>
          </>}


          <Grid item xs={12} sm={12} md={12} className="pt-0">
            <label className={Style.labelform}>
              Current Occupant
              <Tooltip title={TooltipSpilitName("If the property does not have a current letting in the system, set the current occupant. Viewing alerts will be sent to this contact. If there is a current letting leave this field blank and by default alerts will be sent to the current tenant.", 250)} placement="top">
                <img src={questionImg} alt="Info" />
              </Tooltip>

            </label>
            <div className="w-100">
              <SelectInput
                name="currentOccupant"
                options={propertyCategory === "residential" && listingType === "let" ? optioncurrentLet : optioncurrentOccupant}
                selectedValue={formik.values.currentOccupant}
                onChange={(e) => {
                  const value = e.target.value;
                  // Prevent selecting "External Tenant"
                  if (value !== 'External Tenant') {
                    formik.setFieldValue('currentOccupant', value);
                  }
                }}
                size="small"
                customClass={Style.custom_select}
                sx={{ backgroundColor: '#F5F8FA' }}
                placeholder="Select "
              />
            </div>
          </Grid>

          {/* {(propertyCategory === "residential" && listingType === "let") || (propertyCategory === "commercial" && listingType === "let") ? <>
            <Grid item xs={12} sm={12} md={12}>
              <label className={Style.labelform}>
                Current Tenant
                <ButtonGroup
                  disableElevation
                  variant="outlined"
                  aria-label="On The Market Button Group"
                  sx={{
                    borderColor: '#C9CDDB',
                    borderWidth: '1.15px',
                    marginLeft: '15px'
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: formik.values.currentTenant === "Yes" ? '#DEE2E6' : '#f8f8f8',
                      borderColor: '#C9CDDB',
                      borderWidth: '1.15px',
                      textTransform: 'capitalize',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#C1C9D0',
                        borderColor: '#C9CDDB',
                      },
                    }}
                    onClick={() => formik.setFieldValue('currentTenant', 'Yes')} // Set value to Yes
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: formik.values.currentTenant === "No" ? '#DEE2E6' : '#f8f8f8',
                      borderColor: '#C9CDDB',
                      borderWidth: '1.15px',
                      textTransform: 'capitalize',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#C1C9D0',
                        borderColor: '#C9CDDB',
                      },
                    }}
                    onClick={() => formik.setFieldValue('currentTenant', 'No')} // Set value to No
                  >
                    No
                  </Button>
                </ButtonGroup>
              </label>
            </Grid>
          </> : ""} */}

          <Grid item xs={12} sm={12} md={12} className="pt-0" style={{ marginTop: '10px' }}>
            <hr />
            <Typography variant="h6" className={Style.listDetailsFont}>
              Property Description
            </Typography>

            <label className={Style.labelform}>
              Detailed Description  <AstericSymbol />
            </label>
            <textarea
              rows="4"
              name="propertyDescription"
              // className="form-control"
              placeholder="Enter the detailed property description here..."
              value={formik.values.propertyDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={Style.custom_textarea + " w-100 "}
              style={{ backgroundColor: '#F5F8FA', border: '1px solid #CBD6E2' }}
            ></textarea>

            <ErrorMsg hasError={formik.touched.propertyDescription && formik.errors.propertyDescription} > {formik.errors.propertyDescription} </ErrorMsg>

          </Grid>
        </Grid>
        <hr />
        <div className={Style.propertycss1 + " " + Style.button_nav}>
          <div style={{ marginRight: '12px' }}>
            <Button color="secondary"
              className={Style.delete_btn + " " + Style.edit_btn} onClick={handleBackButton}>
              <KeyboardArrowLeftIcon /> Back
            </Button>
          </div>
          <div>
            <ButtonLoader
              type={"button"}
              buttonName="Next"
              loaderColor={"white"}
              className={Style.Add_btn}
              onClickhandle={formik.handleSubmit}

              icon={<KeyboardArrowRightIcon />}

            />

          </div>
        </div>
      </div>
    </form>
  );
};

export default PropertyDescription;
